import { gql } from "@apollo/client";
import { tz } from "@date-fns/tz";
import {
  endOfISOWeek,
  endOfMonth,
  isThisYear,
  startOfISOWeek,
  startOfMonth,
} from "date-fns";
import { capitalize } from "lodash-es";
import { memo } from "react";

import type { ArticlePublishDate_articleFragment } from "@/gql-types";
import { DateLike, formatForParis } from "@/services/time-formatter";

export const formatDate = (date: DateLike) => {
  if (isThisYear(date)) {
    return capitalize(formatForParis(date, "eee d MMM"));
  }
  return formatForParis(date, "dd/MM/yyyy");
};

export const formatRangeDate = (date: DateLike, type: "from" | "to") => {
  if (isThisYear(date)) {
    return formatForParis(date, "d MMM");
  }
  if (type === "from") {
    return formatForParis(date, "dd/MM");
  }
  return formatForParis(date, "dd/MM/yyyy");
};

export type SpreadArticlePublishDateProps = ArticlePublishDate_articleFragment;

export const SpreadArticlePublishDate = memo<SpreadArticlePublishDateProps>(
  (props) => {
    if (props.initialFirstPublished) {
      return <>{formatDate(props.initialFirstPublished)}</>;
    }
    if (!props.planning?.date) {
      return <div className="text-grey-on">Non daté</div>;
    }

    if (
      props.planning?.range &&
      ["week", "month"].includes(props.planning.range)
    ) {
      const range = props.planning.range as "week" | "month";

      const startOf = range === "week" ? startOfISOWeek : startOfMonth;
      const endOf = range === "week" ? endOfISOWeek : endOfMonth;

      const startDate = formatRangeDate(
        startOf(props.planning.date, { in: tz("Europe/Paris") }),
        "from",
      );
      const endDate = formatRangeDate(
        endOf(props.planning.date, { in: tz("Europe/Paris") }),
        "to",
      );

      return (
        <>
          {startDate} <span className="font-sans">→</span> {endDate}
        </>
      );
    }
    return <>{formatDate(props.planning.date)}</>;
  },
);

export type ArticlePublishDateProps = {
  article: SpreadArticlePublishDateProps;
};

export const ArticlePublishDate = (props: ArticlePublishDateProps) => {
  return <SpreadArticlePublishDate {...props.article} />;
};

ArticlePublishDate.fragments = {
  article: gql`
    fragment ArticlePublishDate_article on Article {
      initialFirstPublished
      planning {
        date
        range
      }
    }
  `,
};
