import { Content } from "@tiptap/core";
import { Editor } from "@tiptap/react";
import deepEqual from "deep-equal";
import { useEffect } from "react";

export function useEditorContentSync({
  editor,
  value = null,
  parse = (v) => v,
  format = (v) => v,
}: UseEditorContentSyncProps) {
  useEffect(() => {
    if (!editor || editor.isDestroyed || editor.isFocused) return;
    // If editor has focus, then we ignore input value
    // this component cannot be controlled while the user is editing.
    const internalValue = parse(editor);
    if (deepEqual(internalValue, value)) return;
    editor.commands.setContent(format(value));
  }, [editor, format, parse, value]);
}

export interface UseEditorContentSyncProps<TValue = any> {
  editor: Editor | null;
  value: TValue;
  parse: (editor: Editor) => TValue;
  format: (value: TValue) => Content;
}
