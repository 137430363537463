import type { Node } from "@tiptap/pm/model";

export const convertTextOffset = (doc: Node, textOffset: number): number => {
  let pos: number | undefined;
  let firstBlock = true;
  let remaining = textOffset;
  doc.descendants((node, nodePos) => {
    if (pos != null) return;
    if (node.isBlock) {
      if (!firstBlock) {
        remaining -= 2; // Consume line breaks
      }
      firstBlock = false;
      return;
    }
    if (!node.isText || !node.text) return;
    if (node.text.length < remaining) {
      remaining -= node.text.length;
    } else {
      pos = nodePos + remaining;
    }
  });
  return pos ?? 0;
};
