import { textInputRule, textPasteRule } from "@tiptap/core";

export const inputRule = () =>
  textInputRule({
    find: /'$/,
    replace: "’",
  });

export const pasteRule = () =>
  textPasteRule({
    find: /'/g,
    replace: "’",
  });
