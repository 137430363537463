import createEditorControl from "@/components/editor/controls/createEditorControl";
import { Bold } from "@/components/icons";

export const BoldControl = createEditorControl({
  name: "bold",
  label: "Gras",
  shortcut: ["⌘", "b"],
  icon: <Bold />,
  command: ({ editor }) => editor.chain().focus().toggleBold().run(),
  canExecute: ({ editor }) => editor.can().toggleBold(),
});
