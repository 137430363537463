import createEditorControl from "@/components/editor/controls/createEditorControl";
import { OrderedList } from "@/components/icons";

export const OrderedListControl = createEditorControl({
  name: "orderedList",
  label: "Liste ordonnée",
  shortcut: ["⌘", "o"],
  icon: <OrderedList />,
  command: ({ editor }) => editor.chain().focus().toggleOrderedList().run(),
  canExecute: ({ editor }) => editor.can().toggleOrderedList(),
});
