// @ts-check
import { useEffect, useMemo } from "react";
import { useForm, useFormState } from "react-final-form";
import { useLiveRef } from "swash/utils/useLiveRef";
import { useDebouncedCallback } from "use-debounce";

import { useActivateForm } from "./CollaborativeFormState";
import { useDirtyFieldsRef } from "./FormDirtyFields";

/**
 * Checks if an element is a text box (input, textarea, draft or TipTap editor).
 * @param {Element | null} element - The DOM element to check.
 * @returns {boolean} True if the element is a text box, false otherwise.
 */
const isTextBox = (element) =>
  Boolean(
    element instanceof HTMLElement &&
      // draft-js editor
      (element.getAttribute("role") === "textbox" ||
        // input[type=text]
        (element.tagName === "INPUT" &&
          element.getAttribute("type") === "text") ||
        // textarea
        element.tagName === "TEXTAREA" ||
        // tiptap editor
        element.getAttribute("contenteditable") === "true"),
  );

export const useFormAutoSubmitState = () => {
  const {
    valid,
    hasValidationErrors,
    submitting,
    dirty,
    submitSucceeded,
    submitFailed,
    modifiedSinceLastSubmit,
    dirtyFields,
    dirtyFieldsSinceLastSubmit,
    active,
  } = useFormState({
    subscription: {
      hasValidationErrors: true,
      valid: true,
      submitting: true,
      dirty: true,
      submitSucceeded: true,
      submitFailed: true,
      dirtyFields: true,
      modifiedSinceLastSubmit: true,
      dirtyFieldsSinceLastSubmit: true,
      active: true,
    },
  });

  const submitted = submitFailed || submitSucceeded;

  const activeTextBox = useMemo(
    () => active && isTextBox(document.activeElement),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [document.activeElement],
  );

  return {
    valid,
    submitting,
    mustSubmit:
      !hasValidationErrors && (submitted ? modifiedSinceLastSubmit : dirty),
    dirtyFields:
      // If the form has been submitted successfully, we want to use the dirty fields since the last submit
      modifiedSinceLastSubmit && submitSucceeded
        ? dirtyFieldsSinceLastSubmit
        : dirtyFields,
    activeTextBox,
  };
};

/**
 * @typedef FormAutoSubmitProps
 * @property {number} [debounceDelay]
 * @property {(form: import('final-form').FormApi) => void} [onAutoSubmit]
 */

/** @type {import('react').FC<FormAutoSubmitProps>}  */
export const FormAutoSubmit = ({ onAutoSubmit, debounceDelay = 500 }) => {
  const onAutoSubmitRef = useLiveRef(onAutoSubmit);
  const dirtyFieldsRef = useDirtyFieldsRef();
  const form = useForm();
  const activateForm = useActivateForm();
  const { submitting, mustSubmit, dirtyFields, activeTextBox } =
    useFormAutoSubmitState();
  const dirtyRef = useLiveRef(mustSubmit && !submitting);

  const debouncedSubmit = useDebouncedCallback(
    () => {
      if (dirtyFieldsRef) {
        dirtyFieldsRef.current = dirtyFields;
      }
      if (onAutoSubmitRef.current) {
        onAutoSubmitRef.current(form);
      }
      // @ts-expect-error final-form does not take any argument to submit but we took one to disable the focus-on-error decorator
      form.submit({ focusOnError: false });
    },
    debounceDelay,
    { maxWait: 10000, leading: true },
  );

  useEffect(() => {
    const active = mustSubmit || submitting;
    activateForm(active);
  }, [activateForm, mustSubmit, submitting]);

  // Only run effect when a value has changed
  useEffect(() => {
    if (mustSubmit && !submitting && !activeTextBox) {
      debouncedSubmit();
    }
  }, [form, debouncedSubmit, mustSubmit, submitting, activeTextBox]);

  useEffect(
    () => () => {
      if (dirtyRef.current) {
        debouncedSubmit.flush();
      }
    },
    [debouncedSubmit, dirtyRef],
  );

  return null;
};
