import { Node } from "@tiptap/pm/model";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

export class InvisibleCharacterBuilder {
  priority: number;
  createDecoration: (
    from: number,
    to: number,
    doc: Node,
    current: DecorationSet,
  ) => DecorationSet;

  constructor(config: {
    priority: number;
    createDecoration(
      from: number,
      to: number,
      doc: Node,
      current: DecorationSet,
    ): DecorationSet;
  }) {
    this.priority = config.priority;
    this.createDecoration = config.createDecoration;
  }
}

const makeInvisibleCharacterDecoration = (
  pos: number,
  key: string,
  content?: string,
) => {
  const toDom = () => {
    const element = document.createElement("span");
    element.classList.add("Tiptap-invisible-character");
    element.dataset["type"] = key;
    if (content) element.textContent = content;
    return element;
  };
  return Decoration.widget(pos, toDom, { key, marks: [], side: 1e3 });
};

const defaultPosition = (node: Node, pos: number) => pos + node.nodeSize - 1;

export const invisibleNode = ({
  predicate,
  type,
  position = defaultPosition,
  content,
  priority = 100,
}: {
  predicate: (node: Node) => boolean;
  type: string;
  position?: (node: Node, pos: number) => number;
  content?: string;
  priority?: number;
}) => {
  return new InvisibleCharacterBuilder({
    priority,
    createDecoration: (from, to, doc, prev) => {
      let next = prev;
      doc.nodesBetween(from, to, (node, nodePos) => {
        if (predicate(node)) {
          const pos = position(node, nodePos);
          const decorations = next.find(pos, pos, (spec) => spec.key === type);
          next = next
            .remove(decorations)
            .add(doc, [makeInvisibleCharacterDecoration(pos, type, content)]);
        }
      });
      return next;
    },
  });
};

const getTextNodeContents = (from: number, to: number, doc: Node) => {
  const texts: { pos: number; text: string }[] = [];
  doc.nodesBetween(from, to, (node, pos) => {
    if (node.isText) {
      const offset = Math.max(from, pos) - pos;
      texts.push({
        pos: pos + offset,
        text: node.text?.slice(offset, to - pos) || "",
      });
    }
  });
  return texts;
};

export const invisibleCharacter = ({
  predicate,
  type,
  content,
  priority = 100,
}: {
  predicate: (value: string) => boolean;
  type: string;
  content?: string;
  priority?: number;
}) => {
  return new InvisibleCharacterBuilder({
    priority,
    createDecoration: (from, to, doc, prev) => {
      return getTextNodeContents(from, to, doc).reduce(
        (curr, { text, pos }) =>
          text
            .split("")
            .reduce(
              (next, char, index) =>
                predicate(char)
                  ? next.add(doc, [
                      makeInvisibleCharacterDecoration(
                        pos + index,
                        type,
                        content,
                      ),
                    ])
                  : next,
              curr,
            ),
        prev,
      );
    },
  });
};
