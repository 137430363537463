import { gql, useMutation } from "@apollo/client";
import { FORM_ERROR } from "final-form";
import { useMemo, useRef, useState } from "react";
import { useField, useForm } from "react-final-form";
import { ImUpload3 } from "react-icons/im";
import { Button } from "swash/Button";
import { Dialog } from "swash/Dialog";
import { PageLoader } from "swash/Loader";
import { PanelBody, PanelFooter, PanelHeader } from "swash/Panel";
import { useStoreState } from "swash/utils/useStoreState";

import { Image } from "@/components/Image";
import { FileDrop } from "@/components/controls/FileDrop";
import { Form, extractGraphQlErrors } from "@/components/forms/Form";
import { FormErrorToaster } from "@/components/forms/FormError";
import { FormSubmit } from "@/components/forms/FormSubmit";
import { ERRORS } from "@/config/messages";
import { StringField } from "@/containers/admin/CRUD";

export const EditorImageFragment = gql`
  fragment EditorImageFragment on Image {
    id
    credit
    caption
    fixed(width: 200, height: 200) {
      ...Image_fixed
    }
  }

  ${Image.fragments.fixed}
`;

const EditorCreateImageMutation = gql`
  mutation EditorCreateImageMutation($input: CreateImageInput!) {
    createImage(input: $input) {
      ...EditorImageFragment
    }
  }

  ${EditorImageFragment}
`;

const ImageUploaderField = ({ name }) => {
  const field = useField(name, {
    validate: (value) => {
      if (!value) return "Requis";
    },
    parse: (v) => v,
    format: (v) => v,
  });
  const form = useForm();
  const dropzoneRef = useRef();
  const openDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };
  const [createImage, { loading, error: mutationError }] = useMutation(
    EditorCreateImageMutation,
  );
  const [rejectedErrors, setRejectedErrors] = useState([]);
  const errorMessages = useMemo(() => {
    // Compute errors from rejectedErrors and mutationErrors
    if (mutationError && rejectedErrors.length === 0) {
      const graphQLErrors = extractGraphQlErrors(mutationError);
      return [
        Object.keys(graphQLErrors).length === 0
          ? ERRORS.upload.bucketStorage
          : graphQLErrors[FORM_ERROR],
      ];
    }
    const [file, ...errors] = rejectedErrors;
    return errors.map((error) => {
      switch (error.code) {
        case "file-invalid-type":
          return ERRORS.upload.unallowedMimeType(file.type);
        case "file-too-large":
          return ERRORS.upload.sizeTooLarge;
        default:
          return ERRORS.upload.bucketStorage;
      }
    });
  }, [rejectedErrors, mutationError]);

  if (loading) return <PageLoader />;
  return (
    <div>
      <div className="mx-auto mb-2 w-3/5">
        <FileDrop
          ref={dropzoneRef}
          required
          selfValidation={false}
          {...field.input}
          invalid={field.meta.touched && field.meta.invalid}
          onReject={({ errors, file }) => {
            setRejectedErrors([file, ...errors]);
          }}
          onChange={(file) => {
            if (file) {
              setRejectedErrors([]);
              createImage({ variables: { input: { file } } }).then(
                ({ data: { createImage } }) => {
                  field.input.onChange({
                    id: createImage.id,
                    fixed: createImage.fixed,
                  });
                  form.batch(() => {
                    form.change("description", createImage.caption || "");
                    form.change("source", createImage.credit || "");
                  });
                },
              );
            }
          }}
          accept={{ "image/*": [".jpeg", ".jpg", ".png"] }}
          noClick
          noKeydown
        >
          <div className="p-4 font-bold">
            {field.input.value ? (
              <>
                <Image {...field.input.value.fixed} className="mb-2" />
                <Button
                  type="button"
                  onClick={(event) => {
                    event.stopPropagation();
                    field.input.onChange(null);
                    // clear fields
                    form.batch(() => {
                      form.change("description", "");
                      form.change("source", "");
                    });
                  }}
                >
                  Supprimer l’image
                </Button>
              </>
            ) : (
              <div className="flex w-full flex-col items-center justify-center">
                <div className="flex flex-row justify-around">
                  <ImUpload3 size={24} />
                  <div className="ml-1 flex flex-col">
                    <span>Faire glisser une image ici</span>
                    <span fontWeight="normal">JPEG, PNG</span>
                  </div>
                </div>
                <Button onClick={openDialog} className="mt-4">
                  Sélectionner l’image à importer
                </Button>
              </div>
            )}
          </div>
        </FileDrop>
      </div>
      <ul className="text-danger-on">
        {errorMessages.map((message) => (
          <li key={message}>{message}</li>
        ))}
      </ul>
    </div>
  );
};

export function ImportImageDialog({ onSubmit, store }) {
  const title = "Importer une image";
  const open = useStoreState(store, "open");
  return (
    <Dialog store={store}>
      {open && (
        <Form
          aria-label={title}
          initialValues={{ description: "", source: "", image: null }}
          onSubmit={onSubmit}
        >
          <PanelHeader title={title} onClose={store.hide} />
          <PanelBody>
            <div className="flex flex-col gap-4">
              <ImageUploaderField name="image" />
              <StringField
                name="description"
                label="Description"
                placeholder="Paris, France le 22 Mai 2021 : Devant le Panthéon, un groupe de personnes récitent du Victor Hugo"
                multiline
                rich
                rows={2}
              />
              <StringField
                name="source"
                label="Source"
                placeholder="Robert Doisneau"
                rich
              />
            </div>
          </PanelBody>
          <PanelFooter>
            <FormErrorToaster />
            <Button
              type="button"
              variant="secondary"
              appearance="text"
              onClick={store.hide}
            >
              Annuler
            </Button>
            <FormSubmit>Importer l’image</FormSubmit>
          </PanelFooter>
        </Form>
      )}
    </Dialog>
  );
}
