import { Editor } from "@tiptap/core";
import { useEditorState } from "@tiptap/react";
import { PropsWithoutRef, ReactNode, forwardRef, useMemo } from "react";
import { useEditorContext } from "swash/editor";
import { ToolbarItem, ToolbarItemProps } from "swash/editor/components/Toolbar";
import { useEventCallback } from "swash/utils/useEventCallback";

import { generateShortcut } from "@/services/shortcuts";

type EditorControlProps<TProps> = Omit<
  PropsWithoutRef<ControlProps<TProps>>,
  "shortcut"
> & {
  editor: Editor;
};

interface EditorControlOptions<TProps> {
  name: string;
  label?: string;
  shortcut?: string[];
  exclude?: string[];
  icon?: ReactNode | ((props: EditorControlProps<TProps>) => ReactNode);
  command: (props: EditorControlProps<TProps>) => void;
  isActive?: (props: EditorControlProps<TProps>) => boolean;
  canExecute: (props: EditorControlProps<TProps>) => boolean;
}

const createEditorControl = <TProps,>({
  exclude = ["title", "chapo"],
  ...options
}: EditorControlOptions<TProps>) => {
  return forwardRef<HTMLButtonElement, ControlProps<TProps>>(
    ({ shortcut: shortcutProp, ...props }, ref) => {
      const { editor } = useEditorContext();
      const editorState = useEditorState({
        editor,
        selector: ({ editor }) => {
          if (!editor) return null;
          const active = options.isActive
            ? options.isActive({ editor, ...props })
            : editor.isActive(options.name);
          const disabled =
            !options.canExecute({ editor, ...props }) ||
            exclude.some((name) => editor.isActive(name));
          return { active, disabled };
        },
      });

      const handleClick = useEventCallback(() => {
        if (!editor) return;
        options.command({ editor, ...props });
      });

      const shortcut = useMemo(
        () => generateShortcut(options.shortcut || shortcutProp),
        [shortcutProp],
      );

      if (!editor) return null;

      return (
        <ToolbarItem
          ref={ref}
          label={options.label}
          shortcut={shortcut}
          active={editorState?.active ?? false}
          disabled={editorState?.disabled ?? true}
          onClick={handleClick}
          {...props}
        >
          {typeof options.icon === "function"
            ? options.icon({ editor, ...props })
            : options.icon}
        </ToolbarItem>
      );
    },
  );
};

type ControlProps<T> = Omit<ToolbarItemProps, "shortcut"> &
  T & {
    shortcut?: string[];
  };

export default createEditorControl;
