import styled from "@xstyled/styled-components";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "swash/Button";
import { EllipsisVertical } from "swash/Icon";
import { Popover, PopoverDisclosure, usePopoverStore } from "swash/Popover";
import { useLiveRef } from "swash/utils/useLiveRef";
import { useStoreState } from "swash/utils/useStoreState";

import { Separator } from "../Separator";
import { RichFieldEditorToolbarProvider } from "./RichEditorToolbar";

export const RichFieldCommands = ({ lockFocus, ...props }) => {
  const { readOnly, hasFocus, plugins, anchorBlock } = props;
  const propsRef = useLiveRef(props);
  const handleKeyCommand = useCallback(
    (command, eventTimeStamp) => {
      const { handleKeyCommand, editorState } = propsRef.current;
      handleKeyCommand(command, editorState, eventTimeStamp);
    },
    [propsRef],
  );
  const isAtomicAnchorBlock = anchorBlock?.getType() === "atomic";
  const value = {
    readOnly,
    hasFocus,
    handleKeyCommand,
    plugins,
    isAtomicAnchorBlock,
  };
  const commands = useMemo(
    () =>
      plugins?.reduce(
        (pluginCommands, { ButtonCommand, ListItem, label }) =>
          ButtonCommand
            ? [...pluginCommands, { label, ButtonCommand, ListItem }]
            : pluginCommands,
        [],
      ),
    [plugins],
  );

  const popover = usePopoverStore({
    placement: "bottom-end",
  });

  return (
    <RichFieldEditorToolbarProvider value={value}>
      <RichFieldButton
        popover={popover}
        lockFocus={lockFocus}
        readOnly={readOnly}
      />
      <RichFieldButtonPopover
        commands={commands}
        popover={popover}
        lockFocus={lockFocus}
        {...props}
      />
    </RichFieldEditorToolbarProvider>
  );
};

const RichFieldButton = ({ lockFocus, readOnly, popover }) => {
  const open = useStoreState(popover, "open");
  useEffect(() => {
    if (open) {
      lockFocus();
    }
  }, [open, lockFocus]);
  return (
    <PopoverDisclosure
      store={popover}
      render={<Button />}
      variant="secondary"
      appearance="text"
      aria-label="More"
      scale="xs"
      iconOnly
      disabled={readOnly}
    >
      <EllipsisVertical />
    </PopoverDisclosure>
  );
};
const RichFieldButtonPopover = ({
  commands,
  popover,
  unlockFocus,
  ...props
}) => {
  const [actionOpen, setActionOpen] = useState(false);

  return (
    <Popover
      store={popover}
      portal
      aria-label="More commands"
      hidden={actionOpen || undefined}
    >
      {commands?.map(({ label, ButtonCommand }, i) => (
        <List key={label}>
          {i > 0 && <Separator />}
          <ButtonCommand
            key={label}
            {...props}
            unlockFocus={() => {
              popover.hide();
              setTimeout(() => unlockFocus(), 0);
            }}
            rich
            disabledWithoutFocus={false}
            as={ListItem}
            menuPopover={popover}
            onOpenAction={setActionOpen}
          />
        </List>
      ))}
    </Popover>
  );
};

const ListItem = forwardRef(({ children, label, order, ...props }, ref) => {
  return (
    <Item ref={ref} {...props}>
      <IconContainer>{children}</IconContainer>
      {label}
    </Item>
  );
});

const HEIGHT = 36;

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  width: auto;
  border: none;
  align-items: center;
  height: ${HEIGHT}px;
  padding: 0 3 0 2;
  gap: 2;

  &:focus {
    box-shadow: none;
  }
`;

const IconContainer = styled.box`
  display: flex;
  align-items: center;
  font-family: accent;
  font-style: normal;
  font-weight: 400;
  font-size: base;
  line-height: 16px;

  > svg {
    width: 20px;
    height: 20px;
  }
`;

const List = styled.div`
  &:hover {
    outline: none;
    background-color: primary-dark;
    color: white;
  }
  &:first-of-type {
    border-top-left-radius: base;
    border-top-right-radius: base;
  }
  &:last-of-type {
    border-bottom-left-radius: base;
    border-bottom-right-radius: base;
  }
`;
