import { makeIcon } from "./utils";

export const SvgSuperscript = makeIcon(
  "SvgSuperscript",
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.054 12a.5.5 0 0 1-.5-.5V6.94l-.752.672a.5.5 0 0 1-.694-.027l-.019-.02a.5.5 0 0 1 .007-.7l1.466-1.468a.5.5 0 0 1 .354-.147h.334a.5.5 0 0 1 .5.5v5.75a.5.5 0 0 1-.5.5h-.196Z"
      fill="currentColor"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      d="M13.5 6H6M10.125 6v12"
    />
  </svg>,
);
