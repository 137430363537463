import * as React from "react";

import {
  Select,
  SelectClear,
  SelectPopover,
  SelectStore,
  useSelectStore,
} from "../Select";
import { useLiveRef } from "../utils/useLiveRef";
import {
  RichSelectFooter,
  RichSelectItem,
  RichSelectScale,
  RichSelectState,
  RichSelectStateProps,
  RichSelectValue,
  useRichSelectState,
} from "./RichSelect";

export interface EnumSelectStateProps<
  TItem,
  TValue extends TItem | TItem[] | null,
> extends RichSelectStateProps<TItem, TValue> {
  items: TItem[];
}

export interface EnumSelectState<TItem> extends RichSelectState<TItem> {
  select: SelectStore;
  items: TItem[];
}

export const useEnumSelectState = <
  TItem extends object | string,
  TValue extends TItem | TItem[] | null,
>(
  props: EnumSelectStateProps<TItem, TValue>,
): EnumSelectState<TItem> => {
  const rich = useRichSelectState(props);
  const { labelSelector, valueSelector } = rich;
  const refs = useLiveRef({
    valueSelector,
    labelSelector,
    onChange: props.onChange,
    items: props.items,
  });

  const getSelected = React.useCallback(
    (value: string | string[]) => {
      const { valueSelector, items } = refs.current;
      if (value === "") {
        return null;
      } else if (Array.isArray(value)) {
        return items.filter((item) => value.includes(valueSelector(item)));
      } else {
        return items.find((item) => valueSelector(item) === value) ?? null;
      }
    },
    [refs],
  );

  const value = React.useMemo(() => {
    const { valueSelector } = refs.current;
    if (props.value === null) return "";
    if (Array.isArray(props.value)) {
      return props.value.map((v) => valueSelector(v));
    }
    return valueSelector(props.value as TItem);
  }, [props.value, refs]);

  const setValue = React.useCallback(
    (value: string | string[]) => {
      const { onChange } = refs.current;
      const selected = getSelected(value);
      onChange(selected as TValue);
    },
    [refs, getSelected],
  );

  const select = useSelectStore({
    value,
    setValue,
  });

  return {
    ...rich,
    select,
    items: props.items,
  };
};

export interface EnumSelectListProps<TItem> {
  state: EnumSelectState<TItem>;
  "aria-label"?: string;
}

export const EnumSelectList = <TItem,>(props: EnumSelectListProps<TItem>) => {
  const {
    state: { items, emptyMessage },
  } = props;
  return (
    <>
      {items.length ? (
        items.map((item, i) => {
          return <RichSelectItem key={i} state={props.state} item={item} />;
        })
      ) : (
        <div className="p-2 text-sm text-gray-500">{emptyMessage}</div>
      )}
    </>
  );
};

export interface EnumSelectProps<TItem> {
  state: EnumSelectState<TItem>;
  placeholder?: string;
  placeholderIcon?: React.ReactNode;
  "aria-label"?: string;
  "aria-invalid"?: boolean;
  clearLabel?: string;
  gutter?: number;
  disabled?: boolean;
  scale?: RichSelectScale;
  modal?: boolean;
  className?: string;
}

const InnerEnumSelect = <TItem,>(
  props: EnumSelectProps<TItem>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) => {
  return (
    <>
      <Select
        ref={ref}
        store={props.state.select}
        scale={props.scale}
        disabled={props.disabled}
        className={props.className}
        aria-label={props["aria-label"]}
        aria-invalid={props["aria-invalid"]}
      >
        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
          <RichSelectValue
            state={props.state}
            placeholder={props.placeholder}
            placeholderIcon={props.placeholderIcon}
          />
        </div>
        <SelectClear
          store={props.state.select}
          clearable={!props.state.required}
          aria-label={props.clearLabel}
        />
      </Select>
      <SelectPopover
        store={props.state.select}
        gutter={props.gutter}
        modal={props.modal}
        aria-label={props["aria-label"]}
        unmountOnHide
      >
        <EnumSelectList state={props.state} />
        {props.state.footer && <RichSelectFooter footer={props.state.footer} />}
      </SelectPopover>
    </>
  );
};

export const EnumSelect = React.forwardRef(InnerEnumSelect) as <TItem>(
  props: EnumSelectProps<TItem> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  },
) => ReturnType<typeof InnerEnumSelect>;
