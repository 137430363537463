/**
 * @see https://github.com/ProseMirror/prosemirror-keymap/blob/1.2.2/src/keymap.ts
 */
import { useEffect } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";
import { base, keyName } from "w3c-keyname";

const mac =
  typeof navigator != "undefined"
    ? /Mac|iP(hone|[oa]d)/.test(navigator.platform)
    : false;

const normalizeKeyName = (name: string) => {
  const parts = name.split(/-(?!$)/);
  let result = parts[parts.length - 1]!;
  if (result == "Space") result = " ";
  let alt, ctrl, shift, meta;
  for (let i = 0; i < parts.length - 1; i++) {
    const mod = parts[i]!;
    if (/^(cmd|meta|m)$/i.test(mod)) meta = true;
    else if (/^a(lt)?$/i.test(mod)) alt = true;
    else if (/^(c|ctrl|control)$/i.test(mod)) ctrl = true;
    else if (/^s(hift)?$/i.test(mod)) shift = true;
    else if (/^mod$/i.test(mod)) {
      if (mac) meta = true;
      else ctrl = true;
    } else throw new Error("Unrecognized modifier name: " + mod);
  }
  if (alt) result = "Alt-" + result;
  if (ctrl) result = "Ctrl-" + result;
  if (meta) result = "Meta-" + result;
  if (shift) result = "Shift-" + result;
  return result;
};

const modifiers = (name: string, event: KeyboardEvent, shift = true) => {
  if (event.altKey) name = "Alt-" + name;
  if (event.ctrlKey) name = "Ctrl-" + name;
  if (event.metaKey) name = "Meta-" + name;
  if (shift && event.shiftKey) name = "Shift-" + name;
  return name;
};

/**
 * Key names may be strings like `"Shift-Ctrl-Enter"`—a key
 * identifier prefixed with zero or more modifiers. Key identifiers
 * are based on the strings that can appear in
 * [`KeyEvent.key`](https:///developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key).
 * Use lowercase letters to refer to letter keys (or uppercase letters
 * if you want shift to be held). You may use `"Space"` as an alias
 * for the `" "` name.
 *
 * Modifiers can be given in any order. `Shift-` (or `s-`), `Alt-` (or
 * `a-`), `Ctrl-` (or `c-` or `Control-`) and `Cmd-` (or `m-` or
 * `Meta-`) are recognized. For characters that are created by holding
 * shift, the `Shift-` prefix is implied, and should not be added
 * explicitly.
 *
 * You can use `Mod-` as a shorthand for `Cmd-` on Mac and `Ctrl-` on
 * other platforms.
 */
export const useShortcut = (
  key: string,
  callback: (event: KeyboardEvent) => void,
  options: AddEventListenerOptions = {},
) => {
  const stableCallback = useLiveRef(callback);
  const { capture, once, passive, signal } = options;
  const pattern = normalizeKeyName(key);

  useEffect(() => {
    const cb = (event: KeyboardEvent) => {
      const name = keyName(event);
      const modified = modifiers(name, event);
      if (modified === pattern) {
        return stableCallback.current(event);
      }

      // A character key
      if (name.length == 1 && name != " ") {
        if (event.shiftKey) {
          // In case the name was already modified by shift, try looking
          // it up without its shift modifier
          const modifiedNoShift = modifiers(name, event, false);
          if (modifiedNoShift === pattern) {
            return stableCallback.current(event);
          }
        }

        if (
          event.shiftKey ||
          event.altKey ||
          event.metaKey ||
          name.charCodeAt(0) > 127
        ) {
          const baseName = base[event.keyCode]!;
          if (baseName != name) {
            // Try falling back to the keyCode when there's a modifier
            // active or the character produced isn't ASCII, and our table
            // produces a different name from the the keyCode. See #668,
            // #1060
            const modifiedFromCode = modifiers(baseName, event);
            if (modifiedFromCode === pattern) {
              return stableCallback.current(event);
            }
          }
        }
      }
    };

    const options = { capture, once, passive, signal };
    document.addEventListener("keydown", cb, options);
    return () => {
      document.removeEventListener("keydown", cb);
    };
  }, [pattern, stableCallback, capture, once, passive, signal]);
};
