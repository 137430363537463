import { gql } from "@apollo/client";

import {
  CardListItem,
  CardListItemEditDisclosure,
} from "@/components/CardListItem";
import { useDragUrl } from "@/containers/Dnd";
import { useLayoutMode } from "@/containers/search/LayoutModeContext";
import { RemoteEditSnippetDialogDisclosure } from "@/containers/snippet/SnippetEditDialog";
import { SnippetPreview } from "@/containers/snippet/SnippetPreview";
import { SnippetSummary } from "@/containers/snippet/SnippetSummary";

const SnippetCardNoEdit = ({ snippet }) => {
  const layoutMode = useLayoutMode();
  return (
    <CardListItem
      variant={snippet.obsolete ? "danger" : "secondary"}
      layoutMode={layoutMode}
    >
      <SnippetPreview />
      <SnippetSummary snippet={snippet} />
    </CardListItem>
  );
};

export const SnippetCard = ({ snippet, editable = false }) => {
  const snippetId = snippet.id;
  const [, dragRef] = useDragUrl(
    () => new URL(`/snippets/${snippetId}`, window.location.origin).href,
  );
  const layoutMode = useLayoutMode();

  if (!editable) return <SnippetCardNoEdit snippet={snippet} />;
  return (
    <CardListItem
      ref={dragRef}
      variant={snippet.obsolete && "danger"}
      layoutMode={layoutMode}
    >
      <CardListItemEditDisclosure
        title="Éditer le snippet"
        snippetId={snippetId}
        as={RemoteEditSnippetDialogDisclosure}
      />
      <SnippetPreview snippet={snippet} />
      <SnippetSummary snippet={snippet} />
    </CardListItem>
  );
};

SnippetCard.fragments = {
  snippet: gql`
    fragment SnippetCard_snippet on Snippet {
      id
      obsolete
      ...SnippetPreview_snippet
      ...SnippetSummary_snippet__search
    }
    ${SnippetPreview.fragments.snippet}
    ${SnippetSummary.fragments.snippet__search}
  `,
};
