import { memo, useEffect } from "react";
import { Button } from "swash/Button";
import { IoPencil } from "swash/Icon";
import { PanelBody, PanelFooter } from "swash/Panel";
import { Popover, PopoverDisclosure, usePopoverStore } from "swash/Popover";
import { useLiveRef } from "swash/utils/useLiveRef";
import { usePrevious } from "swash/utils/usePrevious";
import { useStoreState } from "swash/utils/useStoreState";

import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { useTextInputField } from "@/components/fields/TextInputField";
import { Form } from "@/components/forms/Form";
import { FormSubmit } from "@/components/forms/FormSubmit";
import {
  EditorBlockCapsuleToolbarButton,
  muteEditorEvents,
} from "@/components/teleporters/EditorBlockCapsule";
import { SimpleTextEditorField } from "@/containers/fields/SimpleTextEditorField";

const CaptionField = () => {
  const field = useTextInputField("caption", {
    parse: (v) => v,
    format: (v) => v,
  });

  return (
    <FieldGroup {...field}>
      <FieldLabel {...field}>Légende de l’image dans le live</FieldLabel>
      <SimpleTextEditorField {...field} w={1} multiline />
    </FieldGroup>
  );
};

const EditCaptionPopover = ({ onSubmit, initialValues, store, open }) => {
  return (
    <Popover
      modal
      store={store}
      {...muteEditorEvents()}
      aria-label="Édition de la légende de l’image"
      style={{ width: 500 }}
    >
      {open && (
        <Form
          onSubmit={(data) => {
            onSubmit(data);
            setTimeout(() => store.hide());
          }}
          initialValues={initialValues}
        >
          <PanelBody>
            <Fieldset>
              <FieldsetField>
                <CaptionField />
              </FieldsetField>
            </Fieldset>
          </PanelBody>
          <PanelFooter>
            <Button
              type="button"
              variant="secondary"
              appearance="text"
              onClick={store.hide}
            >
              Annuler
            </Button>
            <FormSubmit>Enregistrer</FormSubmit>
          </PanelFooter>
        </Form>
      )}
    </Popover>
  );
};

export const ImageNodeEditCaption = memo(({ onUpdate, onEdit, caption }) => {
  const popover = usePopoverStore();
  const open = useStoreState(popover, "open");

  const onEditRef = useLiveRef(onEdit);
  const editing = open;
  const previousEditing = usePrevious(editing);

  useEffect(() => {
    const onEdit = onEditRef.current;
    if (!onEdit) return;
    if (previousEditing === undefined) return;
    if (previousEditing === editing) return;
    onEdit(editing);
  }, [onEditRef, editing, previousEditing]);

  return (
    <>
      <PopoverDisclosure
        store={popover}
        render={
          <EditorBlockCapsuleToolbarButton
            title="Éditer la légende"
            icon={IoPencil}
          />
        }
      />
      <EditCaptionPopover
        store={popover}
        open={open}
        initialValues={{ caption }}
        onSubmit={onUpdate}
      />
    </>
  );
});
