import { gql } from "@apollo/client";
import { IoEllipsisHorizontalCircleOutline } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";
import { cn } from "swash/utils/classNames";

import { Time } from "@/components/Time";

import { EventStatusIndicator } from "./EventStatusIndicator";
import { List } from "./List";

function getActionLabel(action) {
  switch (action) {
    case "updated":
      return "Mis à jour";
    case "created":
      return "Créé";
    case "closed":
      return "Fermé";
    case "opened":
      return "Ouvert";
    case "published":
      return "Publié";
    case "exported":
      return "Exporté";
    default:
      return action;
  }
}

function EventDetail({ event, dateFormat }) {
  return (
    <div
      className="grid items-center gap-1"
      style={{ gridTemplateColumns: "auto 1fr" }}
    >
      <EventStatusIndicator event={event} />
      <div>
        {getActionLabel(event.payload.action)}{" "}
        <Time date={event.date} format={dateFormat} />
        {event.actor ? ` par ${event.actor.fullName}` : ""}
      </div>
    </div>
  );
}

function PreviousEvents({ events, dateFormat, ...props }) {
  return (
    <Tooltip
      tooltip={
        <List>
          {events.map((event) => (
            <li key={event.id}>
              <EventDetail event={event} dateFormat={dateFormat} />
            </li>
          ))}
        </List>
      }
    >
      <span
        className="inline-flex"
        aria-label="Événements précédents"
        {...props}
      >
        <IoEllipsisHorizontalCircleOutline />
      </span>
    </Tooltip>
  );
}

export function EventsHistory({ events, dateFormat, className, ...props }) {
  if (events.length === 0) return null;
  return (
    <div
      className={cn(className, "flex items-center gap-1 font-accent text-xs")}
      {...props}
    >
      <EventDetail event={events[0]} dateFormat={dateFormat} />
      {events.length > 1 && (
        <PreviousEvents
          events={events.slice(1)}
          ml={1}
          dateFormat={dateFormat}
        />
      )}
    </div>
  );
}

EventsHistory.fragments = {
  events: gql`
    fragment EventsHistory_events on Event {
      id
      name
      payload
      date
      actor {
        id
        fullName
      }
      statuses {
        id
      }
      ...EventStatusIndicator_event
    }

    ${EventStatusIndicator.fragments.event}
  `,
};
