import { makeIcon } from "./utils";

export const SvgH2 = makeIcon(
  "SvgH2",
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.955 18.75a.5.5 0 0 1-.5-.5v-.18c0-.15.067-.29.182-.386 1.175-.976 1.982-1.705 2.421-2.187.461-.51.692-.992.692-1.447 0-.324-.109-.574-.326-.75a1.191 1.191 0 0 0-.781-.268c-.463 0-.868.128-1.216.382-.25.183-.616.19-.81-.05-.142-.175-.15-.427.01-.584.213-.207.46-.375.74-.502A2.955 2.955 0 0 1 17.624 12c.622 0 1.146.183 1.571.55.426.364.639.864.639 1.5 0 .572-.22 1.142-.66 1.71-.438.565-1.108 1.219-2.01 1.962h2.21a.5.5 0 0 1 .5.5v.028a.5.5 0 0 1-.5.5h-3.42Z"
      fill="currentColor"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      d="M4.875 6v12M12.375 12h-7.5M13.125 6v12"
    />
  </svg>,
);
