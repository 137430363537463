import { forwardRef } from "react";
import { cn } from "swash/utils/classNames";

export const CapsuleList = forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>(function CapsuleList(props, ref) {
  return (
    <div
      ref={ref}
      {...props}
      className={cn(
        "relative flex-1 space-y-4 px-3 py-5 opacity-100 transition-all aria-[disabled=true]:opacity-70",
        props.className,
      )}
      style={{ minHeight: 60, ...props.style }}
    />
  );
});
