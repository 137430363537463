import { Extension } from "@tiptap/core";

import { InvisibleCharacterBuilder } from "./builders/common";
import { HardBreakNode } from "./builders/hard-break";
import { NarrowNoBreakSpaceCharacter } from "./builders/narrow-no-break-space";
import { NoBreakSpaceCharacter } from "./builders/no-break-space";
import { ParagraphNode } from "./builders/paragraph";
import { SpaceCharacter } from "./builders/space";
import {
  InvisibleCharactersPlugin,
  InvisibleCharactersPluginKey,
} from "./invisible-characters-plugin";

export type InvisibleCharactersOptions = {
  visible: boolean;
  builders: InvisibleCharacterBuilder[];
};

export const InvisibleCharactersExtension =
  Extension.create<InvisibleCharactersOptions>({
    name: "invisibleCharacters",

    addOptions() {
      return {
        visible: false,
        builders: [
          SpaceCharacter,
          NoBreakSpaceCharacter,
          NarrowNoBreakSpaceCharacter,
          HardBreakNode,
          ParagraphNode,
        ],
      };
    },

    addProseMirrorPlugins() {
      return [InvisibleCharactersPlugin(this.editor.state, this.options)];
    },

    addStorage() {
      return { visibility: () => this.options.visible };
    },

    onBeforeCreate() {
      this.storage.visibility = () => {
        return InvisibleCharactersPluginKey.getState(this.editor.state)
          ?.visible;
      };
    },

    addCommands() {
      return {
        showInvisibleCharacters:
          (visible = true) =>
          ({ dispatch, tr }) => {
            if (dispatch) {
              tr.setMeta("setInvisibleCharactersVisible", visible);
            }
            return true;
          },

        hideInvisibleCharacters:
          () =>
          ({ dispatch, tr }) => {
            if (dispatch) {
              tr.setMeta("setInvisibleCharactersVisible", false);
            }
            return true;
          },

        toggleInvisibleCharacters:
          () =>
          ({ dispatch, tr, state }) => {
            const target =
              !InvisibleCharactersPluginKey.getState(state)?.visible;
            if (dispatch) {
              tr.setMeta("setInvisibleCharactersVisible", target);
            }
            return true;
          },
      };
    },

    addKeyboardShortcuts() {
      return {
        "Mod-$": () => this.editor.commands.toggleInvisibleCharacters(),
        "Mod-*": () => this.editor.commands.toggleInvisibleCharacters(),
      };
    },
  });

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    invisibleCharacters: {
      showInvisibleCharacters: (visible?: boolean) => ReturnType;
      hideInvisibleCharacters: () => ReturnType;
      toggleInvisibleCharacters: () => ReturnType;
    };
  }
}
