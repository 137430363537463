import { memo, useMemo } from "react";
import { useForm } from "react-final-form";
import { Alert } from "swash/Alert";
import { Button } from "swash/Button";
import { useEditorContext } from "swash/editor";

import { FormSubmit } from "@/components/forms/FormSubmit";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { EnumFiltersField } from "@/containers/admin/CRUD";

type AnchorValue = {
  value: string;
  label: string;
};

export const TabAnchor = memo(function TabAnchor() {
  const { editor } = useEditorContext();

  const anchors = useMemo((): AnchorValue[] => {
    if (!editor?.state) return [];
    const anchors: AnchorValue[] = [];
    editor.state.doc.descendants((node) => {
      const anchor = node.marks.find((mark) => mark.type.name === "anchor");
      if (anchor) {
        anchors.push({
          value: "#" + anchor.attrs["id"],
          label: "#" + node.textContent,
        });
      }
    });
    return anchors;
  }, [editor?.state]);

  const form = useForm();
  const anchorValue = useSubscribeFormValue("anchor");

  const missingAnchor =
    anchorValue && !anchors.some((anchor) => anchor.value === anchorValue);
  const hasAnchors = anchors.length > 0;

  if (missingAnchor) {
    return (
      <Alert
        className="mt-2"
        level="warning"
        compact
        actions={
          hasAnchors
            ? ({ buttonProps }) => (
                <Button
                  {...buttonProps}
                  onClick={() => form.change("anchor", null)}
                >
                  Choisir une autre ancre
                </Button>
              )
            : undefined
        }
      >
        L’ancre sélectionnée n’est plus présente dans cet article.
      </Alert>
    );
  }

  if (!hasAnchors) {
    return (
      <Alert level="info" compact className="mt-2">
        Aucune ancre n’est présente dans cet article. Commencez par ajouter une
        ancre à votre texte avant de faire un lien vers cette ancre.
      </Alert>
    );
  }

  return (
    <div className="mt-2 flex flex-col gap-2">
      <AnchorField anchors={anchors} />
      <FormSubmit scale="xs">Insérer l’ancre</FormSubmit>
    </div>
  );
});

type AnchorFieldProps = { anchors: AnchorValue[] };

const AnchorField = memo(({ anchors }: AnchorFieldProps) => {
  return (
    <EnumFiltersField
      name="anchor"
      enum={anchors}
      format={(v: string) => anchors.find((item) => item.value === v) || null}
      parse={(v: AnchorValue) => v?.value}
      label="Choisir une ancre"
    />
  );
});
