import { makeIcon } from "./utils";

export const SvgUndo = makeIcon(
  "SvgUndo",
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.808 8.58 4.5 10.86l2.308 2.28"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.077 10.86h10.6c2.12 0 3.823 1.757 3.823 3.848v.712"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
