import { mergeAttributes } from "@tiptap/core";

import { Title, TitleOptions } from "./title";

export interface ChapoOptions extends TitleOptions {}

export const Chapo = Title.extend({
  name: "chapo",

  parseHTML() {
    return [{ tag: "h2" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "h2",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});
