import { TypedDocumentNode, gql } from "@apollo/client";

// eslint-disable-next-line @lemonde/import/no-illegal-import
import { ResultOf, useSafeQuery } from "@/containers/Apollo";
import { AllEmojisQuery } from "@/gql-types";

const Query: TypedDocumentNode<AllEmojisQuery, never> = gql`
  query AllEmojis {
    emojis(where: { enabled: true }) {
      nodes {
        id
        name
        title
        encodedImage
      }
    }
  }
`;

export const useEmojiQuery = () => {
  const { data, ...other } = useSafeQuery(Query);
  return { data: data?.emojis.nodes, ...other };
};

export type Emoji = ResultOf<typeof Query>["emojis"]["nodes"][number];
