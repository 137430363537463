import { useMutation, useSubscription } from "@apollo/client";
import { capitalize } from "lodash-es";
import { memo, useMemo } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import { Button } from "swash/Button";
import { Card } from "swash/Card";
import {
  EmptyState,
  EmptyStateAction,
  EmptyStatePicture,
  EmptyStateTitle,
} from "swash/EmptyState";
import { PageLoader } from "swash/Loader";

import faceWithMonocle from "@/assets/imgs/emojis/face_with_monocle.png";
import { WatchButton } from "@/components/WatchButton";
import { PageLink } from "@/components/teleporters/Header";
import { HeaderToolbarItem } from "@/components/teleporters/HeaderToolbar";
import { useSafeQuery } from "@/containers/Apollo";
import { useConnection } from "@/containers/Ping";
import { useRemoteConfig } from "@/containers/RemoteConfig";
import { useIdParam } from "@/containers/Router";
import { useUser } from "@/containers/User";
import { CRUDActivityHistory } from "@/containers/admin/CRUD/history/ActivityHistory";
import { getFieldsMap } from "@/containers/admin/CRUD/history/AuditTrail";
import { useWatchState } from "@/containers/watch/WatchState";

const WatchToolbarItem = ({ id, resourceType }) => {
  const watch = useWatchState(`${resourceType}:${id}`);
  const connection = useConnection();
  return (
    <HeaderToolbarItem>
      <WatchButton scale="sm" disabled={!connection.synced} {...watch} />
    </HeaderToolbarItem>
  );
};

/**
 * Create CRUD edit.
 * @template TNode
 * @template TFilters
 * @template TValues
 * @param {object} options
 * @param {import('./index').CRUDDescriptor<TNode, TFilters, TValues>} options.descriptor
 * @returns {{ Edit: React.FC | null }}
 */
export function createCRUDEdit({ descriptor }) {
  if (!descriptor.crudOperations.includes("edit")) {
    return { Edit: null };
  }

  const Subscriptions = memo(({ id }) => {
    if (descriptor.operations.NodeSubscriptions) {
      descriptor.operations.NodeSubscriptions.forEach((subscription) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSubscription(subscription, {
          variables: {
            id: Number(id),
            ...descriptor.operations.operationVariables,
          },
        });
      });
    }
    return null;
  });

  function CRUDEdit() {
    const id = useIdParam("id");

    const extraQueryVariables = descriptor.operations.nodeQueryVariables || {};
    const { data } = useSafeQuery(descriptor.operations.NodeQuery, {
      variables: { id: Number(id), ...extraQueryVariables },
      fetchPolicy: "network-only",
    });

    const [updateNode] = useMutation(descriptor.operations.UpdateNodeMutation);

    const user = useUser();
    const config = useRemoteConfig();
    const node = data?.node ?? null;
    const initialValues = useMemo(
      () => descriptor.formatValues(node, { user, config }),
      [node, user, config],
    );

    if (!data) {
      return <PageLoader />;
    }

    if (!node) {
      return (
        <EmptyState>
          <EmptyStatePicture src={faceWithMonocle} />
          <EmptyStateTitle>
            {capitalize(descriptor.term.singular)} introuvable.
          </EmptyStateTitle>
          <EmptyStateAction>
            <Button asChild>
              <RouterLink to={`${descriptor.baseUrl}/${descriptor.slug}`}>
                Retour à la liste des {descriptor.term.plural}
              </RouterLink>
            </Button>
          </EmptyStateAction>
        </EmptyState>
      );
    }

    async function handleSubmit(values) {
      await updateNode({
        variables: {
          input: { id: node.id, ...descriptor.parseValues(values, node) },
        },
      });
    }

    return descriptor.wrapPageElement({
      element: (
        <>
          <PageLink to={`${descriptor.baseUrl}/${descriptor.slug}/:id/*`}>
            Éditer {descriptor.term.specificArticle}
            {descriptor.term.singular} « {node?.label} »
          </PageLink>
          {descriptor.components.Tabs ? (
            <descriptor.components.Tabs
              initialValues={initialValues}
              onSubmit={handleSubmit}
              node={node}
            />
          ) : (
            <Card>
              <descriptor.components.Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
                node={node}
                operations={descriptor.operations}
              />
            </Card>
          )}
          {descriptor.activities && (
            <>
              <WatchToolbarItem
                id={node.id}
                resourceType={descriptor.activities.resource}
              />
              <CRUDActivityHistory
                resourceId={node.id}
                resource={descriptor.activities.resource}
                scope={descriptor.activities.scope}
                fieldsMap={getFieldsMap(descriptor)}
                term={descriptor.term}
              />
            </>
          )}
          <Subscriptions id={id} />
        </>
      ),
    });
  }

  return { Edit: CRUDEdit };
}
