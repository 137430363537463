import createEditorControl from "@/components/editor/controls/createEditorControl";
import { Underline } from "@/components/icons";

export const UnderlineControl = createEditorControl({
  name: "underline",
  label: "Souligner",
  shortcut: ["⌘", "u"],
  icon: <Underline />,
  command: ({ editor }) => editor.chain().focus().toggleUnderline().run(),
  canExecute: ({ editor }) => editor.can().toggleUnderline(),
});
