import {
  DragEvent,
  MouseEvent,
  RefCallback,
  TransitionEvent,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { useCallback, useMemo } from "use-memo-one";

import { dropAnimationFinished } from "../../store/actions";
import type { DraggableDescriptor } from "../../types";
import AppContext from "../context/app-context";
import DroppableContext from "../context/droppable-context";
import { useDraggablePublisher } from "../use-draggable-publisher";
import useRequiredContext from "../use-required-context";
import type { DragHandleProps, Props, Provided } from "./draggable-types";
import { useMappedProps } from "./get-selector";
import { getStyle } from "./get-style";

function preventHtml5Dnd(event: DragEvent) {
  event.preventDefault();
}

function focusCurrentTarget(event: MouseEvent) {
  if (event.currentTarget instanceof HTMLElement) {
    event.currentTarget.focus();
  }
}

export default function Draggable(props: Props) {
  // reference to DOM node
  const ref = useRef<HTMLElement | null>(null);
  const setRef = useCallback<RefCallback<HTMLElement>>((el) => {
    ref.current = el;
  }, []);
  const getRef = useCallback(() => ref.current, []);

  // context
  const { contextId, dragHandleUsageInstructionsId, registry } =
    useRequiredContext(AppContext);
  const { type, droppableId } = useRequiredContext(DroppableContext);

  const descriptor = useMemo(
    (): DraggableDescriptor => ({
      id: props.draggableId,
      index: props.index,
      type,
      droppableId,
    }),
    [props.draggableId, props.index, type, droppableId],
  );

  const dispatch = useDispatch();
  const dropAnimationFinishedAction = useCallback(
    () => dispatch(dropAnimationFinished()),
    [dispatch],
  );

  // props
  const mapped = useMappedProps(props);
  const { children, draggableId, isDragDisabled } = props;
  const isEnabled = !isDragDisabled;

  // Being super sure that isClone is not changing during a draggable lifecycle
  useDraggablePublisher({
    descriptor,
    registry,
    getDraggableRef: getRef,
    isEnabled,
  });

  const dragHandleProps = useMemo(
    (): DragHandleProps | undefined =>
      isEnabled
        ? {
            // See `draggable-types` for an explanation of why these are used
            tabIndex: 0,
            role: "button",
            "aria-describedby": dragHandleUsageInstructionsId,
            "data-rbd-drag-handle-draggable-id": draggableId,
            "data-rbd-drag-handle-context-id": contextId,
            draggable: false,
            onDragStart: preventHtml5Dnd,
            onMouseDown: focusCurrentTarget,
          }
        : undefined,
    [contextId, dragHandleUsageInstructionsId, draggableId, isEnabled],
  );

  const onMoveEnd = useCallback(
    (event: TransitionEvent) => {
      if (mapped.type !== "DRAGGING") return;
      if (!mapped.dropping) return;

      // There might be other properties on the element that are
      // being transitioned. We do not want those to end a drop animation!
      if (event.propertyName !== "transform") return;

      dropAnimationFinishedAction();
    },
    [dropAnimationFinishedAction, mapped],
  );

  const provided = useMemo((): Provided => {
    return {
      innerRef: setRef,
      draggableProps: {
        "data-rbd-draggable-context-id": contextId,
        "data-rbd-draggable-id": draggableId,
        style: getStyle(mapped),
        onTransitionEnd:
          mapped.type === "DRAGGING" && mapped.dropping ? onMoveEnd : undefined,
      },
      dragHandleProps,
    };
  }, [contextId, dragHandleProps, draggableId, mapped, onMoveEnd, setRef]);

  return children(provided, mapped.snapshot);
}
