import {
  Disclosure,
  DisclosureContent,
  DisclosureContentProps,
  DisclosureProps,
  DisclosureStore,
  DisclosureStoreProps,
  useDisclosureStore,
} from "@ariakit/react";
import { forwardRef } from "react";

import { cn } from "./utils/classNames";

export type ExpandDisclosureStore = {
  disclosure: DisclosureStore;
};

export function useExpandDisclosureStore(
  options: DisclosureStoreProps,
): ExpandDisclosureStore {
  const disclosure = useDisclosureStore({ ...options, animated: true });

  return {
    disclosure,
  };
}

export type ExpandDisclosureOptions = ExpandDisclosureStore &
  Omit<DisclosureProps, "store">;

export const ExpandDisclosure = forwardRef<
  HTMLButtonElement,
  ExpandDisclosureOptions
>(({ disclosure, ...props }, ref) => {
  return <Disclosure ref={ref} store={disclosure} {...props} />;
});

ExpandDisclosure.displayName = "ExpandDisclosure";

export type ExpandDisclosureContentOptions = ExpandDisclosureStore &
  Omit<DisclosureContentProps, "store">;

export const ExpandDisclosureContent = forwardRef<
  HTMLDivElement,
  ExpandDisclosureContentOptions
>(({ disclosure, children, className, ...props }, ref) => {
  return (
    <DisclosureContent
      ref={ref}
      store={disclosure}
      alwaysVisible
      className={cn(
        "grid grid-rows-[0fr] overflow-hidden transition-all duration-300",
        "data-[enter]:grid-rows-[1fr]",
        "*:overflow-hidden *:p-0",
        className,
      )}
      {...props}
    >
      <div>{children}</div>
    </DisclosureContent>
  );
});

ExpandDisclosureContent.displayName = "ExpandDisclosureContent";
