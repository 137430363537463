import { Extension, deleteProps, getNodeType, isList } from "@tiptap/core";
import { Plugin, PluginKey, Transaction } from "@tiptap/pm/state";
import { ReplaceAroundStep, ReplaceStep, StepMap } from "@tiptap/pm/transform";

import { getSelectedBlocks } from "../helpers/getSelectedBlocks";

type BlockTemplateData = {
  name: string;
  css?: Record<string, string> | null;
  nodeType: {
    name: string;
    attrs?: Record<string, any> | null;
  };
};

export interface BlockTemplateOptions {
  types: string[];
  blockTemplates: BlockTemplateData[];
}

export const BlockTemplate = Extension.create<BlockTemplateOptions>({
  name: "blockTemplate",

  addOptions() {
    return {
      types: [
        "paragraph",
        "heading",
        "blockquote",
        "listItem",
        "orderedList",
        "bulletList",
      ],
      blockTemplates: [],
    };
  },

  addStorage() {
    return {
      blockTemplates: this.options.blockTemplates,
      types: this.options.types,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          styleName: {
            default: null,
            renderHTML: (attr) => {
              const styleName = attr.styleName;
              const blockTemplate = findBlockTemplate(
                styleName,
                this.storage.blockTemplates,
              );
              return blockTemplate?.css
                ? {
                    "data-style-name": styleName,
                    style: objectToCss(blockTemplate.css),
                  }
                : { "data-style-name": styleName };
            },
            parseHTML: (el) => el.getAttribute("data-style-name"),
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setBlockTemplate:
        (styleName) =>
        ({ commands, chain, dispatch, state }) => {
          const nodes = getSelectedBlocks(state);
          if (
            !nodes.every((node) => this.options.types.includes(node.type.name))
          )
            return false;
          const blockTemplate = findBlockTemplate(
            styleName,
            this.storage.blockTemplates,
          );
          if (
            styleName &&
            (!blockTemplate ||
              !this.options.types.includes(blockTemplate.nodeType.name) ||
              // Ensure that the heading block template is a valid heading level
              (blockTemplate.nodeType.name === "heading" &&
                !this.editor.extensionManager.extensions
                  .find((ext) => ext.name === "heading")
                  ?.options.levels.includes(
                    blockTemplate.nodeType.attrs?.level,
                  )))
          )
            return false;

          if (dispatch) {
            if (!styleName || !blockTemplate) return false;
            this.options.types.forEach((type) => {
              chain().liftAll(type).setParagraph();
            });
            const { nodeType } = blockTemplate;
            const type = getNodeType(nodeType.name, state.schema);
            if (type.isTextblock) {
              return chain()
                .setNode(type, { ...nodeType.attrs, styleName })
                .run();
            } else {
              if (
                isList(nodeType.name, this.editor.extensionManager.extensions)
              ) {
                chain().wrapInList(nodeType.name, {
                  ...nodeType.attrs,
                  styleName,
                });
              } else {
                chain().wrapIn(type, {
                  ...nodeType.attrs,
                  styleName,
                });
              }
              // Remove the styleName attribute from other block types
              this.options.types
                .filter((t) => t !== nodeType.name)
                .forEach((t) => chain().resetAttributes(t, "styleName"));
            }
            return chain().run();
          }
          return this.options.types
            .map((type) => commands.updateAttributes(type, { styleName }))
            .every((response) => response);
        },

      unsetBlockTemplate:
        () =>
        ({ commands }) =>
          this.options.types
            .map((type) => commands.resetAttributes(type, "styleName"))
            .every(Boolean),
    };
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("resetStyleName"),
        appendTransaction: (transactions, oldState, newState) => {
          const tr = newState.tr;
          const { selection } = newState;
          const { $to } = selection;
          let modified = false;

          const blockTemplate = findBlockTemplate(
            $to.node().attrs?.styleName,
            this.storage.blockTemplates,
          );

          // Remove styleName if node type does not match
          if (
            blockTemplate &&
            $to.node().type.name !== blockTemplate.nodeType.name
          ) {
            tr.setNodeMarkup(
              $to.pos - $to.depth,
              undefined,
              deleteProps($to.node().attrs, "styleName"),
            );
            modified = true;
          }

          for (const transaction of transactions) {
            if (!transaction.docChanged) continue;

            const ranges = replacedRanges(transaction);

            for (const { from, to } of ranges) {
              if (to + $to.depth >= newState.doc.content.size) continue;
              newState.doc.nodesBetween(from, to, (node, pos) => {
                if (!node.isBlock) return;

                const mappedPos = transaction.mapping.map(pos);
                if (oldState.doc.content.size <= mappedPos) return;
                const oldNode = oldState.doc.nodeAt(mappedPos);

                // Remove styleName if the node type changed
                if (
                  oldNode?.attrs.styleName &&
                  node.type !== oldNode.type &&
                  node.attrs.styleName === oldNode.attrs.styleName
                ) {
                  tr.setNodeMarkup(
                    pos,
                    undefined,
                    deleteProps(node.attrs, "styleName"),
                  );
                  modified = true;
                }

                // Reset styleName for styled child
                if (!node.attrs.styleName && node.content.childCount) {
                  node.content.forEach((child, offset) => {
                    if (!child.attrs.styleName) return;
                    const isNonTextAtom = node.isAtom && !node.isText;
                    const targetPos = pos + offset + (isNonTextAtom ? 0 : 1);
                    tr.setNodeMarkup(
                      targetPos,
                      undefined,
                      deleteProps(child.attrs, "styleName"),
                    );
                    modified = true;
                  });
                }
              });
            }
          }

          return modified ? tr : null;
        },
      }),
    ];
  },
});

function objectToCss(obj: Record<string, string>) {
  return Object.entries(obj)
    .map(
      ([k, v]) => `${k.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`)}:${v}`,
    )
    .join(";");
}

function findBlockTemplate(
  name: string | undefined,
  blockTemplates: BlockTemplateData[],
) {
  return name ? blockTemplates.find((tpl) => tpl.name === name) : undefined;
}

function replacedRanges(
  tr: Transaction,
): { from: number; to: number; stepMap: StepMap }[] {
  const ranges = [];
  for (const step of tr.steps) {
    const stepMap = step.getMap();
    if (step instanceof ReplaceStep || step instanceof ReplaceAroundStep) {
      ranges.push({ from: step.from, to: step.to, stepMap });
    }
    for (const range of ranges) {
      range.from = stepMap.map(range.from, -1);
      range.to = stepMap.map(range.to, 1);
    }
  }
  return ranges;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    blockTemplate: {
      setBlockTemplate: (blockTemplateName?: string) => ReturnType;
      unsetBlockTemplate: () => ReturnType;
    };
  }
}
