import { TypedDocumentNode, gql } from "@apollo/client";
import { ForwardRefExoticComponent, forwardRef } from "react";
import { cn } from "swash/utils/classNames";

import { UserDisplayName_userFragment } from "@/gql-types";

export const UserDisplayName = forwardRef<
  HTMLSpanElement,
  UserDisplayNameProps
>(({ user, ...props }, ref) => (
  <span ref={ref} {...props} className={cn("font-semibold", props.className)}>
    {user.firstNameInitials} {user.lastName}
  </span>
)) as ForwardRefExoticComponent<UserDisplayNameProps> & {
  fragments: { user: TypedDocumentNode<UserDisplayName_userFragment> };
};

UserDisplayName.fragments = {
  user: gql`
    fragment UserDisplayName_user on User {
      firstNameInitials
      lastName
    }
  `,
};

interface UserDisplayNameProps extends React.HTMLAttributes<HTMLSpanElement> {
  user: UserDisplayName_userFragment;
}
