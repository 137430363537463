import { useApolloClient } from "@apollo/client";
import { useMemo } from "react";
import { useStoreState } from "swash/utils/useStoreState";
import { useComboboxStore } from "swash/v2/Combobox";
import { RemoteSelect, useRemoteSelectState } from "swash/v2/RemoteSelect";

import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { useSelectField } from "@/components/fields/SelectField";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { mergeConnections, useSafeQuery } from "@/containers/Apollo";

const HasOneSelectField = ({
  name,
  modelName,
  fragment,
  label,
  placeholder,
  scale,
  labelSelector,
  valueSelector,
  labelElementSelector,
  disabledSelector,
  emptyMessage,
  disabled,
  required,
  isEqual,
  ariaLabel,
  hint,
  searchable,
  data,
  combobox,
  fetchMore,
  loading,
  clearable,
  iconSelector,
  modal,
  ...others
}) => {
  const client = useApolloClient();
  const format = (v) => {
    if (!v) return null;
    return client.readFragment({
      id: `${modelName}:${v}`,
      fragment,
      fragmentName: fragment.definitions[0].name.value,
    });
  };
  const parse = (v) => v?.id ?? null;
  const field = useSelectField(name, {
    required,
    format,
    parse,
    isEqual,
    label: ariaLabel || label,
    ...others,
  });
  const { value, onChange } = field.state.field.input;

  const state = useRemoteSelectState({
    title: label,
    value: value || null,
    onChange,
    data,
    combobox,
    fetchMore: (previousData) => {
      fetchMore({
        variables: { offset: previousData.items.length },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!previousResult.connection) return previousResult;
          return {
            ...previousResult,
            connection: mergeConnections(
              previousResult.connection,
              fetchMoreResult.connection,
            ),
          };
        },
      });
    },
    getItem: (id) => {
      const value = client.readFragment({
        id: `${modelName}:${id}`,
        fragment,
        fragmentName: fragment.definitions[0].name.value,
      });
      if (!value) {
        throw new Error(`${modelName} (id: ${id}) not found`);
      }
      return value;
    },
    labelSelector,
    labelElementSelector,
    iconSelector,
    valueSelector,
    searchable,
    emptyMessage,
    disabledSelector: (item) => disabled || disabledSelector(item),
    loading,
    required: required || !clearable,
  });

  return (
    <FieldGroup {...field}>
      {label ? <FieldLabel {...field}>{label}</FieldLabel> : null}
      <FieldError {...field} />
      {hint ? (
        <FieldHint {...field} className="mb-1">
          {hint}
        </FieldHint>
      ) : null}
      <RemoteSelect
        state={state}
        placeholder={placeholder || "Sélectionner..."}
        scale={scale}
        disabled={disabled}
        modal={modal}
        aria-label={ariaLabel || label}
        clearLabel="Vider"
      />
    </FieldGroup>
  );
};

export function HasOneField({
  name,
  query,
  searchVariables,
  required,
  searchable = false,
  label,
  "aria-label": ariaLabel,
  hint,
  placeholder,
  scale = "lg",
  labelSelector = (item) => item?.label,
  valueSelector = (item) => item?.id?.toString(),
  labelElementSelector,
  iconSelector = () => null,
  useInitialValue = (v) => v,
  initialVariables,
  fragment,
  modelName,
  renderExtraBlock,
  parseNodes = (data) => data.connection.nodes,
  isEqual = Object.is,
  clearable = false,
  modal = false,
  disabledSelector = () => false,
  emptyMessage,
  disabled,
  hideOnEmpty = false,
  ...others
}) {
  const combobox = useComboboxStore();
  const search = useStoreState(combobox, "value");

  const initialValue = useSubscribeFormValue(name);

  useSafeQuery(query, {
    variables: {
      value: useInitialValue(initialValue),
      ...initialVariables,
    },
  });

  const queryResult = useSafeQuery(query, {
    variables: {
      search,
      ...searchVariables,
    },
  });

  const data = useMemo(() => {
    const tempData = queryResult.data ?? queryResult.previousData;
    if (!tempData)
      return {
        items: [],
        totalCount: 0,
        hasMore: false,
      };
    return {
      items: parseNodes(tempData) ?? [],
      totalCount:
        tempData.connection?.totalCount ?? parseNodes(tempData).length,
      hasMore: tempData.connection?.pageInfo?.hasMore ?? false,
    };
  }, [queryResult.data, queryResult.previousData, parseNodes]);

  if (hideOnEmpty && !search && !data.items.length && !queryResult.loading)
    return null;

  return (
    <HasOneSelectField
      name={name}
      modelName={modelName}
      fragment={fragment}
      label={label}
      placeholder={placeholder}
      scale={scale}
      labelSelector={labelSelector}
      valueSelector={valueSelector}
      labelElementSelector={labelElementSelector}
      iconSelector={iconSelector}
      disabledSelector={disabledSelector}
      emptyMessage={emptyMessage}
      disabled={disabled}
      required={required}
      isEqual={isEqual}
      ariaLabel={ariaLabel}
      hint={hint}
      searchable={searchable}
      data={data}
      combobox={combobox}
      fetchMore={queryResult.fetchMore}
      loading={queryResult.loading}
      clearable={clearable}
      modal={modal}
      {...others}
    />
  );
}
