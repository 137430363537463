import { useVirtualizer } from "@tanstack/react-virtual";
import {
  CSSProperties,
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { mergeRefs } from "react-merge-refs";
import { cn } from "swash/utils/classNames";
import { useResizeObserver } from "swash/utils/useResizeObserver";

import { ListTotalCount } from "@/components/ListTotalCount";
import {
  useTrackVirtualItems,
  useVirtualNotifier,
} from "@/components/VirtualNotifier";
import { ScrollingProvider, useScrolling } from "@/containers/Scrolling";

import type { CellType, ListState } from "./ListState";
import { HeaderRow } from "./rows/HeaderRow";
import { Row, estimateSize } from "./rows/Row";

type ListReadOnlyType = { readOnly: boolean };

const ListReadOnlyContext = createContext<ListReadOnlyType | null>(null);

const ListReadOnlyProvider = (props: {
  children: React.ReactNode;
  readOnly?: boolean;
}) => {
  return (
    <ListReadOnlyContext.Provider value={{ readOnly: props.readOnly ?? false }}>
      {props.children}
    </ListReadOnlyContext.Provider>
  );
};

export const useListReadOnly = () => {
  const context = useContext(ListReadOnlyContext);
  if (!context) {
    throw new Error(
      "useListReadOnly must be used within a ListReadOnlyProvider",
    );
  }

  return context.readOnly;
};

type Node = any;

export type ListProps = {
  loadMore: () => void;
  loading: boolean;
  state: ListState<Node>;
  totalCount?: number;
};

export const getHeaderStyle = <TNode,>(
  cell: CellType<TNode>,
): CSSProperties => {
  return {
    flexGrow: cell.width === "1fr" ? 1 : 0,
    flexShrink: cell.width === "1fr" ? 1 : 0,
    width: cell.width !== "1fr" ? cell.width : "auto",
    minWidth: cell.minWidth,
    textAlign: cell.align ?? "left",
    padding: cell.padding,
    margin: cell.margin,
  };
};

export const getCellStyle = <TNode,>(cell: CellType<TNode>): CSSProperties => {
  const style = {
    ...getHeaderStyle(cell),
  };
  if (cell.cellPadding !== undefined) {
    style.padding = cell.cellPadding;
  }
  return style;
};

const useListScrolling = (toolbar: ListProps["state"]["toolbar"]) => {
  const { scrollListenerRef, scrolling } = useScrolling();

  return toolbar
    ? { scrollListenerRef, scrolling }
    : { scrollListenerRef: null, scrolling: false };
};

const InnerList = memo((props: ListProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [listWidth, setListWidth] = useState<number>(0);
  const listRef = useResizeObserver((entry) => {
    const { clientWidth, scrollWidth } = entry.target;
    if (clientWidth < scrollWidth) {
      setListWidth(scrollWidth);
    }
  });

  const notifier = useVirtualNotifier();

  const {
    rows,
    toggleGroup,
    hasMore,
    readOnly,
    portal,
    toolbar,
    stickyHeader,
  } = props.state;

  const { scrollListenerRef, scrolling } = useListScrolling(toolbar);

  const count = rows.length;
  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => containerRef.current,
    estimateSize: (index) => {
      const row = rows[index];
      if (!row) return 0;
      return estimateSize(row);
    },
    overscan: 20,
    paddingStart: 8,
    paddingEnd: 64,
  });

  const virtualRows = virtualizer.getVirtualItems();

  const nodes = useMemo(
    () =>
      rows.map((row) => {
        if ("node" in row) {
          return { id: row.node.id };
        }
        return undefined;
      }),
    [rows],
  );

  const { checkViewed } = useTrackVirtualItems({
    list: nodes,
    virtualizer,
    notifier,
  });

  // Fetch more when we get to the last item
  const lastItem = virtualRows[virtualRows.length - 1] ?? null;
  const lastRow = lastItem ? rows[lastItem?.index] : null;

  const { loading, loadMore } = props;

  // Fetch more when we have the list item that is a loader
  useEffect(() => {
    if (hasMore && !loading && lastRow?.type === "loader") {
      loadMore();
    }
  }, [lastRow, hasMore, loading, loadMore, count]);

  return (
    <ListReadOnlyProvider readOnly={readOnly}>
      <div
        ref={mergeRefs([containerRef, scrollListenerRef])}
        className="scrollbar-light h-full flex-1 overflow-y-auto overflow-x-hidden"
        style={{
          contain: portal ? "content" : "strict",
        }}
      >
        {toolbar && (
          <div
            className={cn(
              "sticky top-0 z-40 -mb-4 flex items-center justify-end gap-4 px-8 pt-4",
              scrolling && "drop-shadow-lg",
            )}
          >
            {toolbar}
          </div>
        )}

        {props.totalCount && (
          <ListTotalCount
            className="px-4 pb-0 pt-4"
            totalCount={props.totalCount}
          />
        )}
        {stickyHeader && (
          <HeaderRow
            cells={props.state.cells}
            className={`sticky top-0 z-10 mx-4 bg-grey-bg-light w-[${listWidth}]`}
          />
        )}
        <div
          className="w-auto overflow-x-scroll"
          style={{
            position: "relative",
            height: virtualizer.getTotalSize(),
          }}
        >
          <div
            ref={listRef}
            style={{
              marginLeft: 16,
              marginRight: 16,
              position: "relative",
              minWidth: listWidth,
            }}
          >
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];

              if (!row) return null;

              return (
                <div
                  key={virtualRow.key}
                  ref={virtualizer.measureElement}
                  role={row.type}
                  data-index={virtualRow.index}
                  className="flex"
                  style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    position: "absolute",
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  <Row
                    row={row}
                    cells={props.state.cells}
                    interactions={props.state.interactions}
                    provider={props.state.provider}
                    getGroupLabel={props.state.grouping?.getGroupLabel ?? null}
                    getSubGroupLabel={
                      props.state.subGrouping?.getGroupLabel ?? null
                    }
                    toggleGroup={toggleGroup}
                    onActiveChange={props.state.onActiveChange}
                    checkViewed={checkViewed}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ListReadOnlyProvider>
  );
});

export const List = memo((props: ListProps) => {
  return (
    <ScrollingProvider>
      <InnerList {...props} />
    </ScrollingProvider>
  );
});
