import {
  ApolloError,
  DocumentNode,
  OperationVariables,
  ServerError,
  TypedDocumentNode,
  useApolloClient,
} from "@apollo/client";
import deepEqual from "deep-equal";
import { Mutator } from "final-form";
import { omit } from "lodash-es";
import React, {
  HTMLAttributes,
  cloneElement,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button } from "swash/Button";
import {
  EmptyState,
  EmptyStateAction,
  EmptyStatePicture,
  EmptyStateText,
  EmptyStateTitle,
} from "swash/EmptyState";
import { IoRefresh } from "swash/Icon";
import { PageLoader } from "swash/Loader";
import {
  Toolbar,
  ToolbarActions,
  ToolbarControls,
  ToolbarDrawer,
  ToolbarDrawerToggle,
  useToolbarDrawerState,
} from "swash/Toolbar";
import { cn } from "swash/utils/classNames";
import * as storage from "swash/utils/localStorage";
import { useLiveRef } from "swash/utils/useLiveRef";
import { useResizeObserver } from "swash/utils/useResizeObserver";
import { useStorage } from "swash/utils/useStorage";
import { useStoreState } from "swash/utils/useStoreState";

// @ts-expect-error ts does not support this import
import faceWithHeadBandage from "@/assets/imgs/emojis/face_with_head_bandage.png";
// @ts-expect-error ts does not support this import
import faceWithMonocle from "@/assets/imgs/emojis/face_with_monocle.png";
import { SimpleInfiniteScroll } from "@/components/InfiniteScroll";
import { ListTotalCount } from "@/components/ListTotalCount";
import { useSearchParams } from "@/components/SearchParams";
import { Form } from "@/components/forms/Form";
import { FormAutoSubmit } from "@/components/forms/FormAutoSubmit";
import { FormReset } from "@/components/forms/FormReset";
import { mergeConnections, useFetchMoreQuery } from "@/containers/Apollo";
import { ScrollingProvider, useScrolling } from "@/containers/Scrolling";
import { checkUserHasPermissions, useUser } from "@/containers/User";
import {
  type LayoutMode,
  LayoutModeSwitcher,
} from "@/containers/search/LayoutModeContext";

import { useAmplitudeFormDecorator } from "../Amplitude";
import { SearchBoxField, SearchBoxOptions } from "./SearchBox";

const defaultConfig = {
  ignoreCount: ["search"],
  storage: { version: "2", ignore: ["search"] },
  filters: [],
  sorter: null,
  advancedSearch: true,
  layoutMode: null,
  onLayoutModeChange: null,
  enableSearchParams: true,
};

type Scale = "sm" | "base" | "lg";

export type SearchValues = Record<string, any>;

export type OrderBy = {
  field: string;
  direction: "asc" | "desc";
};

export type SearchVariables = {
  orderBy?: OrderBy[] | null;
  where: SearchValues;
  offset?: number;
};

export type InputSearchVariables = {
  orderBy?: OrderBy[];
  where?: SearchValues;
};

export type Sorter<TValue = string> = {
  name: string;
  element: JSX.Element;
  initialValue: TValue;
  parse: (value: TValue) => OrderBy[];
};

export type Filter = {
  name: string;
  element: JSX.Element;
  initialValue: any;
  permissions?: string[];
  query?: DocumentNode;
  condition?: (data: any) => boolean;
  parseSearchParam?: boolean;
  persisted?: boolean;
  spread?: boolean;
  scope?: string;
};

type StorageConfig = {
  version: string;
  ignore: string[];
};

export type SearchFormStateConfig = {
  name: string;
  advancedSearch?: boolean;
  filters?: Filter[];
  sorter?: Sorter<any>;
  storage?: StorageConfig | null;
  layoutMode?: LayoutMode;
  onLayoutModeChange?: (mode: LayoutMode) => void;
  emptyStateElement?: JSX.Element | null;
  enableSearchParams?: boolean;
};

export type SearchFormState = {
  name: string;
  initialValues: SearchValues;
  values: SearchValues;
  setValues: (values: SearchValues) => void;
  filters: Filter[];
  sorter: Sorter<any> | null;
  layoutMode: LayoutMode | null;
  onLayoutModeChange: ((mode: LayoutMode) => void) | null;
  variables: SearchVariables;
  reset: () => void;
  advancedSearch: boolean;
  emptyStateElement: JSX.Element | null;
};

const useStoredValues = (
  name: string,
  storage: StorageConfig | null,
): [Partial<SearchValues> | null, (values: SearchValues) => void] => {
  const key = useMemo(
    () =>
      storage
        ? ["search", name, storage.version].filter(Boolean).join(":")
        : null,
    [storage, name],
  );
  const [values, setValues] = useStorage<SearchValues>(key, {});

  const storedValues = useMemo(
    () => (storage ? omit(values, storage.ignore ?? []) : null),
    [values, storage],
  );
  const setStoredValues = useCallback(
    (values: SearchValues) => {
      if (storage) {
        setValues(omit(values, storage.ignore ?? []));
      }
    },
    [storage, setValues],
  );
  return [storedValues, setStoredValues];
};

const getFilterCount = (
  state: SearchFormState,
  { scope = null }: { scope?: string | null } = {},
) => {
  const { filters, initialValues, values } = state;
  return filters.reduce((count, filter) => {
    const { scope: filterScope = "default" } = filter;
    if (scope && filterScope !== scope) return count;
    if (!deepEqual(initialValues[filter.name], values[filter.name])) {
      return count + 1;
    }
    return count;
  }, 0);
};

export const checkHasActiveFilter = (state: SearchFormState) => {
  if (state.values["search"] !== null) return true;
  return getFilterCount(state) > 0;
};

export function useSearchFormState(
  config: SearchFormStateConfig,
): SearchFormState {
  const {
    name,
    advancedSearch = defaultConfig.advancedSearch,
    filters: configFilters = defaultConfig.filters,
    sorter = defaultConfig.sorter,
    storage = defaultConfig.storage,
    enableSearchParams = defaultConfig.enableSearchParams,
    layoutMode = defaultConfig.layoutMode,
    onLayoutModeChange = defaultConfig.onLayoutModeChange,
    emptyStateElement = null,
  } = config;
  const user = useUser();
  const client = useApolloClient();
  const [storedValues, setStoredValues] = useStoredValues(name, storage);

  const [filters, setFilters] = useState<Filter[]>(configFilters);
  const refs = useRef({
    filters: configFilters,
    storedValues,
  });

  useEffect(() => {
    const fetchData = async () => {
      const { filters, storedValues } = refs.current;
      const result = (
        await Promise.all(
          filters.map(async (filter) => {
            let allowed = true;
            if (filter.permissions) {
              allowed = checkUserHasPermissions(user, filter.permissions, {
                method: "some",
              });
              if (!allowed) return false;
            }
            if (filter.condition && filter.query) {
              const { condition, query } = filter;
              if (!storedValues?.[filter.name]) {
                try {
                  const { data } = await client.query({
                    query,
                    variables: {
                      skip: true,
                    },
                  });
                  allowed = condition(data);
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error);
                  allowed = false;
                }
              }
              // filter should exist
            }
            return allowed ? filter : false;
          }),
        )
      ).filter(Boolean) as Filter[];
      refs.current.filters = result;
      setFilters(result);
    };

    fetchData();
  }, [client, configFilters, user]);

  const parseIgnore = useMemo(
    () =>
      refs.current.filters
        .filter((filter) => filter.parseSearchParam === false)
        .map((filter) => filter.name),
    [],
  );
  const initialValues = useMemo(() => {
    const { filters } = refs.current;
    const input = sorter ? [...filters, sorter] : filters;
    return input.reduce(
      (acc: SearchValues, filter) => {
        acc[filter.name] = filter.initialValue;
        return acc;
      },
      { search: null },
    );
  }, [sorter]);

  const [formValues, setFormValues] = useState(initialValues);
  const [searchParams, setSearchParams] = useSearchParams(initialValues, {
    initialState: {
      ...initialValues,
      ...storedValues,
      search: null,
    },
    parseIgnore,
    cleanSearchParams: true,
  });

  const values = enableSearchParams ? searchParams : formValues;
  const valuesForLocalStorage = useMemo(
    () =>
      omit(
        values,
        refs.current.filters
          .filter((f) => f.persisted === false)
          .map((f) => f.name),
      ),
    [values],
  );
  const valuesToVariables = useCallback(
    (values: SearchValues) => {
      const { filters } = refs.current;
      return filters.reduce(
        (variables, filter) => {
          const parse = filter.spread
            ? (value: any) => value
            : (value: any) => ({ [filter.name]: value });
          return {
            ...variables,
            where: {
              ...variables.where,
              ...parse(values[filter.name]),
            },
          };
        },
        {
          orderBy: sorter ? sorter.parse(values[sorter.name]) : null,
          where: {
            search: values["search"] !== null ? String(values["search"]) : null,
          },
        },
      );
    },
    [sorter],
  );
  const variables = useMemo(
    () => valuesToVariables(values),
    [valuesToVariables, values],
  );
  const setValues = useCallback(
    (values: SearchValues) => {
      if (enableSearchParams) {
        setSearchParams(values);
      } else {
        setFormValues(values);
      }
    },
    [setSearchParams, setFormValues, enableSearchParams],
  );

  useEffect(() => {
    setStoredValues(valuesForLocalStorage);
  }, [setStoredValues, valuesForLocalStorage]);

  const reset = useCallback(() => {
    setValues(initialValues);
  }, [initialValues, setValues]);

  const state = useMemo(
    () => ({
      name,
      initialValues,
      values,
      setValues,
      filters,
      sorter,
      layoutMode,
      onLayoutModeChange,
      variables,
      reset,
      advancedSearch,
      emptyStateElement,
    }),
    [
      name,
      initialValues,
      values,
      setValues,
      filters,
      sorter,
      layoutMode,
      onLayoutModeChange,
      variables,
      reset,
      advancedSearch,
      emptyStateElement,
    ],
  );

  return state;
}

const useSearchFormDrawerState = (name: string) => {
  const key = `search:${name}-drawer-visible`;
  const drawer = useToolbarDrawerState({
    defaultOpen: storage.getItem(key) ?? false,
  });

  const drawerOpen = useStoreState(drawer.expandDisclosure.disclosure, "open");

  useEffect(() => {
    storage.setItem(key, drawerOpen);
  }, [key, drawerOpen]);
  return drawer;
};

const SearchFormDrawer = ({
  drawerState,
  searchFormState,
  scale,
}: {
  drawerState: ReturnType<typeof useToolbarDrawerState>;
  searchFormState: SearchFormState;
  scale: Scale;
}) => {
  const activeCount = getFilterCount(searchFormState, { scope: "default" });
  return (
    <ToolbarDrawerToggle
      {...drawerState}
      count={activeCount}
      label={scale === "base" ? "Filtres" : undefined}
    />
  );
};

const useMeasureWidth = (): [(element: HTMLElement | null) => void, number] => {
  const [width, setWidth] = useState(0);
  const containerRef = useResizeObserver(
    (entry) => {
      setWidth(entry.contentRect.width);
    },
    (element) => {
      if (element) {
        setWidth(element.getBoundingClientRect().width);
      }
    },
  );
  return [containerRef, width];
};

export const SearchForm = ({
  state,
  className,
}: {
  state: SearchFormState;
  className?: string;
}) => {
  const drawer = useSearchFormDrawerState(state.name);
  const amplitudeFormDecorator = useAmplitudeFormDecorator({
    eventType: `search:${state.name}`,
  });
  const decorators = useMemo(
    () => [amplitudeFormDecorator],
    [amplitudeFormDecorator],
  );

  const mutators = useMemo<Record<string, Mutator>>(
    () => ({
      setSearch: (args, state, utils) => {
        utils.changeValue(state, "search", () => args[0]);
      },
    }),
    [],
  );
  const [toolbarRef, toolbarWidth] = useMeasureWidth();
  const scale = toolbarWidth < 800 ? "sm" : "base";
  const { setValues } = state;
  const handleSubmit = useCallback(
    (values: any) => setValues(values),
    [setValues],
  );
  const drawerFilters = useMemo(
    () => state.filters.filter((filter) => !filter.scope),
    [state.filters],
  );
  const mainFilters = useMemo(
    () => state.filters.filter((filter) => filter.scope === "main"),
    [state.filters],
  );
  const searchBoxFilters = useMemo(
    () => state.filters.filter((filter) => filter.scope === "search-box"),
    [state.filters],
  );

  return (
    <Form
      as="div"
      initialValues={state.values}
      onSubmit={handleSubmit}
      decorators={decorators}
      mutators={mutators}
    >
      <FormAutoSubmit />
      <Toolbar ref={toolbarRef} aria-label="Filtres" className={className}>
        <ToolbarControls>
          {drawerFilters.length > 0 && (
            <SearchFormDrawer
              searchFormState={state}
              drawerState={drawer}
              scale={scale}
            />
          )}
          {mainFilters.map((filter) =>
            cloneElement(filter.element, {
              key: filter.name,
              query: filter.query,
            }),
          )}
          <div className="flex-1">
            <SearchBoxField advancedSearch={state.advancedSearch} scale={scale}>
              {searchBoxFilters.length > 0 && (
                <SearchBoxOptions>
                  {searchBoxFilters.map((filter) => (
                    <div
                      key={filter.name}
                      className="border-l border-l-dusk-border-light pl-2"
                    >
                      {filter.element}
                    </div>
                  ))}
                </SearchBoxOptions>
              )}
            </SearchBoxField>
          </div>
          {state.sorter ? (
            <div className="flex shrink-0 items-center gap-2">
              <div className="font-accent text-sm font-semibold">
                Trier par :
              </div>
              {cloneElement(state.sorter.element, { scale })}
            </div>
          ) : null}
          <LayoutModeSwitcher.Target />
        </ToolbarControls>
      </Toolbar>
      {drawerFilters.length > 0 && (
        <ToolbarDrawer {...drawer} aria-label="Filtres avancés">
          <ToolbarControls>
            {drawerFilters.map((filter) =>
              cloneElement(filter.element, {
                key: filter.name,
                query: filter.query,
              }),
            )}
            <ToolbarActions>
              <FormReset
                scale="sm"
                disabled={false}
                initialValues={state.initialValues}
              />
            </ToolbarActions>
          </ToolbarControls>
        </ToolbarDrawer>
      )}
    </Form>
  );
};

type SearchNoResultAbstractProps = {
  children: React.ReactNode;
  state: SearchFormState;
  resettable?: boolean;
};

const SearchNoResultAbstract = memo<SearchNoResultAbstractProps>(
  ({ children, state, resettable }) => {
    return (
      <EmptyState className="flex-1">
        {children}
        {resettable && (
          <EmptyStateAction>
            <Button type="button" onClick={state.reset}>
              <IoRefresh />
              Réinitialiser ma recherche
            </Button>
          </EmptyStateAction>
        )}
      </EmptyState>
    );
  },
);

type SearchNoResultProps = {
  state: SearchFormState;
};

export const SearchNoResult = memo<SearchNoResultProps>(({ state }) => {
  const hasActiveFilter = checkHasActiveFilter(state);
  if (!hasActiveFilter && state.emptyStateElement)
    return state.emptyStateElement;
  return (
    <SearchNoResultAbstract state={state} resettable={hasActiveFilter}>
      <EmptyStatePicture src={faceWithMonocle} />
      <EmptyStateTitle>
        {hasActiveFilter
          ? "Aucun résultat ne correspond à votre recherche"
          : "Aucun résultat"}
      </EmptyStateTitle>
    </SearchNoResultAbstract>
  );
});

type SearchErrorProps = {
  error: ApolloError;
  state: SearchFormState;
};

export const SearchError = memo<SearchErrorProps>(({ error, state }) => {
  const { reset } = state;
  useEffect(() => {
    if (!error.networkError || !("statusCode" in error.networkError)) return;
    const networkError = error?.networkError as ServerError;
    if (
      networkError.statusCode === 400 &&
      typeof networkError.result !== "string" &&
      networkError.result?.["errors"].every(
        (error: any) => error.extensions.code === "BAD_USER_INPUT",
      )
    ) {
      reset();
    }
  }, [error, reset]);

  if (!checkHasActiveFilter(state)) {
    throw error;
  }

  return (
    <SearchNoResultAbstract state={state} resettable>
      <EmptyStatePicture src={faceWithHeadBandage} />
      <EmptyStateTitle>
        Une erreur est survenue lors de la recherche
      </EmptyStateTitle>
      <EmptyStateText>
        Vous pouvez tenter de réinitialiser vos filtres pour corriger le
        problème. S’il persiste, contactez le support.
      </EmptyStateText>
    </SearchNoResultAbstract>
  );
});

type SearchQueryProps<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
> = {
  state: SearchFormState;
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
  children: (args: {
    data: TData;
    loading: boolean;
    refetch: () => void;
    loadMore: () => void;
  }) => React.ReactElement | null;
  onFetch?: (args: {
    data: TData;
    loading: boolean;
    fetchMoreLoading: boolean;
    refetchLoading: boolean;
  }) => void;
};

type Handle = {
  refetch: () => void;
};

export const SearchQuery = forwardRef<Handle, SearchQueryProps>(
  ({ state, query, children, onFetch }, ref) => {
    const {
      error,
      data,
      loading,
      fetchMore,
      fetchMoreLoading,
      refetch,
      refetchLoading,
    } = useFetchMoreQuery(query, {
      variables: {
        ...state.variables,
        isGrid: state.layoutMode === "grid",
        isList: state.layoutMode === "list",
      },
      fetchPolicy: "network-only",
    });

    const refs = useLiveRef({ data, onFetch });
    refs.current.data = data;

    useImperativeHandle(ref, () => ({ refetch }), [refetch]);

    useEffect(() => {
      const { onFetch } = refs.current;
      if (onFetch) {
        onFetch({ data, loading, fetchMoreLoading, refetchLoading });
      }
    }, [refs, data, loading, fetchMoreLoading, refetchLoading]);

    const loadMore = useCallback(() => {
      const { data } = refs.current;
      fetchMore({
        variables: { offset: data.connection.nodes.length },
        updateQuery: (
          previousResult: any,
          { fetchMoreResult }: { fetchMoreResult: any },
        ) => {
          return {
            connection: mergeConnections(
              previousResult.connection,
              fetchMoreResult.connection,
            ),
          };
        },
      });
    }, [fetchMore, refs]);

    if (error) {
      return <SearchError error={error} state={state} />;
    }

    if (loading) {
      return <PageLoader />;
    }

    const { nodes } = data.connection;

    if (nodes.length === 0) {
      return <SearchNoResult state={state} />;
    }

    return children({
      data,
      loadMore,
      loading: fetchMoreLoading,
      refetch,
    });
  },
) as <TData = any, TVariables extends OperationVariables = OperationVariables>(
  props: SearchQueryProps<TData, TVariables> & React.RefAttributes<Handle>,
) => React.ReactElement;

const InnerSearchFormContainer = (props: HTMLAttributes<HTMLDivElement>) => {
  const { scrollListenerRef, scrolling } = useScrolling();
  return (
    <div
      ref={scrollListenerRef}
      className={cn(
        "relative flex-1 overflow-auto",
        scrolling && "group/search-form-container-scrolling",
      )}
      {...props}
    />
  );
};

export const SearchFormContainer = (props: HTMLAttributes<HTMLDivElement>) => (
  <ScrollingProvider>
    <InnerSearchFormContainer {...props} />
  </ScrollingProvider>
);

export const SearchToolbar = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "sticky top-0 z-40 -mb-4 flex items-center justify-end gap-4 px-8 pt-4 group-hover/search-form-container-scrolling:drop-shadow-lg",
      className,
    )}
    {...props}
  />
);
export const SearchFormList = ({
  loading,
  loadMore,
  children,
  connection,
}: {
  loading: boolean;
  loadMore: () => void;
  connection: {
    totalCount: number;
    pageInfo: { hasMore: boolean };
  };
  children: React.ReactNode;
}) => {
  return (
    <>
      <div className="px-4">
        <ListTotalCount totalCount={connection.totalCount} />
      </div>
      <div className="px-4 pb-4">{children}</div>
      <SimpleInfiniteScroll
        hasMore={connection.pageInfo.hasMore}
        loadMore={loadMore}
        loading={loading}
      />
    </>
  );
};
