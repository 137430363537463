import { makeIcon } from "./utils";

export const SvgLink = makeIcon(
  "SvgLink",
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.313 15.375h-2.25a3.375 3.375 0 0 1 0-6.75h2.25M13.688 8.625h2.25a3.375 3.375 0 1 1 0 6.75h-2.25M8.74 12h6.59"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
