import { gql } from "@apollo/client";
import { memo } from "react";

import { useSafeQuery } from "@/containers/Apollo";
import { EnumField } from "@/containers/admin/CRUD/fields/EnumField";
import { useNodesToEnumArray } from "@/containers/admin/CRUD/fields/util";

const ImageDialogMediasQuery = gql`
  query ImageDialogMediasQuery {
    mediaDescriptors(
      offset: 0
      limit: 10
      where: { primaryType: { eq: "image" } }
    ) {
      nodes {
        id: secondaryType
        label
        enabled
      }
    }
  }
`;

export const TypeField = memo((props) => {
  const { data } = useSafeQuery(ImageDialogMediasQuery);
  const enumValues = useNodesToEnumArray(data?.mediaDescriptors?.nodes ?? []);
  const disabledValues = enumValues.filter((v) => !v.enabled);

  return (
    <EnumField
      required
      enum={enumValues}
      disabledValues={disabledValues}
      {...props}
    />
  );
});
