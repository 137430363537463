import { makeIcon } from "./utils";

export const SvgOrderedList = makeIcon(
  "SvgOrderedList",
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.539 8.01h10.384"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.539 12h10.384"
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.539 15.99h10.384"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.32 9.12V7.262l-.407.433-.298-.326.776-.81h.458V9.12H5.32ZM4.67 13.159v-.43c.526-.405.875-.693 1.045-.866.172-.172.258-.334.258-.486a.288.288 0 0 0-.119-.245.457.457 0 0 0-.283-.088.868.868 0 0 0-.658.294l-.298-.36a1.09 1.09 0 0 1 .426-.313c.168-.07.343-.105.523-.105.27 0 .494.074.672.223a.73.73 0 0 1 .269.595c0 .21-.081.416-.243.618-.163.201-.418.429-.765.683h1.026v.48H4.67ZM4.615 16.633l.275-.356c.084.09.187.161.31.214a.928.928 0 0 0 .367.078c.148 0 .262-.03.343-.09a.288.288 0 0 0 .123-.241c0-.105-.04-.182-.121-.23-.08-.048-.204-.072-.373-.072-.18 0-.283 0-.307.003v-.476c.03.003.132.004.307.004.14 0 .25-.024.331-.071a.232.232 0 0 0 .123-.214.254.254 0 0 0-.128-.227.605.605 0 0 0-.33-.08c-.24 0-.448.09-.627.27l-.26-.338c.232-.271.546-.407.943-.407.288 0 .515.063.681.188a.601.601 0 0 1 .25.506.52.52 0 0 1-.16.386.719.719 0 0 1-.38.197.678.678 0 0 1 .397.187.552.552 0 0 1 .178.428.66.66 0 0 1-.267.544c-.176.14-.41.21-.703.21-.216 0-.408-.038-.577-.115a1.038 1.038 0 0 1-.395-.298Z"
      fill="currentColor"
    />
  </svg>,
);
