import { gql } from "@apollo/client";

import { ArticleDateEditor } from "@/containers/article/ArticleDateEditor";
import {
  ArticleAlertSelector,
  ArticleExposureAlertEditor,
  ArticleExposureAlertEditorProps,
} from "@/containers/article/ArticleExposureAlertEditor";
import type { CellType } from "@/containers/list/ListState";
import type { ArticleDateEditor_articleFragment } from "@/gql-types";

import {
  ArticleExposurePrintDate,
  ArticleExposurePrintDateProps,
} from "../../ArticleExposurePrintDate";

export type DateCellArticle = ArticleDateEditor_articleFragment;

export const dateCell: CellType<DateCellArticle> = {
  key: "date",
  title: "Date de publication numérique",
  width: 110,
  cellPadding: "0.25rem 0",
  render: ({ node }) => <ArticleDateEditor article={node} />,
  fragment: gql`
    fragment dateCell_article on Article {
      ...ArticleDateEditor_article
    }

    ${ArticleDateEditor.fragments.article}
  `,
};

export type AlertArticleExposureCellProps =
  ArticleExposureAlertEditorProps["article"];

export const alertCell: CellType<AlertArticleExposureCellProps> = {
  key: "date",
  title: "Date d’alerte",
  width: 130,
  cellPadding: "0.25rem 0",
  render: ({ node }) => <ArticleAlertSelector article={node} />,
  fragment: gql`
    fragment alertCell_article on Article {
      ...ArticleExposureAlertEditor_article
    }
    ${ArticleExposureAlertEditor.fragments.article}
  `,
};
export type PrintArticleExposureCellProps =
  ArticleExposurePrintDateProps["article"];

export const printDateCell: CellType<PrintArticleExposureCellProps> = {
  key: "date",
  title: "Date d’édition",
  width: 110,
  cellPadding: "0.25rem 0",
  render: ({ node }) => <ArticleExposurePrintDate article={node} />,
  fragment: gql`
    fragment printDateCell_article on Article {
      ...ArticleExposurePrintDate_article
    }
    ${ArticleExposurePrintDate.fragments.article}
  `,
};
