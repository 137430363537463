import { gql, useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import { useToaster } from "swash/Toast";
import { Tooltip } from "swash/Tooltip";

import { useArticleAuditTrailTooltip } from "@/components/ArticleAuditTrail";
import { EditInline } from "@/components/EditInline";
import {
  CharCountEditor_updateArticleMutation,
  CharCountEditor_updateArticleMutationVariables,
} from "@/gql-types";

const Mutation = gql`
  mutation CharCountEditor_updateArticle($articleId: Int!, $nbCharMax: Int!) {
    updateArticle(input: { id: $articleId, nbCharMax: $nbCharMax }) {
      id
      nbCharMax
    }
  }
`;

type useCharCountEditorStateProps = {
  articleId?: number;
  title?: string;
};

export const useCharCountEditorState = ({
  articleId,
  title,
}: useCharCountEditorStateProps) => {
  const [editing, setEditing] = useState(false);
  return {
    editing,
    setEditing,
    articleId: articleId ?? null,
    buttonProps: {
      title,
      onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        setEditing(true);
      },
      className: "group/edit-inline cursor-pointer",
    },
  };
};

const EditInlineInput = (props: any) => {
  return <input type="number" {...props} />;
};

type CharCountEditorProps = {
  state: ReturnType<typeof useCharCountEditorState>;
  onSubmit?: (value: number) => void;
  placeholder?: string;
  nbChar: number;
  nbCharMax: number;
  disabled?: boolean;
};
export const CharCountEditor = ({
  state,
  onSubmit,
  placeholder,
  nbChar,
  nbCharMax,
  disabled,
}: CharCountEditorProps) => {
  const { editing, setEditing, articleId } = state;
  const [updateArticleNbCharMax] = useMutation<
    CharCountEditor_updateArticleMutation,
    CharCountEditor_updateArticleMutationVariables
  >(Mutation);
  const tooltip = useArticleAuditTrailTooltip("nbCharMax");
  const toaster = useToaster();

  const handleSubmit = useCallback(
    (value: string) => {
      setEditing(false);
      const numValue = parseInt(value, 10);

      if (onSubmit) {
        onSubmit(numValue);
        return;
      }

      if (!articleId) return;

      updateArticleNbCharMax({
        variables: {
          articleId: articleId,
          nbCharMax: numValue,
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateArticle: {
            __typename: "Article",
            id: articleId,
            nbCharMax: numValue,
          },
        },
      }).catch(() => {
        toaster.danger("La mise à jour du nombre de signes maximal a échoué");
      });
    },
    [articleId, toaster, updateArticleNbCharMax, setEditing, onSubmit],
  );

  const handleClose = useCallback(() => {
    setEditing(false);
  }, [setEditing]);

  return (
    <Tooltip
      tooltip={
        state.editing || disabled
          ? null
          : (tooltip ?? "Nombre de signes maximal")
      }
    >
      <div className="flex items-center tabular-nums">
        <span className={nbChar > nbCharMax ? "font-bold text-warning-on" : ""}>
          {nbChar.toLocaleString()}
        </span>
        <span className="mx-px pl-1 text-grey-on-light">/</span>
        <EditInline
          // @ts-expect-error use a js component
          className="px-1 tabular-nums"
          placeholder={placeholder ?? " "}
          ariaLabel="Nombre de signes maximal"
          editing={editing}
          initialValue={nbCharMax}
          onClose={handleClose}
          onSubmit={handleSubmit}
          inputComponent={EditInlineInput}
          renderValue={(value: string) => value.toLocaleString()}
        />
      </div>
    </Tooltip>
  );
};
