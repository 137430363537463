import * as Ariakit from "@ariakit/react";
import clsx from "clsx";
import * as React from "react";
import { forwardRef } from "react";

import { Button, ButtonProps } from "./Button";
import {
  IoCheckmark,
  IoChevronDown,
  IoChevronUp,
  IoCloseCircleOutline,
} from "./Icon";
import {
  ItemClassNameProps,
  ListSeparatorClassNameProps,
  getItemClassName,
  getListSeparatorClassName,
} from "./Menu";
import { PopoverCard } from "./Popover";
import { invariant } from "./utils/invariant";
import { InputClassNameProps, getInputClassName } from "./v2/TextInput";

export type { SelectStore } from "@ariakit/react";

export const useSelectStore = ({ ...props }: Ariakit.SelectStoreProps = {}) => {
  return Ariakit.useSelectStore(props);
};

export type SelectListProps = Ariakit.SelectListProps;

export const SelectList = forwardRef<HTMLDivElement, SelectListProps>(
  (props, ref) => {
    return <Ariakit.SelectList ref={ref} focusable={false} {...props} />;
  },
);
export type SelectLabelProps = Ariakit.SelectLabelProps & { label?: string };

export const SelectLabel: React.FC<SelectLabelProps> = (props) => {
  const { label } = props;

  if (!label) return null;

  return (
    <div className="relative -top-2 flex h-0 px-2 font-accent text-xs">
      <Ariakit.SelectLabel {...props} className="select-label text-white">
        {label}
      </Ariakit.SelectLabel>
      <Ariakit.SelectLabel {...props} className="absolute text-grey-on">
        {label}
      </Ariakit.SelectLabel>
    </div>
  );
};

export interface SelectArrowProps {
  open: boolean;
}

export const SelectArrow: React.FC<SelectArrowProps> = (props) => {
  return (
    <div className="pointer-events-none">
      {props.open ? <IoChevronUp /> : <IoChevronDown />}
    </div>
  );
};

export interface SelectClearProps extends ButtonProps {
  store?: Ariakit.SelectStore;
  clearable?: boolean;
  onClear?: (value: any) => void;
}

export const SelectClear = forwardRef<HTMLButtonElement, SelectClearProps>(
  (
    {
      store,
      "aria-label": ariaLabel = "Vider",
      onClear,
      clearable = true,
      ...props
    },
    ref,
  ) => {
    const context = Ariakit.useSelectContext();
    store = store || context;

    invariant(
      store,
      process.env.NODE_ENV !== "production" &&
        "SelectClear must receive a `store` prop or be wrapped in a SelectProvider component.",
    );

    const value = Ariakit.useStoreState(store, "value");
    const open = Ariakit.useStoreState(store, "open");
    const empty = !value || (Array.isArray(value) && value.length === 0);
    if (empty || !clearable) {
      return <SelectArrow open={open ?? false} />;
    }

    return (
      <Button
        ref={ref}
        appearance="text"
        scale="xs"
        iconOnly
        asChild
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          if (onClear) {
            onClear(store.getState().value);
          } else {
            store.setValue((value) => {
              if (Array.isArray(value)) {
                return [];
              }
              return "";
            });
          }
          // on clear, force focus and blur so the form
          // is considered as touched and errors are displayed
          store.getState().selectElement?.focus();
          store.getState().selectElement?.blur();
          store.hide();
        }}
        className="-my-2 -mr-1"
        aria-label={ariaLabel}
        {...props}
      >
        <div>
          <IoCloseCircleOutline />
        </div>
      </Button>
    );
  },
);

export interface SelectProps
  extends Ariakit.SelectProps<"button">,
    Omit<InputClassNameProps, "placeholder"> {}

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
  ({ className, scale, children, store, ...props }, ref) => {
    const context = Ariakit.useSelectContext();
    store = store || context;
    const value = Ariakit.useStoreState(store, "value");

    const placeholder = value === "" || value?.length === 0;

    const inputClassName = getInputClassName({
      className,
      scale,
      placeholder,
    });
    const funcChildren = typeof children === "function";

    return (
      <Ariakit.Select
        ref={ref}
        store={store}
        className={clsx(
          inputClassName,
          !funcChildren &&
            "inline-flex items-center justify-between gap-2 overflow-hidden overflow-ellipsis whitespace-nowrap bg-white",
        )}
        {...props}
      >
        {children}
      </Ariakit.Select>
    );
  },
);

export interface SelectButtonProps extends SelectProps {
  /**
   * Use the child as the component.
   */
  asChild?: boolean;
  children?: React.ReactNode;
}

export const SelectButton = forwardRef<HTMLButtonElement, SelectButtonProps>(
  ({ children, asChild, ...props }, ref) => {
    return (
      <Ariakit.Select
        ref={ref}
        render={asChild ? (children as React.ReactElement) : undefined}
        {...props}
        role="button"
      >
        {asChild ? null : children}
      </Ariakit.Select>
    );
  },
);

const SelectItemCheckedContext = React.createContext(false);

export function checkIsSelected(
  stateValue?: string | string[],
  itemValue?: string,
) {
  if (stateValue == null) return false;
  if (itemValue == null) return false;
  if (Array.isArray(stateValue)) {
    return stateValue.includes(itemValue);
  }
  return stateValue === itemValue;
}

export type SelectItemProps = Ariakit.SelectItemProps & ItemClassNameProps;

export const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ variant, className, store, ...props }, ref) => {
    const context = Ariakit.useSelectContext();
    store = store || context;
    const value = Ariakit.useStoreState(store, "value");
    const itemClassName = getItemClassName({ variant, className });
    const checked = checkIsSelected(value, props.value);
    return (
      <SelectItemCheckedContext.Provider value={checked}>
        <Ariakit.SelectItem
          ref={ref}
          store={store}
          className={itemClassName}
          // preserve autofocus handling
          accessibleWhenDisabled
          {...props}
        />
      </SelectItemCheckedContext.Provider>
    );
  },
);

export const SelectMarker = React.memo(
  ({ multi, checked }: { multi: boolean; checked: boolean }) => {
    if (multi) {
      return (
        <div
          className={clsx(
            "flex shrink-0 items-center justify-center rounded-sm text-white",
            checked
              ? "bg-primary-bg-strong"
              : "bg-white ring-1 ring-grey-border-light",
          )}
          style={{ width: 14, height: 14 }}
        >
          <IoCheckmark size={10} />
        </div>
      );
    }
    return (
      <IoCheckmark className={clsx("shrink-0", !checked && "invisible")} />
    );
  },
);

export const SelectItemCheck = React.memo<{ store?: Ariakit.SelectStore }>(
  ({ store }) => {
    const context = Ariakit.useSelectContext();
    store = store || context;
    invariant(
      store,
      process.env.NODE_ENV !== "production" &&
        "SelectItemCheck must receive a `store` prop or be wrapped in a SelectProvider component.",
    );
    const value = Ariakit.useStoreState(store, "value");
    const multi = Array.isArray(value);
    const singleEmpty = value === "";
    const checked = React.useContext(SelectItemCheckedContext);
    if (singleEmpty) {
      return null;
    }
    return <SelectMarker multi={multi} checked={checked} />;
  },
);

export interface SelectPopoverProps
  extends Omit<Ariakit.SelectPopoverProps, "children"> {
  children: React.ReactNode;
  /**
   * Whether the select contains a combobox.
   * @default false
   */
  combobox?: boolean;
}

export const SelectPopover = forwardRef<HTMLDivElement, SelectPopoverProps>(
  ({ className, style, combobox, gutter = 4, ...props }, ref) => {
    return (
      <Ariakit.SelectPopover
        ref={ref}
        className={clsx(
          className,
          combobox ? "flex flex-col overflow-hidden" : "overflow-auto p-1",
        )}
        wrapperProps={{
          className: "!z-popover",
        }}
        gutter={gutter}
        style={{
          maxHeight: "min(var(--popover-available-height, 320px), 320px)",
          ...style,
        }}
        composite={!combobox}
        {...props}
        render={<PopoverCard />}
      />
    );
  },
);

export type SelectSeparatorProps = Ariakit.SelectSeparatorProps &
  ListSeparatorClassNameProps;

export const SelectSeparator = forwardRef<HTMLHRElement, SelectSeparatorProps>(
  ({ className, ...props }, ref) => {
    const listSeparatorClassName = getListSeparatorClassName({ className });
    return (
      <Ariakit.SelectSeparator
        ref={ref}
        className={listSeparatorClassName}
        {...props}
      />
    );
  },
);
