import { Plugin, PluginKey } from "@tiptap/pm/state";

export const TypographicRulesPluginKey =
  new PluginKey<TypographicRulesPluginState>("invisibleCharacters");

export type TypographicRulesPluginState = {
  typographicalCorrection: boolean;
  italicsBetweenQuotes: boolean;
};

export const SetTypographicRulesActiveTransaction = "setTypographicRulesActive";

/**
 * Very simple plugin to hold the state of the extension
 */
export const TypographicRulesPlugin = (
  initialState: TypographicRulesPluginState,
) => {
  return new Plugin<TypographicRulesPluginState>({
    key: TypographicRulesPluginKey,
    state: {
      init: () => {
        return initialState;
      },
      apply: (tr, state) => {
        const update = tr.getMeta(SetTypographicRulesActiveTransaction);
        if (update != null) {
          return {
            ...state,
            ...update,
          };
        }
        return state;
      },
    },
  });
};
