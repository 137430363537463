import memoizeOne from "memoize-one";

import type {
  DraggableDimension,
  DraggableDimensionMap,
  DroppableId,
} from "../types";
import { toDraggableList } from "./dimension-structures";

export default memoizeOne(
  (
    // using droppableId to avoid cache busted when we
    // update the droppable (such as when it scrolls)
    droppableId: DroppableId,
    draggables: DraggableDimensionMap,
  ): DraggableDimension[] => {
    const result = toDraggableList(draggables)
      .filter((draggable) => droppableId === draggable.descriptor.droppableId)
      // Dimensions are not guarenteed to be ordered in the same order as keys
      // So we need to sort them so they are in the correct order
      .sort((a, b) => a.descriptor.index - b.descriptor.index);

    return result;
  },
);
