import { useField } from "react-final-form";
import { EnumSelect, useEnumSelectState } from "swash/v2/EnumSelect";

const items = [{ value: "true" }, { value: "false" }];

function ValueLabel({ value, label, trueLabel, falseLabel }) {
  if (!value) return label;
  return (
    <div className="flex items-center">
      {value === "true" ? trueLabel : falseLabel}
    </div>
  );
}

export function BooleanFiltersField({
  name,
  label,
  scale,
  trueLabel,
  falseLabel,
  ...others
}) {
  const {
    input: { onChange, value },
  } = useField(name, {
    format: (v) => items.find((i) => i.value === String(v)) || null,
    parse: (v) => {
      switch (v?.value) {
        case "true":
          return true;
        case "false":
          return false;
        default:
          return null;
      }
    },
    ...others,
  });

  const state = useEnumSelectState({
    value: value || null,
    onChange,
    items,
    labelSelector: ({ value }) => (
      <ValueLabel
        showTooltip
        value={value}
        label={label}
        trueLabel={trueLabel}
        falseLabel={falseLabel}
      />
    ),
    valueSelector: (filter) => filter.value,
  });

  return (
    <div>
      <EnumSelect
        state={state}
        scale={scale}
        placeholder={label}
        aria-label={label}
      />
    </div>
  );
}
