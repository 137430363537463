import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Children, isValidElement, useState } from "react";
import { createPortal } from "react-dom";

type Direction = "horizontal" | "vertical";

type SimpleSortableProps = React.ComponentProps<typeof DndContext> & {
  withDragOverlay?: boolean;
  direction: Direction;
  // Preset's SortableContext props
  disabled?: boolean;
  items: (UniqueIdentifier | { id: UniqueIdentifier })[];
  id?: string;
};

export function SimpleSortable({
  children,
  onDragStart,
  withDragOverlay,
  direction,
  disabled,
  items,
  id,
  ...props
}: SimpleSortableProps) {
  const [dragOverlay, setDragOverlay] = useState<HTMLElement>();

  const dragOverlaysChildren = Children.toArray(children).filter(
    (child) => isValidElement(child) && child.type === DragOverlay,
  );
  const otherChildren = Children.toArray(children).filter(
    (child) => !isValidElement(child) || child.type !== DragOverlay,
  );

  const handleDragStart = (event: DragStartEvent) => {
    if (withDragOverlay && event.activatorEvent.target instanceof HTMLElement) {
      const preview = (
        event.activatorEvent.target.matches('[aria-roledescription="sortable"]')
          ? event.activatorEvent.target
          : event.activatorEvent.target.closest(
              '[aria-roledescription="sortable"]',
            )
      )?.cloneNode(true) as HTMLElement;
      preview.style.cursor = "grabbing";
      setDragOverlay(preview);
    }
    onDragStart?.(event);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      {...props}
    >
      <SortableContext
        id={id}
        items={items}
        disabled={disabled}
        strategy={
          direction === "horizontal"
            ? horizontalListSortingStrategy
            : verticalListSortingStrategy
        }
      >
        {otherChildren}
      </SortableContext>
      {withDragOverlay &&
        createPortal(
          <DragOverlay>
            <div ref={(el) => dragOverlay && el?.appendChild(dragOverlay)} />
          </DragOverlay>,
          document.body,
        )}
      {dragOverlaysChildren.length > 0 &&
        createPortal(dragOverlaysChildren, document.body)}
    </DndContext>
  );
}

type SortableHookParams = Parameters<typeof useSortable>[0];

export const useSimpleSortable = ({ ...params }: SortableHookParams) => {
  const { transform, transition, isDragging, ...props } = useSortable({
    ...params,
  });

  const style: React.CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };
  if (isDragging) {
    style.opacity = 0.5;
  }

  return {
    ...props,
    isDragging,
    style,
  };
};

export const getIndex = (
  items: (UniqueIdentifier | { id: UniqueIdentifier })[],
  id: UniqueIdentifier,
) => {
  return items.findIndex((item) => {
    return typeof item === "object" ? item.id === id : item === id;
  });
};

export const getIndices = (
  items: (UniqueIdentifier | { id: UniqueIdentifier })[],
  event: DragEndEvent,
) => {
  const activeIndex = getIndex(items, event.active.id);
  const overIndex = event.over ? getIndex(items, event.over.id) : -1;
  return { activeIndex, overIndex };
};
