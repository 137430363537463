import { tz } from "@date-fns/tz";
import {
  Duration,
  formatISO,
  intervalToDuration,
  isToday,
  format as rawFormat,
  set,
} from "date-fns";
import { fr } from "date-fns/locale";

export type DateLike = Date | number | string;

export const formatDuration = (seconds?: number | null) => {
  if (seconds == null) return "–";

  const duration = intervalToDuration({ start: 0, end: seconds * 1e3 });

  if (duration.months) {
    return `${duration.months}mois et ${duration.days ?? 0}j`;
  }
  if (duration.days) {
    return `${duration.days}j et ${duration.hours ?? 0}h`;
  }
  if (duration.hours) {
    return `${duration.hours}h et ${duration.minutes ?? 0}min`;
  }
  if (duration.minutes) {
    return `${duration.minutes}min et ${duration.seconds ?? 0}sec`;
  }
  return `${duration.seconds ?? 0}sec`;
};

/**
 * @see https://github.com/moment/moment/blob/2.30.1/src/lib/duration/create.js#L50-L60
 */
export const parseIsoDuration = (input: string): Duration => {
  const isoRegex =
    /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;

  const parseIso = (val: string | undefined, sign: -1 | 1) => {
    // We'd normally use ~~inp for this, but unfortunately it also
    // converts floats to ints.
    // inp may be undefined, so careful calling replace on it.
    const res = val ? parseFloat(val.replace(",", ".")) : 0;
    // apply sign while we're at it
    return (isNaN(res) ? 0 : res) * sign;
  };

  const match = isoRegex.exec(input);
  if (!match) throw new Error("Invalid ISO duration: " + input);
  const sign = match[1] === "-" ? -1 : 1;
  return {
    years: parseIso(match[2], sign),
    months: parseIso(match[3], sign),
    weeks: parseIso(match[4], sign),
    days: parseIso(match[5], sign),
    hours: parseIso(match[6], sign),
    minutes: parseIso(match[7], sign),
    seconds: parseIso(match[8], sign),
  };
};

export const formatPublicationDate = (date: DateLike): string => {
  return isToday(date)
    ? `à ${rawFormat(date, "H:mm")}`
    : `le ${rawFormat(date, "dd/MM/yyyy")}`;
};

export const formatISOinUTC = (date: DateLike): string => {
  return (
    formatISO(date, { in: tz("UTC") })
      // Manually add milliseconds
      .replace(/Z$/, rawFormat(date, `'.'SSS'Z'`))
  );
};

export const formatForParis = (date: DateLike, pattern: string): string => {
  return rawFormat(date, pattern, { locale: fr, in: tz("Europe/Paris") });
};

export type DateTimePart = { date: string; time: string };

export const dateToParts = (value?: DateLike | null): DateTimePart | null => {
  if (!value) return null;
  const date = formatISO(value, { in: tz("UTC") });
  return {
    date: rawFormat(date, "yyyy-MM-dd", { in: tz("Europe/Paris") }),
    time: rawFormat(date, "HH:mm:ss", { in: tz("Europe/Paris") }),
  };
};

export const partsToDate = (dateParts?: DateTimePart | null): Date | null => {
  if (!dateParts) return null;
  if (!dateParts.time || !dateParts.date) return null;
  const [hours = 0, minutes = 0] = dateParts.time.split(":").map(Number);
  return set(dateParts.date, { hours, minutes }, { in: tz("Europe/Paris") });
};
