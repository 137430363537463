import { Node } from "@tiptap/pm/model";
import { EditorState } from "@tiptap/pm/state";

export function getSelectedBlocks(state: EditorState) {
  const { from, to } = state.selection;
  const nodes: Node[] = [];
  state.doc.nodesBetween(from, to, (node) => {
    if (node.isBlock) nodes.push(node);
  });
  return nodes;
}
