import * as React from "react";

import { shallow } from "./shallow";

export { useId } from "@ariakit/react-core/utils/hooks";

/**
 * returns a memoized version of a selector function using a shallow comparison for memoization.
 */
export function useShallow<S, K extends keyof S, U>(
  keyOrSelector: K | ((state: S) => U | undefined),
): (state: S) => U | S[K] | undefined {
  const prev = React.useRef<U>();
  return (state) => {
    const next =
      typeof keyOrSelector === "function" ? keyOrSelector(state) : null;
    const key = typeof keyOrSelector === "string" ? keyOrSelector : undefined;
    if (next) {
      return shallow(prev.current, next)
        ? (prev.current as U)
        : (prev.current = next);
    }
    if (key) return state[key];
    return undefined;
  };
}
