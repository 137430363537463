import { Button } from "swash/Button";
import { IoPencil } from "swash/Icon";

import { RemoteEditImageDialogDisclosure } from "@/containers/image/ImageEditDialog";

export const ImageEditDisclosure = ({
  imageId,
  label,
  scale = "sm",
  variant = "secondary",
  icon = true,
  editor,
  preview,
  onDelete,
  ...props
}) => {
  return (
    <RemoteEditImageDialogDisclosure
      dialogProps={{
        imageId,
        editor,
        preview,
        onDelete,
      }}
      render={
        <Button
          type="button"
          label={label}
          scale={scale}
          variant={variant}
          title="Éditer l’image"
        />
      }
      {...props}
    >
      {icon && <IoPencil />} {label}
    </RemoteEditImageDialogDisclosure>
  );
};
