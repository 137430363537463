import { isMac } from "swash/utils/platform";

import createEditorControl from "@/components/editor/controls/createEditorControl";
import { Erase } from "@/components/icons";

export const RemoveStyleControl = createEditorControl({
  name: "removeStyle",
  label: "Supprimer la mise en forme",
  shortcut: isMac() ? ["⌘", "/"] : ["⌘", ":"],
  icon: <Erase />,
  command: ({ editor }) => editor.chain().focus().removeStyle().run(),
  canExecute: ({ editor }) => editor.can().removeStyle(),
});
