import { forwardRef, useEffect, useMemo } from "react";
import { useFormState } from "react-final-form";
import { useToaster } from "swash/Toast";

export const useFormErrors = () => {
  const { submitFailed, submitError, invalid, error } = useFormState({
    subscription: {
      submitFailed: true,
      submitError: true,
      invalid: true,
      error: true,
    },
  });

  return useMemo(() => {
    if ((submitFailed && submitError) || (invalid && error)) {
      let errors: string[] = [];
      if (Array.isArray(submitError)) {
        errors = [...errors, ...submitError];
      } else if (submitError) {
        errors = [...errors, submitError];
      }
      if (Array.isArray(error)) {
        errors = [...errors, ...error];
      } else if (error) {
        errors = [...errors, error];
      }

      return errors;
    }

    return [];
  }, [submitFailed, submitError, invalid, error]);
};

type ErrorMessageProps = {
  errors: string[];
};

const ErrorMessage = (props: ErrorMessageProps) => {
  if (props.errors.length > 1) {
    return (
      <ul>
        {props.errors.map((error, index) => (
          <li key={index} className="ml-6 list-disc">
            {error}
          </li>
        ))}
      </ul>
    );
  }

  return (props.errors[0] ?? null) as React.ReactElement | null;
};

export const FormErrorToaster = () => {
  const errors = useFormErrors();
  const toaster = useToaster();

  useEffect(() => {
    if (errors.length) {
      toaster.danger(<ErrorMessage errors={errors} />, { dismissDelay: 0 });
    }
  }, [errors, toaster]);

  return null;
};

export type FormErrorProps = {
  children?: (errors: string[]) => React.ReactNode;
} & Omit<React.HTMLAttributes<HTMLDivElement>, "children">;

export const FormError = forwardRef<HTMLDivElement, FormErrorProps>(
  ({ children, ...props }, ref) => {
    const errors = useFormErrors();

    if (!errors.length) return null;
    return (
      <div
        ref={ref}
        className="font-accent text-danger-on"
        role="alert"
        {...props}
      >
        {typeof children === "function" ? (
          children(errors)
        ) : (
          <ErrorMessage errors={errors} />
        )}
      </div>
    );
  },
);
