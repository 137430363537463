import { gql } from "@apollo/client";
import { useMediaQuery } from "react-responsive";

import { mediaQueries } from "@/config/mediaQueries";
import {
  ArticleSectionsEdit,
  ArticleSectionsEditProps,
} from "@/containers/article/ArticleSectionsEdit";
import { useListReadOnly } from "@/containers/list/List";
import type { CellType } from "@/containers/list/ListState";

export type SectionsCellArticle = ArticleSectionsEditProps["article"];

export const sectionsCell: CellType<SectionsCellArticle> = {
  key: "sections",
  title: "Services",
  width: 96,
  cellPadding: "0.25rem 0",
  render: ({ node }) => <ArticleSectionsEdit article={node} />,
  useIsVisible: () => {
    const matchesMediaQuery = useMediaQuery({
      query: mediaQueries.desktop.list.md,
    });
    const listReadOnly = useListReadOnly();
    return !matchesMediaQuery || listReadOnly;
  },
  fragment: gql`
    fragment sectionsCell_article on Article {
      ...ArticleSectionsEdit_article
    }

    ${ArticleSectionsEdit.fragments.article}
  `,
};
