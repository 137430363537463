import { format as formatDate, parse as parseDate } from "date-fns";
import { fr } from "date-fns/locale/fr";
import { ComponentProps, useState } from "react";
import {
  DayPicker,
  DateRange as DayPickerDateRange,
  PropsRange,
  PropsSingle,
} from "react-day-picker";
import "react-day-picker/style.css";

import { cn } from "../utils/classNames";
import "./assets/datepicker.css";

export type DatePickerProps = ComponentProps<typeof DayPicker>;
export type { Modifiers as DayModifiers } from "react-day-picker";

export const DatePicker = ({
  numberOfMonths = 1,
  className,
  ...props
}: DatePickerProps) => {
  return (
    <DayPicker
      fixedWeeks
      numberOfMonths={numberOfMonths}
      showOutsideDays={numberOfMonths === 1}
      locale={fr}
      modifiersClassNames={{ hover: "rdp-modifier-hover" }}
      className={cn(
        "day-picker",
        props.disabled && "x-rdp-disabled",
        className,
      )}
      {...props}
    />
  );
};

if (process.env["NODE_ENV"] !== "production") {
  DatePicker.displayName = "DatePicker";
}

const formatSingle = (value: string | null) => {
  if (!value) return null;
  return parseDate(value, "yyyy-MM-dd", new Date(), { locale: fr });
};

const parseSingle = (value: Date | undefined) => {
  if (!value) return null;
  return formatDate(value, "yyyy-MM-dd", { locale: fr });
};

export type SingleDatePickerProps = Omit<
  PropsSingle & DatePickerProps,
  "mode" | "selected"
> & {
  value: string | null;
  onChange: (value: string | null) => void;
  defaultMonth?: Date;
};

export const SingleDatePicker = ({
  defaultMonth,
  value,
  onChange,
  ...props
}: SingleDatePickerProps) => {
  const selected = formatSingle(value);
  return (
    <DatePicker
      mode="single"
      defaultMonth={defaultMonth ?? selected ?? undefined}
      selected={selected ?? undefined}
      onSelect={(value) => onChange(parseSingle(value))}
      {...props}
    />
  );
};

if (process.env["NODE_ENV"] !== "production") {
  SingleDatePicker.displayName = "SingleDatePicker";
}

export type DateRange = null | { from: string | null; to: string | null };

const formatRange = (value: DateRange) => {
  if (!value) return null;
  return { from: formatSingle(value.from), to: formatSingle(value.to) };
};

const parseRange = (value: DayPickerDateRange | undefined): DateRange => {
  if (!value) return null;

  return {
    from: parseSingle(value.from),
    to: parseSingle(value.to ?? value.from),
  };
};

export type RangeDatePickerProps = Omit<
  PropsRange & DatePickerProps,
  "mode" | "selected"
> & {
  value: DateRange;
  onChange: (value: DateRange) => void;
  defaultMonth?: Date;
};

export const RangeDatePicker = (props: RangeDatePickerProps) => {
  const { value, onChange, defaultMonth, ...otherProps } = props;
  const selected = formatRange(value);
  const [defaultSelectedMonth] = useState(
    defaultMonth ?? selected?.from ?? null,
  );

  return (
    <DatePicker
      mode="range"
      defaultMonth={defaultSelectedMonth ?? undefined}
      selected={
        selected
          ? { from: selected.from ?? undefined, to: selected.to ?? undefined }
          : undefined
      }
      onSelect={(value) => onChange(parseRange(value))}
      {...otherProps}
    />
  );
};

if (process.env["NODE_ENV"] !== "production") {
  RangeDatePicker.displayName = "RangeDatePicker";
}

if (process.env["NODE_ENV"] !== "production") {
  DatePicker.displayName = "DatePicker";
}
