import moment from "moment";

import { ImageForm_imageFragment as ImageFragment } from "@/gql-types";

export type FieldName =
  | "type"
  | "caption"
  | "credit"
  | "photographer"
  | "expiration"
  | "shootingDate"
  | "city"
  | "keywords"
  | "printAuthorized"
  | "webAuthorized"
  | "restrictionMode"
  | "usageLimit";

type FieldInfo<Field extends FieldName> = {
  name: Field;
  label: string;
  formatValue?: (value: ImageFragment[Field]) => string;
  format?: (value: ImageFragment[Field] | null) => ImageFragment[Field];
};

export const fieldsMap: { [field in FieldName]: FieldInfo<field> } = {
  type: {
    name: "type",
    label: "Type",
  },
  caption: {
    name: "caption",
    label: "Description",
  },
  credit: {
    name: "credit",
    label: "Source",
  },
  photographer: {
    name: "photographer",
    label: "Créateur",
  },
  expiration: {
    name: "expiration",
    label: "Date d’expiration",
    formatValue: (value) => moment(value).format("DD/MM/YYYY"),
    format: (value) => (value ? moment(value).format("YYYY-MM-DD") : null),
  },
  shootingDate: {
    name: "shootingDate",
    label: "Date de prise de vue",
    formatValue: (value) => moment(value).format("DD/MM/YYYY"),
    format: (value) => (value ? moment(value).format("YYYY-MM-DD") : null),
  },
  city: {
    name: "city",
    label: "Lieu de prise de vue",
  },
  keywords: {
    name: "keywords",
    label: "Mots-clés",
  },
  printAuthorized: {
    name: "printAuthorized",
    label: "Print",
  },
  webAuthorized: {
    name: "webAuthorized",
    label: "Numérique",
  },
  restrictionMode: {
    name: "restrictionMode",
    label: "Restriction",
    formatValue: (value) => {
      if (!value) return "";
      const restrictionsEnumValues = {
        default: "Aucune limite d’usage",
        limited: "Usage limité",
        blocked: "Usage bloqué",
      };
      return restrictionsEnumValues[value];
    },
    format: (value) => (value ? value : "default"),
  },
  usageLimit: {
    name: "usageLimit",
    label: "Limite d’utilisation",
    format: (value) => (value ? value : null),
  },
};
