import createEditorControl from "@/components/editor/controls/createEditorControl";
import { Case } from "@/components/icons";

export const ToggleCaseControl = createEditorControl({
  name: "toggleCase",
  label: "Inverser la casse",
  shortcut: ["⌘", "⇧", "k"],
  icon: <Case />,
  command: ({ editor }) => editor.chain().focus().toggleCase().run(),
  canExecute: ({ editor }) => editor.can().toggleCase(),
});
