import { gql } from "@apollo/client";
import { useMediaQuery } from "react-responsive";

import { mediaQueries } from "@/config/mediaQueries";
import {
  ArticleAuthorsEdit,
  ArticleAuthorsEditProps,
} from "@/containers/article/ArticleAuthorsEdit";
import { useListReadOnly } from "@/containers/list/List";
import type { CellType } from "@/containers/list/ListState";

export type SignaturesCellArticle = ArticleAuthorsEditProps["article"];

export const signaturesCell: CellType<SignaturesCellArticle> = {
  key: "signatures",
  title: "Signataires",
  width: 120,
  cellPadding: "0.25rem 0",
  fragment: gql`
    fragment signaturesCell_article on Article {
      ...ArticleAuthorsEdit_article
    }
    ${ArticleAuthorsEdit.fragments.article}
  `,
  useIsVisible: () => {
    const matchesMediaQuery = useMediaQuery({
      query: mediaQueries.desktop.list.md,
    });
    const listReadOnly = useListReadOnly();
    return !matchesMediaQuery || listReadOnly;
  },
  render: ({ node }) => <ArticleAuthorsEdit article={node} />,
};
