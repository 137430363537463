import baseSanitize from "sanitize-html";

const DEFAULT_ALLOWED_ATTRIBUTES = {
  div: ["style", "data-block"],
  p: ["style"],
  span: ["style", "id"],
  li: ["style"],
};
const DEFAULT_ALLOWED_TAGS = ["div", "p", "span", "br"];
const DEFAULT_TRANSFORM_TAGS = {
  h4: "p",
  h5: "p",
  h6: "p",
};

const CHARS_TO_DELETE = ["\u00AD"];

const NON_BREAKING_SPACE = 160;
const SPACE = 32;

const getAllowedTags = ({ allowedAttributes = {} } = {}) => {
  const allowedTags = Object.keys(allowedAttributes);
  let result = [...DEFAULT_ALLOWED_TAGS, ...allowedTags];
  if (allowedTags.includes("ol") || allowedTags.includes("ul")) {
    result.push("li");
  }
  return result;
};

const getAllowedAttributes = ({ allowedAttributes = {} } = {}) => {
  return Object.entries(allowedAttributes).reduce(
    (attrs, [key, value]) => {
      attrs[key] = DEFAULT_ALLOWED_ATTRIBUTES[key]
        ? [...DEFAULT_ALLOWED_ATTRIBUTES[key], ...value]
        : value;
      return attrs;
    },
    { ...DEFAULT_ALLOWED_ATTRIBUTES },
  );
};

const clearTagText = () => ({
  text: "",
});

const wordTransform = {
  body: clearTagText,
  html: clearTagText,
  head: clearTagText,
};

const getTransformTags = ({ transformTags } = {}) => {
  return { ...DEFAULT_TRANSFORM_TAGS, ...wordTransform, ...transformTags };
};

/**
 * Sanitize HTML
 * @param {string} html
 * @param {{ allowedAttributes?: { [key: string]: string[] } | undefined, transformTags?: { [key: string]: string | Function } | undefined}} ctx
 * @returns {string}
 */
export const sanitizeHTML = (html, ctx) => {
  return baseSanitize(
    html.replace(new RegExp(CHARS_TO_DELETE.join("|"), "ug"), ""),
    {
      allowedTags: getAllowedTags(ctx),
      allowedAttributes: getAllowedAttributes(ctx),
      transformTags: getTransformTags(ctx),
      exclusiveFilter: (node) => {
        if (
          node.tag === "span" &&
          node.mediaChildren.length &&
          node.mediaChildren[0] === "img"
        ) {
          return false;
        }
        if (
          node.tag === "span" &&
          (node.text.charCodeAt(0) === NON_BREAKING_SPACE ||
            (node.text.charCodeAt(0) === SPACE && node.text.length === 1))
        ) {
          return false;
        }
        if (node.tag === "br") {
          // set text to "br" to avoid removing the node if surrounding by an empty span
          // text is ignored by the sanitizer
          node.text = "br";
        }
        return (node.tag === "div" || node.tag === "span") && !node.text.trim();
      },
    },
  );
};
