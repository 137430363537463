import { ERRORS } from "@/config/messages";

export const mustNotBeSiriusURL = (value?: string) => {
  if (!value) return undefined;

  try {
    const url = new URL(value);
    if (url.hostname.endsWith(".sirius.press"))
      return ERRORS.forms.validators.mustNotBeSiriusURL;
  } catch {
    return undefined;
  }
  return;
};

export const mustNotBePreviewURL = (
  value: string | null | undefined,
  urls: string[] = [],
) => {
  if (!value || !urls.length) return undefined;

  const regexps = urls.map(
    (url) => new RegExp(url.replace(/\$\{(?:\w|\.)+\}/g, "(\\w+)")),
  );

  return regexps.some((re) => re.test(value))
    ? ERRORS.forms.validators.mustNotBeSiriusURL
    : undefined;
};
