import { Position } from "css-box-model";
import memoizeOne from "memoize-one";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import * as animation from "../../animation";
import {
  whatIsDraggedOver,
  whatIsDraggedOverFromResult,
} from "../../services/droppable/utils";
import { origin } from "../../services/position";
import type {
  DragImpact,
  DraggableDimension,
  DraggableId,
  DroppableId,
  MovementMode,
  State,
} from "../../types";
import isStrictEqual from "../is-strict-equal";
import type {
  DropAnimation,
  MappedProps,
  Props,
  StateSnapshot,
} from "./draggable-types";

type Selector = (state: State, ownProps: Props) => MappedProps;
type TrySelect = (state: State, ownProps: Props) => MappedProps | null;

function getDraggableSelector(): TrySelect {
  const memoizedOffset = memoizeOne(
    (x: number, y: number): Position => ({
      x,
      y,
    }),
  );

  const getMemoizedSnapshot = memoizeOne(
    (
      mode: MovementMode,
      draggingOver?: DroppableId,
      dropping?: DropAnimation,
    ): StateSnapshot => ({
      isDragging: true,
      isDropAnimating: Boolean(dropping),
      dropAnimation: dropping,
      mode,
      draggingOver,
    }),
  );

  const getMemoizedProps = memoizeOne(
    (
      offset: Position,
      mode: MovementMode,
      dimension: DraggableDimension,
      // the id of the droppable you are over
      draggingOver?: DroppableId,
      forceShouldAnimate?: boolean,
    ): MappedProps => ({
      type: "DRAGGING",
      dropping: undefined,
      draggingOver,
      mode,
      offset,
      dimension,
      forceShouldAnimate,
      snapshot: getMemoizedSnapshot(mode, draggingOver, undefined),
    }),
  );

  const selector: TrySelect = (state, ownProps) => {
    // Dragging
    if (state.isDragging) {
      // not the dragging item
      if (state.critical.draggable.id !== ownProps.draggableId) return null;

      const offset = state.current.client.offset;
      const dimension = state.dimensions.draggables[ownProps.draggableId]!;
      const draggingOver = whatIsDraggedOver(state.impact) ?? undefined;
      const forceShouldAnimate = state.forceShouldAnimate ?? undefined;

      return getMemoizedProps(
        memoizedOffset(offset.x, offset.y),
        state.movementMode,
        dimension,
        draggingOver,
        forceShouldAnimate,
      );
    }

    // Dropping
    if (state.phase === "DROP_ANIMATING") {
      const completed = state.completed;
      if (completed.result.draggableId !== ownProps.draggableId) return null;

      const dimension = state.dimensions.draggables[ownProps.draggableId]!;
      const result = completed.result;
      const mode = result.mode;
      // these need to be pulled from the result as they can be different to the final impact
      const draggingOver = whatIsDraggedOverFromResult(result) ?? undefined;
      const duration = state.dropDuration;

      // not memoized as it is the only execution
      const dropping: DropAnimation = {
        duration,
        curve: animation.curves.drop,
        moveTo: state.newHomeClientOffset,
      };

      return {
        type: "DRAGGING",
        offset: state.newHomeClientOffset,
        dimension,
        dropping,
        draggingOver,
        mode,
        forceShouldAnimate: undefined,
        snapshot: getMemoizedSnapshot(mode, draggingOver, dropping),
      };
    }

    return null;
  };

  return selector;
}

const atRest: MappedProps = {
  type: "SECONDARY",
  offset: origin,
  shouldAnimateDisplacement: true,
  snapshot: {
    isDragging: false,
    isDropAnimating: false,
  },
};

function getSecondarySelector(): TrySelect {
  const memoizedOffset = memoizeOne(
    (x: number, y: number): Position => ({
      x,
      y,
    }),
  );

  const getMemoizedProps = memoizeOne(
    (offset: Position, shouldAnimateDisplacement: boolean): MappedProps => ({
      type: "SECONDARY",
      offset,
      shouldAnimateDisplacement,
      snapshot: {
        isDragging: false,
        isDropAnimating: false,
      },
    }),
  );

  const getProps = (
    ownId: DraggableId,
    impact: DragImpact,
  ): MappedProps | null => {
    const visualDisplacement = impact.displaced.visible[ownId];
    if (!visualDisplacement) return null;

    const displaceBy = impact.displacedBy.point;
    const offset = memoizedOffset(displaceBy.x, displaceBy.y);

    return getMemoizedProps(offset, visualDisplacement.shouldAnimate);
  };

  const selector: TrySelect = (state, ownProps) => {
    // Dragging
    if (state.isDragging) {
      // we do not care about the dragging item
      if (state.critical.draggable.id === ownProps.draggableId) return null;
      return getProps(ownProps.draggableId, state.impact);
    }

    // Dropping
    if (state.phase === "DROP_ANIMATING") {
      const completed = state.completed;
      // do nothing if this was the dragging item
      if (completed.result.draggableId === ownProps.draggableId) return null;
      return getProps(ownProps.draggableId, completed.impact);
    }

    // Otherwise
    return null;
  };

  return selector;
}

// Returning a function to ensure each
// Draggable gets its own selector
export const makeMapStateToProps = (): Selector => {
  const draggingSelector = getDraggableSelector();
  const secondarySelector = getSecondarySelector();

  return (state, ownProps) =>
    draggingSelector(state, ownProps) ||
    secondarySelector(state, ownProps) ||
    atRest;
};

export const useMappedProps = (props: Props) => {
  const selector = useMemo(() => makeMapStateToProps(), []);
  return useSelector((state: State) => selector(state, props), isStrictEqual);
};
