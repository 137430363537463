import { DocumentNode, gql } from "@apollo/client";
import moment from "moment";
import { forwardRef } from "react";
import { IoInformationOutline } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";
import { cn } from "swash/utils/classNames";

import { ArticleExposureIcon } from "@/components/ExposureIcon";
import { ArticlePrintInfos } from "@/containers/article/ArticlePrintInfos";
import { ArticlePrintSummary } from "@/containers/article/ArticlePrintSummary";
import type { PrintCoreExposure_articleFragment } from "@/gql-types";

function shoulDisplayPriorPrintIndicator(
  article: PrintCoreExposure_articleFragment,
) {
  if (article.publicationUrl) {
    return false;
  }

  return moment(article.printDate).isBefore(moment(article.schedulerDate));
}

const InnerPrintDueDateIndicator: React.FC<{ className?: string }> = ({
  className,
}) => (
  <div
    className={cn("rounded-full bg-primary-on ring-white", className)}
    data-testid="print-due-date-indicator"
  >
    <IoInformationOutline className="text-white" />
  </div>
);

type PriorPrintDueDateIndicatorProps = {
  article: PrintCoreExposure_articleFragment;
  children?: React.ReactNode;
};

const PriorPrintDueDateIndicator = forwardRef<
  HTMLDivElement,
  PriorPrintDueDateIndicatorProps
>(({ article, children }, ref) => {
  return (
    <div ref={ref} className="relative">
      {children}
      {shoulDisplayPriorPrintIndicator(article) && (
        <div className="absolute -bottom-[15%] left-1/2">
          <InnerPrintDueDateIndicator className="text-[0.8rem]" />
        </div>
      )}
    </div>
  );
}) as React.ForwardRefExoticComponent<
  PriorPrintDueDateIndicatorProps & React.RefAttributes<HTMLDivElement>
> & { fragments: { article: DocumentNode } };
if (process.env["NODE_ENV"] !== "production") {
  PriorPrintDueDateIndicator.displayName = "PriorPrintDueDateIndicator";
}

PriorPrintDueDateIndicator.fragments = {
  article: gql`
    fragment PriorPrintDueDateIndicator_article on Article {
      dueDate
      printDate
      schedulerDate
      publicationUrl
    }
  `,
};

type PrintCoreExposureProps = {
  article: PrintCoreExposure_articleFragment;
};

export function PrintCoreExposure({
  article,
}: PrintCoreExposureProps): React.ReactElement | null {
  // Print exposure is fulfilled
  if (article.printInfos) {
    return (
      <Tooltip
        tooltip={
          <div className="flex flex-col gap-2">
            <div className="font-bold">
              <ArticlePrintSummary {...article} />
            </div>
            <ArticlePrintInfos printInfos={article.printInfos} />
            {shoulDisplayPriorPrintIndicator(article) && (
              <div className="flex items-start gap-2">
                <InnerPrintDueDateIndicator className="mt-1 text-base" />
                <span className="font-semibold text-white">
                  L’article est prévu pour une édition print qui précède la date
                  de publication numérique
                </span>
              </div>
            )}
          </div>
        }
      >
        <PriorPrintDueDateIndicator article={article}>
          <ArticleExposureIcon
            label="Print"
            icon={article.printInfos.decorrelated ? "PaperCopy" : "Paper"}
            state="fulfilled"
          />
        </PriorPrintDueDateIndicator>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      tooltip={
        <>
          <span className="font-bold">Print</span> (suggéré)
        </>
      }
    >
      <PriorPrintDueDateIndicator article={article}>
        <ArticleExposureIcon label="Print" icon="Paper" state="suggested" />
      </PriorPrintDueDateIndicator>
    </Tooltip>
  );
}

PrintCoreExposure.fragments = {
  article: gql`
    fragment PrintCoreExposure_article on Article {
      id
      printInfos {
        decorrelated
        ...ArticlePrintInfos_printInfos
      }
      ...ArticlePrintSummary_printSummary
      ...PriorPrintDueDateIndicator_article
    }

    ${ArticlePrintInfos.fragments.printInfos}
    ${ArticlePrintSummary.fragments.article}
    ${PriorPrintDueDateIndicator.fragments.article}
  `,
};
