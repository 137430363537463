import { useStoreState } from "@ariakit/react";
import { useCallback, useMemo } from "react";
import { Tab, TabList, TabPanel, useTabState } from "swash/Tab";
import { useEditorContext } from "swash/editor";

import { Form } from "@/components/forms/Form";
import { checkIsAnchor } from "@/services/anchor";

import { TabAnchor } from "./TabAnchor";
import { TabArticleUrl } from "./TabArticleUrl";
import type { LinkData } from "./types";

type Values = {
  url: string;
  text: string;
  affiliate: boolean;
  link: string | null;
  anchor: string | null;
};

type LinkEditorMenuFormProps = {
  link: LinkData;
  setEditMode: (value: boolean) => void;
  updateLink: (data: LinkData) => void;
  removeLink: () => void;
};

export function LinkEditorMenuForm({
  link,
  setEditMode,
  updateLink,
  removeLink,
}: LinkEditorMenuFormProps) {
  const { editor } = useEditorContext();
  const hasAnchors = useMemo(
    () => editor?.schema.marks["anchor"] != null,
    [editor],
  );

  const tab = useTabState({
    variant: "bar",
    size: "sm",
    defaultSelectedId: checkIsAnchor(link.url) ? "anchor" : "link",
  });

  const currentTab = useStoreState(tab.store, "selectedId");

  const handleSubmit = useCallback(
    ({ link, affiliate, anchor, text }: Values) => {
      const valueUrl = (currentTab === "anchor" ? anchor : link) ?? "";
      updateLink({
        url: valueUrl.startsWith("#") ? valueUrl : withHttp(valueUrl),
        affiliate,
        text,
      });
      setEditMode(false);
    },
    [setEditMode, updateLink, currentTab],
  );

  const initialValues = useMemo((): Values => {
    const { url, text, affiliate } = link;
    return {
      url,
      text,
      affiliate,
      link: !checkIsAnchor(url) ? url : null,
      anchor: checkIsAnchor(url) ? url : null,
    };
  }, [link]);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      mutators={{
        setTouched: (name, state) => {
          const field = state.fields[name];
          if (field) {
            field.touched = true;
          }
        },
      }}
    >
      {hasAnchors ? (
        <>
          <TabList state={tab} aria-label="article link" className="w-80">
            <Tab state={tab} id="link">
              URL ou article
            </Tab>
            <Tab state={tab} id="anchor">
              Ancre
            </Tab>
          </TabList>
          <TabPanel state={tab} tabId="link" lazy>
            <TabArticleUrl
              setEditMode={setEditMode}
              removeIfInvalid={link.url === ""}
              removeLink={removeLink}
            />
          </TabPanel>
          <TabPanel state={tab} tabId="anchor" lazy>
            <TabAnchor />
          </TabPanel>
        </>
      ) : (
        <TabArticleUrl
          setEditMode={setEditMode}
          removeIfInvalid={link.url === ""}
          removeLink={removeLink}
        />
      )}
    </Form>
  );
}

const withHttp = (url: string) =>
  !/^https?:\/\//i.test(url) ? `http://${url}` : url;
