import { formatISO } from "date-fns";

import { GeneratedPreset } from "@/components/controls/SelectDatePicker";

export type Preset = {
  label: string;
  from: Date | number | string;
  to: Date | number | string;
};

export const generatePresets = (presets: Preset[]): GeneratedPreset[] =>
  presets.map(({ label, from, to }, id) => ({
    id,
    label,
    value: {
      from: formatISO(from, { representation: "date" }),
      to: formatISO(to, { representation: "date" }),
    },
  }));
