import { makeIcon } from "./utils";

export const SvgOmega = makeIcon(
  "SvgOmega",
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 19a.5.5 0 0 1-.5-.5v-.844c0-.17.088-.328.225-.429 1.302-.957 2.4-2.876 2.4-5.064 0-2.305-1.5-4.522-4.125-4.522s-4.125 2.217-4.125 4.523c0 2.187 1.098 4.106 2.4 5.063a.536.536 0 0 1 .225.429v.844a.5.5 0 0 1-.5.5H6.75a.5.5 0 0 1-.5-.5V18a.5.5 0 0 1 .5-.5h1.51c.486 0 .725-.677.401-1.041C7.677 15.353 7 13.878 7 12.163 7 8.685 9.625 6.25 13 6.25s6 2.435 6 5.913c0 1.715-.677 3.19-1.661 4.296-.324.364-.085 1.041.402 1.041h1.509a.5.5 0 0 1 .5.5v.5a.5.5 0 0 1-.5.5H15Z"
      fill="currentColor"
    />
  </svg>,
);
