import { useStoreState } from "@ariakit/react";
import { ReactRenderer } from "@tiptap/react";
import { SuggestionOptions, SuggestionProps } from "@tiptap/suggestion";
import { SuggestionKeyDownProps } from "@tiptap/suggestion/src/suggestion";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
} from "react";
import {
  ComboboxItem,
  ComboboxPopover,
  useComboboxStore,
} from "swash/v2/Combobox";

import { AllEmojisQuery } from "@/gql-types";

export const EmojiSuggestionList = forwardRef<
  EmojiRefHandle,
  EmojiSuggestionListProps
>(function EmojiSuggestionList(props, ref) {
  const combobox = useComboboxStore();

  const selectItem = (name: string) => {
    props.command({ name });
  };

  const hasMatches = !!props.items.length;
  const hasRenderedItems = useStoreState(
    combobox,
    (state) => state.renderedItems.length,
  );

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: SuggestionKeyDownProps) => {
      if (!hasMatches) return false;
      if (event.key === "ArrowUp") {
        combobox.move(combobox.up());
        return true;
      }

      if (event.key === "ArrowDown") {
        combobox.move(combobox.down());
        return true;
      }

      if (event.key === "Enter") {
        const item = combobox.getState().activeValue;
        if (!item) return false;
        selectItem(item);
        combobox.hide();
        return true;
      }

      return false;
    },
  }));

  useLayoutEffect(() => {
    const raf = requestAnimationFrame(() => {
      combobox.setOpen(hasMatches);
    });
    return () => cancelAnimationFrame(raf);
  }, [combobox, hasMatches]);

  useEffect(() => {
    if (hasRenderedItems) {
      // Autofocus the first item when items are rendered,
      // default Ariakit behavior doesn't work in the current setup
      combobox.move(combobox.first());
    }
  }, [combobox, hasRenderedItems]);

  return (
    <ComboboxPopover
      store={combobox}
      hidden={!hasMatches}
      unmountOnHide
      fitViewport
      getAnchorRect={props.clientRect!}
    >
      {props.items.map((item) => (
        <ComboboxItem
          className="flex items-center gap-1"
          key={item.name}
          value={item.name}
          onClick={() => selectItem(item.name)}
        >
          <img className="h-4" src={item.encodedImage} alt={item.title} /> :
          {item.name}:
        </ComboboxItem>
      ))}
    </ComboboxPopover>
  );
});

interface EmojiRefHandle {
  onKeyDown: (props: SuggestionKeyDownProps) => boolean;
}

interface EmojiSuggestionListProps
  extends SuggestionProps<AllEmojisQuery["emojis"]["nodes"][0]> {}

export const renderEmojiSuggestionList: SuggestionOptions["render"] = () => {
  let reactRenderer: ReactRenderer<EmojiRefHandle, EmojiSuggestionListProps>;

  return {
    onStart: (props) => {
      if (!props.clientRect) return;
      reactRenderer = new ReactRenderer(EmojiSuggestionList, {
        props,
        editor: props.editor,
      });
      document.body.appendChild(reactRenderer.element);
    },

    onUpdate(props) {
      reactRenderer.updateProps(props);
    },

    onKeyDown(props) {
      if (props.event.key === "Escape") {
        reactRenderer.destroy();
        reactRenderer.element.remove();
        return true;
      }
      return reactRenderer.ref?.onKeyDown(props) ?? false;
    },

    onExit() {
      setTimeout(() => {
        reactRenderer.destroy();
        reactRenderer.element.remove();
      }, 0);
    },
  };
};
