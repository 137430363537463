import * as anchorControl from "@/components/rich-editor/plugins/anchor-control";
import * as blockTemplatesControl from "@/components/rich-editor/plugins/block-templates-control";
import * as blockquoteControl from "@/components/rich-editor/plugins/blockquote-control";
import * as boldControl from "@/components/rich-editor/plugins/bold-control";
import * as commentControl from "@/components/rich-editor/plugins/comment/control";
import * as emoji from "@/components/rich-editor/plugins/emoji";
import * as headerThreeControl from "@/components/rich-editor/plugins/header-three-control";
import * as headerTwoControl from "@/components/rich-editor/plugins/header-two-control";
import * as historyControl from "@/components/rich-editor/plugins/history-control";
import * as imageControl from "@/components/rich-editor/plugins/image-control";
import * as italicControl from "@/components/rich-editor/plugins/italic-control";
import * as linkControl from "@/components/rich-editor/plugins/link-control";
import * as merciappControl from "@/components/rich-editor/plugins/merciapp-spell-check-control";
import * as orderedListItemControl from "@/components/rich-editor/plugins/ordered-list-item-control";
import * as removeStyleControl from "@/components/rich-editor/plugins/remove-style-control";
import * as separatorControl from "@/components/rich-editor/plugins/separator-control";
import * as specialCharactersControl from "@/components/rich-editor/plugins/special-characters-control";
import * as spellCheckControl from "@/components/rich-editor/plugins/spell-check-control";
import * as strikethroughControl from "@/components/rich-editor/plugins/strikethrough-control";
import * as subscriptControl from "@/components/rich-editor/plugins/subscript-control";
import * as superscriptControl from "@/components/rich-editor/plugins/superscript-control";
import * as switchCaseControl from "@/components/rich-editor/plugins/switch-case-control";
import * as textToSpeech from "@/components/rich-editor/plugins/text-to-speech";
import * as underlineControl from "@/components/rich-editor/plugins/underline-control";
import * as unorderedListItemControl from "@/components/rich-editor/plugins/unordered-list-item-control";

/**
 * @typedef Options
 * @param {boolean} [spellCheck]
 * @param {boolean} [headerThree]
 * @param {boolean} [separator]
 * @param {boolean} [blocks]
 */

/**
 * @param {Options} options
 */
const presetControls = (options) => {
  let spellCheckPlugin = [];
  if (options?.spellCheck) {
    spellCheckPlugin =
      options.defaultLanguage === "en"
        ? [merciappControl]
        : [spellCheckControl];
  }

  return {
    plugins: [
      ...spellCheckPlugin,
      headerTwoControl,
      ...(options?.headerThree !== false ? [headerThreeControl] : []),
      orderedListItemControl,
      unorderedListItemControl,
      blockquoteControl,
      blockTemplatesControl,
      specialCharactersControl,
      emoji,
      ...(options.blocks !== false && options.separator !== false
        ? [separatorControl]
        : []),
      ...(options.blocks !== false &&
      options.image !== false &&
      options.image?.add !== false
        ? [imageControl]
        : []),
      historyControl,
      boldControl,
      italicControl,
      underlineControl,
      ...(options?.strikethrough ? [strikethroughControl] : []),
      subscriptControl,
      superscriptControl,
      switchCaseControl,
      linkControl,
      ...(options.anchor !== false ? [anchorControl] : []),
      removeStyleControl,
      ...(options?.textToSpeech ? [[textToSpeech, options.textToSpeech]] : []),
      ...(options?.comment ? [commentControl] : []),
    ],
  };
};

export default presetControls;
