// eslint-disable-next-line import/named
import { gql } from "@apollo/client";
import { memo } from "react";
import { Chip } from "swash/Chip";
import { Tooltip } from "swash/Tooltip";
import { SproutOutline } from "swash/icons";

import type { ArticleEvergreenAlert_articleFragment } from "@/gql-types";

import { HasPermission } from "../User";

export const EVERGREEN_TITLE = "Evergreen";

export type EvergreenAlertProps = {
  evergreen: { score: number };
};

export const EvergreenAlert = memo<EvergreenAlertProps>((props) => {
  return (
    <Tooltip
      placement="top"
      tooltip={
        <>
          <div>
            <strong>Article intemporel</strong>
          </div>
          Probabilité evergreen :{" "}
          {`${(props.evergreen.score * 100).toFixed()}/100`}
        </>
      }
    >
      <Chip tint="#E0F9EA" scale="sm" aria-label="Evergreen">
        <SproutOutline />
        {EVERGREEN_TITLE}
      </Chip>
    </Tooltip>
  );
});

export type ArticleEvergreenAlertProps = {
  article: ArticleEvergreenAlert_articleFragment;
};

export const ArticleEvergreenAlert = (props: ArticleEvergreenAlertProps) => {
  if (!props.article.evergreen) {
    return null;
  }
  return (
    <HasPermission permission="evergreen:view">
      <EvergreenAlert evergreen={props.article.evergreen} />
    </HasPermission>
  );
};

ArticleEvergreenAlert.fragments = {
  article: gql`
    fragment ArticleEvergreenAlert_article on Article {
      evergreen {
        score
      }
    }
  `,
};
