import createEditorControl from "@/components/editor/controls/createEditorControl";
import { Italic } from "@/components/icons";

export const ItalicControl = createEditorControl({
  name: "italic",
  label: "Italique",
  shortcut: ["⌘", "i"],
  icon: <Italic />,
  command: ({ editor }) => editor.chain().focus().toggleItalic().run(),
  canExecute: ({ editor }) => editor.can().toggleItalic(),
});
