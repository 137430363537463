import { gql } from "@apollo/client";
import * as Ariakit from "@ariakit/react";
import { Fragment } from "react";

import { UserAvatar } from "@/containers/user/UserAvatar";
import { UserHoverCard_userFragment } from "@/gql-types";

export const UserHoverCard = ({
  user,
  children,
  placement,
  gutter,
  ...props
}: UserHoverCardProps) => {
  const roleService = [
    user.currentRole?.name,
    user.currentSection?.name,
  ].filter(Boolean) as string[];
  return (
    <Ariakit.HovercardProvider placement={placement}>
      <Ariakit.HovercardAnchor render={children} {...props} />
      <Ariakit.Hovercard
        portal
        unmountOnHide
        style={{ minWidth: 224 }}
        className="z-tooltip flex flex-col items-center gap-1 rounded border border-grey-border-light bg-white p-4 text-dusk-on drop-shadow-md"
        gutter={gutter}
      >
        <UserAvatar className="mb-1" user={user} size={42} />
        <Ariakit.HovercardHeading className="font-accent font-semibold">
          {user.fullName}
        </Ariakit.HovercardHeading>
        <div className="text-sm text-grey-on">{user.email}</div>
        <div className="mt-2 text-sm font-semibold">
          {roleService.map((name, index) => (
            <Fragment key={name}>
              {index > 0 && <span className="text-grey-on"> | </span>}
              {name}
            </Fragment>
          ))}
        </div>
      </Ariakit.Hovercard>
    </Ariakit.HovercardProvider>
  );
};

interface UserHoverCardProps extends Ariakit.HovercardAnchorProps {
  user: UserHoverCard_userFragment;
  children: React.ReactElement;
  placement?: Ariakit.HovercardProviderProps["placement"];
  gutter?: Ariakit.HovercardProps["gutter"];
}

UserHoverCard.fragments = {
  user: gql`
    fragment UserHoverCard_user on User {
      id
      fullName
      email
      currentRole {
        id
        name
      }
      currentSection {
        id
        name
      }
      ...UserAvatar_user
    }
    ${UserAvatar.fragments.user}
  `,
};
