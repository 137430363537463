import { clsx } from "clsx";
import * as React from "react";
import { ButtonHTMLAttributes, forwardRef } from "react";

import { IoCloseCircleOutline } from "./Icon";
import { getBetterContrast } from "./utils/colors";
import { useWarning } from "./utils/warning";

export type ChipScale = "sm" | "md";

export type ChipTint =
  | "primary"
  | "secondary"
  | "danger"
  | "success"
  | "warning"
  | "ai"
  | string;

export const tints: Record<ChipTint, string> = {
  primary: /* tw */ `bg-primary-bg text-primary-on`,
  secondary: /* tw */ `bg-secondary-bg-light text-secondary-on`,
  danger: /* tw */ `bg-danger-bg text-danger-on`,
  success: /* tw */ `bg-success-bg text-success-on`,
  warning: /* tw */ `bg-warning-bg text-warning-on`,
  ai: /* tw */ `bg-purple-bg text-purple-on`,
};

const hoverTints: Record<ChipTint, string> = {
  primary: /* tw */ `hover:bg-primary-bg-hover-transparent`,
  secondary: /* tw */ `hover:bg-secondary-bg-hover-transparent`,
  danger: /* tw */ `hover:bg-danger-bg-hover-transparent`,
  success: /* tw */ `hover:bg-success-bg-hover-transparent`,
  warning: /* tw */ `hover:bg-warning-bg-hover-transparent`,
  ai: /* tw */ `hover:bg-purple-bg-hover-transparent`,
};

export interface ChipProps extends ButtonHTMLAttributes<HTMLDivElement> {
  /**
   * Chip tint. You can use any color as a tint like `#000` or `#fff`.
   * @default "secondary"
   */
  tint?: ChipTint;

  /**
   * Chip scale.
   * @default "md"
   */
  scale?: ChipScale;

  /**
   * Display the chip as a button. This will add aria and styles attributes to the chip.
   * @default false
   */
  asButton?: boolean;
}

export const scales: Record<ChipScale, string> = {
  sm: /* tw */ `text-xs gap-1.5 leading-normal py-px`,
  md: /* tw */ `text-sm gap-2 leading-4 py-1`,
};

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      tint = "secondary",
      scale = "md",
      className,
      style,
      children,
      asButton,
      ...props
    },
    ref,
  ) => {
    const tintClassname = tints[tint];

    return (
      <div
        ref={ref}
        role={asButton ? "button" : undefined}
        className={clsx(
          className,
          "inline-flex select-none items-center whitespace-nowrap rounded px-2 text-sm ring-1 ring-inset ring-black/10",
          scales[scale],
          asButton && hoverTints[tint],
          tintClassname ?? "text-dusk-on",
        )}
        style={
          tintClassname
            ? style
            : {
                backgroundColor: tint,
                color: getBetterContrast(tint, "#fff", "#090B0F"),
                ...style,
              }
        }
        {...props}
      >
        {children}
      </div>
    );
  },
);

Chip.displayName = "Chip";
export const ChipRemoveButton = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button">
>((props, ref) => {
  useWarning(
    !props["aria-label"] && !props["aria-labelledby"] && !props.title,
    '"You should provide either `title`, `aria-label` or `aria-labelledby` props."',
  );
  return (
    <button
      ref={ref}
      type="button"
      className="-mr-0.5 opacity-60 transition hover:opacity-100"
      {...props}
    >
      <IoCloseCircleOutline />
    </button>
  );
});

ChipRemoveButton.displayName = "ChipRemoveButton";
