/* eslint-disable no-restricted-syntax */
/**
 * @typedef MediaData
 * @property {number} [id]
 * @property {string} [url]
 *
 *
 * @typedef MediaProvider
 * @property {'archives' | 'articles' | 'custom_types_contents' | 'images' | 'products' | 'products_summaries' | 'snippets' | 'tweets' | 'videos' } type
 * @property {(params: {url: string, frontHost: string, imageHost: string, services?: string[] }) => MediaData|null} getData
 *
 */
import { provider as archiveProvider } from "./archives";
import { provider as articlesProvider } from "./articles";
import { provider as customTypeContentsProvider } from "./custom-types-contents";
import { provider as imagesProvider } from "./images";
import { provider as snippetsProvider } from "./snippets";
import { provider as videosProvider } from "./videos";

// preserve order
const providers = [
  articlesProvider,
  archiveProvider,
  customTypeContentsProvider,
  imagesProvider,
  videosProvider,
  snippetsProvider,
];

/**
 * @param {{url: string, frontHost: string, imageHost: string, services?: string[]}} params
 * @returns {{data: MediaData, provider: MediaProvider} | null}
 */
export function getMediaProviderData({ url, frontHost, imageHost, services }) {
  for (const provider of providers) {
    const data = provider.getData({ url, frontHost, imageHost, services });
    if (data !== null) return { data, provider };
  }
  return null;
}

export {
  articlesProvider,
  archiveProvider,
  customTypeContentsProvider,
  imagesProvider,
  videosProvider,
  snippetsProvider,
};
