import type { Position } from "css-box-model";

import { isEqual, origin } from "./services/position";

export const curves = {
  outOfTheWay: "cubic-bezier(0.2, 0, 0, 1)",
  drop: "cubic-bezier(.2,1,.1,1)",
} as const;

export const timings = {
  outOfTheWay: 0.2,
  // greater than the out of the way time
  // so that when the drop ends everything will
  // have to be out of the way
  minDropTime: 0.33,
  maxDropTime: 0.55,
} as const;

const outOfTheWayTiming = `${timings.outOfTheWay}s ${curves.outOfTheWay}`;

export const transitions = {
  fluid: `opacity ${outOfTheWayTiming}`,
  snap: `transform ${outOfTheWayTiming}, opacity ${outOfTheWayTiming}`,
  drop: (duration: number): string => {
    const timing = `${duration}s ${curves.drop}`;
    return `transform ${timing}, opacity ${timing}`;
  },
  outOfTheWay: `transform ${outOfTheWayTiming}`,
  placeholder: `height ${outOfTheWayTiming}, width ${outOfTheWayTiming}, margin ${outOfTheWayTiming}`,
} as const;

export const moveTo = (offset: Position) =>
  isEqual(offset, origin)
    ? undefined
    : `translate(${offset.x}px, ${offset.y}px)`;
