import { startOfToday } from "date-fns";
import React, { useMemo, useState } from "react";
import { Select, SelectClear, SelectLabel, SelectPopover } from "swash/Select";
import { DayModifiers } from "swash/controls/DatePicker";
import { shallowEqual } from "swash/utils/shallowEqual";
import { EnumSelect, useEnumSelectState } from "swash/v2/EnumSelect";
import { RichSelectScale, RichSelectValue } from "swash/v2/RichSelect";

import {
  DateSelectState,
  GeneratedPreset,
  Range,
  RangeDatePickerLabel,
  SelectDatePicker,
  formatRange,
  parseRange,
  useDateSelectState,
} from "@/components/controls/SelectDatePicker";
import { FieldError } from "@/components/fields/FieldError";
import { useSelectField } from "@/components/fields/SelectField";

import { Preset, generatePresets } from "./presets";

export { formatRange, parseRange };

export const usePresets = (
  factory: (today: Date) => Preset[],
): GeneratedPreset[] => {
  const today = startOfToday();

  // Ensure the factory never changes
  const [fixedFactory] = useState(() => factory);

  return useMemo(
    () => generatePresets(fixedFactory(today)),
    [today, fixedFactory],
  );
};

type FilterRange = {
  gte: string | undefined | null;
  lte: string | undefined | null;
};

export const formatDate = (value?: FilterRange | null) => {
  if (!value) return null;
  return formatRange({
    from: value.gte,
    to: value.lte,
  });
};
export const parseDate = (value?: Range | null) => {
  if (!value) return null;
  const range = parseRange(value);
  return {
    gte: range?.from,
    lte: range?.to,
  };
};

type DateFiltersFieldStateProps = {
  name: string;
  label: string;
  initialValue?: FilterRange;
  format?: (value: FilterRange) => Range;
  parse?: (value: Range) => FilterRange;
  required?: boolean;
  modifiers?: DayModifiers;
  defaultMonth?: Date;
  presets?: GeneratedPreset[];
  labelSelector?: (value: Range) => React.ReactNode;
};

export const useDateFiltersFieldState = (props: DateFiltersFieldStateProps) => {
  const {
    name,
    label,
    initialValue: initialValueProp,
    format = (value: FilterRange | null) => {
      if (!value) return null;
      return formatRange({
        from: value.gte,
        to: value.lte,
      });
    },
    parse = (value: Range | null) => {
      if (!value) return null;
      const range = parseRange(value);
      return {
        gte: range?.from,
        lte: range?.to,
      };
    },
    required = false,
    modifiers,
    defaultMonth,
    presets = [],
    labelSelector = (value: Range) => <RangeDatePickerLabel value={value} />,
  } = props;

  const isEqual = shallowEqual;
  const {
    state: { field },
  } = useSelectField(name, {
    format,
    parse,
    initialValue: initialValueProp,
    isEqual,
  });

  const select = useDateSelectState({
    value: field.input.value,
    onChange: field.input.onChange,
    labelSelector,
    required,
    range: true,
    modifiers,
    defaultMonth,
    title: label,
    presets,
  });

  return { field, select };
};

type SelectPeriodsProps = {
  presets: GeneratedPreset[];
  value: Range;
  onChange: (value: Range | null) => any;
};

export const SelectPeriods = (props: SelectPeriodsProps) => {
  const { presets, value, onChange } = props;
  const enumSelect = useEnumSelectState({
    value: presets.find((preset) => shallowEqual(preset.value, value)) || null,
    onChange: (value) => {
      onChange(value ? value.value : null);
    },
    items: presets,
    title: "Périodes prédéfinies",
    required: true,
    labelSelector: (preset) => preset.label,
    valueSelector: (preset) => preset.id.toString(),
    emptyMessage: "Pas de périodes prédéfinies",
  });

  if (!presets.length) return null;

  return (
    <div className="w-56">
      <EnumSelect
        state={enumSelect}
        placeholder="Période prédéfinie..."
        scale="md"
      />
    </div>
  );
};

type SelectDateFiltersProps = {
  select: DateSelectState;
  dataTestHidden?: boolean;
  scale?: RichSelectScale;
  placeholder?: string;
  labeled?: boolean;
};

export const SelectDateFilter = (props: SelectDateFiltersProps) => {
  const { select, dataTestHidden, scale, placeholder, labeled } = props;

  return (
    <>
      {labeled && <SelectLabel store={select.select} label={select.title} />}
      <Select
        data-test-hidden={dataTestHidden}
        store={select.select}
        scale={scale}
      >
        <RichSelectValue
          state={select}
          scale={scale}
          placeholder={placeholder}
        />
        <SelectClear store={select.select} clearable={!select.required} />
      </Select>
    </>
  );
};

export const InnerSelectDatePopover = (props: {
  select: DateSelectState;
  children: React.ReactNode;
}) => {
  const { select, children } = props;
  const childrenFilters = React.Children.toArray(children);

  return (
    <div className="flex">
      <div className="flex flex-col items-center gap-1 p-4">
        <SelectDatePicker state={select} />
        <SelectPeriods
          presets={select.datePicker.presets}
          value={select.value}
          onChange={select.onChange}
        />
      </div>
      {childrenFilters.length ? (
        <>
          <hr
            aria-orientation="vertical"
            className="h-auto border-0 border-l border-grey-border-light"
          />
          <div className="flex flex-col gap-4 p-4">{children}</div>
        </>
      ) : null}
    </div>
  );
};

type DateFiltersFieldProps = {
  scale?: RichSelectScale;
  placeholder?: string;
  dataTestHidden?: boolean;
  children?: React.ReactNode;
  labeled?: boolean;
} & DateFiltersFieldStateProps;

export const DateFiltersField = (props: DateFiltersFieldProps) => {
  const { dataTestHidden, scale, placeholder, children, labeled, ...rest } =
    props;
  const { field, select } = useDateFiltersFieldState(rest);

  return (
    <div>
      <SelectDateFilter
        select={select}
        dataTestHidden={dataTestHidden}
        scale={scale}
        placeholder={placeholder ?? rest.label}
        labeled={labeled}
      />
      <SelectPopover store={select.select} className="!p-4">
        <InnerSelectDatePopover select={select}>
          {children}
        </InnerSelectDatePopover>
      </SelectPopover>
      <FieldError state={{ field }} className="ml-2" />
    </div>
  );
};
