import { Button, ButtonProps } from "swash/Button";
import { Tooltip } from "swash/Tooltip";
import { cn } from "swash/utils/classNames";
import { useClipboard } from "use-clipboard-copy";

import { CopyButton } from "./CopyButton";

export const GlobalId: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    globalId: string;
  }
> = ({ globalId, ...props }) => {
  return (
    <div
      {...props}
      className={cn("my-1 flex items-center gap-1 text-xs", props.className)}
    >
      Global ID: {globalId}{" "}
      <CopyButton value={globalId} title="Copier l’ID" scale="xs" />
    </div>
  );
};

export const GlobalIdButton: React.FC<
  ButtonProps & {
    globalId: string;
  }
> = ({ globalId, ...props }) => {
  const clipboard = useClipboard({ copiedTimeout: 1000 });
  return (
    <Tooltip tooltip={`${globalId} — Cliquer pour copier`} timeout={0}>
      <Button
        variant="secondary"
        appearance="text"
        onClick={() => clipboard.copy(globalId)}
        aria-pressed={clipboard.copied}
        {...props}
      >
        {clipboard.copied ? "Copié" : "#ID"}
      </Button>
    </Tooltip>
  );
};
