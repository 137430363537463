import { DialogStore, useDialogStore } from "@ariakit/react";
import {
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dialog } from "swash/Dialog";
import { DialogPanel } from "swash/DialogPanel";
import { Panel, PanelProps } from "swash/Panel";
import { shallowEqual } from "swash/utils/shallowEqual";
import { useStoreState } from "swash/utils/useStoreState";

import { ErrorBoundary } from "./ErrorBoundary";
import { GenericErrorBlock } from "./GenericErrorPage";

export type PanelDescriptorProps = Record<string, unknown>;

export type PanelDescriptor<
  TProps extends PanelDescriptorProps = PanelDescriptorProps,
> = {
  key: string;
  size?: "md" | "lg";
  title: string;
  render: (props: TProps & { onClose: () => void }) => React.ReactNode;
};

export type PanelState = {
  descriptor: PanelDescriptor;
  props: PanelDescriptorProps | null;
};

const DialogPanelStoreContext = createContext<DialogStore | null>(null);

const PanelContext = createContext<PanelState | null | undefined>(undefined);
const SetPanelContext = createContext<
  React.Dispatch<React.SetStateAction<PanelState | null>> | undefined
>(undefined);

const DialogPanelStateProvider = (props: { children: React.ReactNode }) => {
  const activePanel = useActivePanel();
  const setActivePanel = useSetActivePanel();
  const open = Boolean(activePanel);
  const setOpen = useCallback(
    (open: boolean) => {
      if (!open) {
        setActivePanel(null);
      }
    },
    [setActivePanel],
  );

  const store = useDialogStore({
    open,
    setOpen,
  });

  return (
    <DialogPanelStoreContext.Provider value={store}>
      {props.children}
    </DialogPanelStoreContext.Provider>
  );
};

const usePanelDialogContextStore = () => {
  const value = useContext(DialogPanelStoreContext);
  if (!value) {
    throw new Error(
      "useDialogPanelState must be used within a DialogPanelStateProvider",
    );
  }
  return value;
};

export const PanelManagerProvider = (props: { children: React.ReactNode }) => {
  const [panel, setPanel] = useState<PanelState | null>(null);
  return (
    <PanelContext.Provider value={panel}>
      <SetPanelContext.Provider value={setPanel}>
        <DialogPanelStateProvider>{props.children}</DialogPanelStateProvider>
      </SetPanelContext.Provider>
    </PanelContext.Provider>
  );
};

const useActivePanel = () => {
  const panel = useContext(PanelContext);
  if (panel === undefined) {
    throw new Error(
      "useActivePanel must be used within a PanelManagerProvider",
    );
  }
  return panel;
};

const useSetActivePanel = () => {
  const value = useContext(SetPanelContext);
  if (value === undefined) {
    throw new Error(
      "useSetActivePanel must be used within a PanelManagerProvider",
    );
  }
  return value;
};

const useActivatePanel = <TProps extends PanelDescriptorProps>(
  descriptor: PanelDescriptor<TProps>,
) => {
  const setActivePanel = useSetActivePanel();
  const activePanel = useActivePanel();
  return useCallback(
    (props?: TProps) => {
      // handle toggle panel
      if (
        activePanel?.descriptor === descriptor &&
        shallowEqual(activePanel?.props, props)
      ) {
        setActivePanel(null);
        return;
      }
      setActivePanel({
        descriptor: descriptor as PanelDescriptor,
        props: props ?? null,
      });
    },
    [descriptor, setActivePanel, activePanel],
  );
};

const useCheckIsPanelActivated = <TProps extends PanelDescriptorProps>(
  descriptor: PanelDescriptor<TProps>,
) => {
  const activePanel = useActivePanel();
  return useCallback(
    (props?: TProps) => {
      const active = activePanel?.descriptor === descriptor;
      if (!active) return false;
      if (props === undefined) return true;
      if (props === activePanel.props) return true;
      return shallowEqual(activePanel.props, props);
    },
    [descriptor, activePanel],
  );
};

const sizesPx = {
  md: 400,
  lg: 548,
};

export const DialogAnchorPanel = memo(() => {
  const store = usePanelDialogContextStore();
  const { hide } = store;
  const animating = useStoreState(store, "animating");

  const activePanel = useActivePanel();

  const children =
    activePanel?.descriptor.render({
      ...activePanel.props,
      onClose: hide,
    }) ?? null;
  const childrenRef = useRef<React.ReactNode>(null);
  childrenRef.current = children
    ? children
    : animating
      ? childrenRef.current
      : null;

  useEffect(() => () => hide(), [hide]);

  return (
    <Dialog
      store={store}
      aria-label={activePanel?.descriptor.title}
      style={{
        width: activePanel?.descriptor.size === "lg" ? 670 : undefined,
        height: 800,
      }}
    >
      <ErrorBoundary component={GenericErrorBlock}>
        {childrenRef.current}
      </ErrorBoundary>
    </Dialog>
  );
});

export const FloatingAnchorPanel = memo(() => {
  const dialog = usePanelDialogContextStore();
  const activePanel = useActivePanel();
  const size = activePanel?.descriptor.size ?? "md";
  const { hide } = dialog;
  const animating = useStoreState(dialog, "animating");

  const children =
    activePanel?.descriptor.render({
      ...activePanel.props,
      onClose: hide,
    }) ?? null;
  // Preserve the last children so that we can render it while the panel is animating
  const childrenRef = useRef<React.ReactNode>(null);
  childrenRef.current = children
    ? children
    : animating
      ? childrenRef.current
      : null;

  useEffect(() => () => hide(), [hide]);

  return (
    <DialogPanel store={dialog} style={{ width: sizesPx[size] }}>
      <ErrorBoundary component={GenericErrorBlock}>
        {childrenRef.current}
      </ErrorBoundary>
    </DialogPanel>
  );
});

export type AnchorPanelProps = PanelProps;

export const AnchorPanel = memo<AnchorPanelProps>(({ style, ...props }) => {
  const activePanel = useActivePanel();
  const setActivePanel = useSetActivePanel();
  const size = activePanel?.descriptor.size ?? "md";
  const onClose = useCallback(() => setActivePanel(null), [setActivePanel]);
  const children =
    activePanel?.descriptor.render({ ...activePanel.props, onClose }) ?? null;

  useEffect(() => () => onClose(), [onClose]);

  if (!activePanel) return null;
  return (
    <Panel style={{ width: sizesPx[size], ...style }} {...props}>
      <ErrorBoundary component={GenericErrorBlock}>{children}</ErrorBoundary>
    </Panel>
  );
});

export type PanelInterface<TProps extends PanelDescriptorProps> = {
  key: string;
  useActivate: () => (props?: TProps | undefined) => void;
  useCheckIsActivated: () => (props?: TProps | undefined) => boolean;
};

/**
 * Create a panel from a descriptor.
 */
export const createPanel = <TProps extends PanelDescriptorProps>(
  descriptor: PanelDescriptor<TProps>,
): PanelInterface<TProps> => {
  return {
    key: descriptor.key,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useActivate: () => useActivatePanel<TProps>(descriptor),
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCheckIsActivated: () => useCheckIsPanelActivated<TProps>(descriptor),
  };
};
