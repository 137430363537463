import { type Position, type Rect, getRect } from "css-box-model";

import type {
  Axis,
  DisplacedBy,
  DisplacementGroups,
  DragImpact,
  DraggableDimension,
  DraggableDimensionMap,
  DraggableId,
  DroppableDimension,
  DroppableDimensionMap,
  LiftEffect,
  Viewport,
} from "../types";
import { calculateReorderImpact } from "./calculate-reorder-impact";
import getDidStartAfterCritical from "./did-start-after-critical";
import { isHomeOf } from "./droppable/utils";
import getDisplacedBy from "./get-displaced-by";
import getDraggablesInsideDroppable from "./get-draggables-inside-droppable";
import getDroppableOver from "./get-droppable-over";
import { noImpact } from "./no-impact";
import removeDraggableFromList from "./remove-draggable-from-list";
import { offsetByPosition } from "./spacing";

type Args = {
  pageOffset: Position;
  draggable: DraggableDimension;
  // all dimensions in system
  draggables: DraggableDimensionMap;
  droppables: DroppableDimensionMap;
  previousImpact: DragImpact;
  viewport: Viewport;
  afterCritical: LiftEffect;
};

export function getDragImpact({
  pageOffset,
  draggable,
  draggables,
  droppables,
  previousImpact,
  viewport,
  afterCritical,
}: Args): DragImpact {
  const pageBorderBox = offsetRectByPosition(
    draggable.page.borderBox,
    pageOffset,
  );

  const destinationId = getDroppableOver({
    pageBorderBox,
    draggable,
    droppables,
  });

  // not dragging over anything

  if (!destinationId) {
    // A big design decision was made here to collapse the home list
    // when not over any list. This yielded the most consistently beautiful experience.
    return noImpact;
  }

  const destination = droppables[destinationId]!;
  const insideDestination = getDraggablesInsideDroppable(
    destination.descriptor.id,
    draggables,
  );

  // Where the element actually is now.
  // Need to take into account the change of scroll in the droppable
  const pageBorderBoxWithDroppableScroll = withDroppableScroll(
    destination,
    pageBorderBox,
  );

  return getReorderImpact({
    pageBorderBoxWithDroppableScroll,
    draggable,
    destination,
    insideDestination,
    last: previousImpact.displaced,
    viewport,
    afterCritical,
  });
}

function atIndex({
  draggable,
  closest,
  inHomeList,
}: {
  draggable: DraggableDimension;
  closest?: DraggableDimension;
  inHomeList: boolean;
}): number | null {
  if (!closest) return null;
  if (!inHomeList) return closest.descriptor.index;
  if (closest.descriptor.index > draggable.descriptor.index) {
    return closest.descriptor.index - 1;
  }
  return closest.descriptor.index;
}

function getReorderImpact({
  pageBorderBoxWithDroppableScroll: targetRect,
  draggable,
  destination,
  insideDestination,
  last,
  viewport,
  afterCritical,
}: {
  pageBorderBoxWithDroppableScroll: Rect;
  draggable: DraggableDimension;
  destination: DroppableDimension;
  insideDestination: DraggableDimension[];
  last: DisplacementGroups;
  viewport: Viewport;
  afterCritical: LiftEffect;
}): DragImpact {
  const axis: Axis = destination.axis;
  const displacedBy: DisplacedBy = getDisplacedBy(
    destination.axis,
    draggable.displaceBy,
  );
  const displacement = displacedBy.value;

  const targetStart = targetRect[axis.start];
  const targetEnd = targetRect[axis.end];

  const withoutDragging = removeDraggableFromList(draggable, insideDestination);

  const closest = withoutDragging.find((child) => {
    const id = child.descriptor.id;
    const childCenter = child.page.borderBox.center[axis.line];

    const didStartAfterCritical = getDidStartAfterCritical(id, afterCritical);

    const isDisplaced = getIsDisplaced({ displaced: last, id });

    /*
      Note: we change things when moving *past* the child center - not when it hits the center
      If we make it when we *hit* the child center then there can be
      a hit on the next update causing a flicker.

      - Update 1: targetBottom hits center => displace backwards
      - Update 2: targetStart is now hitting the displaced center => displace forwards
      - Update 3: goto 1 (boom)
    */

    if (didStartAfterCritical) {
      // Continue to displace while targetEnd before the childCenter
      // Move once we *move forward past* the childCenter
      if (isDisplaced) {
        return targetEnd <= childCenter;
      }

      // Has been moved backwards from where it started
      // Displace forwards when targetStart *moves backwards past* the displaced childCenter
      return targetStart < childCenter - displacement;
    }

    // Item has been shifted forward.
    // Remove displacement when targetEnd moves forward past the displaced center
    if (isDisplaced) {
      return targetEnd <= childCenter + displacement;
    }

    // Item is behind the dragging item
    // We want to displace it if the targetStart goes *backwards past* the childCenter
    return targetStart < childCenter;
  });

  const newIndex = atIndex({
    draggable,
    closest,
    inHomeList: isHomeOf(draggable, destination),
  });

  // TODO: index cannot be null?
  // otherwise return null from there and return empty impact
  // that was calculate reorder impact does not need to account for a null index
  return calculateReorderImpact({
    draggable,
    insideDestination,
    destination,
    viewport,
    last,
    displacedBy,
    index: newIndex,
  });
}

const offsetRectByPosition = (rect: Rect, point: Position): Rect =>
  getRect(offsetByPosition(rect, point));

const withDroppableScroll = (
  droppable: DroppableDimension,
  area: Rect,
): Rect => {
  const frame = droppable.frame;
  if (!frame) return area;
  return offsetRectByPosition(area, frame.scroll.diff.value);
};

const getIsDisplaced = ({
  displaced,
  id,
}: {
  displaced: DisplacementGroups;
  id: DraggableId;
}) => Boolean(displaced.visible[id] || displaced.invisible[id]);
