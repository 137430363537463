import { gql } from "@apollo/client";
import { Button as AriakitButton } from "@ariakit/react";
import clsx from "clsx";
import {
  createContext,
  forwardRef,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { mergeRefs } from "react-merge-refs";
import { Button } from "swash/Button";
import { DialogDisclosure, useDialogStore } from "swash/Dialog";
import {
  IoAddCircle,
  IoArrowBackCircle,
  IoArrowForwardCircle,
  IoCheckmark,
  IoCloseCircle,
} from "swash/Icon";
import { PageLoader } from "swash/Loader";
import { cn } from "swash/utils/classNames";
import { useEventListener } from "swash/utils/useEventListener";
import { useLiveRef } from "swash/utils/useLiveRef";

import { Image } from "@/components/Image";
import { PreviewDialog } from "@/components/PreviewDialog";
import { Time } from "@/components/Time";
import { useSafeMutation, useSafeQuery } from "@/containers/Apollo";

import { ImageEditDisclosure } from "./ImageEditDisclosure";

const PreviewContext = createContext();

export function PreviewProvider({ imageIds, onChange, children }) {
  const disclosuresRefsRef = useRef({});
  const registerDisclosureRef = useCallback((imageId, ref) => {
    disclosuresRefsRef.current[imageId] = ref;
    return () => {
      delete disclosuresRefsRef.current[imageId];
    };
  }, []);

  const finalFocusRef = useRef();

  const onChangeRef = useLiveRef(onChange);
  const setImageId = useCallback(
    (imageId) => {
      if (onChangeRef.current) {
        onChangeRef.current(imageId);
      }
      if (imageId) {
        finalFocusRef.current = disclosuresRefsRef.current[imageId].current;
      }
    },
    [onChangeRef],
  );

  const value = useMemo(
    () => ({
      setImageId,
      imageIds,
      registerDisclosureRef,
      disclosuresRefsRef,
      finalFocusRef,
    }),
    [setImageId, imageIds, registerDisclosureRef, disclosuresRefsRef],
  );

  return (
    <PreviewContext.Provider value={value}>{children}</PreviewContext.Provider>
  );
}

const usePreviewContext = () => {
  return useContext(PreviewContext);
};

const PreviewArticleContext = createContext();

export const useImagePreviewDialogStore = ({
  imageId,
  editable = true,
  ...options
}) => {
  const dialog = useDialogStore(options);
  return { imageId, editable, dialog };
};

export const ImagePreviewDialogDisclosure = forwardRef(
  ({ imageId, dialog, ...props }, ref) => {
    const localRef = useRef();
    const ctx = usePreviewContext();
    const registerDisclosureRef = ctx?.registerDisclosureRef;
    useEffect(() => {
      if (registerDisclosureRef) {
        return registerDisclosureRef(imageId, localRef);
      }
    }, [registerDisclosureRef, imageId]);
    return (
      <DialogDisclosure
        ref={mergeRefs([ref, localRef])}
        store={dialog}
        {...props}
      />
    );
  },
);

export function PreviewArticleProvider({ articleId, children }) {
  return (
    <PreviewArticleContext.Provider value={articleId}>
      {children}
    </PreviewArticleContext.Provider>
  );
}

const usePreviewArticleId = () => {
  return useContext(PreviewArticleContext);
};

const ArticleMediasImageFragment = gql`
  fragment ImagePreview_articleMediasImage on Image {
    articleMedias(where: { articleId: { eq: $articleId } })
      @include(if: $withArticleMedias) {
      id
      used
    }
  }
`;

const ImageQuery = gql`
  query ImagePreview_image(
    $id: Int!
    $withArticleMedias: Boolean!
    $articleId: Int
  ) {
    image(id: $id) {
      id
      path
      width
      height
      url
      credit
      caption
      expiration
      fluid(maxHeight: 900) {
        ...Image_fluid
      }
      ...ImagePreview_articleMediasImage
    }
  }
  ${Image.fragments.fluid}
  ${ArticleMediasImageFragment}
`;

const InnerNavButton = forwardRef(function InnerNavButton(props, ref) {
  return (
    <button
      ref={ref}
      {...props}
      className={cn(
        "cursor-pointer text-white drop-shadow-[0_0_2px_rgba(0,_0,_0,_0.5)] transition-all",
        "[&_svg]:size-10",
        "focus:outline-none",
        props.className,
      )}
    />
  );
});

const NavigableImage = memo(({ hidden, image, onComplete }) => {
  return (
    <div className="pointer-events-none relative flex max-w-6xl items-center justify-center">
      <Image
        {...image.fluid}
        className={clsx("max-w-full object-contain", hidden && "hidden")}
        style={{
          maxHeight: "85vh",
        }}
        onLoad={() => onComplete()}
        onError={() => onComplete()}
      />
    </div>
  );
});

const CreateArticleMediaMutation = gql`
  mutation ImagePreview_createArticleMedia($imageId: Int!, $articleId: Int!) {
    createArticleMedia(
      input: { mediaType: "images", mediaId: $imageId, articleId: $articleId }
    ) {
      id
    }
  }
`;

const DeleteArticleMediaMutation = gql`
  mutation ImagePreview_deleteArticleMedia($articleMediaId: Int!) {
    deleteArticleMedia(input: { id: $articleMediaId }) {
      id
    }
  }
`;

const UnsuggestButton = ({ image }) => {
  const articleId = usePreviewArticleId();
  const [articleMedia] = image.articleMedias;

  const [deleteArticleMedia] = useSafeMutation(DeleteArticleMediaMutation, {
    variables: { articleMediaId: articleMedia.id },
    optimisticResponse: {
      __typename: "Mutation",
      deleteArticleMedia: {
        __typename: "ArticleMedia",
        id: articleMedia.id,
      },
    },
    update: (cache) => {
      cache.writeFragment({
        id: `Image:${image.id}`,
        variables: {
          articleId,
          withArticleMedias: true,
        },
        data: {
          __typename: "Image",
          articleMedias: [],
        },
        fragment: ArticleMediasImageFragment,
      });
    },
  });
  const [active, setActive] = useState(false);
  return (
    <Button
      type="button"
      variant={active ? "secondary" : "success"}
      onFocus={() => {
        setActive(true);
      }}
      onBlur={() => {
        setActive(false);
      }}
      onMouseOver={() => {
        setActive(true);
      }}
      onMouseOut={() => {
        setActive(false);
      }}
      onClick={() => {
        if (articleMedia.id !== -1) {
          deleteArticleMedia();
        }
      }}
      className="w-full"
    >
      {active ? <IoCloseCircle /> : <IoCheckmark />}
      <span style={{ minWidth: 170, display: "inline-block" }}>
        {active ? "Supprimer des suggestions" : "Suggérée pour cet article"}
      </span>
    </Button>
  );
};

const SuggestButton = ({ image }) => {
  const articleId = usePreviewArticleId();
  const [createArticleMedia] = useSafeMutation(CreateArticleMediaMutation, {
    variables: { imageId: image.id, articleId },
    optimisticResponse: {
      __typename: "Mutation",
      createArticleMedia: {
        __typename: "ArticleMedia",
        id: -1,
      },
    },
    update: (cache, { data: { createArticleMedia: articleMedia } }) => {
      cache.writeFragment({
        id: `Image:${image.id}`,
        variables: {
          articleId,
          withArticleMedias: true,
        },
        data: {
          __typename: "Image",
          articleMedias: [articleMedia],
        },
        fragment: ArticleMediasImageFragment,
      });
    },
  });
  return (
    <Button
      type="button"
      onClick={() => {
        createArticleMedia();
      }}
      className="w-full"
    >
      <IoAddCircle />
      Suggérer pour cet article
    </Button>
  );
};

const ImageSuggestion = memo(({ image }) => {
  const articleId = usePreviewArticleId();
  if (!articleId) return null;
  const [articleMedia] = image.articleMedias;

  // hide suggest / unsuggest buttons when articleMedia is used
  if (articleMedia && articleMedia?.used) return null;

  return (
    <div>
      {articleMedia ? (
        <UnsuggestButton image={image} />
      ) : (
        <SuggestButton image={image} />
      )}
    </div>
  );
});

export const ImageDescription = memo(({ image, editable }) => {
  return (
    <div className="mt-6 flex w-full max-w-6xl shrink-0 gap-8 text-white">
      <div className="flex flex-1 flex-col gap-1">
        <div>{image ? image.caption || "-" : "..."}</div>
        <div className="font-semibold">
          {image ? (
            <>
              {image.credit ? `${image.credit} - ` : ""}
              {image.expiration ? (
                <>
                  Utilisable jusqu’au{" "}
                  <Time date={image.expiration} format="dd/MM/yyyy" />
                </>
              ) : (
                "Pas d’expiration"
              )}
            </>
          ) : (
            "..."
          )}
        </div>
      </div>
      <div className="flex w-64 flex-col gap-2">
        {editable && (
          <ImageEditDisclosure
            imageId={image.id}
            label="Éditer l’image"
            scale="md"
            className="w-full"
          />
        )}
        <ImageSuggestion image={image} />
      </div>
    </div>
  );
});

ImageDescription.fragments = {
  image: gql`
    fragment ImageDescription_image on Image {
      caption
      credit
      expiration
    }
  `,
};

const NavButton = forwardRef((props, ref) => {
  return (
    <AriakitButton
      ref={ref}
      render={<InnerNavButton />}
      {...props}
      className="hover:text-grey-bg-light"
    />
  );
});

const BackButton = ({ ...props }) => {
  return (
    <div className="absolute left-[2%] z-[1]">
      <NavButton aria-label="Image précédente" {...props}>
        <IoArrowBackCircle />
      </NavButton>
    </div>
  );
};

const ForwardButton = forwardRef((props, ref) => {
  return (
    <div className="absolute right-[2%] z-[1]">
      <NavButton aria-label="Image suivante" {...props} ref={ref}>
        <IoArrowForwardCircle />
      </NavButton>
    </div>
  );
});

const Navigation = memo(({ imageId, onChange, forwardButtonRef }) => {
  const ctx = usePreviewContext();
  const ctxSetImageId = ctx.setImageId;
  const index = ctx.imageIds.indexOf(imageId);
  const previousImageId = index > 0 ? ctx.imageIds[index - 1] : null;
  const nextImageId =
    index !== -1 && index < ctx.imageIds.length - 1
      ? ctx.imageIds[index + 1]
      : null;

  const setImageId = useCallback(
    (imageId) => {
      onChange(imageId);
      ctxSetImageId(imageId);
    },
    [ctxSetImageId, onChange],
  );
  const goBackward = useCallback(() => {
    if (previousImageId) {
      setImageId(previousImageId);
    }
  }, [previousImageId, setImageId]);

  const goForward = useCallback(() => {
    if (nextImageId) {
      setImageId(nextImageId);
    }
  }, [nextImageId, setImageId]);

  useEventListener(document, "keydown", (event) => {
    switch (event.key) {
      case "ArrowLeft":
        goBackward();
        break;
      case "ArrowRight":
        goForward();
        break;
      default:
        break;
    }
  });

  return (
    <>
      {previousImageId ? <BackButton onClick={() => goBackward()} /> : null}
      {nextImageId ? (
        <ForwardButton
          ref={forwardButtonRef}
          onClick={() => {
            goForward();
          }}
        />
      ) : null}
    </>
  );
});

const OnlyIfContext = ({ children }) => {
  const ctx = usePreviewContext();
  return ctx ? children : null;
};

function DialogContent({
  imageId: initialImageId,
  editable,
  forwardButtonRef,
}) {
  const articleId = usePreviewArticleId();
  const [state, setState] = useState({ id: initialImageId, loaded: false });

  const { data } = useSafeQuery(ImageQuery, {
    variables: {
      id: state.id,
      withArticleMedias: Boolean(articleId),
      articleId,
    },
    fetchPolicy: "cache-and-network",
  });
  const handleChange = useCallback((id) => setState({ id, loaded: false }), []);
  const handleComplete = useCallback(
    () => setState((state) => ({ ...state, loaded: true })),
    [],
  );
  const ctx = usePreviewContext();
  const ctxSetImageId = ctx?.setImageId ?? null;
  // Trigger `onChange` at the loading of dialog
  useEffect(() => {
    ctxSetImageId?.(initialImageId);
  }, [initialImageId, ctxSetImageId]);
  // Trigger `onChange(null)` when the dialog is unmounted
  useEffect(() => {
    return () => {
      ctxSetImageId?.(null);
    };
  }, [ctxSetImageId]);

  return (
    <>
      {(!data || !state.loaded) && <PageLoader />}
      <OnlyIfContext>
        <Navigation
          imageId={state.id}
          onChange={handleChange}
          forwardButtonRef={forwardButtonRef}
        />
      </OnlyIfContext>
      {data ? (
        <>
          <NavigableImage
            image={data.image}
            hidden={!state.loaded}
            onComplete={handleComplete}
          />
          <ImageDescription image={data.image} editable={editable} />
        </>
      ) : null}
    </>
  );
}

export function ImagePreviewDialog({ imageId, editable, dialog }) {
  const initialFocusRef = useRef();
  const ctx = usePreviewContext();
  return (
    <PreviewDialog
      label="Prévisualisation de l'image"
      initialFocusRef={initialFocusRef}
      finalFocusRef={ctx?.finalFocusRef ?? undefined}
      store={dialog}
    >
      <DialogContent
        imageId={imageId}
        editable={editable}
        forwardButtonRef={initialFocusRef}
      />
    </PreviewDialog>
  );
}
