import { gql } from "@apollo/client";
import { memo } from "react";

import { UserAvatar } from "@/containers/user/UserAvatar";
import { UserHoverCard } from "@/containers/user/UserHoverCard";
import { ArticleLocker_articleFragment } from "@/gql-types";

type Locker = NonNullable<ArticleLocker_articleFragment["locker"]>;

export type SpreadArticleLockerProps = {
  locker: Locker;
  size?: number;
};

export const SpreadArticleLocker = memo<SpreadArticleLockerProps>(
  ({ locker, size = 24 }) => {
    return (
      <div className="inline-flex">
        <UserHoverCard user={locker.user}>
          <UserAvatar user={locker.user} size={size} />
        </UserHoverCard>
      </div>
    );
  },
  // We only need to compare user id to ensure it is the same locker
  (prevProps, nextProps) =>
    prevProps.locker.user.id === nextProps.locker.user.id,
);

export type ArticleLockerProps = {
  article: ArticleLocker_articleFragment;
  size?: number;
};

export const ArticleLocker = (props: ArticleLockerProps) => {
  if (!props.article.locker) return null;
  return (
    <SpreadArticleLocker locker={props.article.locker} size={props.size} />
  );
};

ArticleLocker.fragments = {
  article: gql`
    fragment ArticleLocker_article on Article {
      locker {
        user {
          id
          ...UserHoverCard_user
          ...UserAvatar_user
        }
      }
    }
    ${UserHoverCard.fragments.user}
    ${UserAvatar.fragments.user}
  `,
};
