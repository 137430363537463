import { SimpleTextKit } from "@sirius/editor-model";
import { Content } from "@tiptap/core";
import { UseEditorOptions } from "@tiptap/react";
import { useCallback, useEffect, useRef } from "react";
import { MenuItem } from "swash/Menu";
import { Editor, EditorProps, useEditor } from "swash/editor";
import { FixedMenu } from "swash/editor/components/FixedMenu";
import { cn } from "swash/utils/classNames";

import {
  Emoji,
  renderEmojiSuggestionList,
} from "@/components/editor/extensions/emoji";
import { MerciAppSpellCheckControl } from "@/components/editor/extensions/merciapp";
import { ProlexisSpellCheckControl } from "@/components/editor/extensions/prolexis";
import { SpecialCharControl } from "@/components/editor/extensions/special-characters";
import { useEditorContentSync } from "@/components/editor/hooks/useEditorContentSync.ts";
import { Omega, SpellCheck } from "@/components/icons";
import { StandaloneRichEditorTextBox } from "@/components/rich-editor/RichEditorTextBox";
import { useHasExperimentalFeature } from "@/containers/User";
import {
  useEditorOptionsSelector,
  useHasPlugin,
  useInitialEditorOptions,
} from "@/containers/editor/EditorOptions";

/**
 * This component is used to switch between next gen simple text editor and the current.
 * @TODO Remove this component once the next gen simple text editor is stable.
 */
export const SimpleTextEditorSwitcher = ({
  showTools,
  readOnly,
  rich,
  plugins,
  w,
  ...props
}: SimpleTextEditorSwitcherProps) => {
  const hasExperimentalFeature = useHasExperimentalFeature(
    "next-gen-simple-text-editor",
  );

  const Component = hasExperimentalFeature
    ? SimpleTextEditor
    : StandaloneRichEditorTextBox;

  if (!hasExperimentalFeature) {
    props = {
      //@ts-expect-error temporary code
      showTools,
      readOnly,
      rich,
      plugins,
      variant: props.appearance,
      stripPastedStyles: rich,
      w,
      ...props,
    };
  }

  return <Component {...props} />;
};

export interface SimpleTextEditorSwitcherProps extends SimpleTextEditorProps {
  showTools?: boolean;
  readOnly?: boolean;
  rich?: boolean;
  plugins?: any;
  w?: number;
}

const transformToHTML = (value: string, emojis: Emoji[]): string => {
  return value
    .split("\n")
    .map((line) => {
      line = line.replaceAll(/:([a-zA-Z0-9_+-]+):/g, (original, name) => {
        const emoji = emojis?.find((emoji) => name === emoji.name);
        if (!emoji) return original;
        return `<span data-type="emoji" data-name="${emoji.name}"></span>`;
      });

      return `<p>${line}</p>`;
    })
    .join("");
};

export const SimpleTextEditor = ({
  name,
  value = null,
  disabled,
  multiline,
  placeholder,
  onChange,
  autoFocus = false,
  children,
  rows = multiline ? 3 : undefined,
  maxRows = multiline ? 5 : undefined,
  invalid,
  ...props
}: SimpleTextEditorProps) => {
  const { emojis, typographicalCorrection: initialTypographicalCorrection } =
    useInitialEditorOptions();
  const hasProlexis = useHasPlugin("prolexis");
  const hasMerciApp = useHasPlugin("merciapp");
  const { typographicalCorrection } = useEditorOptionsSelector((state) => ({
    typographicalCorrection: state.plugins.some(
      (plugin) => plugin.name === "typographicRules",
    ),
  }));

  const format = useCallback(
    (value: Content) =>
      typeof value === "string" ? transformToHTML(value, emojis) : value,
    [emojis],
  );

  const formattedValue = useRef<Content>();
  formattedValue.current = format(value);

  const parse = useCallback((editor: Editor) => {
    return editor.getText({ blockSeparator: "\n" });
  }, []);

  const editor = useEditor({
    editable: !disabled,
    content: formattedValue.current,
    onUpdate: ({ editor }) => {
      const internalValue = parse(editor);
      if (value !== internalValue) {
        onChange?.(internalValue);
      }
    },
    extensions: [
      SimpleTextKit.configure({
        typographicRules: {
          typographicalCorrection: initialTypographicalCorrection,
          italicsBetweenQuotes: false,
        },
        emoji: {
          emojis,
          suggestion: {
            render: renderEmojiSuggestionList,
          },
        },
        multiline,
        placeholder: {
          placeholder,
        },
      }),
    ],
    autofocus: autoFocus,
  });

  useEditorContentSync({
    editor,
    value,
    format,
    parse,
  });

  useEffect(() => {
    if (!editor) return;
    editor.commands.setActiveTypographicalCorrection(typographicalCorrection);
  }, [editor, typographicalCorrection]);

  return (
    <Editor
      appearance="textBox"
      editor={editor}
      rows={rows}
      maxRows={maxRows}
      {...props}
      className={cn("max-w-full", props.className)}
      intent={invalid ? "danger" : props.intent}
    >
      <FixedMenu hideOnBlur={props.appearance === "inline"}>
        {hasMerciApp && (
          <MerciAppSpellCheckControl
            render={
              <MenuItem>
                <SpellCheck /> Corriger le texte avec MerciApp
              </MenuItem>
            }
          />
        )}
        {hasProlexis && (
          <ProlexisSpellCheckControl
            render={
              <MenuItem>
                <SpellCheck /> Corriger le texte avec Prolexis
              </MenuItem>
            }
          />
        )}
        <SpecialCharControl
          render={
            <MenuItem hideOnClick={false}>
              <Omega /> Insérer un caractère spécial
            </MenuItem>
          }
        />
      </FixedMenu>
      {children}
    </Editor>
  );
};

export interface SimpleTextEditorProps
  extends Omit<EditorProps, "value" | "autoFocus" | "onChange"> {
  /**
   * The name of the field.
   * @example "myField"
   */
  name: string;

  /**
   * The value of the field.
   * @default null
   * @example "Hello, world!"
   */
  value?: Content;

  /**
   * Control whether the field is disabled or not.
   * @default false
   * @example true
   */
  disabled?: boolean;

  /**
   * Control whether the field is multiline or not.
   * @default false
   * @example true
   */
  multiline?: boolean;

  /**
   * Whether the editor should be focused when the component is mounted.
   */
  autoFocus?: UseEditorOptions["autofocus"];

  /**
   * The placeholder text.
   */
  placeholder?: string;

  /**
   * Callback when the field value changes.
   */
  onChange?: (value: string) => void;

  /**
   * Whether the field is invalid or not.
   */
  invalid?: boolean;
}
