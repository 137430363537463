import memoizeOne from "memoize-one";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  whatIsDraggedOver,
  whatIsDraggedOverFromResult,
} from "../../services/droppable/utils";
import type {
  Critical,
  DimensionMap,
  DraggableDimension,
  DroppableId,
  State,
  TypeId,
} from "../../types";
import isStrictEqual from "../is-strict-equal";
import type { MapProps, Props, StateSnapshot } from "./droppable-types";

type Selector = (state: State, ownProps: Props) => MapProps;

const isMatchingType = (type: TypeId, critical: Critical): boolean =>
  type === critical.droppable.type;

const getDraggable = (
  critical: Critical,
  dimensions: DimensionMap,
): DraggableDimension => dimensions.draggables[critical.draggable.id]!;

// Returning a function to ensure each
// Droppable gets its own selector
export const makeMapStateToProps = (): Selector => {
  const idleWithAnimation: MapProps = {
    placeholder: null,
    shouldAnimatePlaceholder: true,
    snapshot: {
      isDraggingOver: false,
      draggingOverWith: null,
      draggingFromThisWith: null,
      isUsingPlaceholder: false,
    },
  };

  const idleWithoutAnimation: MapProps = {
    ...idleWithAnimation,
    shouldAnimatePlaceholder: false,
  };

  const getMapProps = memoizeOne(
    (
      id: DroppableId,
      isEnabled: boolean,
      isDraggingOverForConsumer: boolean,
      isDraggingOverForImpact: boolean,
      dragging: DraggableDimension,
    ): MapProps => {
      const draggableId = dragging.descriptor.id;
      const isHome = dragging.descriptor.droppableId === id;

      if (isHome) {
        const snapshot: StateSnapshot = {
          isDraggingOver: isDraggingOverForConsumer,
          draggingOverWith: isDraggingOverForConsumer ? draggableId : null,
          draggingFromThisWith: draggableId,
          isUsingPlaceholder: true,
        };

        return {
          placeholder: dragging.placeholder,
          shouldAnimatePlaceholder: false,
          snapshot,
        };
      }

      if (!isEnabled) {
        return idleWithoutAnimation;
      }

      // not over foreign list - return idle
      if (!isDraggingOverForImpact) {
        return idleWithAnimation;
      }

      const snapshot: StateSnapshot = {
        isDraggingOver: isDraggingOverForConsumer,
        draggingOverWith: draggableId,
        draggingFromThisWith: null,
        isUsingPlaceholder: true,
      };

      return {
        placeholder: dragging.placeholder,
        // Animating placeholder in foreign list
        shouldAnimatePlaceholder: true,
        snapshot,
      };
    },
  );

  return (state, ownProps) => {
    // not checking if item is disabled as we need the home list to display a placeholder

    const id = ownProps.droppableId;
    const type = ownProps.type;
    const isEnabled = !ownProps.isDropDisabled;

    if (state.isDragging) {
      const critical = state.critical;
      if (!isMatchingType(type, critical)) {
        return idleWithoutAnimation;
      }

      const dragging = getDraggable(critical, state.dimensions);
      const isDraggingOver = whatIsDraggedOver(state.impact) === id;

      return getMapProps(
        id,
        isEnabled,
        isDraggingOver,
        isDraggingOver,
        dragging,
      );
    }

    if (state.phase === "DROP_ANIMATING") {
      const completed = state.completed;
      if (!isMatchingType(type, completed.critical)) {
        return idleWithoutAnimation;
      }

      const dragging = getDraggable(completed.critical, state.dimensions);

      // Snapshot based on result and not impact
      // The result might be null (cancel) but the impact is populated
      // to move everything back
      return getMapProps(
        id,
        isEnabled,
        whatIsDraggedOverFromResult(completed.result) === id,
        whatIsDraggedOver(completed.impact) === id,
        dragging,
      );
    }

    if (state.phase === "IDLE" && state.completed && !state.shouldFlush) {
      const completed = state.completed;
      if (!isMatchingType(type, completed.critical)) {
        return idleWithoutAnimation;
      }

      // Looking at impact as this controls the placeholder
      const wasOver = whatIsDraggedOver(completed.impact) === id;
      const isHome = completed.critical.droppable.id === id;

      if (wasOver) {
        // if reordering we need to cut an animation immediately
        // if merging: animate placeholder closed after drop
        return idleWithoutAnimation;
      }

      // we need to animate the home placeholder closed if it is not
      // being dropped into
      if (isHome) {
        return idleWithAnimation;
      }

      return idleWithoutAnimation;
    }

    // default: including when flushed
    return idleWithoutAnimation;
  };
};

export const useMappedProps = (props: Props) => {
  const selector = useMemo(() => makeMapStateToProps(), []);
  return useSelector((state: State) => selector(state, props), isStrictEqual);
};
