import { gql } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { cn } from "swash/utils/classNames";

import { ImageCard } from "@/containers/image/ImageCard";
import { PreviewProvider } from "@/containers/image/ImagePreview";
import { useLayoutMode } from "@/containers/search/LayoutModeContext";
import { SearchFormList } from "@/containers/search/SearchForm";

import { useMultiSelectStore } from "../image-edit-multiple/MultiSelectStore";

export const ImageListImageFragment = gql`
  fragment ImageListImageFragment on Image {
    id
    ...ImageCard_image
  }
  ${ImageCard.fragments.image}
`;

export function ImageList({ data, loadMore, loading, style, className }) {
  const layoutMode = useLayoutMode();
  const imageIds = useMemo(
    () => data.connection.nodes.map((node) => node.id),
    [data],
  );

  const { setIds } = useMultiSelectStore();
  useEffect(() => {
    setIds(imageIds);
  }, [setIds, imageIds]);

  return (
    <PreviewProvider
      imageIds={imageIds}
      onChange={(id) => {
        if (!data.connection.pageInfo.hasMore || !id || loading) return;
        const index = imageIds.indexOf(id);
        if (imageIds.length - index === 2) {
          loadMore();
        }
      }}
    >
      <SearchFormList
        loadMore={loadMore}
        loading={loading}
        connection={data.connection}
      >
        <div
          role="list"
          className={cn(
            "grid gap-4",
            layoutMode === "grid" ? "grid-cols-3" : "grid-cols-2",
            className,
          )}
          style={style}
        >
          {data.connection.nodes.map((image) => (
            <ImageCard key={image.id} image={image} />
          ))}
        </div>
      </SearchFormList>
    </PreviewProvider>
  );
}
