import { TypedDocumentNode, gql } from "@apollo/client";

import { useSafeQuery } from "@/containers/Apollo";
import {
  BlockTemplatesQueryQuery,
  BlockTemplatesQueryQueryVariables,
  useBlockTemplates_blockTemplateFragment,
} from "@/gql-types";

export type BlockTemplate = useBlockTemplates_blockTemplateFragment;

const BlockTemplateFragment = gql`
  fragment useBlockTemplates_blockTemplate on BlockTemplate {
    id
    name
    title
    type
    editorType
    nodeType {
      name
      attrs
    }
    css
    enabled
  }
`;

const BlockTemplatesQuery: TypedDocumentNode<
  BlockTemplatesQueryQuery,
  BlockTemplatesQueryQueryVariables
> = gql`
  query BlockTemplatesQuery($enabled: Boolean) {
    blockTemplates(where: { enabled: $enabled }) {
      nodes {
        id
        ...useBlockTemplates_blockTemplate
      }
    }
  }
  ${BlockTemplateFragment}
`;

export function useBlockTemplates(
  variables?: BlockTemplatesQueryQueryVariables,
) {
  const { data } = useSafeQuery(BlockTemplatesQuery, { variables });
  return data?.blockTemplates.nodes ?? [];
}
