import { isMac } from "swash/utils/platform";

const OSXToWindowsKeyMapping: Record<string, string> = {
  "⌘": "Ctrl",
  "⌥": "Alt",
};

const VisualKeyMapping: Record<string, string> = {
  ArrowUp: "↑",
  ArrowRight: "→",
  ArrowLeft: "←",
  ArrowDown: "↓",
  " ": "Espace",
};

const firstUpper = (s: string) => (s ? s[0]!.toUpperCase() + s.slice(1) : "");

export const generateKey = (key: string) => {
  let result = firstUpper(key);
  if (!isMac()) {
    result = OSXToWindowsKeyMapping[result] ?? result;
  }
  result = VisualKeyMapping[result] ?? result;
  return result;
};

export const generateShortcut = (shortcut?: string[]) => {
  if (!shortcut) return;
  return shortcut.map((key) => generateKey(key)).join(" + ");
};
