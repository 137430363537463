import * as React from "react";

import { SelectItem, SelectItemCheck, SelectItemProps } from "../Select";
import { ComboboxItem, ComboboxItemProps } from "./Combobox";

export type SelectComboboxItemProps = Omit<ComboboxItemProps, "store"> & {
  children: React.ReactNode;
  value: Exclude<SelectItemProps["value"], undefined>;
  store: SelectItemProps["store"];
  showSelectCheck?: boolean;
};

export const SelectComboboxItem = React.forwardRef<
  HTMLDivElement,
  SelectComboboxItemProps
>(({ children, store, value, showSelectCheck = true, ...props }, ref) => {
  return (
    <ComboboxItem
      ref={ref}
      focusOnHover
      {...props}
      render={<SelectItem store={store} value={value} />}
    >
      {showSelectCheck && <SelectItemCheck store={store} />}
      {children}
    </ComboboxItem>
  );
});
