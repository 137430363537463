import { BsTypeStrikethrough as StrikethroughIcon } from "react-icons/bs";

import createEditorControl from "@/components/editor/controls/createEditorControl";

export const StrikethroughControl = createEditorControl({
  name: "strike",
  label: "Barrer",
  shortcut: ["⌘", "⌥", "x"],
  icon: <StrikethroughIcon />,
  command: ({ editor }) => editor.chain().focus().toggleStrike().run(),
  canExecute: ({ editor }) => editor.can().toggleStrike(),
});
