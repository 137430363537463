import { gql, useQuery } from "@apollo/client";

import {
  useSubscribeFormValue,
  useSubscribePreviousFormValues,
} from "@/components/forms/FormSubscribe";
import { HasOneField } from "@/containers/admin/CRUD";
import { ConnectionFragment } from "@/services/fragments/connectionFragment";

export const PeriodicalSectionLayoutFieldPeriodicalSectionFragment = gql`
  fragment PeriodicalSectionLayoutFieldPeriodicalSectionFragment on PeriodicalSectionLayout {
    id
    label
    path
    enabled
  }
`;

const PeriodicalSectionLayoutFieldPeriodicalSectionQuery = gql`
  query PeriodicalSectionLayoutFieldPeriodicalSectionQuery(
    $offset: Int
    $periodicalSectionId: Int
    $search: String
    $value: Int
  ) {
    connection: periodicalSectionLayouts(
      limit: 30
      offset: $offset
      where: {
        periodicalSectionId: { eq: $periodicalSectionId }
        search: $search
        id: { eq: $value }
        enabled: true
      }
    ) {
      nodes {
        id
        ...PeriodicalSectionLayoutFieldPeriodicalSectionFragment
      }
      ...ConnectionFragment
    }
  }
  ${ConnectionFragment}
  ${PeriodicalSectionLayoutFieldPeriodicalSectionFragment}
`;

const PeriodicalSectionLayoutFieldPeriodicalSectionSingleQuery = gql`
  query PeriodicalSectionLayoutFieldPeriodicalSectionSingleQuery($id: Int!) {
    periodicalSectionLayout(id: $id) {
      ...PeriodicalSectionLayoutFieldPeriodicalSectionFragment
    }
  }
  ${PeriodicalSectionLayoutFieldPeriodicalSectionFragment}
`;

export const useFormPeriodicalSectionLayout = (periodicalSectionLayoutId) => {
  const { data } = useQuery(
    PeriodicalSectionLayoutFieldPeriodicalSectionSingleQuery,
    {
      variables: { id: periodicalSectionLayoutId },
      skip: !periodicalSectionLayoutId,
    },
  );
  return data?.periodicalSectionLayout;
};

export function PeriodicalSectionLayoutField({
  children,
  required,
  disabled,
  label = "Thème",
  placeholder = "Sélectionnez un thème",
  modal,
}) {
  const periodicalSectionId = useSubscribeFormValue("periodicalSectionId");

  useSubscribePreviousFormValues(({ values, previousValues, form }) => {
    if (values.periodicalSectionId !== previousValues.periodicalSectionId) {
      form.change("periodicalSectionLayoutId", null);
    }
  });

  const field = (
    <HasOneField
      name="periodicalSectionLayoutId"
      label={label}
      placeholder={placeholder}
      query={PeriodicalSectionLayoutFieldPeriodicalSectionQuery}
      fragment={PeriodicalSectionLayoutFieldPeriodicalSectionFragment}
      searchVariables={{
        periodicalSectionId,
      }}
      modelName="PeriodicalSectionLayout"
      clearable
      searchable
      hideOnEmpty
      modal={modal}
      required={required}
      disabled={disabled}
    />
  );

  return typeof children === "function" ? children({ children: field }) : field;
}
