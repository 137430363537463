import { TypedDocumentNode, gql } from "@apollo/client";
import { useStoreState } from "@ariakit/react";
import { memo } from "react";
import { useEventCallback } from "swash/utils/useEventCallback";
import {
  Combobox,
  ComboboxItem,
  ComboboxPopover,
  useComboboxStore,
} from "swash/v2/Combobox";

import {
  CheckboxField,
  useCheckboxField,
} from "@/components/fields/CheckboxField";
import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { useSelectField } from "@/components/fields/SelectField";
import { FormSubmit } from "@/components/forms/FormSubmit";
// eslint-disable-next-line @lemonde/import/no-illegal-import
import { useSafeQuery } from "@/containers/Apollo";
// eslint-disable-next-line @lemonde/import/no-illegal-import
import { StringField } from "@/containers/admin/CRUD";
import type {
  AllPreviewUrlsQuery as AllPreviewUrlsQueryData,
  AllPreviewUrlsQueryVariables,
  LinkPluginSearchArticleQueryQuery,
  LinkPluginSearchArticleQueryQueryVariables,
} from "@/gql-types";
import { mustBeURL } from "@/services/forms/validators";

import { mustNotBePreviewURL, mustNotBeSiriusURL } from "./validators";

const SearchArticleQuery: TypedDocumentNode<
  LinkPluginSearchArticleQueryQuery,
  LinkPluginSearchArticleQueryQueryVariables
> = gql`
  query LinkPluginSearchArticleQueryTiptap($search: String) {
    articles(
      where: {
        search: $search
        searchTemplate: titleAndChapo
        isPublished: true
      }
      limit: 10
    ) {
      nodes {
        id
        title
        url
      }
    }
  }
`;

const AllPreviewUrlsQuery: TypedDocumentNode<
  AllPreviewUrlsQueryData,
  AllPreviewUrlsQueryVariables
> = gql`
  query AllPreviewUrlsTiptap {
    previews(where: { enabled: true }) {
      nodes {
        id
        urls
      }
    }
  }
`;

type TabArticleUrlProps = {
  removeIfInvalid?: boolean;
  setEditMode: (value: boolean) => void;
  removeLink: () => void;
};

export const TabArticleUrl = memo(function TabArticleUrl({
  removeIfInvalid,
  removeLink,
  setEditMode,
}: TabArticleUrlProps) {
  const handleEscape = useEventCallback(() => {
    setEditMode(false);
    if (removeIfInvalid) {
      removeLink();
    }
  });

  return (
    <div className="mt-2 flex flex-col gap-2">
      <TextField onEscape={handleEscape} />
      <UrlField onEscape={handleEscape} />
      <AffiliatedUrlField />
      <FormSubmit scale="xs">Insérer le lien</FormSubmit>
    </div>
  );
});

type TextFieldProps = {
  onEscape: () => void;
};

const TextField = memo(function TextField({ onEscape }: TextFieldProps) {
  return (
    <>
      <StringField
        scale="xs"
        name="text"
        rich
        placeholder="Texte du lien"
        required
        showTools={false}
        maxRows={3}
        onKeyDown={(event: React.KeyboardEvent) => {
          switch (event.key) {
            case "Escape":
              onEscape();
              break;
          }
        }}
      />
    </>
  );
});

type UrlFieldProps = {
  onEscape: () => void;
};

const UrlField = memo(function UrlField({ onEscape }: UrlFieldProps) {
  const { data: previewData } = useSafeQuery(AllPreviewUrlsQuery);
  const previewUrls = previewData?.previews.nodes.flatMap((node): string[] =>
    node.urls ? Object.values(node.urls) : [],
  );

  const field = useSelectField("link", {
    validate: (value?: string) => {
      if (value?.startsWith("#")) return undefined;
      return (
        mustBeURL(value, { requireProtocol: false }) ||
        mustNotBeSiriusURL(value) ||
        mustNotBePreviewURL(value, previewUrls)
      );
    },
    required: true,
    disabled: false,
    label: "Lien",
    id: "link",
    orientation: "horizontal",
  });

  const { value, onChange } = field.state.field.input;
  const combobox = useComboboxStore({ value, setValue: onChange });

  const search = useStoreState(combobox, "value");
  const { data: searchData } = useSafeQuery(SearchArticleQuery, {
    variables: { search },
  });
  const articles = searchData?.articles.nodes ?? [];

  return (
    <>
      <FieldGroup
        {...field}
        className="w-full gap-2"
        data-orientation="vertical"
      >
        <Combobox
          autoFocus
          store={combobox}
          raw={false}
          placeholder="Coller un lien ou effectuer une recherche"
          onKeyDown={(event) => {
            switch (event.key) {
              case "Escape":
                onEscape();
                break;
            }
          }}
        />
        <FieldError {...field} className="text-sm" />
      </FieldGroup>
      {!!search && (
        <ComboboxPopover store={combobox} aria-label="Articles" gutter={4}>
          {articles.map((article) => (
            <ComboboxItem
              store={combobox}
              key={article.id}
              value={article.url!}
            >
              {article.title}
            </ComboboxItem>
          ))}
          {!articles.length && (
            <div className="p-1 text-grey-on-light">Aucun article trouvé.</div>
          )}
        </ComboboxPopover>
      )}
    </>
  );
});

type AffiliatedUrlFieldProps = NonNullable<
  Parameters<typeof useCheckboxField>[1]
>;

function AffiliatedUrlField(props: AffiliatedUrlFieldProps) {
  const field = useCheckboxField("affiliate", {
    orientation: "vertical",
    ...props,
  });
  return (
    <CheckboxField {...field}>
      <span className="text-sm">Lien d’affiliation</span>
    </CheckboxField>
  );
}
