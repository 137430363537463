import { TypedDocumentNode, gql } from "@apollo/client";
import { forwardRef } from "react";

import { Image_fixedFragment, Image_fluidFragment } from "@/gql-types";

export const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ alt, src, srcSet, width, height, maxWidth, maxHeight, ...props }, ref) => {
    return (
      <img
        ref={ref}
        sizes={
          maxWidth
            ? `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`
            : undefined
        }
        srcSet={srcSet}
        src={src}
        alt={alt || "image"}
        width={width}
        height={height || maxHeight || undefined}
        {...props}
        style={{
          ...(maxWidth ? { width: "100%", maxWidth } : null),
          ...props.style,
        }}
      />
    );
  },
) as React.ForwardRefExoticComponent<
  ImageProps & React.RefAttributes<HTMLImageElement>
> & {
  fragments: {
    fixed: TypedDocumentNode<Image_fixedFragment>;
    fluid: TypedDocumentNode<Image_fluidFragment>;
  };
};

if (process.env["NODE_ENV"] !== "production") {
  Image.displayName = "Image";
}

Image.fragments = {
  fixed: gql`
    fragment Image_fixed on ImageFixed {
      srcSet
      src
      width
      height
      alt
    }
  `,
  fluid: gql`
    fragment Image_fluid on ImageFluid {
      srcSet
      src
      maxWidth
      maxHeight
      alt
    }
  `,
};

export interface ImageProps
  extends React.HTMLAttributes<HTMLImageElement>,
    Partial<Omit<Image_fixedFragment, "__typename">>,
    Partial<Omit<Image_fluidFragment, "__typename">> {}
