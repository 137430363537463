import { makeIcon } from "./utils";

export const SvgItalic = makeIcon(
  "SvgItalic",
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.25 6H10.5M13.5 18H6.75M14.25 6l-4.5 12"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
