import { InputRule, PasteRule } from "@tiptap/core";

const SPACE = "\u0020";
const NARROW_NO_BREAK_SPACE = "\u202F";
const NO_BREAK_SPACE = "\u00A0";
const QUESTION_MARK = "?";
const EXCLAMATION_MARK = "!";
const COLON = ":";
const SEMICOLON = ";";

const EURO = "€";
const DOLLAR = "$";
const DEGREE = "°";
const PERCENT = "%";

const UNITS = [EURO, DOLLAR, DEGREE, PERCENT];

const PUNCTUATION_SIGNS = [QUESTION_MARK, EXCLAMATION_MARK, COLON, SEMICOLON];

const CHARS = [...PUNCTUATION_SIGNS, ...UNITS];

const SPACES = [SPACE, NARROW_NO_BREAK_SPACE, NO_BREAK_SPACE];

/** Get the last word. */
const getLastWord = (text: string) => {
  let i = text.length;
  let word = "";
  while (i > 0) {
    i--;
    const char = text.charAt(i);
    if (SPACES.includes(char)) break;

    word = char + word;
  }
  return word;
};

/** Check is we are writing a word. */
const checkIsValidWord = (text: string) => {
  const word = getLastWord(text);
  if (word.length === 0) return true;
  // URL
  if (word.startsWith("http")) return false;
  // Emoji
  if (word.startsWith(":")) return false;
  return true;
};

export const inputRule = () =>
  new InputRule({
    find: new RegExp(`[${SPACES.join("")}]?[${CHARS.join("")}]$`, "u"),
    handler: ({ state, range, match }) => {
      if (!checkIsValidWord(match.input ?? "")) return;
      const char = match[0].at(-1)!;
      state.tr.insertText(NO_BREAK_SPACE + char, range.from, range.to);
    },
  });

export const pasteRule = () =>
  new PasteRule({
    find: new RegExp(`[${SPACES.join("")}]?[${CHARS.join("")}]`, "g"),
    handler: ({ state, range, match }) => {
      if (!checkIsValidWord(match.input ?? "")) return;
      const char = match[0].at(-1)!;
      state.tr.insertText(NO_BREAK_SPACE + char, range.from, range.to);
    },
  });
