import { getNodeType } from "@tiptap/core";
import { Node, NodeType } from "@tiptap/pm/model";
import { EditorState } from "@tiptap/pm/state";

export function getAllNodesAttributes(
  state: EditorState,
  typeOrName: string | NodeType,
) {
  const type = getNodeType(typeOrName, state.schema);
  const { from, to } = state.selection;
  const nodes: Node[] = [];

  state.doc.nodesBetween(from, to, (node) => {
    nodes.push(node);
  });

  return nodes.reduce(
    (acc, node) => {
      if (node.type.name === type.name) {
        return [...acc, node.attrs];
      }
      return acc;
    },
    [] as Record<string, any>[],
  );
}
