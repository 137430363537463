import createEditorControl from "@/components/editor/controls/createEditorControl";
import { Undo } from "@/components/icons";

export const UndoControl = createEditorControl({
  name: "undo",
  label: "Annuler",
  shortcut: ["⌘", "z"],
  exclude: [],
  icon: <Undo />,
  command: ({ editor }) => editor.chain().focus().undo().run(),
  canExecute: ({ editor }) => editor.can().undo(),
});
