import { textInputRule, textPasteRule } from "@tiptap/core";

export const inputRule = () =>
  textInputRule({
    find: /[0-9](\u0020)$/u,
    replace: "\u00A0",
  });

export const pasteRule = () =>
  textPasteRule({
    find: /[0-9](\u0020)/gu,
    replace: "\u00A0",
  });
