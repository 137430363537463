import createEditorControl from "@/components/editor/controls/createEditorControl";
import { Superscript } from "@/components/icons";

export const SuperscriptControl = createEditorControl({
  name: "superscript",
  label: "Exposant",
  shortcut: ["⌘", "⌥", "s"],
  icon: <Superscript />,
  command: ({ editor }) => editor.chain().focus().toggleSuperscript().run(),
  canExecute: ({ editor }) => editor.can().toggleSuperscript(),
});
