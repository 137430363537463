import { cn } from "swash/utils/classNames";

export const ModernCapsuleCounter = ({
  children,
  disabled,
  dragIndicator,
}: {
  children: React.ReactNode;
  disabled?: boolean;
  dragIndicator?: boolean;
}) => (
  <div
    className={cn(
      "w-4 shrink-0 select-none text-sm font-semibold tabular-nums text-grey-on",
      !disabled &&
        dragIndicator &&
        "group-hover:-ml-0.5 group-hover:mr-0.5 group-hover:bg-grey-on-light group-hover:[mask-image:url('@/assets/imgs/inline-svg/drag.svg')] group-hover:[mask-size:20px]",
    )}
  >
    {children}
  </div>
);
