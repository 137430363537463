import clsx from "clsx";
import * as React from "react";

import { IoCheckmark, IoClose } from "../Icon";

type SwitchTint =
  | "primary"
  | "secondary"
  | "danger"
  | "warning"
  | "success"
  | string;

type InputProps = {
  checked: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

type InnerSwitchProps = {
  tint?: SwitchTint;
  "data-checked"?: boolean;
  "data-disabled"?: boolean;
  children: React.ReactNode;
};

type SwitchProps = {
  /**
   * The function to call when the switch is toggled.
   */
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  /**
   * Whether the switch is checked.
   */
  checked: boolean;
  /**
   * Whether the switch is disabled.
   */
  disabled?: boolean;
  /**
   * The label of the switch.
   */
  "aria-label"?: string;
  /**
   * The id of the switch.
   */
  "aria-labelledby"?: string;
  /**
   * The value of the switch.
   */
  value?: string;
  /**
   * The tint of the switch. You can also pass a custom color like `#AAB005`.
   * @default "primary"
   */
  tint?: SwitchTint;
  /**
   * The id of the switch.
   */
  id?: string;
};

export const tints: SwitchTint[] = [
  "primary",
  "secondary",
  "danger",
  "warning",
  "success",
];

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { onChange, checked, disabled, value, ...rest } = props;
  return (
    <input
      ref={ref}
      type="checkbox"
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      value={value}
      className={clsx(
        "absolute left-0 top-0 h-5 w-9 cursor-pointer opacity-0",
        disabled && "pointer-events-none",
      )}
      {...rest}
    />
  );
});

const InnerSwitch = React.forwardRef<HTMLDivElement, InnerSwitchProps>(
  (props, ref) => {
    const { tint = "primary", ...rest } = props;

    const isConventionalTint = tints.includes(tint);

    const bgColor = isConventionalTint ? `bg-${tint}-bg-strong` : undefined;

    return (
      <div
        ref={ref}
        className={clsx(
          "group relative inline-flex h-5 w-9 items-center justify-center rounded-full ring-0 transition-all",
          "focus-within:ring-2",
          `ring-${tint}-bg-light`,
          props["data-checked"] ? bgColor : "bg-grey-bg-strong",
          `data-[disabled=true]:opacity-40`,
        )}
        style={{
          backgroundColor:
            !isConventionalTint && props["data-checked"] ? tint : undefined,
        }}
        {...rest}
      />
    );
  },
);

const InnerSwitchToggle = () => {
  return (
    <div
      className={clsx(
        "h-4 w-4 -translate-x-2 rounded-full bg-white shadow-switch transition-all",
        "group-data-[checked=true]:translate-x-2",
      )}
    />
  );
};

const InnerSwitchIndicator = () => {
  return (
    <div
      className={clsx(
        "absolute inset-0 flex w-full items-center px-1",
        "group-data-[checked=false]:justify-end",
      )}
    >
      <IoCheckmark className="hidden text-xs text-white group-data-[checked=true]:flex" />
      <IoClose className="hidden text-xs text-white group-data-[checked=false]:flex" />
    </div>
  );
};

export const Switch = React.forwardRef<HTMLDivElement, SwitchProps>(
  (props, ref) => {
    const {
      onChange,
      checked,
      disabled,
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabelledBy,
      value,
      tint = "primary",
      id,
    } = props;

    return (
      <div ref={ref} className="relative inline-flex py-0.5">
        <InnerSwitch
          data-checked={checked}
          data-disabled={disabled}
          tint={tint}
        >
          <InnerSwitchToggle />
          <InnerSwitchIndicator />
          <Input
            type="checkbox"
            onChange={onChange}
            checked={checked}
            disabled={disabled}
            value={value}
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledBy}
            id={id}
          />
        </InnerSwitch>
      </div>
    );
  },
);
