type Overflow = {
  overflowX: string;
  overflowY: string;
};

const isEqual = (base: string) => (value: string) => base === value;
const isScroll = isEqual("scroll");
const isAuto = isEqual("auto");
const isEither = (overflow: Overflow, fn: (value: string) => boolean) =>
  fn(overflow.overflowX) || fn(overflow.overflowY);

const isElementScrollable = (el: Element): boolean => {
  const style: CSSStyleDeclaration = window.getComputedStyle(el);
  const overflow: Overflow = {
    overflowX: style.overflowX,
    overflowY: style.overflowY,
  };

  return isEither(overflow, isScroll) || isEither(overflow, isAuto);
};

const getClosestScrollable = (el?: HTMLElement | null): HTMLElement | null => {
  // cannot do anything else!
  if (el == null) return null;

  // not allowing us to go higher then body
  if (el === document.body) return null;

  // Should never get here, but just being safe
  if (el === document.documentElement) return null;

  if (!isElementScrollable(el)) {
    // keep recursing
    return getClosestScrollable(el.parentElement);
  }

  // success!
  return el;
};

export default getClosestScrollable;
