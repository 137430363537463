import {
  Store as CoreStore,
  StoreState,
  createStore,
} from "@ariakit/core/utils/store";
import { State, batch } from "@ariakit/core/utils/store";
import {
  useSafeLayoutEffect,
  useUpdateEffect,
} from "@ariakit/react-core/utils/hooks";
import {
  Store,
  useStore,
  useStoreState,
} from "@ariakit/react-core/utils/store";
import * as React from "react";

import { useShallow } from "../../utils/hooks";

/**
 * A hook to update the store with props.
 * This is similarly to Ariakit `useStoreProps` but supports multiple keys.
 */
function useStoreProps<
  S extends State,
  P extends Partial<S>,
  K extends keyof S,
>(store: CoreStore<S>, props: P, keys: K[]) {
  const setStates = () => {
    keys.forEach((key) => {
      const value = props[key];
      if (value === undefined) return;
      store.setState(key, value);
    });
  };
  useSafeLayoutEffect(() => {
    setStates();
    return batch(store, keys, () => {
      setStates();
    });
  });
}

/**
 * Creates a selector with a provider and useSelector hook.
 * @example
 * ```jsx
 * const CustomSelector = createSelector();
 *
 * <CustomSelector.Provider state={{ count: 0 }}>
 *```
 */
export function createSelector<State extends Record<string, any>>() {
  interface ProviderProps {
    state: State;
  }
  type SelectorCoreStore = CoreStore<State>;
  type SelectorStore = Store<SelectorCoreStore>;
  type StateKey = keyof State;

  const context = React.createContext<SelectorStore | undefined>(undefined);

  const Provider = ({
    state,
    ...props
  }: React.PropsWithChildren<ProviderProps>) => {
    const [initialState] = React.useState(state);
    const [store, update] = useStore<SelectorCoreStore, State>(
      createStore,
      initialState,
    );
    useUpdateEffect(update, []);
    const stateKeys = Object.keys(initialState) as StateKey[];
    useStoreProps(store, state, stateKeys);
    return <context.Provider value={store} {...props} />;
  };

  function useSelector<T extends SelectorCoreStore>(): StoreState<T>;

  function useSelector<T extends SelectorCoreStore>():
    | StoreState<T>
    | undefined;

  function useSelector<T extends SelectorCoreStore, K extends StateKey>(
    key: K,
  ): StoreState<T>[K];

  function useSelector<T extends SelectorCoreStore, K extends StateKey>(
    key: K,
  ): StoreState<T>[K] | undefined;

  function useSelector<T extends SelectorCoreStore, V>(
    selector: (state: StoreState<T>) => V,
  ): V;

  function useSelector<T extends SelectorCoreStore, V>(
    selector: (state?: StoreState<T>) => V,
  ): V;

  /**
   * A hook to select state from the store using either a key or a selector function.
   * @example
   * ```jsx
   * const count = useCustomSelector("count");
   * ```
   * @example
   * ```jsx
   * const count = useCustomSelector((state) => state.count);
   * ```
   * @example
   * ```jsx
   * const { id, title } = useCustomSelector((state) => ({
   *   id: state.id,
   *   title: state.title,
   * }));
   *  ```
   */
  function useSelector(keyOrSelector?: any) {
    const store = React.useContext(context);
    return useStoreState(store, useShallow(keyOrSelector));
  }

  function useContext() {
    return React.useContext(context);
  }

  return {
    useContext,
    useSelector,
    Provider,
  };
}
