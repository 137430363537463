import { InputRule, PasteRule, Range } from "@tiptap/core";
import { EditorState } from "@tiptap/pm/state";

const NO_BREAK_SPACE = "\u00A0";

const LEFT_POINTING_QUOTATION_MARK = "«";
const RIGHT_POINTING_QUOTATION_MARK = "»";
const LEFT_DOUBLE_QUOTATION_MARK = "“";
const RIGHT_DOUBLE_QUOTATION_MARK = "”";

/** Check if quotes are opened. */
const checkHasOpenedQuote = (
  { pos, text }: { pos: number; text: string },
  quotes: [string, string],
) => {
  let i = pos;
  while (i > 0) {
    i--;
    if (text[i] === quotes[0]) return true;
    if (text[i] === quotes[1]) return false;
  }
  return false;
};

/** Check if quotes are closed. */
const checkHasClosedQuote = (
  { pos, text }: { pos: number; text: string },
  quotes: [string, string],
) => {
  let i = pos;
  while (i < text.length) {
    i++;
    if (text[i] === quotes[0]) return false;
    if (text[i] === quotes[1]) return true;
  }
  return false;
};

const handler = ({ state, range }: { state: EditorState; range: Range }) => {
  const $pos = state.doc.resolve(range.from);
  const text = $pos.parent.textContent;
  const pos = $pos.parentOffset;

  const opened = checkHasOpenedQuote({ pos, text }, [
    LEFT_POINTING_QUOTATION_MARK,
    RIGHT_POINTING_QUOTATION_MARK,
  ]);
  const closed = checkHasClosedQuote({ pos, text }, [
    LEFT_POINTING_QUOTATION_MARK,
    RIGHT_POINTING_QUOTATION_MARK,
  ]);

  // Surrounded by quotes, use english quotes
  if (opened && closed) {
    const sopened = checkHasOpenedQuote({ pos, text }, [
      LEFT_DOUBLE_QUOTATION_MARK,
      RIGHT_DOUBLE_QUOTATION_MARK,
    ]);
    const sclosed = checkHasClosedQuote({ pos, text }, [
      LEFT_DOUBLE_QUOTATION_MARK,
      RIGHT_DOUBLE_QUOTATION_MARK,
    ]);

    if (sopened && sclosed) return;

    state.tr.insertText(
      sopened ? RIGHT_DOUBLE_QUOTATION_MARK : LEFT_DOUBLE_QUOTATION_MARK,
      range.from,
      range.to,
    );
  } else {
    state.tr.insertText(
      opened
        ? NO_BREAK_SPACE + RIGHT_POINTING_QUOTATION_MARK
        : LEFT_POINTING_QUOTATION_MARK + NO_BREAK_SPACE,
      range.from,
      range.to,
    );
  }
};

export const inputRule = () =>
  new InputRule({
    find: /"$/,
    handler,
  });

export const pasteRule = () =>
  new PasteRule({
    find: /"/g,
    handler,
  });
