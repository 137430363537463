import * as Ariakit from "@ariakit/react";
import * as React from "react";

import { cn } from "./utils/classNames";

export const Tooltip = ({
  children,
  tooltip,
  placement,
  timeout,
  ...props
}: TooltipProps) => {
  const child = React.Children.only(children);
  return (
    <Ariakit.TooltipProvider placement={placement} timeout={timeout}>
      <Ariakit.TooltipAnchor render={child} />
      {tooltip && (
        <Ariakit.Tooltip
          {...props}
          unmountOnHide
          className={cn(
            "z-tooltip max-w-[320px]",
            "whitespace-pre-wrap rounded bg-dusk-bg-stronger fill-dusk-bg-stronger px-2 py-1 text-sm text-white",
            "[&_mark]:bg-white/25 [&_mark]:text-white",
            "[&_li]:list-inside [&_li]:list-disc",
            props.className,
          )}
        >
          {tooltip}
        </Ariakit.Tooltip>
      )}
    </Ariakit.TooltipProvider>
  );
};

export interface TooltipProps
  extends Ariakit.TooltipProps,
    Pick<Ariakit.TooltipProviderProps, "placement" | "timeout"> {
  /**
   * The content of the tooltip. If null, the tooltip will not be displayed.
   */
  tooltip: React.ReactNode;
  /**
   * The child element to display.
   */
  children: React.ReactElement;
}
