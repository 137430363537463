/**
 * Asserts that a condition is true, otherwise throws an error.
 * @example
 * invariant(
 *   condition,
 *   process.env.NODE_ENV !== "production" && "Invariant failed"
 * );
 */
export function invariant(
  condition: any,
  message?: string | boolean,
): asserts condition {
  if (condition) return;
  if (typeof message !== "string") throw new Error("Invariant failed");
  throw new Error(message);
}
