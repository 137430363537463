import { gql } from "@apollo/client";
import { IoExpand, IoImage } from "swash/Icon";
import {
  PreviewLink,
  PreviewLinkContent,
  PreviewLinkHovering,
} from "swash/PreviewLink";
import { Checkbox } from "swash/controls/Checkbox";
import { cn } from "swash/utils/classNames";

import {
  CardListItem,
  CardListItemEditDisclosure,
  CardListItemThumbnail,
} from "@/components/CardListItem";
import { Image } from "@/components/Image";
import { RemoteEditImageDialogDisclosure } from "@/containers/image/ImageEditDialog";
import { useLayoutMode } from "@/containers/search/LayoutModeContext";
import type {
  ImageCard_imageFragment,
  ImagePreview_imageFragment,
  Image_fixedFragment,
  Image_fluidFragment,
} from "@/gql-types";

import { useDragUrl } from "../Dnd";
import {
  ImagePreviewDialog,
  ImagePreviewDialogDisclosure,
  useImagePreviewDialogStore,
} from "./ImagePreview";
import { ImageSummary } from "./ImageSummary";
import { useMultiSelectStore } from "./image-edit-multiple/MultiSelectStore";

export function ImageCard({ image }: { image: ImageCard_imageFragment }) {
  const imageId = image.id;
  const [, dragRef] = useDragUrl(image.siriusUrl ?? "");

  const multiSelectStore = useMultiSelectStore();

  const selected = multiSelectStore.selectedIds.includes(imageId);
  const layoutMode = useLayoutMode();

  return (
    <CardListItem
      ref={dragRef}
      variant={image.expired ? "danger" : undefined}
      layoutMode={layoutMode ?? "grid"}
    >
      {multiSelectStore.selectable && (
        <Checkbox
          checked={selected}
          onChange={(event) => {
            multiSelectStore.toggleOne(imageId, event.currentTarget.checked);
          }}
          className={cn(
            "absolute left-2 top-2 z-30",
            selected ? "opacity-100" : "opacity-0 group-hover:opacity-100",
          )}
        />
      )}
      <CardListItemEditDisclosure
        title="Éditer l’image"
        imageId={imageId}
        // @ts-expect-error JS component
        as={RemoteEditImageDialogDisclosure}
      />
      {layoutMode === "list" && <IoImage className="shrink-0" />}
      <ImagePreview
        image={image}
        source={layoutMode === "list" ? image.fixed : image.fluid}
      />
      <ImageSummary image={image} />
    </CardListItem>
  );
}

function ImagePreview({
  image,
  source,
}: {
  image: ImagePreview_imageFragment;
  source?: Image_fluidFragment | Image_fixedFragment;
}) {
  const previewState = useImagePreviewDialogStore({ imageId: image.id });
  return (
    <>
      {/*@ts-expect-error JS component*/}
      <ImagePreviewDialogDisclosure
        title="Prévisualiser l’image"
        {...previewState}
        // @ts-expect-error JS component
        render={<PreviewLink />}
      >
        <PreviewLinkContent>
          <CardListItemThumbnail alt="image" {...source} />
        </PreviewLinkContent>
        <PreviewLinkHovering>
          <IoExpand />
        </PreviewLinkHovering>
      </ImagePreviewDialogDisclosure>
      <ImagePreviewDialog {...previewState} />
    </>
  );
}

ImagePreview.fragments = {
  image: gql`
    fragment ImagePreview_image on Image {
      id
      fluid(maxHeight: 160) @include(if: $isGrid) {
        ...Image_fluid
      }
      fixed(width: 72, height: 52) @include(if: $isList) {
        ...Image_fixed
      }
    }
    ${Image.fragments.fluid}
    ${Image.fragments.fixed}
  `,
};

ImageCard.fragments = {
  image: gql`
    fragment ImageCard_image on Image {
      id
      expired
      siriusUrl
      ...ImagePreview_image
      ...ImageSummary_image
    }
    ${Image.fragments.fluid}
    ${ImageSummary.fragments.image}
    ${ImagePreview.fragments.image}
  `,
};
