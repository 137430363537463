import { TypedDocumentNode, gql, useSubscription } from "@apollo/client";

import { useSafeQuery } from "@/containers/Apollo";
import type {
  HealthCheckServiceQueryQuery,
  HealthCheckServiceQueryQueryVariables,
  ServiceSubscriptionSubscription,
  ServiceSubscriptionSubscriptionVariables,
  ThirdPartyServiceKey,
} from "@/gql-types";

const ServiceSubscription: TypedDocumentNode<
  ServiceSubscriptionSubscription,
  ServiceSubscriptionSubscriptionVariables
> = gql`
  subscription ServiceSubscription($id: Int!) {
    integrationUpdated(where: { id: { eq: $id } }) {
      id
      enabled
      latestCheck {
        status
      }
    }
  }
`;

const HealthCheckServiceQuery: TypedDocumentNode<
  HealthCheckServiceQueryQuery,
  HealthCheckServiceQueryQueryVariables
> = gql`
  query HealthCheckServiceQuery($serviceKey: ThirdPartyServiceKey!) {
    thirdPartyService(key: $serviceKey) {
      integrations {
        id
        enabled
        latestCheck {
          status
        }
      }
    }
  }
`;

export const useIntegrationHealthCheck = (serviceKey: ThirdPartyServiceKey) => {
  const { data, loading } = useSafeQuery(HealthCheckServiceQuery, {
    variables: { serviceKey },
  });
  const integration = data?.thirdPartyService?.integrations?.[0];

  useSubscription(ServiceSubscription, {
    variables: {
      // @ts-expect-error Skippable
      id: integration?.id,
    },
    skip: !integration?.id,
  });
  const latestCheck = integration?.latestCheck;
  const enabled = integration?.enabled ?? false;

  return {
    isHealthy: latestCheck ? latestCheck.status === "success" : true,
    enabled,
    loading,
  };
};
