import * as Ariakit from "@ariakit/react";
import clsx from "clsx";
import { forwardRef } from "react";

import { Panel } from "./Panel";
import { useStoreState } from "./utils/useStoreState";

export const DialogPanelDisclosure = Ariakit.DialogDisclosure;

export function useDialogPanelStore(props: Ariakit.DialogStoreProps) {
  return Ariakit.useDialogStore({
    animated: true,
    ...props,
  });
}

export type DialogPanelProps = Ariakit.DialogProps & {
  animation?: "from-right" | "from-right-complete";
  fullScreen?: boolean;
};

export const DialogPanel = forwardRef<HTMLDivElement, DialogPanelProps>(
  (
    {
      store,
      className,
      fullScreen = true,
      children,
      animation = "from-right",
      ...props
    },
    ref,
  ) => {
    const animated = useStoreState(store, "animated");
    return (
      <Ariakit.Dialog
        ref={ref}
        className={clsx(
          "swash-dialog-panel",
          fullScreen && "fixed right-0 top-0 z-dialog-panel h-screen",
          className,
        )}
        store={store}
        modal={false}
        hideOnInteractOutside={false}
        autoFocusOnShow={false}
        autoFocusOnHide={false}
        data-animation={animation}
        data-animated={animated ? "" : undefined}
        render={<Panel />}
        {...props}
      >
        {children}
      </Ariakit.Dialog>
    );
  },
);
