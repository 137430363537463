/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { gql } from "@apollo/client";
import { forwardRef, memo, useState } from "react";
import { Clickable } from "swash/Clickable";
import { SelectButton, SelectPopover } from "swash/Select";
import { useEventCallback } from "swash/utils/useEventCallback";

import { MediaIndicator } from "@/components/MediaIndicator";

import {
  ArticleSuggestedMedia,
  ArticleSuggestedMediasSelectList,
  ArticleSuggestedMediasSelectStateProps,
  useArticleSuggestedMediasSelectState,
} from "./ArticleSuggestedMediasSelect";

type ArticleSuggestedMediasButton = {
  suggestedMedias: ArticleSuggestedMedia[];
  onFocus?: () => void;
  onMouseEnter?: () => void;
};

const SuggestedMediaList = ({
  suggestedMedias,
}: {
  suggestedMedias: ArticleSuggestedMedia[];
}) => {
  if (suggestedMedias.length === 0) {
    return <div className="text-sm text-grey-on">Aucun</div>;
  }
  return (
    <div className="flex select-none flex-wrap items-start gap-2 py-0.5 text-lg">
      {suggestedMedias.map((suggestedMedia) => (
        <MediaIndicator key={suggestedMedia.media.id} {...suggestedMedia} />
      ))}
    </div>
  );
};

const ArticleSuggestedMediasButton = memo(
  forwardRef<HTMLButtonElement, ArticleSuggestedMediasButton>(
    ({ suggestedMedias, ...props }, ref) => {
      return (
        <Clickable
          ref={ref}
          role="button"
          tabIndex={0}
          className="flex h-full items-start p-1"
          {...props}
        >
          <SuggestedMediaList suggestedMedias={suggestedMedias} />
        </Clickable>
      );
    },
  ),
);

const ArticleSuggestedMediasSelector = (
  props: ArticleSuggestedMediasEditProps,
) => {
  const state = useArticleSuggestedMediasSelectState({
    article: props.article,
  });

  return (
    <span className="contents" onClick={(event) => event.stopPropagation()}>
      <SelectButton asChild store={state.select}>
        <ArticleSuggestedMediasButton
          suggestedMedias={props.article.suggestedMedias}
        />
      </SelectButton>
      <SelectPopover store={state.select} modal>
        <ArticleSuggestedMediasSelectList state={state} />
      </SelectPopover>
    </span>
  );
};

export type ArticleSuggestedMediasEditProps = {
  article: ArticleSuggestedMediasSelectStateProps["article"];
};

export const ArticleSuggestedMediasEdit = (
  props: ArticleSuggestedMediasEditProps,
) => {
  const [active, setActive] = useState(false);
  const activate = useEventCallback(() => setActive(true));

  if (active) {
    return <ArticleSuggestedMediasSelector article={props.article} />;
  }
  return (
    <ArticleSuggestedMediasButton
      suggestedMedias={props.article.suggestedMedias}
      onFocus={activate}
      onMouseEnter={activate}
    />
  );
};

ArticleSuggestedMediasEdit.fragments = {
  article: gql`
    fragment ArticleSuggestedMediasEdit_article on Article {
      id
      ...ArticleSuggestedMediasSelect_article
    }
    ${useArticleSuggestedMediasSelectState.fragments.article}
  `,
};
