import { useMemo } from "react";

import { createSafeContext } from "@/services/hooks/useSafeContext";
import { Queue } from "@/services/queue";

const QueueContext = createSafeContext<{ queue: Queue }>();

export function QueueProvider({
  children,
  concurrent = 5,
}: {
  children: React.ReactNode;
  concurrent?: number;
}) {
  const queue = useMemo(
    () =>
      new Queue({
        concurrent,
      }),
    [concurrent],
  );

  return (
    <QueueContext.Provider value={{ queue }}>{children}</QueueContext.Provider>
  );
}

export const useQueue = QueueContext.makeSafeHook("useQueue", "QueueProvider");
