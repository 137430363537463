import { TypedDocumentNode, gql } from "@apollo/client";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "swash/Button";
import { SegmentedControl } from "swash/controls/SegmentedControl";

import {
  Config,
  Data,
  ForecastInsightsChart,
  Unit,
} from "@/components/charts/ForecastInsightsChart";
import { valueFormatter } from "@/components/charts/utils";
import { useAmplitude } from "@/containers/Amplitude";
import { useSafeQuery } from "@/containers/Apollo";
import { ErrorBoundary } from "@/containers/ErrorBoundary";
import { useRemoteConfig } from "@/containers/RemoteConfig";
import { HasPermission } from "@/containers/User";
import { AnalyticsGroup } from "@/containers/article/panels/analytics/Group";
import {
  AnalyticsPanelForecastInsightsConversions_articleQuery,
  AnalyticsPanelForecastInsightsConversions_articleQueryVariables,
  AnalyticsPanelForecastInsightsEngagement_articleQuery,
  AnalyticsPanelForecastInsightsEngagement_articleQueryVariables,
  AnalyticsPanelForecastInsightsPageViews_articleQuery,
  AnalyticsPanelForecastInsightsPageViews_articleQueryVariables,
  AnalyticsPanelForecastInsightsTunnelAccess_articleQuery,
  AnalyticsPanelForecastInsightsTunnelAccess_articleQueryVariables,
  AnalyticsPanelForecastInsightsWebTrafficSources_articleQuery,
  AnalyticsPanelForecastInsightsWebTrafficSources_articleQueryVariables,
  ForecastInsightsInterval,
  InsightDataDeviceFragmentFragment,
} from "@/gql-types";

import { ForecastDataAlert } from "./ForecastAlert";

type ForecastInsights = {
  conversions?: InsightDataDeviceFragmentFragment;
  pageViews?: InsightDataDeviceFragmentFragment;
  webTrafficSources?: InsightDataDeviceFragmentFragment;
  tunnelAccess?: InsightDataDeviceFragmentFragment;
  engagement?: {
    averageReadingDuration: number;
    averageReadingRate: number;
    share: number;
    subscriberTrafficRate: number;
    reads: number;
  };
};

const MetricDeviceFragment = gql`
  fragment MetricDeviceFragment on MetricsDevice {
    desktop
    mobile
    app
  }
`;

const MetricsTotalsFragment = gql`
  fragment MetricsTotalsFragment on MetricsTotals {
    overall
    total
  }
`;

const InsightDataDeviceFragment = gql`
  fragment InsightDataDeviceFragment on InsightDataDevice {
    totals {
      ...MetricsTotalsFragment
      ...MetricDeviceFragment
    }
    aggregateDataPoints {
      date
      ...MetricDeviceFragment
    }
  }
  ${MetricsTotalsFragment}
  ${MetricDeviceFragment}
`;

const ArticleConversionsQuery: TypedDocumentNode<
  AnalyticsPanelForecastInsightsConversions_articleQuery,
  AnalyticsPanelForecastInsightsConversions_articleQueryVariables
> = gql`
  query AnalyticsPanelForecastInsightsConversions_article(
    $id: Int!
    $input: ForecastInsightsInput!
  ) {
    article(id: $id) {
      id
      initialFirstPublished
      forecastInsights {
        conversions(input: $input) {
          ...InsightDataDeviceFragment
        }
      }
    }
  }
  ${InsightDataDeviceFragment}
`;

const ArticleTunnelAccessQuery: TypedDocumentNode<
  AnalyticsPanelForecastInsightsTunnelAccess_articleQuery,
  AnalyticsPanelForecastInsightsTunnelAccess_articleQueryVariables
> = gql`
  query AnalyticsPanelForecastInsightsTunnelAccess_article(
    $id: Int!
    $input: ForecastInsightsInput!
  ) {
    article(id: $id) {
      id
      initialFirstPublished
      forecastInsights {
        tunnelAccess(input: $input) {
          ...InsightDataDeviceFragment
        }
      }
    }
  }
  ${InsightDataDeviceFragment}
`;

const ArticlePageViewsQuery: TypedDocumentNode<
  AnalyticsPanelForecastInsightsPageViews_articleQuery,
  AnalyticsPanelForecastInsightsPageViews_articleQueryVariables
> = gql`
  query AnalyticsPanelForecastInsightsPageViews_article(
    $id: Int!
    $input: ForecastInsightsInput!
  ) {
    article(id: $id) {
      id
      initialFirstPublished
      forecastInsights {
        pageViews(input: $input) {
          ...InsightDataDeviceFragment
        }
      }
    }
  }
  ${InsightDataDeviceFragment}
`;

const ArticleWebTrafficSourcesQuery: TypedDocumentNode<
  AnalyticsPanelForecastInsightsWebTrafficSources_articleQuery,
  AnalyticsPanelForecastInsightsWebTrafficSources_articleQueryVariables
> = gql`
  query AnalyticsPanelForecastInsightsWebTrafficSources_article(
    $id: Int!
    $input: ForecastInsightsInput!
  ) {
    article(id: $id) {
      id
      initialFirstPublished
      forecastInsights {
        webTrafficSources(input: $input) {
          totals {
            ...MetricsTotalsFragment
            searchRate
            socialRate
            internalRate
            darkRate
            othersRate
          }
          aggregateDataPoints {
            date
            search
            social
            internal
            dark
            others
          }
        }
      }
    }
  }
  ${MetricsTotalsFragment}
`;

const ArticleEngagementQuery: TypedDocumentNode<
  AnalyticsPanelForecastInsightsEngagement_articleQuery,
  AnalyticsPanelForecastInsightsEngagement_articleQueryVariables
> = gql`
  query AnalyticsPanelForecastInsightsEngagement_article($id: Int!) {
    article(id: $id) {
      id
      initialFirstPublished
      forecastInsights {
        engagement {
          averageReadingDuration
          averageReadingRate
          share
          subscriberTrafficRate
          reads
        }
      }
    }
  }
`;

const Indicator = ({
  value,
  title,
  unit,
}: {
  value?: number;
  title: string;
  unit: Unit;
}) => {
  return (
    <div className="flex items-center justify-between border-secondary-border-light py-2 [&:not(:last-child)]:border-b">
      <h3 className="text-secondary-on">{title}</h3>
      <p className="font-semibold text-info-on">
        {valueFormatter({ value, unit })}
      </p>
    </div>
  );
};

const Engagement = ({ articleId }: { articleId: number }) => {
  const { data } = useSafeQuery(ArticleEngagementQuery, {
    variables: { id: articleId },
  });

  if (!data?.article) return null;

  const insights = data.article.forecastInsights.engagement;

  return (
    // @ts-expect-error use js component
    <AnalyticsGroup title="Engagement">
      <div className="px-2 font-accent">
        <Indicator
          title="Part de trafic abonnés"
          value={insights.subscriberTrafficRate}
          unit="percent"
        />
        <Indicator
          title="Temps de lecture moyen"
          value={insights.reads ? insights.averageReadingDuration : undefined}
          unit="duration"
        />
        <Indicator
          title="Pourcentage lu de l’article"
          value={insights.reads ? insights.averageReadingRate : undefined}
          unit="percent"
        />
        <Indicator
          title="Nombre de partages"
          value={insights.share}
          unit="duration"
        />
      </div>
    </AnalyticsGroup>
  );
};

const useInsightsConfig = () => {
  const {
    forecast: { insights },
  } = useRemoteConfig();

  return {
    yLimit: [0, "dataMax"] as [number | string, number | string],
    height: 140,
    animationDuration: 500,
    groups: {
      ...(insights.conversions && {
        conversions: {
          title: "Conversions",
          yAxisLabel: "conversions",
          query: ArticleConversionsQuery,
          keys: ["app", "desktop", "mobile"],
        },
      }),
      ...(insights.tunnelAccess && {
        tunnelAccess: {
          title: "Entrées tunnel",
          yAxisLabel: "entrées tunnel",
          query: ArticleTunnelAccessQuery,
          keys: ["app", "desktop", "mobile"],
        },
      }),
      pageViews: {
        title: "Pages vues uniques",
        yAxisLabel: "pages vues",
        query: ArticlePageViewsQuery,
        keys: ["app", "desktop", "mobile"],
      },
      webTrafficSources: {
        title: "Sources de trafic Web",
        yAxisLabel: "pages vues",
        query: ArticleWebTrafficSourcesQuery,
        keys: ["search", "social", "internal", "dark", "others"],
        displayTotal: false,
        tooltipValueFormatter: (value?: number, total?: number) => {
          if (total === undefined || value === undefined) return "- (-%)";
          if (value === 0) return "0 (0%)";

          const percentValue = (value / total) * 100;
          if (percentValue < 1) return `${value} (<1%)`;
          return `${value} (${percentValue.toFixed().toLocaleString()}%)`;
        },
        stackOffset: "expand",
      },
    },
    labels: [
      {
        key: "app",
        color: "#FF6B6E",
        tailwind: "border-red-border bg-red-bg",
        legend: "App",
      },
      {
        key: "desktop",
        color: "#5849E2",
        tailwind: "border-purple-border-strong bg-purple-bg",
        legend: "Web (desktop)",
      },
      {
        key: "mobile",
        color: "#5EBCD5",
        tailwind: "border-blue-border bg-blue-bg",
        legend: "Web (mobile)",
      },
      {
        key: "others",
        color: "#5EBCD5",
        tailwind: "border-blue-border bg-blue-bg",
        legend: "Autres",
        hint: "Part du trafic web en provenance d’autres sources : newsletters, partenaires...",
      },
      {
        key: "social",
        color: "#42C798",
        tailwind: "border-green-border bg-green-bg",
        legend: "Réseaux sociaux",
        hint: "Part du trafic web en provenance des réseaux sociaux : Facebook...",
      },
      {
        key: "dark",
        color: "#5849E2",
        tailwind: "border-purple-border bg-purple-bg",
        legend: "Dark social",
        hint: "Part du trafic web de provenance indéterminée, souvent liée à des partages par messagerie",
      },
      {
        key: "internal",
        color: "#FF6B6E",
        tailwind: "border-red-border bg-red-bg",
        legend: "Recirculation",
        hint: "Part du trafic web en provenance d’une autre page du site : homepage, article...",
      },
      {
        key: "search",
        color: "#F0AF60",
        tailwind: "border-orange-border bg-orange-bg",
        legend: "Moteurs",
        hint: "Part du trafic web en provenance des moteurs de recherche : Google, Bing, Yahoo...",
      },
    ],
  };
};

function useLogEventPeriodChange() {
  const { logEvent } = useAmplitude();
  const { pathname } = useLocation();

  const source = useMemo(() => pathname.split("/")[1], [pathname]);

  return useCallback(
    ({ period, chart }: { period: ForecastInsightsInterval; chart: DataKey }) =>
      logEvent?.("statsPanel:chart:changePeriod", {
        source,
        chart,
        period,
      }),
    [logEvent, source],
  );
}

type InsightsConfig = ReturnType<typeof useInsightsConfig>;
type DataKey = keyof InsightsConfig["groups"];

type MetricProps = {
  config: InsightsConfig;
  dataKey: DataKey;
  articleId: number;
};

const Metric = memo(({ config, dataKey, articleId }: MetricProps) => {
  const logEventPeriodChange = useLogEventPeriodChange();
  const [interval, setInterval] =
    useState<ForecastInsightsInterval>("LAST_24_HOURS");

  const refs = useRef({ dataKey, logEventPeriodChange });

  const handleIntervalChange = useCallback(
    (interval: ForecastInsightsInterval) => {
      const { dataKey, logEventPeriodChange } = refs.current;
      logEventPeriodChange({ chart: dataKey, period: interval });
      setInterval(interval);
    },
    [],
  );

  const { groups, ...conf } = config;
  const group = groups[dataKey];
  const ctxConfig = {
    ...conf,
    labels: config.labels.filter((l) => group?.keys.includes(l.key)),
    group,
  };

  const queryResult = useSafeQuery(ctxConfig.group!.query, {
    variables: { id: articleId, input: { interval } },
    fetchPolicy: "network-only",
  });

  const data = queryResult.data ?? queryResult.previousData;
  const article = data?.article;

  if (!article) return null;

  const insights: ForecastInsights = article.forecastInsights;

  const insight = insights[dataKey];

  if (!insight) return null;

  return (
    <AnalyticsGroup
      title={ctxConfig.group!.title}
      value={insight.totals?.overall ?? "-"}
    >
      <SegmentedControl
        className="my-2"
        value={interval}
        scale="sm"
        onChange={(value) =>
          handleIntervalChange(value as ForecastInsightsInterval)
        }
      >
        <Button id="LAST_24_HOURS">24 heures</Button>
        <Button id="LAST_7_DAYS">7 jours</Button>
        <Button id="LAST_30_DAYS">30 jours</Button>
        <Button id="SINCE_PUBLICATION">Total</Button>
      </SegmentedControl>
      <ForecastInsightsChart
        data={insight as unknown as Data<DataKey>}
        config={ctxConfig as Config<DataKey>}
        interval={interval}
        loading={queryResult.loading}
        article={article}
      />
    </AnalyticsGroup>
  );
});

const ForecastEnabledQuery = gql`
  query ArticleAnalyticsPanel_thirdPartyService {
    thirdPartyService(key: forecast) {
      enabled
    }
  }
`;

export function ForecastInsights({ articleId }: { articleId: number }) {
  const { data } = useSafeQuery(ForecastEnabledQuery);
  const config = useInsightsConfig();
  if (!data) return null;
  return (
    <HasPermission permission="stats:full">
      {data.thirdPartyService.enabled && (
        <ErrorBoundary
          key={articleId}
          component={ForecastDataAlert}
          shouldCaptureError={() => false}
        >
          {Object.keys(config.groups).map((key) => {
            return (
              <Metric
                key={key}
                config={config}
                dataKey={key as DataKey}
                articleId={articleId}
              />
            );
          })}
          <Engagement articleId={articleId} />
        </ErrorBoundary>
      )}
    </HasPermission>
  );
}
