import { forwardRef, memo, useCallback, useEffect, useMemo } from "react";
import { Checkbox } from "swash/controls/Checkbox";
import { ChoiceChip } from "swash/controls/ChoiceChip";
import { Radio, RadioGroup, RadioLabel } from "swash/controls/Radio";
import { FormCheckbox } from "swash/form/FormCheckbox";
import { FormLabel } from "swash/form/FormLabel";
import { useLiveRef } from "swash/utils/useLiveRef";
import { useStoreState } from "swash/utils/useStoreState";
import { EnumSelect, useEnumSelectState } from "swash/v2/EnumSelect";

import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { SelectField, useSelectField } from "@/components/fields/SelectField";

export const SelectEnumField = forwardRef(
  (
    {
      name,
      required,
      clearable,
      label,
      "aria-label": ariaLabel,
      enum: unsortedItems,
      disabledValues,
      hint,
      placeholder,
      multi,
      scale = "lg",
      labelElementSelector,
      labelSelector = (field) => field.label,
      sortEntries = (itemA, itemB) => itemA.label.localeCompare(itemB.label),
      modal,
      disabled,
      footer,
      hideOnClick,
      onOpenChange,
      ...others
    },
    ref,
  ) => {
    const emptyValue = useMemo(() => (multi ? [] : null), [multi]);
    const onOpenChangeRef = useLiveRef(onOpenChange);

    const items = useMemo(() => {
      return [...unsortedItems].sort(sortEntries);
    }, [unsortedItems, sortEntries]);

    const format = useCallback(
      (v) => {
        if (multi) {
          return v.map(
            (v) => items.find((item) => item.value === v) || emptyValue,
          );
        }
        return items.find((item) => item.value === v) || emptyValue;
      },
      [multi, items, emptyValue],
    );

    const parse = useCallback(
      (v) => {
        if (multi) {
          return v.map((v) => v.value);
        }
        return v?.value ?? null;
      },
      [multi],
    );

    const field = useSelectField(name, {
      required,
      label: ariaLabel || label,
      format,
      parse,
      ...others,
    });

    const { value, onChange } = field.state.field.input;

    const state = useEnumSelectState({
      title: label,
      value: value && items.length ? value : emptyValue,
      onChange,
      items,
      labelSelector,
      labelElementSelector,
      valueSelector: (field) => field.value?.toString(),
      disabledSelector: (field) =>
        disabledValues?.includes(field.value) ?? disabled,
      required: required || !clearable,
      appearance: multi ? "chip" : "default",
      footer,
      hideOnClick,
    });

    const open = useStoreState(state.select, "open");

    useEffect(() => {
      if (onOpenChangeRef.current) {
        onOpenChangeRef.current(open);
      }
    }, [onOpenChangeRef, open]);

    return (
      <FieldGroup {...field} ref={ref} className="gap-2">
        {label ? <FieldLabel {...field}>{label}</FieldLabel> : null}
        <FieldError {...field} />
        {hint ? (
          <FieldHint {...field} className="pb-1">
            {hint}
          </FieldHint>
        ) : null}
        <EnumSelect
          placeholder={placeholder || `${label}...`}
          aria-label={ariaLabel || label}
          className={others.orientation === "horizontal" ? "flex-1" : null}
          state={state}
          modal={modal}
          disabled={disabled}
          scale={scale === "base" ? "md" : scale}
        />
      </FieldGroup>
    );
  },
);

export function EnumFieldCheckboxControl({
  enum: enumValues,
  sortEntries,
  onBlur,
  onFocus,
  multi,
  onChange,
  name,
  disabled,
  required,
  variant,
  type = "default",
  orientation = "horizontal",
  ...props
}) {
  const Wrapper = type === "default" ? FormCheckbox : ChoiceChip;

  if (multi) {
    return (
      <div className="flex flex-wrap gap-2" data-field-control>
        {Object.entries(enumValues)
          .sort(sortEntries)
          .map(([value, label]) => {
            const id = `${name}-${value}`;
            return (
              <Wrapper
                key={value}
                label={label}
                checked={props.value?.includes(value) || false}
              >
                <Checkbox
                  id={id}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  checked={props.value?.includes(value) || false}
                  onChange={(event) => {
                    const targetValue = event.currentTarget.value;
                    onChange(
                      props.value
                        ? props.value.includes(targetValue)
                          ? props.value.filter((x) => x !== targetValue)
                          : [...props.value, targetValue]
                        : [targetValue],
                    );
                  }}
                  disabled={disabled}
                  variant={variant}
                />
                <FormLabel htmlFor={id}>{label}</FormLabel>
              </Wrapper>
            );
          })}
      </div>
    );
  }
  return (
    <RadioGroup data-field-control data-orientation={orientation}>
      {Object.entries(enumValues)
        .sort(sortEntries)
        .map(([value, label]) => (
          <RadioLabel key={value}>
            <Radio
              name={name}
              value={value}
              onBlur={onBlur}
              onFocus={onFocus}
              checked={value === props.value}
              onChange={(event) => {
                onChange(event.currentTarget.value);
              }}
              disabled={disabled}
            />
            {label}
          </RadioLabel>
        ))}
      {!required && (
        <RadioLabel>
          <Radio
            name={name}
            value="toto"
            onBlur={onBlur}
            onFocus={onFocus}
            checked={props.value == null}
            onChange={() => {
              onChange(null);
            }}
          />
          Aucun
        </RadioLabel>
      )}
    </RadioGroup>
  );
}

const CheckboxEnumField = forwardRef(
  (
    {
      name,
      required,
      label,
      "aria-label": ariaLabel,
      enum: enumValues,
      hint,
      renderOption,
      renderChip,
      multi,
      sortEntries,
      appearance,
      disabled,
      ...others
    },
    ref,
  ) => {
    const field = useSelectField(name, {
      required,
      label: ariaLabel || label,
      ...others,
    });
    return (
      <FieldGroup {...field} ref={ref}>
        {label ? <FieldLabel {...field}>{label}</FieldLabel> : null}
        <FieldError {...field} />
        {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
        <SelectField
          {...field}
          as={EnumFieldCheckboxControl}
          enum={enumValues}
          multi={multi}
          sortEntries={sortEntries}
          disabled={disabled}
          orientation={others.orientation}
        />
      </FieldGroup>
    );
  },
);

/** @type {React.FC<?>} */
export const EnumField = memo(
  forwardRef(({ appearance = "select", type = "default", ...others }, ref) => {
    switch (appearance) {
      case "select":
        return <SelectEnumField ref={ref} {...others} />;
      case "checkbox":
        return <CheckboxEnumField ref={ref} type={type} {...others} />;
      default:
        return null;
    }
  }),
);
