import { makeIcon } from "./utils";

export const SvgClock = makeIcon(
  "SvgClock",
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m7.83 6.58.988-.582 1.492 4.553 3.992 1.822-.485.95-4.457-1.977zM0 11.577l1.957-.232.078.29c.401 1.873 1.37 3.43 2.906 4.67a8.518 8.518 0 0 0 2.83 1.395 7.712 7.712 0 0 0 3.062.233c.284-.039.523-.084.717-.136 1.886-.4 3.43-1.363 4.631-2.887a8.566 8.566 0 0 0 1.415-2.829 7.515 7.515 0 0 0 .213-3.08 5.406 5.406 0 0 0-.117-.68c-.387-1.873-1.356-3.429-2.906-4.67-1.821-1.33-3.785-1.879-5.89-1.646a.877.877 0 0 0-.311.058.557.557 0 0 0-.096.02l-.117.018h-.058l-.057.02c-1.421.283-2.785 1.04-4.09 2.266l1.086.853-4.418 1.88.484-4.98 1.26 1.007C4.103 1.611 5.827.636 7.753.222l.446-.077.019-.019h.116l.174-.039h.117c2.015-.259 3.914.058 5.696.95a9.96 9.96 0 0 1 4.205 3.933 9.007 9.007 0 0 1 1.183 3.178c.194 1.15.213 2.234.058 3.255a10.492 10.492 0 0 1-.893 2.926 8.538 8.538 0 0 1-1.124 1.802c-.632.904-1.485 1.68-2.557 2.325-.53.323-1.105.607-1.725.852-.607.233-1.253.414-1.938.543a9.37 9.37 0 0 1-2.19.135 8.59 8.59 0 0 1-2.208-.407c-1.77-.478-3.295-1.408-4.574-2.79a9.913 9.913 0 0 1-2.48-4.864z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>,
);
