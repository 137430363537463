import { Extension, SingleCommands } from "@tiptap/core";
import { Transaction } from "@tiptap/pm/state";

const removeStyleCommand = () => {
  return ({ tr, commands }: { tr: Transaction; commands: SingleCommands }) => {
    const { selection } = tr;
    if (selection.empty) return false;
    commands.unsetAllMarks();
    return true;
  };
};

export const RemoveStyleExtension = Extension.create({
  name: "removeStyle",
  addCommands() {
    return {
      removeStyle: () => removeStyleCommand(),
    };
  },
  addKeyboardShortcuts() {
    return {
      "Mod-/": () => {
        return this.editor.commands.removeStyle();
      },
      "Mod-:": () => {
        return this.editor.commands.removeStyle();
      },
    };
  },
});

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    removeStyle: {
      removeStyle: () => ReturnType;
    };
  }
}
