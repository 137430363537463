import { TypedDocumentNode, gql } from "@apollo/client";
import {
  type ForwardRefExoticComponent,
  type RefObject,
  forwardRef,
} from "react";
import { cn } from "swash/utils/classNames";

import { Image, ImageProps } from "@/components/Image";
import type {
  InitialsAvatar_userFragment,
  UserAvatar_userFragment,
} from "@/gql-types";

const backgroundRandomColors = [
  "#2a3b4c",
  "#10418e",
  "#4527a0",
  "#8ca1ee",
  "#65b7d7",
  "#65b793",
  "#00796b",
  "#9c1258",
  "#c20006",
  "#ff3d44",
  "#ffb83d",
  "#f58f00",
];

const InitialsAvatar = forwardRef<HTMLDivElement, InitialsAvatarProps>(
  ({ user, size, ...props }, ref) => {
    const randomIndex = user.id % backgroundRandomColors.length;
    return (
      <div
        ref={ref}
        style={{
          backgroundColor: backgroundRandomColors[randomIndex],
          width: size,
          height: size,
        }}
        {...props}
        className={cn(
          "flex shrink-0 select-none items-center justify-center rounded-full",
          props.className,
        )}
      >
        <svg width="100%" height="100%" viewBox="-50 -66 100 100">
          <text fill="white" fontWeight="600" textAnchor="middle" fontSize="50">
            {user.initials}
          </text>
        </svg>
      </div>
    );
  },
) as ForwardRefExoticComponent<
  InitialsAvatarProps & React.RefAttributes<HTMLDivElement>
> & {
  fragments: { user: TypedDocumentNode<InitialsAvatar_userFragment> };
};
if (process.env["NODE_ENV"] !== "production") {
  InitialsAvatar.displayName = "InitialsAvatar";
}

InitialsAvatar.fragments = {
  user: gql`
    fragment InitialsAvatar_user on User {
      id
      initials
    }
  `,
};

interface InitialsAvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  user: InitialsAvatar_userFragment;
  size: number;
}

export const UserAvatar = forwardRef<HTMLElement, UserAvatarImageProps>(
  ({ user, size = 32, ...props }, ref) => {
    if (!user.image) {
      return (
        <InitialsAvatar
          ref={ref as RefObject<HTMLDivElement>}
          user={user}
          size={size}
          {...props}
        />
      );
    }

    const image = size <= 32 ? user.image.avatar : user.image.avatarHD;

    return (
      <Image
        ref={ref as RefObject<HTMLImageElement>}
        {...image}
        alt={user.fullName}
        width={size}
        height={size}
        {...props}
        className={cn("rounded-full", props.className)}
      />
    );
  },
) as ForwardRefExoticComponent<UserAvatarImageProps> & {
  fragments: { user: TypedDocumentNode<UserAvatar_userFragment> };
};

UserAvatar.fragments = {
  user: gql`
    fragment UserAvatar_user on User {
      id
      fullName
      image {
        avatar: fixed(width: 32, height: 32) {
          ...Image_fixed
        }
        avatarHD: fixed(width: 128, height: 128) {
          ...Image_fixed
        }
      }
      ...InitialsAvatar_user
    }
    ${InitialsAvatar.fragments.user}
    ${Image.fragments.fixed}
  `,
};

export type UserAvatarImageProps = (InitialsAvatarProps | ImageProps) & {
  size: number;
  user: UserAvatar_userFragment;
};
