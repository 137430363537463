import { clsx } from "clsx";
import * as React from "react";
import { forwardRef } from "react";

export type BadgeScale = "sm" | "md";
export type BadgeColor = "primary" | "danger" | "success" | "secondary";

export const scales: Record<BadgeScale, string> = {
  sm: /* tw */ `px-0.5 text-2xs min-w-[0.75rem]`,
  md: /* tw */ `px-1 text-xs min-w-[1rem]`,
};

export const colors: Record<BadgeColor, string> = {
  primary: /* tw */ `bg-primary-bg-light text-primary-on ring-primary-border-light`,
  secondary: /* tw */ `bg-secondary-bg-light text-secondary-on ring-secondary-border-light`,
  danger: /* tw */ `bg-danger-bg-light text-danger-on ring-danger-border-light`,
  success: /* tw */ `bg-success-bg-light text-success-on ring-success-border-light`,
};

export interface BadgeProps extends React.ComponentProps<"div"> {
  /**
   * The scale of the badge.
   * Context dependent.
   * @default "md"
   */
  scale?: BadgeScale;
  /**
   * The purpose of the badge.
   * @default "notif"
   */
  color?: BadgeColor;
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  ({ color = "primary", scale = "md", className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          className,
          "inline-block select-none rounded-sm text-center font-accent font-semibold tabular-nums ring-1",
          scales[scale],
          colors[color],
        )}
        {...props}
      />
    );
  },
);

Badge.displayName = "Badge";
