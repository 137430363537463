import { gql } from "@apollo/client";
import { Children, memo } from "react";
import { Chip } from "swash/Chip";
import { IoExpand, IoImage } from "swash/Icon";
import {
  PreviewLink,
  PreviewLinkContent,
  PreviewLinkHovering,
} from "swash/PreviewLink";
import { cn } from "swash/utils/classNames";

import { Image } from "@/components/Image";
import { DisplayEmojifyText } from "@/components/rich-editor/plugins/emoji";
import { EditorBlockCapsuleToolbarActions } from "@/components/teleporters/EditorBlockCapsule";
import { useSafeQuery } from "@/containers/Apollo";
import { RemoteEditImageDialogDisclosure } from "@/containers/image/ImageEditDialog";
import {
  ImagePreviewDialog,
  ImagePreviewDialogDisclosure,
  useImagePreviewDialogStore,
} from "@/containers/image/ImagePreview";

import { ChangeText } from "./ChangeText";
import { ImageNodeEditCaption } from "./ImageNodeEditCaption";
import {
  ImageNodeEditImage,
  useImageNodeEditImageContext,
} from "./ImageNodeEditImage";
import { ImageNodeExpandedCaptionEditor } from "./ImageNodeExpandedCaptionEditor";
import { ImageNodeFragment } from "./ImageNodeFragment";
import {
  EditorNodeBody,
  EditorNodeCard,
  EditorNodeGenAIIcon,
  EditorNodeIcon,
  EditorNodeImage,
  EditorNodeLayout,
  EditorNodeTooltip,
} from "./NodeLayout";

export const ImageNodeMarker = memo(({ children }) => {
  return (
    <div>
      <Chip scale="sm">{children}</Chip>
    </div>
  );
});

const CroppedImageQuery = gql`
  query ImageNode_image(
    $id: Int!
    $region: ImageRegionInput!
    $maxHeight: Int!
  ) {
    image(id: $id) {
      id
      cropped: fluid(region: $region, maxHeight: $maxHeight) {
        ...Image_fluid
      }
    }
  }
  ${Image.fragments.fluid}
`;

const AspectRatioQuery = gql`
  query AspectRatioQuery($id: Int!) {
    aspectRatio(id: $id) {
      id
      slug
    }
  }
`;

const ImageNodeCroppedThumbnailWrapper = ({ metadata, ...props }) => {
  const { data } = useSafeQuery(AspectRatioQuery, {
    variables: {
      id: Number(metadata.aspectRatioId),
    },
  });

  if (!data) return null;

  return (
    <ImageNodeCroppedThumbnail
      metadata={metadata}
      aspectRatio={data.aspectRatio}
      {...props}
    />
  );
};

const ImageNodeCroppedThumbnail = ({
  width,
  height,
  image,
  metadata,
  aspectRatio,
  expanded,
}) => {
  const cropsMetadata = metadata.crops[aspectRatio.slug];
  const { data } = useSafeQuery(CroppedImageQuery, {
    variables: {
      region: {
        top: cropsMetadata.top,
        left: cropsMetadata.left,
        width: cropsMetadata.width,
        height: cropsMetadata.height,
      },
      id: image.id,
      maxHeight: height,
    },
  });
  return (
    <EditorNodeImage
      width={width}
      height={height}
      loading={!data}
      expanded={expanded}
      {...data?.image?.cropped}
    />
  );
};

const ImageNodeThumbnail = ({
  width,
  height,
  image,
  imgProps,
  metadata,
  expanded = false,
}) => {
  const hasCropInfo = Boolean(metadata?.aspectRatioId && metadata.crops);
  return hasCropInfo ? (
    <ImageNodeCroppedThumbnailWrapper
      width={width}
      height={height}
      image={image}
      metadata={metadata}
      expanded={expanded}
    />
  ) : (
    <EditorNodeImage
      width={width}
      height={height}
      {...imgProps}
      expanded={expanded}
    />
  );
};

const ImageNodeExpanded = memo(({ image, metadata }) => {
  return (
    <div className="mb-2" data-expanded="">
      <ImageNodeThumbnail
        width={1}
        height={444}
        image={image}
        metadata={metadata}
        imgProps={image?.expanded}
        expanded
      />
    </div>
  );
});

const ImageNodeDisclosure = memo(({ image, editable, children }) => {
  const imageContext = useImageNodeEditImageContext();
  if (editable === "image" && imageContext) {
    const { renderPreview, renderEditor } = imageContext;
    return (
      <RemoteEditImageDialogDisclosure
        dialogProps={{
          imageId: image.id,
          preview: renderPreview({ imageId: image.id }),
          editor: renderEditor({ imageId: image.id }),
        }}
        render={children}
      />
    );
  }
  if (typeof children === "function") return children();
  return children;
});

/** @type {React.FC<?>} */
export const ImageNode = memo(
  ({
    image,
    metadata,
    published,
    onUpdate,
    editable,
    onEdit,
    tooltip,
    aiRecommended,
    children: childrenProp,
    expanded,
    articleMediaId,
  }) => {
    const children = Children.toArray(childrenProp);
    const marker = children.find((child) => child.type === ImageNodeMarker);
    const previewState = useImagePreviewDialogStore({
      imageId: image.id,
      editable,
    });

    const caption = metadata?.caption ?? image.caption;
    const displayedCaption = caption || "Aucune légende";
    const isNotUsable = !published && (!image.webAuthorized || image.expired);
    return (
      <>
        {editable && (
          <EditorBlockCapsuleToolbarActions>
            {onUpdate && editable === "caption" && (
              <ImageNodeEditCaption
                caption={caption}
                onUpdate={onUpdate}
                onEdit={onEdit}
              />
            )}
            {editable === "image" && (
              <ImageNodeEditImage image={image} onEdit={onEdit} />
            )}
          </EditorBlockCapsuleToolbarActions>
        )}
        {!expanded ? (
          <>
            <ImageNodeDisclosure image={image} editable={editable}>
              {(disclosureProps) => (
                <EditorNodeCard
                  ref={disclosureProps?.ref}
                  {...disclosureProps}
                  variant={isNotUsable && "danger"}
                >
                  <EditorNodeTooltip tooltip={tooltip}>
                    <EditorNodeLayout>
                      <EditorNodeIcon>
                        <IoImage />
                        {aiRecommended && <EditorNodeGenAIIcon />}
                      </EditorNodeIcon>
                      <ImagePreviewDialogDisclosure
                        title="Prévisualiser l’image"
                        {...previewState}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        render={<PreviewLink tabIndex={0} />}
                      >
                        <PreviewLinkContent display="flex">
                          <ImageNodeThumbnail
                            width={72}
                            height={52}
                            image={image}
                            metadata={metadata}
                            imgProps={image.thumbnail}
                          />
                        </PreviewLinkContent>
                        <PreviewLinkHovering>
                          <IoExpand />
                        </PreviewLinkHovering>
                      </ImagePreviewDialogDisclosure>
                      <EditorNodeBody multiline>
                        {marker}
                        <ChangeText rawText={displayedCaption}>
                          <DisplayEmojifyText text={displayedCaption} />
                        </ChangeText>
                      </EditorNodeBody>
                    </EditorNodeLayout>
                  </EditorNodeTooltip>
                </EditorNodeCard>
              )}
            </ImageNodeDisclosure>
            <ImagePreviewDialog {...previewState} />
          </>
        ) : (
          <div className={cn(isNotUsable && "bg-danger-bg-light")}>
            <ImageNodeExpanded image={image} metadata={metadata} />
            <ImageNodeExpandedCaptionEditor
              caption={caption}
              credit={image.credit}
              articleMediaId={articleMediaId}
            />
          </div>
        )}
      </>
    );
  },
);

ImageNode.fragments = ImageNodeFragment;
