import createEditorControl from "@/components/editor/controls/createEditorControl";
import { Subscript } from "@/components/icons";

export const SubscriptControl = createEditorControl({
  name: "subscript",
  label: "Indice",
  shortcut: ["⌘", "⌥", "i"],
  icon: <Subscript />,
  command: ({ editor }) => editor.chain().focus().toggleSubscript().run(),
  canExecute: ({ editor }) => editor.can().toggleSubscript(),
});
