/* eslint-disable react/prop-types */
import { forwardRef } from "react";
import { UseFieldConfig, useField } from "react-final-form";
import { Radio, RadioGroup, RadioLabel } from "swash/controls/Radio";
import { cn } from "swash/utils/classNames";

import { composeValidators, mustBeFilled } from "@/services/forms/validators";

import { FieldControl } from "./FieldControl";
import { FieldState, Orientation, useFieldState } from "./FieldState";

type RadioGroupFieldOptions = UseFieldConfig<any> & {
  required?: boolean;
  id?: string;
  orientation?: Orientation;
};

export function useRadioGroupField(
  name: string,
  {
    required = false,
    validate,
    id,
    orientation = "horizontal",
    ...options
  }: RadioGroupFieldOptions = {},
) {
  const validators = [];
  if (validate) {
    validators.push(validate);
  }
  if (required) {
    validators.push(mustBeFilled);
  }
  const field = useField(name, {
    type: "radio",
    validate: validators.length ? composeValidators(...validators) : undefined,
    ...options,
  });

  return useFieldState({
    field,
    id,
    orientation,
    required,
    fieldOptions: { name, ...options },
  });
}

type RadioFieldOptions = UseFieldConfig<any> & {
  disabled?: boolean;
};

export function useRadioField(
  {
    state: {
      fieldOptions: { name, ...fieldGroupOptions },
    },
  }: { state: FieldState },
  { disabled, ...fieldOptions }: RadioFieldOptions = {},
) {
  const field = useField(name, {
    type: "radio",
    ...fieldGroupOptions,
    ...fieldOptions,
  });

  return useFieldState({ field, disabled });
}

type RadioGroupFieldProps = {
  state?: FieldState;
  children?: React.ReactNode;
  className?: string;
};

export const RadioGroupField = forwardRef<HTMLDivElement, RadioGroupFieldProps>(
  ({ state, ...props }, ref) => {
    return <RadioGroup ref={ref} data-field-control {...props} />;
  },
);
if (process.env["NODE_ENV"] !== "production") {
  RadioGroupField.displayName = "RadioGroupField";
}

type RadioFieldProps = {
  state: FieldState;
  children?: React.ReactNode;
  size?: string;
  className?: string;
};

export const RadioField = forwardRef<HTMLInputElement, RadioFieldProps>(
  ({ children, size = "base", ...props }, ref) => {
    return (
      <FieldControl as="input" ref={ref} {...props}>
        {({ "data-field-control": dataFieldControl, ...fieldControlProps }) => (
          <RadioLabel
            data-scale={size as any}
            className={cn(
              props.state.disabled && "opacity-disabled",
              props.className,
            )}
          >
            <Radio {...fieldControlProps} />
            {children}
          </RadioLabel>
        )}
      </FieldControl>
    );
  },
);
if (process.env["NODE_ENV"] !== "production") {
  RadioField.displayName = "RadioField";
}
