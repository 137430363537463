import clsx from "clsx";
import * as React from "react";
import { forwardRef, memo } from "react";

import { Size, autoUnitSize } from "./utils/size";
import { useDelayedVisible } from "./utils/useDelayedVisible";

export interface LoaderProps extends React.SVGProps<SVGSVGElement> {
  /**
   * Delay the loader from showing up until the delay has passed.
   * @default 300
   */
  delay?: number;

  /**
   * Size of the loader.
   * @default "1em"
   */
  size?: Size;
}

export const Loader = memo(
  forwardRef<SVGSVGElement, LoaderProps>(
    ({ delay = 300, size = "1em", className, style, ...props }, ref) => {
      const visible = useDelayedVisible(delay);
      return (
        <svg
          ref={ref}
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
          role="status"
          aria-busy="true"
          className={clsx(
            className,
            "swash-loader inline-block h-[var(--loader-size)] w-[var(--loader-size)]",
            !visible && "invisible",
          )}
          style={
            {
              "--loader-size": autoUnitSize(size),
              ...style,
            } as React.CSSProperties
          }
          {...props}
        >
          <circle cx="50" cy="50" r="45" />
        </svg>
      );
    },
  ),
);

Loader.displayName = "Loader";
export interface PageLoaderProps extends React.ComponentProps<"div"> {
  /**
   * Delay the loader from showing up until the delay has passed.
   * @default 300
   */
  delay?: number;

  /**
   * Size of the loader.
   * @default "1em"
   */
  size?: Size;
}

export const PageLoader = memo(
  forwardRef<HTMLDivElement, PageLoaderProps>(
    ({ delay = 300, size = 40, className, ...props }, ref) => {
      const visible = useDelayedVisible(delay);
      return (
        <div
          ref={ref}
          className={clsx(
            className,
            "flex flex-1 items-center justify-center p-4",
            !visible && "invisible",
          )}
          {...props}
        >
          <Loader size={size} delay={0} />
        </div>
      );
    },
  ),
);

PageLoader.displayName = "PageLoader";
