import { makeIcon } from "./utils";

export const SvgH3 = makeIcon(
  "SvgH3",
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.212 17.948c-.139-.145-.131-.368-.01-.528.182-.244.55-.246.802-.074.091.063.19.12.294.17.3.143.607.215.921.215.388 0 .692-.09.913-.268a.898.898 0 0 0 .333-.732c0-.615-.442-.923-1.324-.923h-.172a.522.522 0 0 1-.518-.509c0-.274.235-.502.51-.5h.18c.37 0 .665-.072.887-.216.224-.143.336-.358.336-.646a.78.78 0 0 0-.34-.679c-.225-.162-.513-.244-.866-.244-.414 0-.792.122-1.135.364-.232.164-.564.153-.734-.074a.41.41 0 0 1 .02-.535c.503-.512 1.152-.769 1.945-.769.65 0 1.171.158 1.564.474.397.315.595.744.595 1.287 0 .405-.13.74-.39 1.004-.258.265-.561.429-.908.493.34.032.657.19.948.473.291.281.437.653.437 1.115 0 .561-.204 1.019-.612 1.373-.407.354-.952.531-1.634.531-.506 0-.955-.099-1.345-.297a2.541 2.541 0 0 1-.697-.505Z"
      fill="currentColor"
    />
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      d="M4.875 6v12M12.375 12h-7.5M13.125 6v12"
    />
  </svg>,
);
