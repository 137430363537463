import { Editor } from "@tiptap/core";
import { Node } from "@tiptap/pm/model";
import { memo, useEffect, useId, useRef, useState } from "react";
import { useEditorContext } from "swash/editor";
import { cn } from "swash/utils/classNames";
import { useEventCallback } from "swash/utils/useEventCallback";

import { convertTextOffset } from "../../helpers/convertTextOffset";
import { DiffKind, diffWordMode } from "../../helpers/diffWordMode";
import { useMerciAppInstance } from "./MerciAppProvider";

const hasText = (node: Node) => node.textContent.trim().length > 0;

export const MerciAppEditors = memo(function MerciAppEditors() {
  const { editor } = useEditorContext();

  const editors = [editor].filter((editor): editor is Editor => {
    return editor != null && hasText(editor.state.doc);
  });

  const blocks = editor?.$doc.children
    .filter(({ node }) => node.isBlock && hasText(node))
    .map(({ node, pos }, index) => ({ node, pos, index }));

  return (
    <div className="mx-auto flex w-[698px] flex-col">
      {editors.map((editor) =>
        blocks?.map((block) => {
          return (
            <MerciAppEditorTextarea
              key={block.index}
              editor={editor}
              block={block.node}
              pos={block.pos}
            />
          );
        }),
      )}
      {!editors.length && `Aucun contenu à corriger`}
    </div>
  );
});

const MerciAppEditorTextarea = ({
  editor,
  block,
  pos,
}: {
  editor: Editor;
  block: Node;
  pos: number;
}) => {
  const merciAppId = `merciapp-${useId()}`.replace(/:/g, "");

  useMerciAppInstance({
    selector: `#${merciAppId} > .merciapp-editor`,
    spinnerContainerSelector: `#${merciAppId} > .merciapp-spinner`,
  });

  const { elementRef, setValue } = useAutoResizeOnChange(block.textContent);

  const handleOnChange = useEventCallback((event) => {
    const newBlockText = event.target.value;
    const previousText = block.textContent;

    const diffs = diffWordMode(previousText, newBlockText);

    const removedIndex = diffs.findIndex((diff) => diff[0] === DiffKind.Remove);
    const addedIndex = diffs.findIndex((diff) => diff[0] === DiffKind.Add);
    const anchorOffset =
      diffs[0]?.[0] === DiffKind.Keep ? diffs[0][1].length : 0;

    const removedText = removedIndex > -1 ? diffs[removedIndex]![1] : "";
    const addedText = addedIndex > -1 ? diffs[addedIndex]![1] : "";
    const focusOffset = anchorOffset + removedText.length;

    const focusPos = pos + convertTextOffset(block, focusOffset);

    const inBlockFrom = pos + convertTextOffset(block, anchorOffset);
    const inBlockTo =
      pos + convertTextOffset(block, anchorOffset + removedText.length);

    editor
      .chain()
      .insertContentAt({ from: inBlockFrom, to: inBlockTo }, addedText)
      .setTextSelection(focusPos)
      .run();

    setValue(newBlockText);
  });

  if (!block.textContent.trim().length) {
    return (
      <div>
        <br />
      </div>
    );
  }

  return (
    <div
      id={merciAppId}
      className={cn(
        "flex",
        block.type.name === "title" || block.type.name === "chapo"
          ? "mb-4 border-b border-b-grey-border pb-4"
          : "mb-2 last-of-type:mb-0",
      )}
    >
      <textarea
        ref={elementRef}
        className={cn(
          "merciapp-editor",
          "w-full flex-1 resize-none text-base text-dusk-on",
          block.type.name === "title" && "text-lg font-bold",
          block.type.name === "chapo" && "font-semibold",
        )}
        onChange={handleOnChange}
        defaultValue={block.textContent}
        onInput={(event) => {
          const el = event.target as HTMLTextAreaElement;
          el.style.height = "";
          el.style.height = el.scrollHeight + "px";
        }}
      />
      <div className={cn("merciapp-spinner", "relative size-5 self-end")} />
    </div>
  );
};

const useAutoResizeOnChange = (defaultValue: string) => {
  const elementRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (!elementRef.current) return;
    if (value) {
      elementRef.current.style.height = "0px";
      const scrollHeight = elementRef.current.scrollHeight;
      elementRef.current.style.height =
        scrollHeight > 1000 ? "1000px" : scrollHeight + "px";
    }
  }, [value]);

  return { elementRef, setValue };
};
