import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDialogApi } from "swash/Dialog";

import { injectScript, removeScript } from "@/components/Script";

const SCRIPT_VERSION = "4.0.0";
const SCRIPT_SRC = `/assets/vendors/prolexis@${SCRIPT_VERSION}/DiagPlWs.js`;

const ProlexisContext = createContext<{
  analyze: ({
    fields,
    onCorrections,
    parent,
  }: {
    fields: ProlexisField[];
    onCorrections: (corrections: ProlexisCorrection[]) => void;
    parent?: HTMLElement;
  }) => Promise<void>;
}>(null!);

export type ProlexisField = {
  src: string;
  type: "string";
  label: string;
  changed?: boolean;
};

export type ProlexisCorrection = {
  elementIndex: number;
  offset: number;
  length: number;
  correction: string;
};

declare global {
  interface Window {
    DiagPlWs: {
      init: (config: {
        sUrlProxy: string;
        sCorePath: string;
        onCorrection: (correction: ProlexisCorrection) => void;
        hCallBack: () => void;
        applyCorrections: boolean;
      }) => void;
      analyze: (fields: ProlexisField[]) => void;
    };
  }
}

const createBatcher = <T,>(callback: (values: T[]) => void) => {
  let values = [] as T[];
  let timeout: ReturnType<typeof setTimeout>;
  return (value: T) => {
    values.push(value);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callback(values);
      values = [];
    });
  };
};

export const ProlexisProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const handlersRef = useRef<{
    onCorrection?: (correction: ProlexisCorrection) => void;
    hCallBack?: () => void;
  }>({});
  useEffect(() => () => removeScript(SCRIPT_SRC), []);

  const { forceOutsideDialogInteractions } = useDialogApi();

  const analyze = useCallback(
    async ({
      fields,
      onCorrections,
      parent = document.body,
    }: {
      fields: ProlexisField[];
      onCorrections: (corrections: ProlexisCorrection[]) => void;
      parent?: HTMLElement;
    }) => {
      handlersRef.current.onCorrection = createBatcher(onCorrections);

      // Prevent dialog from closing when Prolexis is opened
      const cleanup = forceOutsideDialogInteractions();

      return new Promise<void>((resolve, reject) => {
        handlersRef.current.hCallBack = resolve;

        injectScript({
          src: SCRIPT_SRC,
          parent,
          onload: () => {
            window.DiagPlWs.init({
              sUrlProxy: "/api/prolexis/v4", // url vers le proxy
              sCorePath: `/assets/vendors/prolexis@${SCRIPT_VERSION}/`,
              onCorrection: (correction: ProlexisCorrection) => {
                handlersRef.current.onCorrection?.(correction);
              },
              hCallBack: () => {
                handlersRef.current.hCallBack?.();
              },
              applyCorrections: false,
            });
          },
        })
          .then(() => {
            window.DiagPlWs.analyze(fields);
          })
          .catch(reject);
      }).finally(() => {
        // At the end, restore options
        cleanup();
      });
    },
    [forceOutsideDialogInteractions],
  );

  const value = useMemo(() => ({ analyze }), [analyze]);

  return (
    <ProlexisContext.Provider value={value}>
      {children}
    </ProlexisContext.Provider>
  );
};

export function useProlexis() {
  return useContext(ProlexisContext);
}
