import { noop } from "lodash-es";
import { createContext, useContext, useEffect, useState } from "react";
import { useEventCallback } from "swash/utils/useEventCallback";

export type MultiSelectStore = {
  selectable: boolean;
  selectedIds: number[];
  setIds: (ids: number[]) => void;
  unselectAll: () => void;
  toggleOne: (id: number, selected: boolean) => void;
};

const context = createContext<MultiSelectStore>({
  selectable: false,
  selectedIds: [],
  setIds: noop,
  unselectAll: noop,
  toggleOne: noop,
});

export function MultiSelectStoreProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [allIds, setIds] = useState<number[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [lastUnselectedId, setLastUnselectedId] = useState<number>();

  const unselectAll = useEventCallback(() => {
    setSelectedIds([]);
  });

  const shiftKey = useShiftKey();

  const toggleOne = useEventCallback((id: number, selected: boolean) => {
    setSelectedIds((currentSelectedIds) => {
      if (shiftKey && currentSelectedIds.length) {
        const lastUnselectedImageId = lastUnselectedId;
        const imageIndex = allIds.indexOf(id);
        const lastSelectedImageId =
          currentSelectedIds[currentSelectedIds.length - 1];
        const lastImageId = selected
          ? lastSelectedImageId
          : (lastUnselectedImageId ?? lastSelectedImageId);
        const lastImageIndex =
          lastImageId != null ? allIds.indexOf(lastImageId) : -1;
        const forward = imageIndex > lastImageIndex;
        const start = forward ? lastImageIndex : imageIndex;
        const end = forward ? imageIndex + 1 : lastImageIndex + 1;
        const selectedImageIds = forward
          ? allIds.slice(start, end)
          : allIds.slice(start, end).reverse();

        if (selected) {
          return [...new Set([...currentSelectedIds, ...selectedImageIds])];
        } else {
          return currentSelectedIds.filter(
            (id) => !allIds.slice(start, end).includes(id),
          );
        }
      } else {
        if (selected) {
          return [...currentSelectedIds, id];
        } else {
          return currentSelectedIds.filter((currentId) => currentId !== id);
        }
      }
    });
    setLastUnselectedId(selected ? undefined : id);
  });

  useEffect(() => {
    if (!selectedIds.length) {
      setLastUnselectedId(undefined);
    }
  }, [selectedIds]);

  const store = {
    selectable: true,
    selectedIds,
    setIds,
    unselectAll,
    toggleOne,
  };

  return <context.Provider value={store}>{children}</context.Provider>;
}

export const useMultiSelectStore = () => useContext(context);

const useShiftKey = () => {
  const [shiftKeyOn, setShiftKeyOn] = useState(false);

  useEffect(() => {
    const callback = (event: KeyboardEvent) => setShiftKeyOn(event.shiftKey);

    document.addEventListener("keydown", callback, { passive: true });
    document.addEventListener("keyup", callback, { passive: true });
    return () => {
      document.removeEventListener("keydown", callback);
      document.removeEventListener("keyup", callback);
    };
  }, []);

  return shiftKeyOn;
};
