import { useEffect } from "react";

/**
 * Logs `messages` to the console using `console.warn` based on a `condition`.
 *
 * @example
 * warning(true, "a", "b"); // console.warn("a", "\n", "b")
 * warning(false, "a", "b"); // does nothing
 */
export function warning(condition: boolean, ...messages: string[]) {
  if (process.env["NODE_ENV"] !== "production") {
    if (!condition) return;

    // eslint-disable-next-line no-console
    console.warn(...messages);

    // Throwing an error and catching it immediately to improve debugging
    // A consumer can use 'pause on caught exceptions'
    // https://github.com/facebook/react/issues/4216
    try {
      throw Error(messages.join(""));
    } catch {
      // do nothing
    }
  }
}

/**
 * Logs `messages` to the console using `console.warn` based on a `condition`.
 * This should be used inside components.
 */
export function useWarning(condition: boolean, ...messages: string[]) {
  if (process.env["NODE_ENV"] !== "production") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      warning(condition, ...messages);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition]);
  }
}
