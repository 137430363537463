import { memo } from "react";
import { Button, ButtonProps } from "swash/Button";
import {
  IoCheckmarkCircleOutline,
  IoCopyOutline,
  IoLinkOutline,
} from "swash/Icon";
import { Tooltip } from "swash/Tooltip";
import { useClipboard } from "use-clipboard-copy";

export const CopyButton = memo<ButtonProps & { value: string }>(
  ({ value, title, scale, variant = "primary", ...props }) => {
    const clipboard = useClipboard({ copiedTimeout: 3000 });

    return (
      <div className="inline-flex items-center gap-1">
        <Tooltip tooltip={title} timeout={0}>
          <Button
            iconOnly
            scale={scale}
            appearance="text"
            variant={clipboard.copied ? "success" : variant}
            aria-label={title}
            onClick={() => {
              clipboard.copy(value);
            }}
            className="shrink-0"
            disabled={!value}
            {...props}
          >
            {clipboard.copied ? (
              <IoCheckmarkCircleOutline />
            ) : /^https?:\/\//.test(value) ? (
              <IoLinkOutline />
            ) : (
              <IoCopyOutline />
            )}
          </Button>
        </Tooltip>
        {clipboard.copied && (
          <span className="font-accent text-sm font-normal text-grey-on">
            Copié
          </span>
        )}
      </div>
    );
  },
);
