import { TZDate } from "@date-fns/tz";
import {
  endOfISOWeek,
  endOfMonth,
  set as setInDate,
  startOfISOWeek,
  startOfMonth,
} from "date-fns";
import { memo, useEffect, useMemo, useState } from "react";
import { useFormState } from "react-final-form";
import {
  Select,
  SelectClear,
  SelectPopover,
  useSelectStore,
} from "swash/Select";
import { Tooltip } from "swash/Tooltip";
import { useStoreState } from "swash/utils/useStoreState";

import { useArticleAuditTrailTooltip } from "@/components/ArticleAuditTrail";
import { EmbargoTime } from "@/components/EmbargoTime";
import { Time } from "@/components/Time";
import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { SelectField, useSelectField } from "@/components/fields/SelectField";
import {
  ArticleDateFormFields,
  formatDateValues,
  parseDateValues,
} from "@/containers/article/ArticleDateForm";
import {
  formatDate,
  formatRangeDate,
} from "@/containers/article/ArticlePublishDate";
import { HourRange } from "@/containers/article/ArticlePublishTime";
import { formatForParis } from "@/services/time-formatter";

function shouldNotDisplayHour({ timingMode, plannedRange, plannedTime }) {
  return (
    (["time", "daily"].includes(timingMode) && !plannedTime) ||
    (timingMode === "range" && !plannedRange)
  );
}

function PublicationDateTime({
  plannedDate,
  isUrgent,
  timingMode,
  plannedTime,
  plannedRange,
  isEmbargo,
}) {
  let date = new TZDate(plannedDate, "Europe/Paris");

  if (isUrgent) {
    return (
      <Time date={date}>
        {formatDate(date)}
        {" ASAP"}
      </Time>
    );
  }

  if (plannedRange && timingMode === "range") {
    const [hours, minutes] = plannedRange.split(":").map(Number);
    date = setInDate(date, { hours, minutes });
    return (
      <Time date={date}>
        {formatDate(date)} entre <HourRange date={date} literal />
      </Time>
    );
  }

  if (shouldNotDisplayHour({ timingMode, plannedRange, plannedTime })) {
    return <Time date={date}>{formatDate(date)}</Time>;
  }

  const [hours, minutes] = plannedTime.split(":").map(Number);
  date = setInDate(date, { hours, minutes });

  const time = formatForParis(date, "HH:mm");
  return (
    <Time date={date}>
      {formatDate(date)} à {isEmbargo ? <EmbargoTime time={time} /> : time}
    </Time>
  );
}

function SelectDatePickerLabel({
  value: {
    dated,
    plannedDate,
    planningMode,
    plannedTime,
    plannedRange,
    isEmbargo,
    timingMode,
  },
  placeholder = "Date",
}) {
  if (!dated || !plannedDate)
    return <div className="text-grey-on">{placeholder}</div>;
  if (planningMode === "week") {
    const startDate = startOfISOWeek(plannedDate);
    const endDate = endOfISOWeek(plannedDate);
    return (
      <strong>
        <Time date={startDate}>{formatRangeDate(startDate, "from")}</Time> →{" "}
        <Time date={endDate}>{formatRangeDate(endDate, "to")}</Time>
      </strong>
    );
  }
  if (planningMode === "month") {
    const startDate = startOfMonth(plannedDate);
    const endDate = endOfMonth(plannedDate);
    return (
      <strong>
        <Time date={startDate}>{formatRangeDate(startDate, "from")}</Time> →{" "}
        <Time date={endDate}>{formatRangeDate(endDate, "to")}</Time>
      </strong>
    );
  }

  return (
    <PublicationDateTime
      plannedDate={plannedDate}
      plannedTime={plannedTime}
      plannedRange={plannedRange}
      isUrgent={timingMode === "asap"}
      timingMode={timingMode}
      isEmbargo={isEmbargo}
    />
  );
}

function SelectControl({
  onChangeOpened,
  name,
  value,
  onChange,
  modal,
  ...props
}) {
  const select = useSelectStore({
    value,
    setValue: onChange,
  });
  const open = useStoreState(select, "open");

  const initialValues = useMemo(
    () => ({
      dated: true,
      plannedDate: null,
      planningMode: "day",
      timingMode: "daily",
      plannedTime: null,
      plannedRange: null,
      isEmbargo: false,
      isUrgent: false,
    }),
    [],
  );

  useEffect(() => {
    onChangeOpened(open);
  }, [onChangeOpened, open]);

  return (
    <>
      <Select store={select} scale="lg" {...props}>
        <SelectDatePickerLabel
          value={value}
          placeholder={value.dated ? "Date et heure" : "Non daté"}
        />
        <SelectClear
          store={select}
          clearable={value.dated && value.plannedDate}
          onClear={() => {
            select.setValue(initialValues);
          }}
        />
      </Select>
      <SelectPopover
        aria-label="Date et heure de publication souhaitée"
        style={{ maxHeight: 400 }}
        modal={modal}
        store={select}
      >
        {open ? <ArticleDateFormFields /> : null}
      </SelectPopover>
    </>
  );
}

const SelectDateField = memo(function SelectDateField({
  name,
  required,
  modal,
}) {
  const [opened, setOpened] = useState(false);
  const field = useSelectField(name, {
    required,
    validate: (value) => {
      if (!value.dated) return undefined;
      if (!value.plannedDate) {
        return { error: "Date requise" };
      }

      if (value.planningMode === "day") {
        if (value.timingMode === "time" && !value.plannedTime) {
          return { error: "Heure requise" };
        }
        if (value.timingMode === "range" && !value.plannedRange) {
          return { error: "Créneau requis" };
        }
      }
      return undefined;
    },
  });

  const tooltip =
    useArticleAuditTrailTooltip("publicationDate") ??
    "Éditer la date de publication souhaitée";

  return (
    <Tooltip tooltip={opened ? null : tooltip} placement="left">
      <FieldGroup {...field}>
        <FieldLabel {...field}>
          Date et heure de publication numérique
        </FieldLabel>
        <FieldError {...field} />
        <SelectField
          {...field}
          as={SelectControl}
          modal={modal}
          onChangeOpened={setOpened}
        />
      </FieldGroup>
    </Tooltip>
  );
});

/**
 * @param {object} props
 * @param {boolean} [props.modal]
 */
export function PublicationDateField({ modal }) {
  const {
    values: {
      publicationDate: { dated },
    },
  } = useFormState({ subscription: { values: true } });
  return (
    <SelectDateField name="publicationDate" required={dated} modal={modal} />
  );
}

export const parsePublicationDate = parseDateValues;
export const formatPublicationDate = formatDateValues;
