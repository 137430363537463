import createEditorControl from "@/components/editor/controls/createEditorControl";
import { H2, H3 } from "@/components/icons";

export const HeadingControl = createEditorControl<HeadingProps>({
  name: "heading",
  icon: (props) => {
    switch (props.level) {
      case 2:
        return <H2 />;
      case 3:
        return <H3 />;
      default:
        return null;
    }
  },
  command: ({ editor, level }) =>
    editor.chain().focus().toggleHeading({ level }).run(),
  canExecute: ({ editor, level }) => editor.can().toggleHeading({ level }),
  isActive: ({ editor, level }) => editor.isActive("heading", { level }),
});

interface HeadingProps {
  level: 2 | 3;
}
