import { createContext, useContext } from "react";
import { createTeleporter } from "react-teleporter";
import { Button } from "swash/Button";
import { IoGridOutline, IoListOutline } from "swash/Icon";
import { SegmentedControl } from "swash/controls/SegmentedControl";

export type LayoutMode = "list" | "grid";

const LayoutModeContext = createContext<LayoutMode>("grid");
export const LayoutModeSwitcher = createTeleporter();

export type LayoutModeProviderProps = {
  state: {
    layoutMode: LayoutMode;
    onLayoutModeChange: (mode: LayoutMode | number) => void;
  };
  children: React.ReactNode;
};

export function LayoutModeProvider({
  state,
  children,
}: LayoutModeProviderProps) {
  return (
    <LayoutModeContext.Provider value={state.layoutMode}>
      <LayoutModeSwitcher.Source>
        <SegmentedControl
          value={state.layoutMode}
          onChange={state.onLayoutModeChange}
          scale="sm"
          role="group"
          aria-label="Changer l’affichage"
        >
          <Button iconOnly id="grid" aria-label="Affichage en grille">
            <IoGridOutline />
          </Button>
          <Button iconOnly id="list" aria-label="Affichage en liste">
            <IoListOutline />
          </Button>
        </SegmentedControl>
      </LayoutModeSwitcher.Source>
      {children}
    </LayoutModeContext.Provider>
  );
}

export const useLayoutMode = () => useContext(LayoutModeContext);
