import { gql } from "@apollo/client";
import { PropsWithChildren, useMemo } from "react";
import { createSelector } from "swash/v2/utils/selector";

import { Emoji, useEmojiQuery } from "@/components/editor/extensions/emoji";
import { BlockTemplate, useBlockTemplates } from "@/containers/BlockTemplates";
import { useRemoteConfig } from "@/containers/RemoteConfig";
import { EditorOptionsProvider_editorOptionsFragment } from "@/gql-types";

export type EditorOptionsStoreState =
  EditorOptionsProvider_editorOptionsFragment & {
    emojis: Emoji[];
    blockTemplates: BlockTemplate[];
  };

const EditorOptionsSelector = createSelector<EditorOptionsStoreState>();

export function EditorOptionsProvider({
  children,
  editorOptions,
}: EditorOptionsProviderProps) {
  const { data: emojis } = useEmojiQuery();
  const blockTemplates = useBlockTemplates();
  const { editorConfig } = useRemoteConfig();
  const options = useMemo(() => {
    return {
      ...(editorOptions ?? editorConfig),
      emojis: emojis ?? [],
      blockTemplates: blockTemplates ?? [],
    };
  }, [blockTemplates, editorConfig, editorOptions, emojis]);
  return (
    <EditorOptionsSelector.Provider state={options}>
      {children}
    </EditorOptionsSelector.Provider>
  );
}

EditorOptionsProvider.fragments = {
  editorOptions: gql`
    fragment EditorOptionsProvider_editorOptions on EditorConfig {
      plugins {
        name
      }
    }
  `,
};

export interface EditorOptionsProviderProps extends PropsWithChildren {
  editorOptions?: EditorOptionsProvider_editorOptionsFragment;
}

export const useEditorOptionsSelector = EditorOptionsSelector.useSelector;

export const useEditorOptionsContext = EditorOptionsSelector.useContext;

/**
 * Hook to get the initial editor options
 * useful for setting up the editor
 */
export function useInitialEditorOptions() {
  const store = useEditorOptionsContext();
  const editorOptionsSnapshot = store?.getState();
  const blockTemplates = editorOptionsSnapshot?.blockTemplates ?? [];
  const emojis = editorOptionsSnapshot?.emojis ?? [];
  const typographicalCorrection =
    editorOptionsSnapshot?.plugins.some(
      (plugin) => plugin.name === "typographicRules",
    ) ?? false;
  const italicsBetweenQuotes =
    editorOptionsSnapshot?.plugins.some(
      (plugin) => plugin.name === "italicsBetweenQuotes",
    ) ?? false;
  return {
    blockTemplates,
    emojis,
    typographicalCorrection,
    italicsBetweenQuotes,
  };
}

type PluginKey =
  | "spellcheck"
  | "merciapp"
  | "prolexis"
  | "typographicRules"
  | "italicsBetweenQuotes"
  | "headerThree"
  | "strikethrough"
  | "blockTemplate"
  | "emoji";

/**
 * Hook to check if the editor *should* have a plugin
 */
export function useHasPlugin(pluginKey: PluginKey): boolean {
  const store = useEditorOptionsContext();
  const editorOptionsSnapshot = store?.getState();
  const plugins = editorOptionsSnapshot?.plugins ?? [];
  return plugins.some((plugin) => plugin.name === pluginKey);
}
