import { gql } from "@apollo/client";

import {
  ArticlePublishStatus,
  ArticlePublishStatusProps,
} from "@/containers/article/ArticlePublishStatus";
import {
  ArticleStatesTooltip,
  ArticleStatesTooltipProps,
} from "@/containers/article/ArticleStatesTooltip";
import { ArticleStateIcon } from "@/containers/article/state/ArticleStateIcon";

const ArticleState = (props: ArticleStatePublishProps) => {
  const { state } = props.article;
  if (!state) return null;
  return (
    <ArticleStatesTooltip article={props.article}>
      <ArticleStateIcon className="text-lg" state={state} />
    </ArticleStatesTooltip>
  );
};

export type ArticleStatePublishProps = {
  article: ArticlePublishStatusProps["article"] &
    ArticleStatesTooltipProps["article"];
};

ArticleState.fragments = {
  article: gql`
    fragment ArticleState_article on Article {
      id
      ...ArticleStatesTooltip_article
      state {
        ...ArticleStateIcon_state
      }
    }
    ${ArticleStateIcon.fragments.state}
    ${ArticleStatesTooltip.fragments.article}
  `,
};

export const ArticleStatePublish = (props: ArticleStatePublishProps) => {
  return (
    <div className="flex items-center gap-2">
      <ArticleState article={props.article} />
      <ArticlePublishStatus article={props.article} />
    </div>
  );
};

ArticleStatePublish.fragments = {
  article: gql`
    fragment ArticleStatePublish_article on Article {
      ...ArticlePublishStatus_article
      ...ArticleState_article
    }

    ${ArticlePublishStatus.fragments.article}
    ${ArticleState.fragments.article}
  `,
};
