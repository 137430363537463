import { ListItem as TipTapListItem } from "@tiptap/extension-list-item";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Element, ElementContent, Root } from "hast";
import rehypeParse from "rehype-parse";
import rehypeStringify from "rehype-stringify";
import { unified } from "unified";
import { visit } from "unist-util-visit";

export const ListItem = TipTapListItem.extend({
  content: "paragraph",
  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitListItem(this.name),
    };
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey(this.name),
        props: {
          transformPastedHTML: flattenHtmlLists,
        },
      }),
    ];
  },
});

function flattenHtmlLists(html: string): string {
  if (!/<(ul|ol)(\s[^>]*)?>/.test(html)) {
    return html;
  }
  return unified()
    .use(rehypeParse, { fragment: true })
    .use(flattenListPlugin)
    .use(rehypeStringify)
    .processSync(html)
    .toString();
}

function flattenLists(node: Element) {
  if (!node.children) return;

  const children: ElementContent[] = [];

  node.children.forEach((child) => {
    if (
      child.type === "element" &&
      (child.tagName === "ul" || child.tagName === "ol")
    ) {
      flattenLists(child);
      children.push(...child.children);
    } else {
      children.push(child);
    }
  });

  node.children = children;
}

function flattenListPlugin() {
  return (tree: Root) => {
    visit(tree, "element", (node) => {
      if (node.tagName === "ul" || node.tagName === "ol") {
        flattenLists(node);
      }
    });
  };
}
