/* eslint-disable @lemonde/import/no-illegal-import */
import { gql } from "@apollo/client";
import { cn } from "swash/utils/classNames";

import { useUser } from "@/containers/User";
import { UserHoverCard } from "@/containers/user/UserHoverCard";
import {
  Mention_commentMentionFragment,
  UserMention_commentMentionDataFragment,
} from "@/gql-types";

const UserMention = ({
  user: mentionedUser,
  children,
  className,
}: UserMentionProps) => {
  const user = useUser();
  return (
    <UserHoverCard user={mentionedUser}>
      <span
        spellCheck={false}
        className={cn(
          "rounded px-1 font-semibold",
          mentionedUser.id === user.id
            ? "bg-orange-bg-light text-orange-on-strong"
            : "bg-primary-bg-light text-primary-on-strong",
          className,
        )}
      >
        {children}
      </span>
    </UserHoverCard>
  );
};

UserMention.fragments = {
  commentMentionData: gql`
    fragment UserMention_commentMentionData on CommentMentionData {
      ... on User {
        id
        ...UserHoverCard_user
      }
    }
    ${UserHoverCard.fragments.user}
  `,
};

interface UserMentionProps extends React.HTMLAttributes<HTMLSpanElement> {
  user: UserMention_commentMentionDataFragment;
}

export const Mention = ({ mention, ...props }: MentionProps) => {
  switch (mention.data.__typename) {
    case "User":
      return <UserMention user={mention.data} {...props} />;
    default:
      throw new Error(`Unknown mention type: ${mention.data.__typename}`);
  }
};

Mention.fragments = {
  commentMention: gql`
    fragment Mention_commentMention on CommentMention {
      data {
        __typename
        ...UserMention_commentMentionData
      }
    }
    ${UserMention.fragments.commentMentionData}
  `,
};

interface MentionProps extends Omit<UserMentionProps, "user"> {
  mention: Mention_commentMentionFragment;
}
