import { gql } from "@apollo/client";

import { Image } from "@/components/Image";

const BaseImageNodeFragment = gql`
  fragment BaseImageNodeFragment on Image {
    expired
    credit
    webAuthorized
    caption
    url
    thumbnail: fixed(width: 72, height: 52) {
      ...Image_fixed
    }
  }
  ${Image.fragments.fixed}
`;

export const ImageNodeFragment = {
  image: gql`
    fragment ImageNode_image on Image {
      id
      ...BaseImageNodeFragment
    }
    ${BaseImageNodeFragment}
  `,
  image__expanded: gql`
    fragment ImageNode_image__expanded on Image {
      id
      ...BaseImageNodeFragment
      expanded: fixed(width: 666, height: 444) {
        ...Image_fixed
      }
    }
    ${Image.fragments.fixed}
    ${BaseImageNodeFragment}
  `,
};
