import { Extension } from "@tiptap/core";
import { TextSelection, Transaction } from "@tiptap/pm/state";

type CaseCounts = {
  lower: number;
  upper: number;
};

const transformText = (str: string, caseCounts: CaseCounts) => {
  if (!caseCounts.upper) return str.toUpperCase();
  if (!caseCounts.lower) return str.toLowerCase();
  return caseCounts.lower > caseCounts.upper
    ? str.toLowerCase()
    : str.toUpperCase();
};

const toggleCaseCommand = () => {
  return ({
    tr,
    dispatch,
  }: {
    tr: Transaction;
    dispatch: ((args?: any) => any) | undefined;
  }) => {
    const { selection } = tr;
    if (selection.empty) return false;

    const { from, to } = selection;
    const text = tr.doc.textBetween(from, to, "\n");

    const caseCounts = text
      .trim()
      .split("")
      .reduce(
        (count, letter) => {
          // excluding non-letter characters
          if (letter.toLowerCase() === letter.toUpperCase()) return count;
          count.lower += letter === letter.toLowerCase() ? 1 : 0;
          count.upper += letter === letter.toUpperCase() ? 1 : 0;
          return count;
        },
        { lower: 0, upper: 0 },
      );

    if (dispatch) {
      // Insert the toggled text
      tr.insertText(transformText(text, caseCounts), from, to);
      // Set the new selection (same range)
      tr.setSelection(TextSelection.create(tr.doc, from, to));

      // Commit the transaction
      dispatch(tr);
    }
    return true;
  };
};

export const ToggleCaseExtension = Extension.create({
  name: "toggleCase",
  addCommands() {
    return {
      toggleCase: () => toggleCaseCommand(),
    };
  },
  addKeyboardShortcuts() {
    return {
      "Mod-Alt-k": () => {
        return this.editor.commands.toggleCase();
      },
      "Mod-Alt-K": () => {
        return this.editor.commands.toggleCase();
      },
    };
  },
});

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    toggleCase: {
      toggleCase: () => ReturnType;
    };
  }
}
