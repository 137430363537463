import { gql } from "@apollo/client";
import { memo, useMemo } from "react";
import { Loader } from "swash/Loader";
import { Tooltip, TooltipProps } from "swash/Tooltip";

import { useSafeQuery } from "@/containers/Apollo";
import { ArticleStateIconLabel } from "@/containers/article/state/ArticleStateIconLabel";
import {
  ArticleStateIconLabel_stateFragment,
  ArticleStatesTooltip_articleFragment,
  ArticleStatesTooltip_articleQuery,
  ArticleStatesTooltip_articleQueryVariables,
} from "@/gql-types";

export type ArticleStatesTooltipProps = {
  article: ArticleStatesTooltip_articleFragment;
} & Omit<TooltipProps, "tooltip">;

export const ArticleStatesTooltip = ({
  article,
  ...props
}: ArticleStatesTooltipProps) => {
  return (
    <SpreadArticleStatesTooltip
      articleId={article.id}
      state={article.state}
      {...props}
    />
  );
};

ArticleStatesTooltip.fragments = {
  article: gql`
    fragment ArticleStatesTooltip_article on Article {
      id
      state {
        id
        ...ArticleStateIconLabel_state
      }
    }

    ${ArticleStateIconLabel.fragments.state}
  `,
};

export const ArticleStatesTooltipArticleQuery = gql`
  query ArticleStatesTooltip_article($id: Int!) {
    article(id: $id) {
      id
      states {
        ...ArticleStateIconLabel_state
      }
    }
  }
  ${ArticleStateIconLabel.fragments.state}
`;

export type SpreadArticleStatesTooltipProps = {
  articleId?: ArticleStatesTooltip_articleFragment["id"];
  state: ArticleStateIconLabel_stateFragment;
} & Omit<TooltipProps, "tooltip">;

export const SpreadArticleStatesTooltip = memo<SpreadArticleStatesTooltipProps>(
  ({ articleId, state, ...props }) => {
    return (
      <Tooltip
        tooltip={<TooltipContent articleId={articleId} state={state} />}
        aria-label={state.label}
        {...props}
      />
    );
  },
);

const TooltipContent = memo<TooltipContentProps>(({ articleId, state }) => {
  const { data } = useSafeQuery<
    ArticleStatesTooltip_articleQuery,
    ArticleStatesTooltip_articleQueryVariables
  >(ArticleStatesTooltipArticleQuery, {
    variables: { id: articleId! },
    skip: !articleId,
  });
  const states = useMemo(
    () => data?.article?.states ?? [],
    [data?.article?.states],
  );
  const [, ...previousStates] = states;
  if (!state) return null;
  return (
    <>
      <div className="mb-1 font-semibold">État:</div>
      <ArticleStateIconLabel state={state} />
      {!data && articleId && (
        <div className="mt-4 flex justify-center p-2">
          <Loader size={24} />
        </div>
      )}
      {previousStates.length > 0 && (
        <>
          <div className="mb-1 mt-4 font-semibold">États précédents:</div>
          <div className="flex flex-col gap-2">
            {previousStates.map((state, index) => (
              <ArticleStateIconLabel key={index} state={state} />
            ))}
          </div>
        </>
      )}
    </>
  );
});

interface TooltipContentProps
  extends Pick<SpreadArticleStatesTooltipProps, "articleId" | "state"> {}
