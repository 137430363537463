import { useStoreState } from "@ariakit/react";
import { tz } from "@date-fns/tz";
import { format as formatDate, isFuture, set as setInDate } from "date-fns";
import { fr } from "date-fns/locale/fr";
import {
  Dispatch,
  SetStateAction,
  forwardRef,
  useEffect,
  useMemo,
} from "react";

import {
  Combobox,
  ComboboxItem,
  ComboboxItemCheck,
  ComboboxPopover,
  useComboboxStore,
} from "../v2/Combobox";

// @docs https://gist.github.com/indexzero/6261ad9292c78cf3c5aa69265e2422bf
export const generateSlots = (disablePast: boolean) => {
  const slots = Array.from({ length: 48 }, (_, index) =>
    formatDate(
      setInDate(Date.now(), {
        hours: Math.floor(index / 2),
        minutes: index % 2 === 0 ? 0 : 30,
      }),
      "HH:mm",
      { locale: fr },
    ),
  );
  if (!disablePast) return slots;
  return slots.filter((slot) => {
    const [hours, minutes] = slot.split(":");
    if (!hours || !minutes) return false;
    return isFuture(
      setInDate(
        Date.now(),
        {
          hours: parseInt(hours),
          minutes: parseInt(minutes),
        },
        { in: tz("Europe/Paris") },
      ),
    );
  });
};

export const checkIsValidFullTimeValue = (value: string) =>
  value?.length === 8 &&
  /^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/.test(value);

export const formatFullTime = (value: string) =>
  value ? value.slice(0, 5) : value;

export const parseFullTime = (value: string) =>
  value?.length === 5 ? `${value}:00` : value;

export type TimePickerInputScale = "sm" | "md" | "lg";

type TimePickerInputProps = {
  /**
   * The scale of the time picker input.
   * @default "md"
   */
  scale?: TimePickerInputScale;
  /**
   * The value of the time picker input.
   */
  onChange: Dispatch<SetStateAction<string | null>>;
  /**
   * The slots of the time picker input.
   */
  slots?: string[];
  /**
   * Whether to disable past slots.
   */
  disablePast?: boolean;
  /**
   * Whether the time picker input is required.
   */
  required?: boolean; // FIXME: Used in ArticleDateForm but does nothing
  /**
   * The value of the time picker input.
   */
  value?: string | null;
  /**
   * Whether the time picker input is disabled.
   */
  disabled?: boolean;
  /**
   * Whether the time picker input is modal.
   */
  modal?: boolean;
};

export const TimePickerInput = forwardRef<
  HTMLInputElement,
  TimePickerInputProps
>((props, ref) => {
  const {
    scale = "md",
    value,
    onChange,
    slots: slotsProp,
    disablePast = false,
    required,
    modal = false,
    ...otherProps
  } = props;
  const slots = useMemo(
    () => slotsProp ?? generateSlots(disablePast),
    [slotsProp, disablePast],
  );

  const combobox = useComboboxStore({
    value: value || "",
    setValue: onChange,
  });

  const comboboxValue = useStoreState(combobox, "value");

  useEffect(() => {
    if (comboboxValue && slots.find((slot) => slot === comboboxValue)) {
      combobox.setSelectedValue(comboboxValue);
    }
  }, [comboboxValue, combobox, slots]);

  return (
    <>
      <Combobox
        ref={ref}
        store={combobox}
        scale={scale}
        raw={false}
        showClearButton
        placeholder="HH:mm"
        aria-label="Horaire"
        {...otherProps}
      />
      <ComboboxPopover store={combobox} gutter={8} modal={modal}>
        {slots.map((slot, index) => {
          return (
            <ComboboxItem key={index} value={slot}>
              <ComboboxItemCheck value={slot} store={combobox} />
              {slot}
            </ComboboxItem>
          );
        })}
      </ComboboxPopover>
    </>
  );
});

if (process.env["NODE_ENV"] !== "production") {
  TimePickerInput.displayName = "TimePickerInput";
}
