import { gql } from "@apollo/client";

import { Image } from "@/components/Image";

export const ImageFragment = gql`
  fragment ImageForm_image on Image {
    id
    url
    siriusUrl
    fluid(maxWidth: 600) {
      ...Image_fluid
    }
    expiration
    restrictionMode
    usageLimit
    expired
    caption
    agencies {
      id
      label
    }
    metadata
    city
    credit
    keywords
    shootingDate
    photographer
    webAuthorized
    printAuthorized
    type
    createdAt
    updatedAt
  }
  ${Image.fragments.fluid}
`;
