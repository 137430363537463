// Copied from https://github.com/Soreine/draft-js-diff/blob/master/lib/diffWordMode.js
import diff_match_patch from "diff-match-patch";

// Adapted from
// https://code.google.com/p/google-diff-match-patch/wiki/LineOrWordDiffs

export enum DiffKind {
  Remove = -1,
  Keep = 0,
  Add = 1,
}

export type Diff = [kind: DiffKind, text: string];

/**
 * Find the differences between two texts, word-wise.
 */
export function diffWordMode(text1: string, text2: string): Diff[] {
  return diff_groupMode(text1, text2, /\s/);
}

/**
 * Find the differences between two texts, grouping characters according to a regex
 */
function diff_groupMode(
  text1: string,
  text2: string,
  groupDelimiter: RegExp,
): Diff[] {
  // Convert groups to unique chars, to allow diffing at a group level
  const a = _diff_groupsToChars_(text1, text2, groupDelimiter);
  const lineText1 = a.chars1;
  const lineText2 = a.chars2;
  const groupArray = a.groupArray;

  const dmp = new diff_match_patch();
  const diffs = dmp.diff_main(lineText1, lineText2, false);
  dmp.diff_charsToLines_(diffs, groupArray);

  return diffs;
}

/**
 * @private
 * Copied from diff_match_patch.linesToChars.
 * Adapted to accept a delimiter, in order to make groups of line/words/anything.
 *
 * Split two texts into an array of strings. Reduce the texts to a string of
 * hashes where each Unicode character represents a unique group.
 *
 * @return {{chars1: string, chars2: string, groupArray: !Array.<string>}}
 *     An object containing the encoded text1, the encoded text2 and
 *     the array of unique strings.
 *     The zeroth element of the array of unique strings is intentionally blank.
 */
function _diff_groupsToChars_(
  text1: string,
  text2: string,
  delimiter: RegExp,
): { chars1: string; chars2: string; groupArray: string[] } {
  const groupArray: string[] = []; // e.g. groupArray[4] == 'Hello\n' for a line delimiter /\n/
  const lineHash: Record<string, number> = {}; // e.g. lineHash['Hello\n'] == 4

  // '\x00' is a valid character, but various debuggers don't like it.
  // So we'll insert a junk entry to avoid generating a null character.
  groupArray[0] = "";

  /**
   * Split a text into an array of strings. Reduce the texts to a string of
   * hashes where each Unicode character represents one line.
   * Modifies linearray and linehash through being a closure.
   * @param {string} text String to encode.
   * @return {string} Encoded string.
   * @private
   */
  function diff_groupsToCharsMunge_(text: string): string {
    let chars = "";
    // Walk the text, pulling out a substring for each line.
    // text.split() would temporarily double our memory footprint.
    // Modifying text would create many large strings to garbage collect.
    let lineStart = 0;
    let lineEnd = -1;
    // Keeping our own length variable is faster than looking it up in JS
    let groupArrayLength = groupArray.length;
    while (lineEnd < text.length - 1) {
      lineEnd = regexIndexOf(text, delimiter, lineStart);
      if (lineEnd == -1) {
        lineEnd = text.length - 1;
      }
      const line = text.substring(lineStart, lineEnd + 1);
      lineStart = lineEnd + 1;

      if (
        lineHash.hasOwnProperty
          ? // eslint-disable-next-line no-prototype-builtins
            lineHash.hasOwnProperty(line)
          : lineHash[line] !== undefined
      ) {
        chars += String.fromCharCode(lineHash[line]!);
      } else {
        chars += String.fromCharCode(groupArrayLength);
        lineHash[line] = groupArrayLength;
        groupArray[groupArrayLength++] = line;
      }
    }
    return chars;
  }

  const chars1 = diff_groupsToCharsMunge_(text1);
  const chars2 = diff_groupsToCharsMunge_(text2);
  return { chars1: chars1, chars2: chars2, groupArray: groupArray };
}

/**
 * Same as String.indexOf, but uses RegExp
 */
function regexIndexOf(str: string, regex: RegExp, startpos = 0) {
  const indexOf = str.substring(startpos || 0).search(regex);
  return indexOf >= 0 ? indexOf + (startpos || 0) : indexOf;
}
