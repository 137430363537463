/* eslint-disable no-param-reassign */
import deepEqual from "deep-equal";
import queryString from "query-string";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLiveRef } from "swash/utils/useLiveRef";

import { flattenObject, unflattenObject } from "./util";

const QS_OPTIONS = {
  arrayFormat: "bracket-separator",
  arrayFormatSeparator: ",",
};

export function formatSearchParams(params, initialState) {
  const lightState = Object.entries(params).reduce(
    (lightState, [key, value]) => {
      if (!deepEqual(initialState[key], value)) {
        lightState[key] = value;
      }
      return lightState;
    },
    {},
  );

  return queryString.stringify(flattenObject(lightState), QS_OPTIONS);
}

function parseBoolean(value) {
  if (value === null) return value;
  if (value === undefined) return value;
  if (value === "true") return true;
  if (value === "false") return false;
  if (Array.isArray(value)) return value.map(parseBoolean);
  if (typeof value === "object") return parseValues(parseBoolean, value);
  return value;
}

function parseNumber(value) {
  if (value === null) return value;
  if (value === undefined) return value;
  if (typeof value === "boolean") return value;
  if (Array.isArray(value)) return value.map(parseNumber);
  if (typeof value === "object") return parseValues(parseNumber, value);
  const numValue = Number(value);
  return Number.isNaN(numValue) ? value : numValue;
}

function parseValues(parseValue, obj, ignore = []) {
  return Object.entries(obj).reduce((all, [key, value]) => {
    all[key] = ignore.includes(key) ? value : parseValue(value);
    return all;
  }, {});
}

function parseSearchParams(search, ignore) {
  // parseBooleans option does not work, so we use our own one
  return parseValues(
    parseNumber,
    parseValues(
      parseBoolean,
      unflattenObject(queryString.parse(search, QS_OPTIONS)),
      ignore,
    ),
    ignore,
  );
}

function sanitizeSearchParams(searchParams, defaultState, cleanSearchParams) {
  if (!cleanSearchParams) return searchParams;
  return Object.fromEntries(
    Object.entries(searchParams).filter(
      ([key]) => key in defaultState || key === "context",
    ),
  );
}

function getInitialState({
  defaultState,
  initialState,
  search,
  parse,
  parseIgnore,
}) {
  const searchParams = parse(parseSearchParams(search, parseIgnore));
  const cleanedSearchParams = sanitizeSearchParams(searchParams, defaultState);
  return { ...initialState, ...cleanedSearchParams };
}

function isEmptySearch(query) {
  return Object.keys(queryString.parse(query)).length === 0;
}

const identity = (x) => x;

/**
 * @template {any} T
 * @param {T} defaultState
 * @param {*} options
 * @returns {[state: T, setState: (value: T | ((prevState: T) => T)) => void, resetState: () => void]}
 */
export function useSearchParams(
  defaultState = {},
  {
    initialState,
    parse = identity,
    format = identity,
    parseIgnore = [],
    cleanSearchParams,
  } = {},
) {
  const navigate = useNavigate();
  const location = useLocation();
  const initialStateWithException = getInitialState({
    defaultState,
    initialState,
    search: location.search,
    parse,
    parseIgnore,
  });
  const refs = useLiveRef({ parseIgnore, parse });
  const defaultStateRef = useRef(defaultState);
  const initialStateRef = useRef(initialStateWithException);

  const formatRef = useRef(format);
  useEffect(() => {
    formatRef.current = format;
  });

  const locationRef = useRef(location);
  useEffect(() => {
    locationRef.current = location;
  });

  const [initialized, setInitialized] = useState(
    () => !initialState || !isEmptySearch(locationRef.current.search),
  );

  const getState = useCallback(
    (search) => ({
      ...defaultStateRef.current,
      ...sanitizeSearchParams(
        refs.current.parse(parseSearchParams(search, refs.current.parseIgnore)),
        defaultStateRef.current,
        cleanSearchParams,
      ),
    }),
    [refs, cleanSearchParams],
  );
  const setState = useCallback(
    (stateOrFn) => {
      const nextState =
        typeof stateOrFn === "function"
          ? stateOrFn(getState(locationRef.current.search))
          : stateOrFn;
      const search = formatRef.current(
        formatSearchParams(
          { ...defaultStateRef.current, ...nextState },
          defaultStateRef.current,
        ),
      );

      if (locationRef.current.search !== search) {
        navigate(
          {
            pathname: locationRef.current.pathname,
            search,
          },
          { replace: true },
        );
      }
    },
    [getState, navigate],
  );
  const state = useMemo(
    () => getState(location.search),
    [location.search, getState],
  );
  useEffect(() => {
    const state = sanitizeSearchParams(
      refs.current.parse(
        parseSearchParams(locationRef.current.search, refs.current.parseIgnore),
      ),
      defaultStateRef.current,
      cleanSearchParams,
    );
    setState(state);
  }, [navigate, setState, refs, cleanSearchParams]);
  // If search is empty, we use the initialState provided
  useEffect(() => {
    if (!initialized) {
      setState(initialStateRef.current);
      setInitialized(true);
    }
  }, [setState, initialized]);

  const resetState = useCallback(() => {
    setState(defaultStateRef.current);
  }, [setState]);

  return [
    initialized ? state : initialStateWithException,
    setState,
    resetState,
  ];
}
