import { Blockquote as TipTapBlockquote } from "@tiptap/extension-blockquote";

/**
 * This extension allows you to create blockquotes.
 * This requires the `Italic` extension.
 */
export const Blockquote = TipTapBlockquote.extend({
  content: "paragraph",
  addCommands() {
    return {
      setBlockquote:
        () =>
        ({ chain, state }) => {
          const { from, to } = state.selection;
          return chain()
            .selectParentNode()
            .wrapIn(this.name)
            .setItalic()
            .setTextSelection({ from: from + 1, to: to + 1 })
            .run();
        },
      toggleBlockquote:
        () =>
        ({ chain, state, editor }) => {
          const { from, to } = state.selection;
          const isActive = editor.isActive("blockquote");
          return chain()
            .selectParentNode()
            .toggleWrap(this.name)
            .command(({ commands }) => {
              if (isActive) {
                return commands.unsetItalic();
              } else {
                return commands.setItalic();
              }
            })
            .setTextSelection({
              // compensating for the depth
              from: from + (isActive ? -1 : 1),
              to: to + (isActive ? -1 : 1),
            })
            .run();
        },
      unsetBlockquote:
        () =>
        ({ chain, state }) => {
          const { from, to } = state.selection;
          return chain()
            .selectParentNode()
            .lift(this.name)
            .unsetItalic()
            .setTextSelection({ from: from - 1, to: to - 1 })
            .run();
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-m": () => this.editor.commands.toggleBlockquote(),
      Enter: () => this.editor.commands.splitListItem("blockquote"),
    };
  },
});
