import { createContext } from "react";

import type { DimensionMarshal } from "../../services/dimension-marshal/dimension-marshal-types";
import type { Registry } from "../../services/registry/registry-types";
import type { ContextId, DraggableId, ElementId } from "../../types";
import type { FocusMarshal } from "../use-focus-marshal";

export type AppContextValue = {
  focus: FocusMarshal;
  contextId: ContextId;
  canLift: (id: DraggableId) => boolean;
  isMovementAllowed: () => boolean;
  dragHandleUsageInstructionsId: ElementId;
  marshal: DimensionMarshal;
  registry: Registry;
};

export default createContext<AppContextValue | null>(null);
