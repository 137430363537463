import type { Position } from "css-box-model";

import type {
  DragImpact,
  DraggableDimension,
  DraggableDimensionMap,
  DroppableDimension,
  LiftEffect,
} from "../../../types";
import { withDroppableDisplacement } from "../../with-droppable-displacement";
import whenReordering from "./when-reordering";

type Args = {
  impact: DragImpact;
  afterCritical: LiftEffect;
  draggable: DraggableDimension;
  droppable: DroppableDimension | null;
  draggables: DraggableDimensionMap;
};

const getResultWithoutDroppableDisplacement = ({
  impact,
  draggable,
  droppable,
  draggables,
  afterCritical,
}: Args): Position => {
  const original = draggable.page.borderBox.center;
  if (!droppable) return original;
  if (!impact.destination) return original;
  return whenReordering({
    impact,
    draggable,
    draggables,
    droppable,
    afterCritical,
  });
};

export default (args: Args): Position => {
  const withoutDisplacement = getResultWithoutDroppableDisplacement(args);

  const droppable = args.droppable;

  const withDisplacement = droppable
    ? withDroppableDisplacement(droppable, withoutDisplacement)
    : withoutDisplacement;

  return withDisplacement;
};
