import { autocompletion } from "@codemirror/autocomplete";
import { indentWithTab } from "@codemirror/commands";
import { EditorState, Extension } from "@codemirror/state";
import { oneDarkTheme } from "@codemirror/theme-one-dark";
import { keymap } from "@codemirror/view";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { EditorView, basicSetup } from "codemirror";
import { useEffect, useRef } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import "./codemirror.css";

type UseCodeMirrorOptions = {
  readOnly?: boolean;
  tabSize?: number;
  onChange: (value: string) => void;
  onFocus?: (event: FocusEvent, view: EditorView) => boolean | void;
  onBlur?: (event: FocusEvent, view: EditorView) => boolean | void;
  onKeyDown?: (event: KeyboardEvent, view: EditorView) => boolean | void;
  smartIndent?: boolean;
};

const useCodeMirror = (
  value: string,
  extensions: Extension[],
  {
    readOnly = false,
    tabSize = 2,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
  }: UseCodeMirrorOptions,
) => {
  const ref = useRef<HTMLDivElement>(null);
  const editorRef = useRef<EditorView>();

  const defaultValueRef = useRef(value);
  const extensionsRef = useRef(extensions);
  const tabSizeRef = useRef(tabSize);
  const refs = useLiveRef({
    onChange,
    onBlur,
    onFocus,
    readOnly,
    onKeyDown,
  });

  useEffect(() => {
    const { onChange, onBlur, onFocus, onKeyDown, readOnly } = refs.current;
    if (!editorRef.current) {
      const themes = [oneDarkTheme, dracula];
      const settings = [
        EditorState.readOnly.of(readOnly),
        EditorState.tabSize.of(tabSizeRef.current),
        EditorView.lineWrapping,
      ];
      const events = [
        EditorView.domEventHandlers({
          focus: onFocus,
          blur: onBlur,
          keydown: onKeyDown,
        }),
        EditorView.updateListener.of((editor) => {
          onChange(editor.state.doc.toString());
        }),
      ];
      editorRef.current = new EditorView({
        doc: defaultValueRef.current,
        extensions: [
          basicSetup,
          autocompletion({
            activateOnTyping: false,
          }),
          keymap.of([indentWithTab]),
          ...settings,
          ...themes,
          ...events,
          ...extensionsRef.current,
        ],
        parent: ref.current ?? undefined,
      });
    }

    return () => editorRef.current?.destroy();
  }, [refs]);

  return { ref, editorRef };
};

type EditorProps = UseCodeMirrorOptions & {
  value: string;
  extensions?: Extension[];
};

export function Editor({
  value,
  extensions = [],
  readOnly = false,
  tabSize = 2,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  smartIndent,
}: EditorProps) {
  const { ref } = useCodeMirror(value, extensions, {
    readOnly,
    tabSize,
    smartIndent,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
  });
  return (
    <div
      ref={ref}
      data-testid="code-mirror-editor"
      className="absolute inset-0"
    />
  );
}
