import { useStoreState } from "@ariakit/react";
import * as React from "react";
import { useMemo } from "react";

import { Badge } from "../Badge";
import { Chip, ChipScale } from "../Chip";
import { IoEllipsisHorizontal } from "../Icon";
import {
  SelectItem,
  SelectItemCheck,
  SelectItemProps,
  SelectStore,
  checkIsSelected,
} from "../Select";
import { Tooltip } from "../Tooltip";
import { SelectComboboxItem, SelectComboboxItemProps } from "./SelectCombobox";

export interface RichSelectStateProps<
  TItem,
  TValue extends TItem | TItem[] | null,
> {
  value: TValue;
  onChange: (value: TValue) => void;
  valueSelector?: (item: TItem) => string;
  labelSelector?: (item: TItem) => string | React.ReactNode;
  labelElementSelector?: (item: TItem) => string | React.ReactNode;
  iconSelector?: (item: TItem) => React.ReactNode;
  disabledSelector?: (item: TItem) => boolean;
  appearance?: "default" | "chip";
  emptyMessage?: string | React.ReactNode;
  footer?: React.ReactNode;
  title?: string;
  required?: boolean;
  hideOnClick?: SelectItemProps["hideOnClick"];
}

export interface RichSelectState<TItem> {
  value: TItem | TItem[] | null;
  valueSelector: (item: TItem) => string;
  labelSelector: (item: TItem) => string | React.ReactNode;
  labelElementSelector: (item: TItem) => string | React.ReactNode;
  iconSelector: (item: TItem) => React.ReactNode;
  disabledSelector: ((item: TItem) => boolean) | undefined;
  emptyMessage: string | React.ReactNode;
  appearance: "default" | "chip";
  footer: React.ReactNode;
  title: string;
  required: boolean;
  hideOnClick: SelectItemProps["hideOnClick"];
}

const defaultValueSelector = (item: unknown): string => {
  if (!item) throw new Error("Item is null or undefined.");
  if (typeof item === "string") return item;
  if (typeof item === "number") return item.toString();
  if (typeof item === "object" && "value" in item) {
    if (typeof item.value === "string") return item.value;
    if (typeof item.value === "number") return item.value.toString();
  }
  throw new Error(
    `Item does not have a valid value property, specify a \`valueSelector\``,
  );
};

const defaultLabelSelector = (item: unknown): string => {
  if (!item) throw new Error("Item is null or undefined.");
  if (typeof item === "string") return item;
  if (typeof item === "number") return item.toString();
  if (typeof item === "object" && "label" in item) {
    if (typeof item.label === "string") return item.label;
    if (typeof item.label === "number") return item.label.toString();
  }
  throw new Error(
    `Item does not have a valid label property, specify a \`labelSelector\``,
  );
};

const defaultIconSelector = (item: unknown): React.ReactNode => {
  if (!item) throw new Error("Item is null or undefined.");
  if (typeof item === "object" && "icon" in item) {
    return item.icon as React.ReactNode;
  }
  return null;
};

export const useRichSelectState = <
  TItem,
  TValue extends TItem | TItem[] | null,
>(
  props: RichSelectStateProps<TItem, TValue>,
): RichSelectState<TItem> => {
  const valueSelector = React.useMemo(
    () => props.valueSelector ?? defaultValueSelector,
    [props.valueSelector],
  );
  const labelSelector = React.useMemo(
    () => props.labelSelector ?? defaultLabelSelector,
    [props.labelSelector],
  );
  const labelElementSelector = props.labelElementSelector ?? labelSelector;
  const iconSelector = React.useMemo(
    () => props.iconSelector ?? defaultIconSelector,
    [props.iconSelector],
  );

  const title = props.title ?? "items";

  return {
    iconSelector,
    valueSelector,
    labelSelector,
    labelElementSelector,
    appearance: props.appearance ?? "default",
    disabledSelector: props.disabledSelector,
    emptyMessage: props.emptyMessage ?? "Aucun résultat.",
    title,
    footer: props.footer ?? null,
    value: props.value,
    required: props.required ?? false,
    hideOnClick: props.hideOnClick,
  };
};

type RichSelectPlaceholderProps = {
  placeholder: React.ReactNode | string;
  placeholderIcon: React.ReactNode;
};

const RichSelectPlaceholder = ({
  placeholder,
  placeholderIcon,
}: RichSelectPlaceholderProps) => {
  return (
    <span className="flex items-center gap-2">
      {placeholderIcon} {placeholder}
    </span>
  );
};

type RichSelectChipValueAppearanceProps<TItem> = {
  value: TItem[];
  labelSelector: RichSelectState<TItem>["labelSelector"];
  scale: RichSelectScale;
};

const chipScales: Record<RichSelectScale, ChipScale> = {
  sm: "sm",
  md: "sm",
  lg: "md",
};

const RichSelectChipValueAppearance = <TItem,>({
  value,
  labelSelector,
  scale,
}: RichSelectChipValueAppearanceProps<TItem>) => {
  const chipScale = chipScales[scale];
  return (
    <span className="flex w-full flex-wrap items-center gap-2 whitespace-pre-wrap">
      {value.map((v, i) => (
        <Chip key={i} scale={chipScale} className="w-fit">
          {labelSelector(v)}
        </Chip>
      ))}
    </span>
  );
};

type RichSelectTooltipValueProps<TItem> = {
  value: TItem[];
  labelSelector: RichSelectState<TItem>["labelSelector"];
  iconSelector: RichSelectState<TItem>["iconSelector"];
  children: React.ReactElement;
};

const RichSelectTooltipValue = <TItem,>({
  value,
  labelSelector,
  iconSelector,
  children,
}: RichSelectTooltipValueProps<TItem>) => {
  const tooltip = useMemo(() => {
    if (!value.length || !value[0]) return null;
    if (
      typeof labelSelector(value[0]) === "string" &&
      iconSelector(value[0]) === null
    )
      return value.map((v) => labelSelector(v)).join(",");
    return (
      <div className="flex-col">
        {value.map((v, i) => (
          <div key={i} className="flex items-center gap-2">
            {iconSelector(v)} {labelSelector(v)}
          </div>
        ))}
      </div>
    );
  }, [value, labelSelector, iconSelector]);
  if (!value.length) return null;
  return (
    <Tooltip tooltip={tooltip}>
      <span className="flex items-center gap-2">{children}</span>
    </Tooltip>
  );
};

export type RichSelectScale = "sm" | "md" | "lg";

export interface RichSelectValueProps<TItem> {
  state: RichSelectState<TItem>;
  placeholder?: RichSelectPlaceholderProps["placeholder"];
  placeholderIcon?: RichSelectPlaceholderProps["placeholderIcon"];
  scale?: RichSelectScale;
}

export const RichSelectValue = <TItem,>({
  state: { value, iconSelector, labelSelector, title, appearance },
  placeholder = "Select...",
  placeholderIcon = null,
  scale = "md",
}: RichSelectValueProps<TItem>) => {
  if (!value) {
    return (
      <RichSelectPlaceholder
        placeholder={placeholder}
        placeholderIcon={placeholderIcon}
      />
    );
  }
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return (
        <RichSelectPlaceholder
          placeholder={placeholder}
          placeholderIcon={placeholderIcon}
        />
      );
    }
    if (value.length === 1 && value[0]) {
      const label =
        appearance === "chip" ? (
          <RichSelectChipValueAppearance
            labelSelector={labelSelector}
            scale={scale}
            value={value}
          />
        ) : (
          labelSelector(value[0])
        );
      const icon = iconSelector(value[0]) ?? placeholderIcon;
      return (
        <span className="flex items-center gap-2">
          {icon} {label}
        </span>
      );
    }
    const hasIcon = value[0] && iconSelector(value[0]) !== null;

    if (!hasIcon && appearance === "chip")
      return (
        <RichSelectChipValueAppearance
          value={value}
          scale={scale}
          labelSelector={labelSelector}
        />
      );

    if (!hasIcon && appearance === "default") {
      return (
        <span className="flex items-center gap-2">
          <RichSelectTooltipValue
            labelSelector={labelSelector}
            iconSelector={iconSelector}
            value={value}
          >
            <Badge className="ml-1">{value.length}</Badge>
          </RichSelectTooltipValue>{" "}
          {title}
        </span>
      );
    }

    let icons = value.slice(0, 3).map((v) => iconSelector(v));
    const hasMore = value.length - icons.length > 0;
    icons = hasMore
      ? [...icons.slice(0, 2), <IoEllipsisHorizontal key="more" />]
      : icons;

    return (
      <span className="flex items-center gap-2">
        <RichSelectTooltipValue
          labelSelector={labelSelector}
          iconSelector={iconSelector}
          value={value}
        >
          <span className="flex items-center">
            {icons.map((v, i) => {
              return (
                <span
                  key={i}
                  className="rounded-full bg-grey-bg"
                  style={{
                    fontSize: "0.8em",
                    padding: "0.1em",
                    marginLeft: i > 0 ? "-0.5em" : undefined,
                  }}
                >
                  {v}
                </span>
              );
            })}
          </span>
        </RichSelectTooltipValue>{" "}
        {value.length} {title}
      </span>
    );
  }
  const label = labelSelector(value);
  const icon = iconSelector(value) ?? placeholderIcon;
  return (
    <span className="flex items-center gap-2">
      {icon && <span className="shrink-0">{icon}</span>}
      <span className="truncate">{label}</span>
    </span>
  );
};

interface RichSelectItemProps<TItem>
  extends Omit<SelectComboboxItemProps, "store" | "value" | "children"> {
  state: RichSelectState<TItem> & { select: SelectStore };
  item: TItem;
}

export const RichSelectItem = <TItem,>(props: RichSelectItemProps<TItem>) => {
  const {
    item,
    state: {
      select,
      labelElementSelector,
      iconSelector,
      valueSelector,
      disabledSelector,
      hideOnClick,
    },
    ...otherProps
  } = props;
  const icon = iconSelector(item);
  const label = labelElementSelector(item);
  const value = valueSelector(item);
  const disabled = disabledSelector ? disabledSelector(item) : false;

  return (
    <SelectItem
      store={select}
      value={value}
      disabled={disabled}
      hideOnClick={hideOnClick}
      {...otherProps}
    >
      <>
        <SelectItemCheck store={select} />
        {icon && <span className="shrink-0">{icon}</span>}
        <span className="truncate">{label}</span>
      </>
    </SelectItem>
  );
};

type RichSelectFooter = {
  footer: React.ReactNode;
};

export const RichSelectFooter = ({ footer }: RichSelectFooter) => {
  if (!footer) return null;
  return (
    <div className="border-t bg-grey-bg-light p-4 font-accent">{footer}</div>
  );
};

interface RichSelectComboboxItemProps<TItem>
  extends Omit<SelectComboboxItemProps, "store" | "value" | "children"> {
  state: RichSelectState<TItem> & { select: SelectStore };
  item: TItem;
}

export const RichSelectComboboxItem = <TItem,>(
  props: RichSelectComboboxItemProps<TItem>,
) => {
  const {
    item,
    state: {
      required,
      select,
      labelElementSelector,
      disabledSelector,
      iconSelector,
      valueSelector,
    },
    showSelectCheck,
    disabled: disabledProp,
    ...otherProps
  } = props;
  const icon = iconSelector(item);
  const label = labelElementSelector(item);
  const value = valueSelector(item);
  const selectValue = useStoreState(select, "value");
  const disabled = (() => {
    if (disabledProp !== undefined) return disabledProp;
    if (disabledSelector && disabledSelector(item)) return true;
    if (!required) return false;
    const selected = checkIsSelected(selectValue, value);
    if (!selected) return false;
    if (Array.isArray(selectValue)) return selectValue.length === 1;
    return selectValue !== null;
  })();
  return (
    <SelectComboboxItem
      store={select}
      value={value}
      disabled={disabled}
      showSelectCheck={showSelectCheck}
      {...otherProps}
    >
      {icon && <span className="shrink-0">{icon}</span>}
      <span className="truncate">{label}</span>
    </SelectComboboxItem>
  );
};
