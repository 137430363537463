import * as Ariakit from "@ariakit/react";
import clsx from "clsx";
import * as React from "react";
import { forwardRef } from "react";

import { Button, ButtonProps } from "../Button";
import { IoChevronDown, IoChevronUp, IoCloseCircleOutline } from "../Icon";
import {
  ItemClassNameProps,
  ListSeparatorClassNameProps,
  getItemClassName,
  getListSeparatorClassName,
} from "../Menu";
import { PopoverCard } from "../Popover";
import { SelectMarker } from "../Select";
import { InputClassNameProps, getInputClassName } from "./TextInput";

export type { ComboboxStore, ComboboxStoreProps } from "@ariakit/react";

export const useComboboxStore = ({
  ...props
}: Ariakit.ComboboxStoreProps = {}) => {
  return Ariakit.useComboboxStore(props);
};

export type ComboboxItemProps = Ariakit.ComboboxItemProps & ItemClassNameProps;

export const ComboboxItem = forwardRef<HTMLDivElement, ComboboxItemProps>(
  ({ focusOnHover = true, variant, className, ...props }, ref) => {
    const itemClassName = getItemClassName({ variant, className });
    return (
      <Ariakit.ComboboxItem
        ref={ref}
        focusOnHover={focusOnHover}
        className={itemClassName}
        {...props}
      />
    );
  },
);

export type ComboboxItemCheckProps = Ariakit.ComboboxItemCheckProps & {
  value: string;
};

export const ComboboxItemCheck = forwardRef<
  HTMLDivElement,
  ComboboxItemCheckProps
>(({ store, value, ...props }, ref) => {
  const selectedValue = Ariakit.useStoreState(store, "selectedValue");
  const multi = Array.isArray(selectedValue);
  const checked = multi
    ? selectedValue?.includes(value)
    : selectedValue === value;
  return (
    <Ariakit.ComboboxItemCheck ref={ref} {...props} checked>
      <SelectMarker multi={multi} checked={checked} />
    </Ariakit.ComboboxItemCheck>
  );
});

export interface ComboboxArrowProps {
  open: boolean;
}

export const ComboboxArrow: React.FC<ComboboxArrowProps> = (props) => {
  return (
    <div className="pointer-events-none">
      {props.open ? <IoChevronUp /> : <IoChevronDown />}
    </div>
  );
};

export interface ComboboxClearProps extends ButtonProps {
  store: Ariakit.ComboboxStore;
  clearable?: boolean;
}

export const ComboboxClear = forwardRef<HTMLButtonElement, ComboboxClearProps>(
  (
    { store, "aria-label": ariaLabel = "Vider", clearable = true, ...props },
    ref,
  ) => {
    const value = Ariakit.useStoreState(store, "value");
    const open = Ariakit.useStoreState(store, "open");
    const empty = !value || (Array.isArray(value) && value.length === 0);
    if (empty || !clearable) {
      return <ComboboxArrow open={open} />;
    }
    return (
      <Button
        ref={ref}
        appearance="text"
        scale="xs"
        iconOnly
        asChild
        onMouseDown={(event) => {
          event.stopPropagation();
          event.preventDefault();
          store.setValue("");
          store.hide();
        }}
        className="-my-2 -mr-1"
        aria-label={ariaLabel}
        {...props}
      >
        <div>
          <IoCloseCircleOutline />
        </div>
      </Button>
    );
  },
);

export interface ComboboxProps
  extends Ariakit.ComboboxProps<"input">,
    Omit<InputClassNameProps, "placeholder"> {
  raw?: boolean;
  clearLabel?: string;
  store: Ariakit.ComboboxStore;
  showClearButton?: boolean;
}

export const Combobox = forwardRef<HTMLInputElement, ComboboxProps>(
  (
    {
      className,
      scale,
      raw = true,
      clearLabel,
      children,
      showClearButton,
      ...props
    },
    ref,
  ) => {
    const value = Ariakit.useStoreState(props.store, "value");
    const placeholder = value === "" || value?.length === 0;
    const inputClassName = getInputClassName({
      className,
      scale,
      placeholder,
    });
    const funcChildren = typeof children === "function";
    return (
      <div
        className={
          !raw
            ? clsx(
                inputClassName,
                !funcChildren &&
                  "inline-flex items-center justify-between gap-2 overflow-hidden overflow-ellipsis whitespace-nowrap bg-white",
              )
            : ""
        }
      >
        <Ariakit.Combobox ref={ref} className="w-full" {...props} />
        {showClearButton && (
          <ComboboxClear
            store={props.store}
            clearable={!props.required}
            aria-label={clearLabel}
          />
        )}
      </div>
    );
  },
);

export type ComboboxPopoverProps = Omit<
  Ariakit.ComboboxPopoverProps,
  "children"
> & {
  children: React.ReactNode;
};

export const ComboboxPopover = forwardRef<HTMLDivElement, ComboboxPopoverProps>(
  ({ className, style, gutter = 4, ...props }, ref) => {
    return (
      <Ariakit.ComboboxPopover
        ref={ref}
        className={clsx(className, "flex flex-col overflow-auto p-1")}
        wrapperProps={{
          className: "!z-popover",
        }}
        gutter={gutter}
        style={{
          maxHeight: "min(var(--popover-available-height, 320px), 320px)",
          ...style,
        }}
        render={<PopoverCard />}
        {...props}
      />
    );
  },
);

export type ComboboxSeparatorProps = Ariakit.ComboboxSeparatorProps &
  ListSeparatorClassNameProps;

export const ComboboxSeparator = forwardRef<
  HTMLHRElement,
  ComboboxSeparatorProps
>(({ className, ...props }, ref) => {
  const listSeparatorClassName = getListSeparatorClassName({ className });
  return (
    <Ariakit.ComboboxSeparator
      ref={ref}
      className={listSeparatorClassName}
      {...props}
    />
  );
});
