/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line @lemonde/import/no-illegal-import
import { EditorState, genKey, getDefaultKeyBinding } from "draft-js-es";
import * as React from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import { useArticleId } from "@/containers/routes/article/ArticleContext";
import { fetchArticleMedia } from "@/services/medias/fetchArticleMedia";

import { addDecorator } from "./modifiers/addDecorator";
import { removeAllSelection } from "./modifiers/removeAllSelection";
import { removeBlock } from "./modifiers/removeBlock";
import { removeDecorator } from "./modifiers/removeDecorator";
import { selectBlock } from "./modifiers/selectBlock";
import { setContent } from "./modifiers/setContent";
import * as corePlugin from "./plugins/core";
import * as coreCompositeEntityPlugin from "./plugins/core-composite-entity";
import * as corePendingPlugin from "./plugins/core-pending";
import * as coreSingleLinePlugin from "./plugins/core-single-line";
import * as coreSoftNewLinePlugin from "./plugins/core-soft-new-line";
import { getAdjacentBlock } from "./queries/findBlocks";
import { expandSelection } from "./utils/Selection";
import { useEnhancedState } from "./utils/useEnhancedState";

export const createEmptyEditorState = () => EditorState.createEmpty();

export const getRandomId = () =>
  String(Math.random().toString(36).substr(2, 9));

/**
 * @typedef RichEditorState
 * @property {import('draft-js').EditorState} editorState
 * @property {(editorState: import('draft-js').EditorState) => void} setEditorState
 * @property {(command: string, editorState: Draft.EditorState, eventTimeStamp: number) => Draft.DraftHandleValue} handleKeyCommand
 * @property {(e: React.KeyboardEvent<{}>) => string | null} keyBindingFn
 * @property {import('draft-js').ContentBlock | null} anchorBlock
 * @property {boolean} hasFocus
 * @property {Plugin[]} plugins
 * @property {Plugin[]} activePlugins
 * @property {boolean} readOnly
 * @property {() => void} lockFocus
 */

/**
 * @typedef {RichEditorState & { options: object }} PluginState
 */

/**
 * @typedef InputPlugin
 * @property {(state: PluginState, anchorBlock: import('draft-js').ContentBlock) => boolean} [matchBlock]
 * @property {(state: PluginState, command: string, editorState: Draft.Model.ImmutableData.EditorState, eventTimeStamp: number) => boolean} [handleKeyCommand]
 * @property {(state: PluginState, event: React.KeyboardEvent) => boolean} [keyBindingFn]
 * @property {(state: PluginState, block: Draft.Model.ImmutableData.ContentBlock) => boolean} [blockRendererFn]
 * @property {(params: { BlockComponent: React.Component }) => React.Component} [wrapBlockComponent]
 */

/**
 * @typedef {InputPlugin & { id: string, options: object }} Plugin
 */

const cache = new WeakMap();

/**
 * @param {InputPlugin | [InputPlugin, options: object]} pluginConfig
 * @returns {Plugin}
 */
function resolvePlugin(pluginConfig) {
  if (Array.isArray(pluginConfig)) {
    const [plugin, options] = pluginConfig;
    validateBlockControls(plugin);
    return {
      ...plugin,
      id: getRandomId(),
      options: (() => {
        if (!plugin.validateOptions) return options;
        try {
          return plugin.validateOptions(options);
        } catch (error) {
          const nextError = new Error(
            `Draft plugin [${plugin.name}]: ${error.message}`,
          );
          nextError.stack = error.stack;
          throw nextError;
        }
      })(),
    };
  }
  const plugin = pluginConfig;
  validateBlockControls(plugin);
  return { ...plugin, id: getRandomId(), options: {} };
}

function validateBlockControls(plugin) {
  if (plugin.BlockControls && !plugin.BlockControls.group) {
    const nextError = new Error(
      `Draft plugin [${plugin.name}]: BlockControls group is required`,
    );
    throw nextError;
  }
}

/**
 * @param {import('draft-js').EditorState} editorState
 * @returns {import('draft-js').ContentBlock | null}
 */
function getAnchorBlock(editorState) {
  const anchorKey = editorState.getSelection().getAnchorKey();
  const blockMap = editorState.getCurrentContent().getBlockMap();
  return blockMap.get(anchorKey) ?? null;
}

/**
 * @param {import('draft-js').EditorState} editorState
 * @param {object} [options]
 * @param {object[]} [options.plugins]
 */
export function createInitialEditorState(editorState, { plugins = [] } = {}) {
  return plugins
    .map((plugin) => resolvePlugin(plugin))
    .reduce((nextEditorState, plugin) => {
      if (plugin.getInitialEditorState) {
        const result = plugin.getInitialEditorState({
          editorState: nextEditorState,
          options: plugin.options,
        });
        if (result) return result;
      }
      return nextEditorState;
    }, editorState);
}

const handleDeleteCommands = ({ setEditorState, editorState }, command) => {
  // Deleting must be handled here to avoid weird effect for atomic blocks.
  const anchorBlock = getAnchorBlock(editorState);
  if (!anchorBlock) return undefined;
  if (anchorBlock.get("type") === "atomic") {
    setEditorState(
      removeAllSelection(editorState) ||
        removeBlock(editorState, anchorBlock.getKey()),
    );
    return "handled";
  }
  const selection = editorState.getSelection();
  switch (command) {
    case "backspace": {
      // We must be at the start of the node
      if (selection.getStartOffset() !== 0 || selection.getEndOffset() !== 0)
        return undefined;
      const previousBlock = getAdjacentBlock(
        editorState,
        anchorBlock.key,
        "previous",
      );
      if (previousBlock?.getType() === "atomic") {
        if (
          anchorBlock.getType() !== "atomic" &&
          anchorBlock.getLength() === 0
        ) {
          setEditorState(removeBlock(editorState, anchorBlock.key, -1));
          return "handled";
        }
        // If the previous block is of atomic type we select the previous block
        setEditorState(selectBlock(editorState, previousBlock.key));
        return "handled";
      }
      return undefined;
    }
    case "delete": {
      const length = anchorBlock.getLength();
      if (
        selection.getStartOffset() !== length ||
        selection.getEndOffset() !== length
      )
        return undefined;
      const nextBlock = getAdjacentBlock(editorState, anchorBlock.key, "next");
      if (nextBlock?.getType() === "atomic") {
        if (
          anchorBlock.getType() !== "atomic" &&
          anchorBlock.getLength() === 0
        ) {
          setEditorState(removeBlock(editorState, anchorBlock.key));
          return "handled";
        }
        setEditorState(selectBlock(editorState, nextBlock.getKey()));
        return "handled";
      }
      return undefined;
    }
  }
  return undefined;
};

const EMPTY_ARRAY = [];

const useLazyRef = (getInitialValue) => {
  const ref = React.useRef(null);
  if (ref.current === null) ref.current = getInitialValue();
  return ref;
};

const useMarker = () => {
  const setRef = useLazyRef(() => new WeakSet());
  const checkIsMarked = React.useCallback(
    (value) => setRef.current.has(value),
    [setRef],
  );
  const mark = React.useCallback(
    (value) => {
      setRef.current.add(value);
    },
    [setRef],
  );
  const deleteMark = React.useCallback(
    (value) => {
      setRef.current.delete(value);
    },
    [setRef],
  );
  return React.useMemo(
    () => ({ checkIsMarked, mark, deleteMark }),
    [checkIsMarked, mark, deleteMark],
  );
};

const SelectionDecorator = (props) => (
  <span data-focus-locked="">{props.children}</span>
);

const checkIsSelectionDecorator = (decorator) =>
  decorator.component === SelectionDecorator;

/**
 * @param {object} params
 * @param {import('draft-js').SelectionState} params.selection
 */
const createSelectionDecorator = ({ selectedBlocks }) => {
  return {
    /**
     * @param {import('draft-js').ContentBlock} contentBlock
     * @param {() => void} callback
     * @param {import('draft-js').ContentState} contentState
     */
    strategy: (contentBlock, callback) => {
      const selectedBlock = selectedBlocks.find(
        (selectedBlock) => selectedBlock.blockKey === contentBlock.getKey(),
      );
      if (selectedBlock) {
        callback(selectedBlock.startOffset, selectedBlock.endOffset);
      }
    },
    component: SelectionDecorator,
  };
};

const removeSelectionDecorator = (editorState) => {
  const decorators = editorState.getDecorator()?.getDecorators() ?? [];
  const existingDecorator = decorators.find((decorator) =>
    checkIsSelectionDecorator(decorator),
  );
  return existingDecorator
    ? removeDecorator(editorState, existingDecorator)
    : editorState;
};

const addSelectionDecorator = (editorState) => {
  const selectedBlocks = expandSelection(editorState);
  return addDecorator(
    removeSelectionDecorator(editorState),
    createSelectionDecorator({ selectedBlocks }),
  );
};

/**
 * @typedef UseEditorStateOptions
 * @property {import('draft-js').ContentState} contentState
 * @property {(contentState: import('draft-js').ContentState) => void} setContentState
 * @property {InputPlugin[]} plugins
 * @property {boolean} [readOnly]
 * @property {boolean} [expanded]
 * @property {boolean} [multiline]
 * @property {boolean} [acceptSoftNewLine]
 * @property {string} [whiteSpace]
 * @property {object[]} [blockTemplates]
 * @property {boolean} [isRichEditorTextBox]
 * @property {string} [name]
 * @property {string} [label]
 * @property {(isActive: boolean) => void} [onActive]
 * @property {boolean} [stripPastedStyles]
 * @property {boolean} [audioEnabled]
 *
 * @param {UseEditorStateOptions} options
 * @returns {RichEditorState}
 */
export function useRichEditorState({
  contentState: contentStateInput,
  setContentState,
  plugins: inputPlugins = EMPTY_ARRAY,
  readOnly = false,
  expanded = false,
  multiline = true,
  acceptSoftNewLine = true,
  whiteSpace = "pre-wrap",
  blockTemplates = EMPTY_ARRAY,
  isRichEditorTextBox,
  name,
  label,
  onActive,
  stripPastedStyles,
  audioEnabled = false,
}) {
  const articleId = useArticleId();
  const [editorId] = React.useState(() => genKey(), []);

  const plugins = React.useMemo(
    () =>
      [
        corePlugin,
        corePendingPlugin,
        coreSingleLinePlugin,
        coreSoftNewLinePlugin,
        coreCompositeEntityPlugin,
        ...inputPlugins,
      ].map((plugin) => resolvePlugin(plugin)),
    [inputPlugins],
  );
  const [editingBlocksMap, setEditingBlocksMap] = React.useState({});
  const externalMarker = useMarker();
  const internalMarker = useMarker();
  const refs = useLiveRef({ plugins, onActive });

  const [editorState, setEditorState] = useEnhancedState(
    () =>
      createInitialEditorState(
        contentStateInput
          ? EditorState.createWithContent(contentStateInput)
          : EditorState.createEmpty(),
        {
          plugins,
        },
      ),
    (editorState, previousEditorState) => {
      const contentState = editorState.getCurrentContent();
      const previousContentState = previousEditorState.getCurrentContent();
      if (
        // Avoid notifying parent if the content has not changed
        contentState !== previousContentState &&
        // Do not notify parent if the content comes from itself
        !externalMarker.checkIsMarked(contentState)
      ) {
        externalMarker.deleteMark(previousContentState);
        internalMarker.mark(contentState);
        setContentState(contentState);
      }
    },
  );

  React.useLayoutEffect(() => {
    // If content is external, we accept it
    if (!internalMarker.checkIsMarked(contentStateInput)) {
      externalMarker.mark(contentStateInput);
      setEditorState((editorState) =>
        setContent(editorState, contentStateInput),
      );
    }
  }, [internalMarker, externalMarker, setEditorState, contentStateInput]);

  const stateRef = React.useRef({});
  const selection = editorState.getSelection();
  const blockMap = editorState.getCurrentContent().getBlockMap();
  const hasOneBlockEditing = React.useMemo(() => {
    return blockMap.some((block) => editingBlocksMap[block.key]);
  }, [blockMap, editingBlocksMap]);

  const anchorBlock = getAnchorBlock(editorState);

  const checkBlockIsEditing = React.useCallback(
    (blockKey) => {
      return editingBlocksMap[blockKey] ?? false;
    },
    [editingBlocksMap],
  );

  const setBlockEditing = React.useCallback(
    (blockKey, editing) => {
      // Compute a new editing block map from actual block
      // it ensures we will clean up this map to avoid storing old removed keys
      setEditingBlocksMap((previousMap) => {
        const previousEditing = previousMap[blockKey] ?? false;
        if (previousEditing === editing) return previousMap;
        return Array.from(
          stateRef.current.editorState
            .getCurrentContent()
            .getBlockMap()
            .values(),
        ).reduce((obj, block) => {
          obj[block.key] =
            blockKey === block.key
              ? editing
              : (previousMap[block.key] ?? false);
          return obj;
        }, {});
      });
    },
    [stateRef],
  );

  const getState = React.useCallback(() => stateRef.current, []);
  const [focusLocked, setFocusLocked] = React.useState(false);

  // -- Makes editor active when a block is active, a block is editing or if focus is locked
  const hasOneBlockActive = blockMap.some((block) =>
    block.getData().get("active"),
  );
  const hasFocus = selection.getHasFocus() || focusLocked;
  const isActiveElement =
    document.activeElement === stateRef.current.editorRef?.current?.editor;
  const isActive =
    hasOneBlockEditing || hasOneBlockActive || hasFocus || isActiveElement;
  React.useEffect(() => {
    if (refs.current.onActive) {
      refs.current.onActive(isActive);
    }
  }, [refs, isActive]);
  // --

  stateRef.current.editorId = editorId;
  stateRef.current.editorRef = React.useRef(null);
  stateRef.current.articleId = articleId;
  stateRef.current.name = name;
  stateRef.current.fetchArticleMedia = fetchArticleMedia;
  stateRef.current.label = label;
  stateRef.current.getState = getState;
  stateRef.current.readOnly = readOnly || hasOneBlockEditing;
  stateRef.current.expanded = expanded;
  stateRef.current.multiline = multiline;
  stateRef.current.acceptSoftNewLine = acceptSoftNewLine;
  stateRef.current.whiteSpace = whiteSpace;
  stateRef.current.setBlockEditing = setBlockEditing;
  stateRef.current.checkBlockIsEditing = checkBlockIsEditing;
  stateRef.current.anchorBlock = anchorBlock;
  stateRef.current.hasFocus = selection.getHasFocus() || focusLocked;
  stateRef.current.focusLocked = focusLocked;
  stateRef.current.plugins = plugins;
  stateRef.current.editorState = editorState;
  stateRef.current.setEditorState = setEditorState;
  stateRef.current.stripPastedStyles = stripPastedStyles;
  stateRef.current.allowedAttributes = plugins.reduce(
    (allowedAttributes, plugin) => {
      if (plugin.allowedAttributes) {
        Object.assign(allowedAttributes, plugin.allowedAttributes);
      }
      return allowedAttributes;
    },
    {},
  );

  stateRef.current.audioEnabled = audioEnabled;
  stateRef.current.blockTemplates = blockTemplates;
  stateRef.current.isRichEditorTextBox = isRichEditorTextBox;

  stateRef.current.lockFocus = React.useCallback(() => {
    setFocusLocked(true);
  }, []);

  stateRef.current.unlockFocus = React.useCallback(
    ({ forceSelection } = { forceSelection: true }) => {
      if (!stateRef.current.focusLocked) {
        return;
      }

      if (forceSelection) {
        const { setEditorState, editorState } = stateRef.current;
        setEditorState(
          EditorState.forceSelection(editorState, editorState.getSelection()),
        );
      }

      setFocusLocked(false);
    },
    [],
  );

  React.useEffect(() => {
    const { setEditorState, editorState } = stateRef.current;
    if (focusLocked) {
      setEditorState(addSelectionDecorator(editorState));
    } else {
      setEditorState(removeSelectionDecorator(editorState));
    }
  }, [focusLocked]);

  stateRef.current.activePlugins = plugins.filter(
    (plugin) =>
      !plugin.matchBlock ||
      (anchorBlock &&
        plugin.matchBlock(
          { ...stateRef.current, options: plugin.options },
          anchorBlock,
        )),
  );

  const pluginProps = plugins
    .map((plugin) =>
      plugin.usePluginProps
        ? {
            name: plugin.name,
            props: plugin.usePluginProps({
              ...stateRef.current,
              options: plugin.options,
            }),
          }
        : null,
    )
    .filter((pluginWithProps) => !!pluginWithProps)
    .reduce(
      (allProps, pluginWithProps) => ({
        ...allProps,
        [pluginWithProps.name]: pluginWithProps.props,
      }),
      {},
    );

  React.useEffect(() => {
    refs.current.pluginProps = pluginProps;
  });

  stateRef.current.handleKeyCommand = React.useCallback(
    (command, editorState, eventTimeStamp) => {
      const stateProxy = {
        ...stateRef.current,
        // Use editorState provided in handleKeyCommand to avoid concurrency issues
        editorState,
      };

      if (command === "backspace" || command === "delete") {
        const res = handleDeleteCommands(
          stateProxy,
          command,
          editorState,
          eventTimeStamp,
        );
        if (res) return res;
      }

      for (const plugin of refs.current.plugins) {
        if (plugin.handleKeyCommand) {
          stateProxy.options = plugin.options;
          stateProxy.props = refs.current.pluginProps[plugin.name];
          const res = plugin.handleKeyCommand(
            stateProxy,
            command,
            editorState,
            eventTimeStamp,
          );
          if (res) return res;
        }
      }
      return "not-handled";
    },
    [refs],
  );

  stateRef.current.keyBindingFn = React.useCallback(
    (/** @type {React.KeyboardEvent<{}>} */ event) => {
      const stateProxy = { ...stateRef.current };
      for (const plugin of refs.current.plugins) {
        if (plugin.keyBindingFn) {
          stateProxy.options = plugin.options;
          stateProxy.props = refs.current.pluginProps[plugin.name];
          const res = plugin.keyBindingFn(stateProxy, event);
          if (res) return res;
        }
      }
      return getDefaultKeyBinding(event);
    },
    [refs],
  );

  stateRef.current.blockRendererFn = React.useCallback(
    (block) => {
      /**
       * @param {any} component
       * @param {import('draft-js').ContentBlock} block
       */
      const wrapBlockComponent = (component) => {
        let result = component;
        for (const plugin of refs.current.plugins) {
          if (plugin.wrapBlockComponent) {
            if (cache.has(result)) {
              result = cache.get(result);
            } else {
              const next = plugin.wrapBlockComponent({
                BlockComponent: result,
              });
              cache.set(result, next);
              result = next;
            }
          }
        }
        return result;
      };

      for (const plugin of refs.current.plugins) {
        if (!plugin.blockRendererFn) {
          continue;
        }
        const pluginState = { ...stateRef.current, options: plugin.options };
        if (plugin.matchBlock && !plugin.matchBlock(pluginState, block)) {
          continue;
        }
        const value = plugin.blockRendererFn(pluginState, block);
        if (!value) {
          continue;
        }
        return {
          ...value,
          props: {
            ...value.props,
            options: plugin.options,
            state: stateRef.current,
          },
          component: wrapBlockComponent(value.component),
        };
      }

      return null;
    },
    [refs],
  );
  stateRef.current.handleBeforeInput = React.useCallback(
    (chars, editorState) => {
      // Atomic block are not editable, we prevent text insertion in it
      const anchorBlock = getAnchorBlock(editorState);
      if (!anchorBlock || anchorBlock.get("type") === "atomic") {
        return "handled";
      }

      for (const plugin of refs.current.plugins) {
        if (plugin.handleBeforeInput) {
          const res = plugin.handleBeforeInput(
            { ...stateRef.current, editorState, options: plugin.options },
            chars,
            editorState,
          );
          if (res) return res;
        }
      }
      return "not-handled";
    },
    [refs],
  );

  stateRef.current.handlePastedText = React.useCallback(
    async (text, html, editorState, siriusContent) => {
      for (const plugin of refs.current.plugins) {
        if (plugin.handlePastedText) {
          const res = await plugin.handlePastedText(
            { ...stateRef.current, editorState, options: plugin.options },
            text,
            stripPastedStyles ? undefined : html,
            siriusContent,
          );
          if (res) return res;
        }
      }
      return "not-handled";
    },
    [refs, stripPastedStyles],
  );

  stateRef.current.handleReturn = React.useCallback(
    (e, editorState) => {
      const stateProxy = {
        ...stateRef.current,
        // Use editorState provided in handleKeyCommand to avoid concurrency issues
        editorState,
      };
      for (const plugin of refs.current.plugins) {
        if (plugin.handleReturn) {
          stateProxy.options = plugin.options;
          stateProxy.props = refs.current.pluginProps[plugin.name];
          const res = plugin.handleReturn(stateProxy, e);
          if (res) return res;
        }
      }
      return "not-handled";
    },
    [refs],
  );
  return stateRef.current;
}

/**
 * @template P
 * @param {RichEditorState & P} props
 * @returns {[RichEditorState, Pick<RichEditorState & P, Exclude<keyof P, keyof RichEditorState>>]}
 */
export function useRichEditorStateProps({
  editorState,
  setEditorState,
  handleKeyCommand,
  blockRendererFn,
  keyBindingFn,
  handleBeforeInput,
  ...rest
}) {
  return [
    {
      editorState,
      setEditorState,
      handleKeyCommand,
      blockRendererFn,
      keyBindingFn,
      handleBeforeInput,
    },
    rest,
  ];
}
