import * as Sentry from "@sentry/browser";
import * as React from "react";

import {
  checkIsApolloError,
  checkIsNetworkError,
  checkIsOfflineError,
} from "@/services/apollo/error";

import { RouteError } from "./Router";

const initialState = { error: null, redirecting: false, eventId: null };

class ErrorBoundary extends React.Component {
  state = initialState;

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    const { shouldAutoRetry } = this.props;

    if (shouldAutoRetry && shouldAutoRetry(error)) {
      this.retry();
      return;
    }

    if (checkIsApolloError(error)) {
      if (checkIsNetworkError(error.networkError, 401)) {
        // Redirecting occurs in the Apollo error link
        this.setState({ redirecting: true });
        return;
      }
      if (checkIsOfflineError(error.networkError)) {
        return;
      }
    }

    if (error instanceof RouteError) {
      return;
    }

    const { shouldCaptureError } = this.props;

    if (shouldCaptureError && !shouldCaptureError(error)) return;

    const contexts = { errorInfo };
    const eventId = (() => {
      if (error?.networkError?.eventId) {
        return error.networkError.eventId;
      }
      if (error instanceof Error) {
        return Sentry.captureException(error, { contexts });
      }
      if (error?.message) {
        return Sentry.captureMessage(error.message, { contexts });
      }
      return null;
    })();
    this.setState({ eventId });
  }

  retry = () => {
    this.setState(initialState);
  };

  render() {
    const {
      component: Component,
      render,
      children,
      shouldAutoRetry,
      ...others
    } = this.props;
    if (Component && render) {
      throw new Error(
        "ErrorBoundary: Only one of component or render should be provided",
      );
    }
    if (!Component && !render) {
      throw new Error("ErrorBoundary: Component or render should be provided");
    }
    const { error, eventId, redirecting } = this.state;
    if (redirecting || (error && shouldAutoRetry && shouldAutoRetry(error))) {
      return null;
    }

    if (error) {
      const props = { error, eventId, retry: this.retry, ...others };
      if (Component) {
        return <Component {...props} />;
      }
      if (render) {
        return render(props);
      }
    }
    return children;
  }
}

const FixedTypeErrorBoundary = /** @type {any} */ (ErrorBoundary);

export { FixedTypeErrorBoundary as ErrorBoundary };
