import type {
  DragImpact,
  DraggableDimension,
  DraggableDimensionMap,
  DroppableDimension,
  DroppableDimensionMap,
} from "../types";
import { isHomeOf, whatIsDraggedOver } from "./droppable/utils";
import {
  addPlaceholder,
  removePlaceholder,
} from "./droppable/with-placeholder";
import patchDroppableMap from "./patch-droppable-map";

const clearUnusedPlaceholder = ({
  previousImpact,
  impact,
  droppables,
}: {
  previousImpact: DragImpact;
  impact: DragImpact;
  droppables: DroppableDimensionMap;
}): DroppableDimensionMap => {
  const last = whatIsDraggedOver(previousImpact);
  const now = whatIsDraggedOver(impact);

  if (!last) return droppables;

  // no change - can keep the last state
  if (last === now) return droppables;

  const lastDroppable = droppables[last]!;

  // nothing to clear
  if (!lastDroppable.subject.withPlaceholder) {
    return droppables;
  }

  const updated = removePlaceholder(lastDroppable);
  return patchDroppableMap(droppables, updated);
};

export default ({
  draggable,
  draggables,
  droppables,
  previousImpact,
  impact,
}: {
  draggable: DraggableDimension;
  draggables: DraggableDimensionMap;
  droppables: DroppableDimensionMap;
  impact: DragImpact;
  previousImpact: DragImpact;
}): DroppableDimensionMap => {
  const cleaned: DroppableDimensionMap = clearUnusedPlaceholder({
    previousImpact,
    impact,
    droppables,
  });

  const isOver = whatIsDraggedOver(impact);
  if (!isOver) return cleaned;

  const droppable = droppables[isOver]!;

  // no need to add additional space to home droppable
  if (isHomeOf(draggable, droppable)) return cleaned;

  // already have a placeholder - nothing to do here!
  if (droppable.subject.withPlaceholder) return cleaned;

  // Need to patch the existing droppable
  const patched: DroppableDimension = addPlaceholder(
    droppable,
    draggable,
    draggables,
  );

  return patchDroppableMap(cleaned, patched);
};
