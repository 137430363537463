import createEditorControl from "@/components/editor/controls/createEditorControl";
import { Link as LinkIcon } from "@/components/icons";

export const LinkControl = createEditorControl({
  name: "link",
  label: "Lien",
  shortcut: ["⌘", "k"],
  exclude: [],
  icon: <LinkIcon />,
  command: ({ editor }) => {
    const isLink = editor.isActive("link");
    if (isLink) {
      // Remove link
      editor //
        .chain()
        .focus()
        .unsetLink()
        .run();
    } else {
      // Add link
      editor //
        .chain()
        .focus()
        .setLink({ href: "" })
        .run();
    }
  },
  canExecute: ({ editor }) =>
    !editor.state.selection.empty && editor.can().toggleLink({ href: "#" }),
});
