import type {
  DisplacedBy,
  DisplacementGroups,
  DragImpact,
  DraggableDimension,
  DroppableDimension,
  Viewport,
} from "../types";
import { isHomeOf } from "./droppable/utils";
import getDisplacementGroups from "./get-displacement-groups";
import { emptyGroups } from "./no-impact";
import removeDraggableFromList from "./remove-draggable-from-list";

function getIndexOfLastItem(
  draggables: DraggableDimension[],
  options: {
    inHomeList: boolean;
  },
): number {
  if (!draggables.length) {
    return 0;
  }

  const indexOfLastItem = draggables[draggables.length - 1]!.descriptor.index;

  // When in a foreign list there will be an additional one item in the list
  return options.inHomeList ? indexOfLastItem : indexOfLastItem + 1;
}

function goAtEnd({
  insideDestination,
  inHomeList,
  displacedBy,
  destination,
}: {
  insideDestination: DraggableDimension[];
  inHomeList: boolean;
  displacedBy: DisplacedBy;
  destination: DroppableDimension;
}): DragImpact {
  const newIndex = getIndexOfLastItem(insideDestination, { inHomeList });

  return {
    displaced: emptyGroups,
    displacedBy,
    destination: {
      droppableId: destination.descriptor.id,
      index: newIndex,
    },
  };
}

export function calculateReorderImpact({
  draggable,
  insideDestination,
  destination,
  viewport,
  displacedBy,
  last,
  index,
  forceShouldAnimate,
}: {
  draggable: DraggableDimension;
  insideDestination: DraggableDimension[];
  destination: DroppableDimension;
  viewport: Viewport;
  displacedBy: DisplacedBy;
  last: DisplacementGroups;
  index: number | null;
  forceShouldAnimate?: boolean;
}): DragImpact {
  const inHomeList = isHomeOf(draggable, destination);

  // Go into last spot of list
  if (index == null) {
    return goAtEnd({
      insideDestination,
      inHomeList,
      displacedBy,
      destination,
    });
  }

  // this might be the dragging item
  const match = insideDestination.find(
    (item) => item.descriptor.index === index,
  );

  if (!match) {
    return goAtEnd({
      insideDestination,
      inHomeList,
      displacedBy,
      destination,
    });
  }
  const withoutDragging = removeDraggableFromList(draggable, insideDestination);

  const sliceFrom = insideDestination.indexOf(match);
  const impacted = withoutDragging.slice(sliceFrom);

  const displaced = getDisplacementGroups({
    afterDragging: impacted,
    destination,
    displacedBy,
    last,
    viewport: viewport.frame,
    forceShouldAnimate,
  });

  return {
    displaced,
    displacedBy,
    destination: {
      droppableId: destination.descriptor.id,
      index,
    },
  };
}
