import { Extension } from "@tiptap/core";

export const Id = Extension.create({
  name: "id",
  addOptions() {
    return {
      types: [
        "paragraph",
        "heading",
        "blockquote",
        "listItem",
        "horizontalRule",
      ],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          id: {
            default: null,
            rendered: false,
            keepOnSplit: false,
          },
        },
      },
    ];
  },
});
