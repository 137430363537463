import { Editor } from "@tiptap/core";
import { EditorState, Plugin, PluginKey } from "@tiptap/pm/state";
import { EditorView } from "@tiptap/pm/view";

export interface FixedMenuPluginProps {
  /**
   * The plugin key.
   * @type {PluginKey | string}
   */
  pluginKey: PluginKey | string;

  /**
   * The editor instance.
   */
  editor: Editor;

  /**
   * The DOM element that contains the fixed menu.
   * @type {HTMLElement}
   */
  element: HTMLElement;

  /**
   * Determines whether to display the fixed menu.
   * @default true
   */
  hideOnBlur?: boolean | ((event: FocusEvent) => boolean);
}

export type FixedMenuViewProps = FixedMenuPluginProps & {
  view: EditorView;
};

export class FixedMenuView {
  public editor: Editor;

  public element: HTMLElement;

  public view: EditorView;

  public preventHide = false;

  public previousEditable: boolean;

  public hideOnBlur?: boolean | ((event: FocusEvent) => boolean);

  show() {
    const { element: editorElement } = this.editor.options;
    const editorIsAttached = !!editorElement.parentElement;
    const isEditorContainsMenu = editorElement.contains(this.element);
    if (!editorIsAttached && isEditorContainsMenu) {
      return;
    }
    editorElement.appendChild(this.element);
  }

  hide() {
    this.element.remove();
  }

  constructor({
    editor,
    element,
    view,
    hideOnBlur = true,
  }: FixedMenuViewProps) {
    this.editor = editor;
    this.element = element;
    this.view = view;
    this.previousEditable = false;
    if (hideOnBlur) {
      this.hideOnBlur = hideOnBlur;
    }
    if (this.hideOnBlur) {
      this.element.addEventListener("mousedown", this.mousedownHandler, {
        capture: true,
      });
      this.editor.on("focus", this.focusHandler);
      this.editor.on("blur", this.blurHandler);
    }
    // Detaches menu content from its current parent
    this.element.remove();
  }

  mousedownHandler = () => {
    this.preventHide = true;
  };

  focusHandler = () => {
    // we use `setTimeout` to make sure `selection` is already updated
    setTimeout(() => this.update(this.editor.view));
  };

  blurHandler = ({ event }: { event: FocusEvent }) => {
    const result =
      typeof this.hideOnBlur === "function"
        ? this.hideOnBlur(event)
        : this.hideOnBlur;
    if (!result) {
      return;
    }
    if (this.preventHide) {
      this.preventHide = false;

      return;
    }

    if (
      event?.relatedTarget &&
      this.element.parentNode?.contains(event.relatedTarget as Node)
    ) {
      return;
    }

    if (event?.relatedTarget === this.editor.view.dom) {
      return;
    }
    this.hide();
  };

  update(view: EditorView, oldState?: EditorState) {
    const { state } = view;
    const { doc, selection } = state;
    const isSame =
      oldState &&
      oldState.doc.eq(doc) &&
      oldState.selection.eq(selection) &&
      this.previousEditable === this.editor.isEditable;

    if (isSame) {
      return;
    }

    this.previousEditable = this.editor.isEditable;

    if (!this.editor.isEditable) {
      this.hide();
      return;
    }
    this.show();
  }

  destroy() {
    if (this.hideOnBlur) {
      this.element.removeEventListener("mousedown", this.mousedownHandler, {
        capture: true,
      });
      this.editor.off("focus", this.focusHandler);
      this.editor.off("blur", this.blurHandler);
    }
  }
}

/**
 * Determines whether to display a fixed menu, bound to the editor's editable state.
 */
export const FixedMenuPlugin = (options: FixedMenuPluginProps) => {
  return new Plugin({
    key:
      typeof options.pluginKey === "string"
        ? new PluginKey(options.pluginKey)
        : options.pluginKey,
    view: (view) => new FixedMenuView({ view, ...options }),
  });
};
