import { cloneElement, forwardRef, useRef, useState } from "react";
import { mergeRefs } from "react-merge-refs";
import { useToaster } from "swash/Toast";
import { Tooltip } from "swash/Tooltip";
import { useEditorContext } from "swash/editor";
import { useEventCallback } from "swash/utils/useEventCallback";

import { useIntegrationHealthCheck } from "@/components/rich-editor/utils/IntegrationHealthCheck";
import { useProlexis } from "@/containers/Prolexis";

import { convertTextOffset } from "../../helpers/convertTextOffset";

export const ProlexisSpellCheckControl = forwardRef<
  HTMLButtonElement,
  ProlexisSpellCheckControlProps
>(({ render }, forwardedRef) => {
  const { editor } = useEditorContext();

  const integration = useIntegrationHealthCheck("prolexis");
  const { analyze } = useProlexis();

  const toaster = useToaster();
  const ref = useRef<HTMLButtonElement>(null);
  const [correcting, setCorrecting] = useState(false);

  const analyzeSpelling = useEventCallback(async () => {
    if (!editor) return;
    if (correcting) return;
    try {
      // Start correcting
      setCorrecting(true);
      editor.setEditable(false);

      await analyze({
        fields: [
          {
            src: editor.getText(),
            type: "string",
            label: "Contenu",
          },
        ],
        onCorrections: (corrections) => {
          for (const { offset, length, correction } of corrections) {
            editor.commands.insertContentAt(
              {
                from: convertTextOffset(editor.state.doc, offset),
                to: convertTextOffset(editor.state.doc, offset + length),
              },
              correction,
            );
          }
        },
        parent: ref.current!,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      toaster.danger(
        "L’outil de correction est indisponible pour le moment. Veuillez rafraîchir votre navigateur et réessayer.",
      );
    } finally {
      // Stop correcting
      editor.setEditable(true);
      editor.commands.focus();
      setCorrecting(false);
    }
  });

  if (!editor) return null;

  return (
    <Tooltip
      tooltip={
        !integration.isHealthy
          ? "Le service tiers Prolexis rencontre actuellement un incident et nous indique qu’il est actuellement indisponible."
          : undefined
      }
    >
      {cloneElement(render, {
        ref: mergeRefs([forwardedRef, ref]),
        disabled:
          integration.loading ||
          !integration.isHealthy ||
          !integration.enabled ||
          correcting,
        onClick: analyzeSpelling,
      })}
    </Tooltip>
  );
});

interface ProlexisSpellCheckControlProps {
  render: React.ReactElement;
}
