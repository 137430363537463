import createEditorControl from "@/components/editor/controls/createEditorControl";
import { UnorderedList } from "@/components/icons";

export const BulletListControl = createEditorControl({
  name: "bulletList",
  label: "Liste non ordonnée",
  shortcut: ["⌘", "l"],
  icon: <UnorderedList />,
  command: ({ editor }) => editor.chain().focus().toggleBulletList().run(),
  canExecute: ({ editor }) => editor.can().toggleBulletList(),
});
