import { gql } from "@apollo/client";

import { Image } from "@/components/Image";
import { ArticleHoverCard } from "@/containers/article/ArticleHoverCard";
import { ArticleLayoutPricingIndicator } from "@/containers/article/ArticleLayoutPricingIndicator";
import { ArticlePublishDateTimeStatus } from "@/containers/article/ArticlePublishDateTimeStatus";
import { ArticlePublishedLink } from "@/containers/article/ArticlePublishedLink";
import { getArticleStatusColor } from "@/containers/article/util/status";
import { ProductNode } from "@/containers/editor/nodes/ProductNode";
import { ProductSummaryNode } from "@/containers/editor/nodes/ProductSummaryNode";
import { SnippetNodeFragments } from "@/containers/editor/nodes/SnippetNodeFragments";
import { TweetNode } from "@/containers/editor/nodes/TweetNode";
import { VideoNode } from "@/containers/editor/nodes/VideoNode";

import { ImageNodeFragment } from "./ImageNodeFragment";

const BaseArticleNodeArticleFragment = gql`
  fragment BaseArticleNodeArticleFragment on Article {
    title
    chapo
    url
    states {
      id
      label
      name
      acronym
      color
    }
    editorialType {
      id
      label
      hasTitle
      hasHeading
      hasContent
      hasAuthors
    }
    layout {
      id
      articleLinkPrefix
    }
    layoutEditorialType {
      id
      displayMode
      displayModeSelectable
    }
    legacyCustomFields {
      video {
        id
        media {
          id
          ... on Video {
            playerUrl
          }
        }
      }
    }

    ...ArticleLayoutPricingIndicator_article
    ...getArticleStatusColor
    ...ArticlePublishedLink_article
    ...ArticlePublishDateTimeStatus_article
    ...ArticleHoverCard_article
  }

  ${Image.fragments.fixed}
  ${ArticleLayoutPricingIndicator.fragments.article}
  ${getArticleStatusColor.fragment}
  ${ArticlePublishedLink.fragments.article}
  ${ArticlePublishDateTimeStatus.fragments.article}
  ${ArticleHoverCard.fragments.article}
`;

const ArticleNodeDraftEditorNodeFragment = gql`
  fragment ArticleNodeDraftEditorNodeFragment on EditorNode {
    type

    ... on EditorNodeText {
      text
      metadata {
        type
        styleName
      }
    }

    ... on EditorNodeArticle {
      articlesId
      articleMetadata: metadata {
        title
        prefix
        displayMode
      }
      article {
        id
        ...BaseArticleNodeArticleFragment
      }
    }

    ... on EditorNodeArticleMedia {
      __typename
      articleMedia {
        id
        articleMediaMetadata: metadata
        media {
          ... on Image {
            id
            ...ImageNode_image
          }
          ... on Video {
            id
            ...VideoNode_video
          }
          ... on Snippet {
            id
            ...SnippetNode_snippet
          }
          ... on Tweet {
            id
            ...TweetNode_tweet
          }
          ... on Product {
            id
            ...ProductNode_product
          }
          ... on ProductSummary {
            id
            ...ProductSummaryNode_productSummary
          }
        }
      }
    }

    ... on EditorNodeTweet {
      tweetsId
      tweet {
        id
        ...TweetNode_tweet
      }
    }

    ... on EditorNodeImage {
      imagesId
      imageMetadata: metadata {
        caption
        aspectRatioId
        crops
      }
      image {
        id
        ...ImageNode_image
      }
    }

    ... on EditorNodeVideo {
      videosId
      video {
        id
        ...VideoNode_video
      }
    }

    ... on EditorNodeSnippet {
      snippetsId
      snippet {
        id
        ...SnippetNode_snippet
      }
    }

    ... on EditorNodeProduct {
      productsId
      product {
        id
        ...ProductNode_product
      }
    }

    ... on EditorNodeProductSummary {
      productsSummariesId
      productSummary {
        id
        ...ProductSummaryNode_productSummary
      }
    }
  }

  ${BaseArticleNodeArticleFragment}
  ${TweetNode.fragments.tweet}
  ${ImageNodeFragment.image}
  ${VideoNode.fragments.video}
  ${SnippetNodeFragments.snippet}
  ${ProductNode.fragments.product}
  ${ProductSummaryNode.fragments.productSummary}
`;

export const ArticleNodeFragments = {
  article: gql`
    fragment ArticleNode_article on Article {
      id
      ...BaseArticleNodeArticleFragment
    }

    ${BaseArticleNodeArticleFragment}
  `,
  article__expanded: gql`
    fragment ArticleNode_article__expanded on Article {
      id
      ...BaseArticleNodeArticleFragment
      nodes {
        id
        type
        ...ArticleNodeDraftEditorNodeFragment
      }
    }

    ${BaseArticleNodeArticleFragment}
    ${ArticleNodeDraftEditorNodeFragment}
  `,
};
