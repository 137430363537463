import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PageLoader } from "swash/Loader";
import { useToaster } from "swash/Toast";

import { injectScript, removeScript } from "@/components/Script";

const SCRIPT_SRC = `https://cdn-ext.merci-app.com/merciapp.sdk.js`;

type MerciAppOptions = {
  selector: string;
  launchOnLoad: boolean;
  showLargeSpinner: boolean;
  enableMultilanguage: boolean;
  allowedLanguage: string;
  spinnerContainerSelector: string;
};

type MerciAppInstance = {
  isLoaded: boolean;
  instanceIdentifier: unknown;
  injectedScripts: HTMLScriptElement[];
};

type MerciAppSdk = {
  load(
    token: string,
    options: MerciAppOptions,
    instanceIdentifier?: unknown,
  ): MerciAppInstance;
};

declare global {
  interface Window {
    MerciApp: MerciAppSdk;
  }
}

const MerciAppContext = createContext<{
  load: (options: MerciAppOptions) => MerciAppInstance | null;
}>(null!);

export const MerciAppProvider = ({
  token,
  children,
}: {
  token: string | null;
  children: React.ReactNode;
}) => {
  const toaster = useToaster();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!token) return;

    injectScript({
      src: SCRIPT_SRC,
      parent: document.body,
      onload: () => {
        setReady(true);
      },
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      toaster.danger(
        "L’outil de correction est indisponible pour le moment. Veuillez rafraîchir votre navigateur et réessayer.",
      );
    });

    return () => {
      // Remove the MerciApp custom tag
      Array.from(document.querySelectorAll("#mci-mirror")).forEach((tag) =>
        tag.remove(),
      );

      // Remove init scripts
      const xpath = "//script[contains(text(),'MerciAppTrigger.load')]";
      const initScripts = [];
      const scripts = document.evaluate(
        xpath,
        document.body,
        null,
        XPathResult.ORDERED_NODE_ITERATOR_TYPE,
        null,
      );
      let nextScript = scripts.iterateNext();
      while (nextScript) {
        initScripts.push(nextScript as HTMLScriptElement);
        nextScript = scripts.iterateNext();
      }
      initScripts.forEach((script) => script.remove());

      // Remove sdk
      removeScript(SCRIPT_SRC);
    };
  }, [toaster, token]);

  const load = useCallback(
    (options: MerciAppOptions) => {
      if (!ready || !token) return null;
      return window.MerciApp.load(token, options);
    },
    [token, ready],
  );

  const value = useMemo(() => ({ load }), [load]);

  if (!ready) return <PageLoader />;

  return (
    <MerciAppContext.Provider value={value}>
      {children}
    </MerciAppContext.Provider>
  );
};

export const useMerciAppInstance = (options: Partial<MerciAppOptions>) => {
  const { load } = useContext(MerciAppContext);

  useEffect(() => {
    if (!load) return;

    const merciAppInstance = load({
      selector: "",
      launchOnLoad: true,
      showLargeSpinner: true,
      enableMultilanguage: true,
      allowedLanguage: "en",
      spinnerContainerSelector: "",
      ...options,
    });

    return () => {
      if (!merciAppInstance?.isLoaded) return;
      const unloadEvent = new CustomEvent("MCI_SDK_DISABLE", {
        detail: { instanceIdentifier: merciAppInstance.instanceIdentifier },
      });
      window.document.dispatchEvent(unloadEvent);
      merciAppInstance.injectedScripts.forEach((script) => script.remove());
      merciAppInstance.isLoaded = false;
    };
  }, [load, JSON.stringify(options)]);
};
