import { tz } from "@date-fns/tz";
import { endOfDay, formatISO, startOfDay } from "date-fns";
import { forwardRef, useCallback, useMemo } from "react";
import { SelectStore, useSelectStore } from "swash/Select";
import {
  DayModifiers,
  RangeDatePicker,
  SingleDatePicker,
} from "swash/controls/DatePicker";
import {
  RichSelectState,
  RichSelectStateProps,
  useRichSelectState,
} from "swash/v2/RichSelect";

import { formatForParis, formatISOinUTC } from "@/services/time-formatter";

export type Range = {
  from?: string | null;
  to?: string | null;
};

export type GeneratedPreset = {
  label: string;
  value: Range;
  id: number;
};

export const formatRange = (range: Range | null) => {
  if (!range) return null;
  return {
    from: range.from ? formatISO(range.from, { representation: "date" }) : null,
    to: range.to ? formatISO(range.to, { representation: "date" }) : null,
  };
};

export const parseRange = (range: Range | null) => {
  if (!range) return null;

  const from = range.from ?? Date.now();
  const to = range.to ?? range.from ?? Date.now();

  return {
    from: formatISOinUTC(startOfDay(from, { in: tz("Europe/Paris") })),
    to: formatISOinUTC(endOfDay(to, { in: tz("Europe/Paris") })),
  };
};

export const formatRangeForLabel = (value: Range) => {
  if (!value.to || value.from === value.to) {
    return formatForParis(value.from ?? Date.now(), "d MMMM");
  }
  return [
    formatForParis(value.from ?? Date.now(), "d MMM"),
    formatForParis(value.to ?? Date.now(), "d MMM"),
  ].join(" → ");
};

export const RangeDatePickerLabel = ({ value }: { value: Range }) => {
  return <>{formatRangeForLabel(value)}</>;
};

type DatePickerProps = {
  defaultMonth?: Date;
  range?: boolean;
  presets?: GeneratedPreset[];
  modifiers?: DayModifiers;
};

export type DateSelectState = {
  datePicker: {
    presets: GeneratedPreset[];
  } & Omit<DatePickerProps, "presets">;
  select: SelectStore;
  onChange: (value: Range | null) => void;
  value: Range;
} & Omit<RichSelectState<Range>, "value">;

type DateSelectStateProps = DatePickerProps &
  RichSelectStateProps<Range, Range | null>;

export const useDateSelectState = (
  props: DateSelectStateProps,
): DateSelectState => {
  const {
    defaultMonth,
    range,
    presets = [],
    modifiers,
    onChange,
    valueSelector = (v) => JSON.stringify(v),
    labelSelector = (v) => <RangeDatePickerLabel value={v} />,
    ...rest
  } = props;
  const rich = useRichSelectState({
    valueSelector,
    labelSelector,
    onChange,
    ...rest,
  });

  const value = useMemo(() => {
    const { valueSelector } = rich;
    if (props.value === null) return "";
    return valueSelector(props.value);
  }, [props.value, rich]);

  const setValue = useCallback(
    (value: string | string[]) => {
      onChange(
        value === ""
          ? null
          : Array.isArray(value)
            ? value.map((v) => JSON.parse(v))
            : JSON.parse(value),
      );
    },
    [onChange],
  );

  const select = useSelectStore({
    value,
    setValue,
  });

  return {
    ...rich,
    value: rich.value as Range,
    select,
    onChange,
    datePicker: {
      modifiers: modifiers,
      defaultMonth: defaultMonth,
      range: range,
      presets: presets,
    },
  };
};

type SelectDatePickerProps = {
  state: DateSelectState;
  children?:
    | React.ReactNode
    | ((props: {
        value: Range | null;
        onChange: (value: Range) => void;
      }) => React.ReactNode);
  numberOfMonths?: number;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const SelectDatePicker = forwardRef<
  HTMLDivElement,
  SelectDatePickerProps
>(
  (
    {
      state: { value, onChange, datePicker },
      children,
      numberOfMonths = 2,
      disabled,
      ...props
    },
    ref,
  ) => {
    const Picker = datePicker.range ? RangeDatePicker : SingleDatePicker;

    const handleChange = useCallback(
      (newValue: Range) => {
        onChange(
          !newValue && value && datePicker.range
            ? {
                from: value.from ?? value.to,
                to: value.from ?? value.to,
              }
            : newValue,
        );
      },
      [onChange, datePicker.range, value],
    );

    return (
      <div ref={ref} {...props}>
        {children && typeof children === "function" ? (
          children({ value, onChange })
        ) : (
          <Picker
            value={value as any}
            onChange={handleChange as any}
            numberOfMonths={numberOfMonths}
            disabled={disabled}
            {...datePicker}
          />
        )}
      </div>
    );
  },
);
