import { gql } from "@apollo/client";

import { TypedDocumentNode, useSafeQuery } from "@/containers/Apollo";
import type {
  MerciAppServiceQueryQuery,
  MerciAppServiceQueryQueryVariables,
} from "@/gql-types";

const MerciAppServiceQuery: TypedDocumentNode<
  MerciAppServiceQueryQuery,
  MerciAppServiceQueryQueryVariables
> = gql`
  query MerciAppServiceQueryV2($serviceKey: ThirdPartyServiceKey!) {
    thirdPartyService(key: $serviceKey) {
      integrations {
        id
        config {
          __typename
          ... on MerciappIntegrationConfig {
            token
          }
        }
      }
    }
  }
`;

export const useMerciAppToken = () => {
  const { data } = useSafeQuery(MerciAppServiceQuery, {
    variables: { serviceKey: "merciapp" },
  });
  if (!data) return { loading: true, authToken: null };
  if (
    data.thirdPartyService?.integrations?.[0]?.config.__typename !==
    "MerciappIntegrationConfig"
  ) {
    return { loading: false, authToken: null };
  } else {
    return {
      loading: false,
      authToken: data.thirdPartyService.integrations[0].config.token,
    };
  }
};
