import { RawCommands, getNodeType, isList } from "@tiptap/core";
import { NodeType } from "@tiptap/pm/model";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    liftAll: {
      /**
       * Create a command to lift blocks and lists.
       */
      liftAll: (typeOrName: string | NodeType) => ReturnType;
    };
  }
}

export const liftAll: RawCommands["liftAll"] =
  (typeOrName) =>
  ({ editor, state, commands }) => {
    const type = getNodeType(typeOrName, state.schema);
    if (isList(type.name, editor.extensionManager.extensions))
      return commands.liftListItem("listItem");
    if (!type.isTextblock) return commands.lift(type);
    return true;
  };
