import { gql } from "@apollo/client";

import { Time } from "@/components/Time";
import { EventsHistory } from "@/containers/events/EventsHistory";

const StatusItem = (props) => (
  <div className="status-item font-accent text-xs" {...props} />
);

function Status({ post, lock }) {
  if (lock?.status === "locked-by-someone-else") {
    return (
      <span className="text-sm text-primary-on">
        En cours de modification...
      </span>
    );
  }

  if (post.published) {
    // Modify events to display the first publish date with the latest statuses
    // and display all latest events in info.
    const events = (() => {
      const [firstEvent] = post.firstEvents.nodes;
      if (!firstEvent) return [];
      const [latestEvent] = post.events.nodes;
      if (!latestEvent) return [firstEvent];
      return [
        { ...firstEvent, statuses: latestEvent.statuses },
        ...post.events.nodes,
      ];
    })();
    return (
      <StatusItem>
        <EventsHistory
          events={events}
          dateFormat="'le' dd/MM/yy à HH:mm"
          className="leading-4"
        />
      </StatusItem>
    );
  }

  if (post.updatedAt) {
    return (
      <StatusItem>
        Modifié par {post.updatedBy?.fullName} <Time date={post.updatedAt} />
      </StatusItem>
    );
  }

  return (
    <StatusItem>
      Créé par {post.creator?.fullName} <Time date={post.createdAt} />
    </StatusItem>
  );
}

export function LivePostStatus({ post, lock }) {
  return (
    <div className="grid grid-flow-col gap-2 font-accent text-sm text-grey-on">
      <Status post={post} lock={lock} />
    </div>
  );
}

export const LivePostStatusPostFragment = gql`
  fragment LivePostStatusPostFragment on Post {
    createdAt
    creator {
      id
      fullName
    }
    updatedAt
    updatedBy {
      id
      fullName
    }
    dirty
    published
    publishedAt
    publishedBy {
      id
      fullName
    }
    events(limit: 5) {
      nodes {
        id
        ...EventsHistory_events
      }
    }
    firstEvents: events(limit: 1, orderBy: [{ field: id, direction: asc }]) {
      nodes {
        id
        ...EventsHistory_events
      }
    }
  }

  ${EventsHistory.fragments.events}
`;
