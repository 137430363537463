import { Extension } from "@tiptap/core";

export const SpecialCharactersExtension = Extension.create({
  name: "specialCharacters",

  addKeyboardShortcuts() {
    return {
      "Ctrl-Alt-$": () => this.editor.commands.insertContent("×"),
      "Ctrl-Alt-€": () => this.editor.commands.insertContent("×"),
      "Ctrl-Alt-*": () => this.editor.commands.insertContent("×"),
      "Alt-Shift-7": () => this.editor.commands.insertContent("”"),
      "Alt-7": () => this.editor.commands.insertContent("“"),
      "Ctrl-Alt-;": () => this.editor.commands.insertContent("…"),
      "Ctrl-8": () => this.editor.commands.insertContent("–"),
      "Ctrl-_": () => this.editor.commands.insertContent("–"),
      "Alt-Shift-O": () => this.editor.commands.insertContent("Œ"),
      "Alt-O": () => this.editor.commands.insertContent("œ"),
      "Alt-o": () => this.editor.commands.insertContent("œ"),
      "Ctrl-Space": () => this.editor.commands.insertContent("\u00A0"),
      "Alt-Space": () => this.editor.commands.insertContent("\u00A0"),
    };
  },
});
