import * as keyCodes from "../../key-codes";

type KeyMap = {
  [key: number]: true;
};

const preventedKeys: KeyMap = {
  // submission
  [keyCodes.enter]: true,
  // tabbing
  [keyCodes.tab]: true,
};

export const preventStandardKeyEvents = (event: KeyboardEvent) => {
  if (preventedKeys[event.keyCode]) {
    event.preventDefault();
  }
};

export const supportedPageVisibilityEventName: string = ((): string => {
  const base: string = "visibilitychange";

  // Server side rendering
  if (typeof document === "undefined") {
    return base;
  }

  // See https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
  const candidates: string[] = [
    base,
    `ms${base}`,
    `webkit${base}`,
    `moz${base}`,
    `o${base}`,
  ];

  const supported = candidates.find(
    (eventName) => `on${eventName}` in document,
  );

  return supported || base;
})();
