import { Extension } from "@tiptap/core";

import * as commands from "../commands";

export const Commands = Extension.create<never>({
  name: "utils",

  addCommands() {
    return {
      ...commands,
    };
  },
});
