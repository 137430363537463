import * as Ariakit from "@ariakit/react";
import { forwardRef } from "react";

import { cn } from "./utils/classNames";

export type ClickableProps = Ariakit.ButtonProps<"button">;

export const Clickable = forwardRef<HTMLButtonElement, ClickableProps>(
  ({ className, ...props }, ref) => {
    return (
      <Ariakit.Button
        ref={ref}
        render={<div />}
        className={cn(
          "select-none rounded aria-expanded:bg-grey-bg-hover-transparent",
          "hover:[&:not(:focus-within)]:bg-grey-bg-hover-transparent",
          className,
        )}
        {...props}
      />
    );
  },
);
