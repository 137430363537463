import clsx from "clsx";
import * as React from "react";
import { forwardRef } from "react";

export type ChoiceChipProps = {
  checked: boolean;
  children: React.ReactNode;
} & Omit<React.ComponentProps<"label">, "onChange">;

export const ChoiceChip = forwardRef<HTMLLabelElement, ChoiceChipProps>(
  ({ className, checked, children, ...props }, ref) => {
    return (
      <label
        ref={ref}
        className={clsx(
          className,
          "inline-flex select-none items-center gap-2 rounded p-1 font-accent text-sm transition",
          checked ? "bg-primary-bg-light" : "bg-grey-bg-light",
        )}
        {...props}
      >
        {children}
      </label>
    );
  },
);
