import { makeIcon } from "./utils";

export const SvgCase = makeIcon(
  "SvgCase",
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m3.923 17.682 4.327-11.4 4.327 11.4M11.063 13.692H5.436M14.524 11.412c.44-1.022 1.479-1.71 2.668-1.71 1.66 0 2.885 1.14 2.885 2.85v5.13"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.308 15.652c0 1.282.968 2.066 2.163 2.066 1.947 0 3.606-.962 3.606-3.776v-.535c-.721 0-2.091.036-3.317.178-1.182.138-2.452.677-2.452 2.067Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
