import { Node, mergeAttributes } from "@tiptap/core";

export interface ArticleMediaOptions {}

export const ArticleMedia = Node.create<ArticleMediaOptions>({
  name: "articleMedia",

  group: "block",

  draggable: true,

  addAttributes() {
    return {
      articleMediaId: {
        isRequired: true,
        renderHTML(attributes) {
          return {
            "data-article-media-id": attributes.articleMediaId,
          };
        },
        parseHTML(el) {
          return el.dataset.articleMediaId;
        },
      },
      mediaId: {
        isRequired: true,
        renderHTML(attributes) {
          return {
            "data-media-id": attributes.mediaId,
          };
        },
        parseHTML(el) {
          return el.dataset.mediaId;
        },
      },
      mediaType: {
        isRequired: true,
        renderHTML(attributes) {
          return {
            "data-media-type": attributes.mediaType,
          };
        },
        parseHTML(el) {
          return el.dataset.mediaId;
        },
      },
      metadata: {
        default: null,
        rendered: false,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes, { "data-type": this.name })];
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },
});
