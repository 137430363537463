import { makeIcon } from "./utils";

export const SvgUnorderedList = makeIcon(
  "SvgUnorderedList",
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.539 8.01h10.384M8.539 12h10.384M8.539 15.99h10.384"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.654 8.58a.573.573 0 0 0 .577-.57.573.573 0 0 0-.577-.57.573.573 0 0 0-.577.57c0 .315.258.57.577.57ZM5.654 12.57A.573.573 0 0 0 6.23 12a.573.573 0 0 0-.577-.57.573.573 0 0 0-.577.57c0 .315.258.57.577.57ZM5.654 16.56a.573.573 0 0 0 .577-.57.573.573 0 0 0-.577-.57.573.573 0 0 0-.577.57c0 .315.258.57.577.57Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
);
