import { gql } from "@apollo/client";
import { memo } from "react";
import { IoDocumentOutline } from "swash/Icon";

import { Time } from "@/components/Time";
import { EventStatusIcon } from "@/containers/events/EventStatusIcon";
import { StatusListIndicator } from "@/containers/events/EventStatusIndicator";

import { ChannelsAlertFragment, channels } from "./channels";

export const getAlertStatus = (alert) => {
  const [event] = alert.prePublishedEvent.nodes;
  // Without an event, we are "pending"
  if (!event) return "pending";
  // We trust the event status until it is "success"
  if (event.status !== "success") {
    return event.status;
  }
  // Filter active deliveries
  const deliveries = alert.deliveries.filter((delivery) => {
    const channel = channels.find(
      (channel) => channel.type === delivery.channel,
    );
    if (!channel) return false;
    return alert[channel.fieldName];
  });
  // If one of the delivery is still "in_progress" or "queued", then it is "in_progress"
  if (deliveries.some((delivery) => delivery.status !== "completed")) {
    return "in_progress";
  }
  // If one of the delivery is not in "success", then it is "failure"
  if (deliveries.some((delivery) => delivery.conclusion !== "success")) {
    return "failure";
  }
  // If everything goes right, it is "success"
  return "success";
};

export const AlertStatus = memo(
  ({ alert, status, createdAt, publishedAt, children }) => {
    if (status === "pending" || !publishedAt) {
      return (
        <div className="flex flex-row items-center gap-1">
          {status === "pending" ? (
            <IoDocumentOutline
              className="inline-block"
              style={{ verticalAlign: "-10%" }}
            />
          ) : (
            <StatusListIndicator
              status={status}
              tooltip={<AlertStatusTooltipContent alert={alert} />}
            />
          )}{" "}
          <>
            Brouillon créé le{" "}
            <Time date={createdAt} format="dd/MM/yyyy à HH:mm" />
          </>
        </div>
      );
    }
    return (
      <>
        <StatusListIndicator
          tooltip={<AlertStatusTooltipContent alert={alert} />}
          status={status}
        >
          <div>
            Publiée le <Time date={publishedAt} format="dd/MM/yyyy à HH:mm" />
            {children}
          </div>
        </StatusListIndicator>
      </>
    );
  },
);

export const AlertStatusTooltipContent = memo(({ alert }) => {
  return (
    <div className="flex flex-col gap-2">
      {alert.deliveries.map((delivery) => {
        return (
          <div key={delivery.id}>
            <div className="flex flex-row items-center gap-1 text-sm font-normal">
              <div className="inline-flex align-[-15%] text-sm">
                <EventStatusIcon status={delivery.conclusion} />
              </div>
              <span>
                {`${delivery.channel
                  .charAt(0)
                  .toUpperCase()}${delivery.channel.slice(1)}`}
                {delivery.conclusion !== "failure"
                  ? null
                  : `: ${delivery.output.title}`}
                {delivery.completedAt && (
                  <span className="text-sm">
                    {" - "}
                    <Time
                      date={delivery.completedAt}
                      format="dd/MM/yyyy à HH:mm:ss"
                    />
                  </span>
                )}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
});

const EventFragment = gql`
  fragment getAlertStatus__event on Event {
    id
    status
    alertId
  }
`;

getAlertStatus.fragments = {
  _event: EventFragment,
  alert: gql`
    fragment getAlertStatus_alert on Alert {
      prePublishedEvent: events(
        limit: 1
        where: { action: { eq: "prePublish" } }
      ) {
        nodes {
          id
          ...getAlertStatus__event
        }
      }
      deliveries {
        id
        channel
        status
        conclusion
        completedAt
        output {
          title
        }
      }
      ...ChannelsAlertFragment
    }

    ${EventFragment}
    ${ChannelsAlertFragment}
  `,
};
