import { gql } from "@apollo/client";

import { useSubscribePreviousFormValues } from "@/components/forms/FormSubscribe";
import { useSafeQuery } from "@/containers/Apollo";
import { HasOneField } from "@/containers/admin/CRUD";
import { ConnectionFragment } from "@/services/fragments/connectionFragment";

export const PeriodicalSectionFieldPeriodicalSectionFragment = gql`
  fragment PeriodicalSectionFieldPeriodicalSectionFragment on PeriodicalSection {
    id
    label
    enabled
    rank
    path
    periodicity {
      suggestDateAfterDuration
      timeDeadline
      weekDays
    }
  }
`;

export const PeriodicalSectionFieldPeriodicalSectionQuery = gql`
  query PeriodicalSectionFieldPeriodicalSectionQuery(
    $offset: Int
    $periodicalId: Int
    $search: String
  ) {
    connection: periodicalSections(
      offset: $offset
      where: {
        periodicalId: { eq: $periodicalId }
        search: $search
        enabled: true
      }
    ) {
      nodes {
        id
        ...PeriodicalSectionFieldPeriodicalSectionFragment
      }
      ...ConnectionFragment
    }
  }
  ${ConnectionFragment}
  ${PeriodicalSectionFieldPeriodicalSectionFragment}
`;

export const PeriodicalSectionFieldPeriodicalSectionSingleQuery = gql`
  query PeriodicalSectionFieldPeriodicalSectionSingleQuery($id: Int!) {
    periodicalSection(id: $id) {
      ...PeriodicalSectionFieldPeriodicalSectionFragment
    }
  }
  ${PeriodicalSectionFieldPeriodicalSectionFragment}
`;

export const useFormPeriodicalSection = (periodicalSectionId) => {
  const { data, loading } = useSafeQuery(
    PeriodicalSectionFieldPeriodicalSectionSingleQuery,
    {
      variables: { id: periodicalSectionId },
      skip: !periodicalSectionId,
    },
  );
  return { periodicalSection: data?.periodicalSection, loading };
};

export function PeriodicalSectionField({
  children,
  required,
  disabled,
  periodical,
  name = "periodicalSectionId",
  label = "Rubrique",
  placeholder = "Sélectionnez une rubrique",
  modal,
}) {
  useSubscribePreviousFormValues(({ values, previousValues, form }) => {
    if (values.periodicalId !== previousValues.periodicalId) {
      form.change(name, null);
    }
  });

  if (!periodical) return null;

  const field = (
    <HasOneField
      name={name}
      label={label}
      placeholder={placeholder}
      clearable
      query={PeriodicalSectionFieldPeriodicalSectionQuery}
      fragment={PeriodicalSectionFieldPeriodicalSectionFragment}
      searchVariables={{
        periodicalId: periodical.id,
      }}
      modelName="PeriodicalSection"
      searchable
      hideOnEmpty
      modal={modal}
      required={required}
      disabled={disabled}
    />
  );

  return typeof children === "function" ? children({ children: field }) : field;
}
