import {
  add,
  addDays,
  differenceInDays,
  getISODay,
  isPast,
  parse,
} from "date-fns";
import { useFormState } from "react-final-form";

import { formatForParis, parseIsoDuration } from "@/services/time-formatter";

export function getDefaultLayout(layouts) {
  return layouts.find((layout) => layout.default) ?? null;
}

export function getDefaultEditorialFormat(layouts) {
  const defaultLayout = getDefaultLayout(layouts);
  if (!defaultLayout) return null;
  return defaultLayout.editorialFormats.nodes.find(
    (editorialFormat) => editorialFormat.default,
  );
}

export function getEditorialType({ values, layout }) {
  if (!layout) return null;
  return layout.layoutEditorialTypes.nodes.find(
    (node) => node.editorialType.id === values.editorialTypeId,
  );
}

export function getLayout({ values, layouts }) {
  if (!values.layoutId) return null;
  return layouts.nodes.find((node) => node.id === values.layoutId);
}

export function useFormLayout({ layouts }) {
  const { values } = useFormState({ subscription: { values: true } });
  return getLayout({ values, layouts });
}

export function getDefaultPeriodical({ user, periodicals }) {
  const defaultPeriodicalId = user.currentSection?.defaultPeriodical?.id;
  if (!defaultPeriodicalId) return null;
  return (
    periodicals.nodes
      .filter(({ enabled }) => enabled)
      .find((periodical) => periodical.id === defaultPeriodicalId) || null
  );
}

export function getPeriodical({ values, periodicals }) {
  return values.periodicalId
    ? periodicals.nodes.find((node) => node.id === values.periodicalId)
    : null;
}

export function useFormPeriodical({ periodicals }) {
  const { values } = useFormState({ subscription: { values: true } });
  return getPeriodical({ values, periodicals });
}

export function getDefaultPeriodicalSectionId({ sections, user }) {
  if (!user || !user.currentSection || !sections) return null;
  return sections.nodes.find(
    (section) =>
      section.defaultPeriodicalSection?.id ===
      user.currentSection?.defaultPeriodicalSection?.id,
  )?.defaultPeriodicalSection?.id;
}

function checkIsEditionDateValid(periodicity, date) {
  const dayOfWeek = getISODay(date) - 1;
  return periodicity.weekDays.includes(dayOfWeek);
}

export function getDefaultPeriodicalEditionDate(periodicalSection, section) {
  if (!periodicalSection || (section && !section.defaultPeriodicalSectionDate))
    return null;
  const { periodicity } = periodicalSection;

  if (periodicity.weekDays.length === 7) return null;

  let potentialDate = add(
    Date.now(),
    parseIsoDuration(periodicity.suggestDateAfterDuration),
  );

  // Daily publications are ready before the limit,
  // so after it, we add 1 day to the suggested date
  if (periodicity.timeDeadline) {
    const deadline = parse(periodicity.timeDeadline, "HH:mm:ss", Date.now());
    if (isPast(deadline)) {
      potentialDate = addDays(potentialDate, 1);
    }
  }

  // Trying all week days
  while (differenceInDays(potentialDate, Date.now()) < 14) {
    if (checkIsEditionDateValid(periodicity, potentialDate)) {
      return formatForParis(potentialDate, "yyyy-MM-dd");
    }
    potentialDate = addDays(potentialDate, 1);
  }

  return null;
}
