import { Node } from "@tiptap/pm/model";

export const findNodePos = (doc: Node, target: Node) => {
  let targetPos = 0;
  doc.descendants((node, pos) => {
    if (node === target) {
      targetPos = pos;
    }
    return !targetPos;
  });
  return targetPos;
};
