import createEditorControl from "@/components/editor/controls/createEditorControl";
import { Redo } from "@/components/icons";

export const RedoControl = createEditorControl({
  name: "redo",
  label: "Rétablir",
  shortcut: ["⌘", "⇧", "z"],
  exclude: [],
  icon: <Redo />,
  command: ({ editor }) => editor.chain().focus().redo().run(),
  canExecute: ({ editor }) => editor.can().redo(),
});
