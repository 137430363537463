import { makeIcon } from "./utils";

export const SvgMatinale = makeIcon(
  "SvgMatinale",
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.698 1.667c-1.555 0-2.09.103-2.638.396a2.372 2.372 0 0 0-.997.997c-.293.548-.396 1.083-.396 2.638v8.604c0 1.555.103 2.09.396 2.638.232.434.563.765.997.997.548.293 1.083.396 2.638.396h8.604c1.555 0 2.09-.103 2.638-.396.434-.232.765-.563.997-.997.293-.548.396-1.083.396-2.638V5.698c0-1.555-.103-2.09-.396-2.638a2.372 2.372 0 0 0-.997-.997c-.548-.293-1.083-.396-2.638-.396zM14.302 0c1.982 0 2.7.206 3.424.594a4.038 4.038 0 0 1 1.68 1.68c.388.724.594 1.442.594 3.424v8.604c0 1.982-.206 2.7-.594 3.424s-.955 1.293-1.68 1.68-1.442.594-3.424.594H5.698c-1.982 0-2.7-.206-3.424-.594a4.038 4.038 0 0 1-1.68-1.68C.206 17.002 0 16.284 0 14.302V5.698c0-1.982.206-2.7.594-3.424a4.038 4.038 0 0 1 1.68-1.68C2.998.206 3.716 0 5.698 0zM5.804 13.89c-1.612.017-3.146 1.233-3.804 1.99l.173.231c.907-.724 2.003-1.037 2.77-1.02.815.032 1.268.51 1.425.97l2.207-1.381-.203-.428-.705.411c-.423-.477-1.017-.773-1.863-.773zM4.662 4.136l-.345-.247c-.438.593-.61.84-.61.84-.705.97-.267 1.743.767 1.595.25-.033.657-.1.923-.148.661-.124.784.114.797.727l.001.128v4.803c0 .597-.09 1.114-.471 1.5l-.124.112.158.28 1.565-1.037c.581-.381.73-.92.749-1.652l.002-.173V6.16l.799-.476.656.559c.209.175.27.273.28.604l.003.136v9.08h.125c.625-.346 1.078-.577 1.078-.577.542-.291.66-.397.673-.971l.001-.13V6.191l.83-.526L14.366 6.8l-.25.198c-.408.32-.708.825-.748 1.739l-.005.218v5.625c0 .453.1.712.33.913l.094.074.673.478 1.957-1.234-.189-.396-.563.346-.203-.115c-.181-.103-.239-.257-.25-.568l-.001-.124V7.656c0-.728.173-1.22.68-1.588l.135-.09.64-.394-.234-.412-.297.165c-.33.18-.569.306-1.004.086l-.123-.069-1.52-.905-1.971 1.282L9.92 4.45 8.074 5.617c-.079-.592-.627-1.596-2.113-.988-.345.15-.83.346-1.08.445-.5.185-.764-.034-.525-.47l.055-.09.251-.378-.345-.247z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>,
);
