import { gql } from "@apollo/client";
import { Fragment, ReactElement } from "react";
import { Link } from "swash/Link";
import { cn } from "swash/utils/classNames";

import { Mention } from "@/components/rich-editor/plugins/mention/Mention";
import { Comment_commentFragment } from "@/gql-types";

type CommentProps = {
  comment: Comment_commentFragment;
  className?: string;
};

const CommentMentionFragment = gql`
  fragment Comment_commentMention on CommentMention {
    blockIndex
    startOffset
    endOffset
    data {
      ... on User {
        id
      }
    }
    mutability
  }
`;

const sliceUrl = (url: string) => {
  const start = url.slice(0, 25);
  const end = url.slice(-15);
  return `${start}[...]${end}`;
};

const URL_REGEX = /((?:http|https):\/\/(?:\S+))/g;

const LinkDecorator = ({ text }: { text: string }) => {
  if (!text) return null;
  const textArray = text.split(URL_REGEX);
  return (
    <>
      {textArray.map((str, index) => {
        if (URL_REGEX.test(str)) {
          const link = str.length > 46 ? sliceUrl(str) : str;
          return (
            <Link key={index} target="_blank" href={str}>
              {link}
            </Link>
          );
        }
        return index === textArray.length - 1 ? str : `${str} `;
      })}
    </>
  );
};

/**
 * Display comment with mentions
 */
export const Comment = ({ comment, className }: CommentProps): ReactElement => {
  const { value, mentions } = comment;

  return (
    <p className={cn("whitespace-pre-wrap text-sm", className)}>
      {value.split("\n").map((block, index) => {
        const blockMentions = mentions.filter(
          ({ blockIndex }) => blockIndex === index,
        );

        if (!blockMentions.length) {
          return (
            <Fragment key={index}>
              <LinkDecorator text={block} />
              {"\n"}
            </Fragment>
          );
        }

        const textAfterMention = block.substring(
          // blockMentions[blockMentions.length - 1] should not be undefined here , make ts happy
          blockMentions[blockMentions.length - 1]?.endOffset ?? 0,
          block.length,
        );

        return (
          <Fragment key={index}>
            {blockMentions.map((mention, index) => {
              const { startOffset, endOffset } = mention;
              const text = block.substring(startOffset, endOffset);

              const textBeforeMention = block.substring(
                blockMentions[index - 1]?.endOffset ?? 0,
                startOffset,
              );

              return (
                <Fragment key={index}>
                  <LinkDecorator text={textBeforeMention} />
                  <Mention mention={mention}>{text}</Mention>
                </Fragment>
              );
            })}
            <LinkDecorator text={textAfterMention} />
            {"\n"}
          </Fragment>
        );
      })}
    </p>
  );
};

Comment.fragments = {
  comment: gql`
    fragment Comment_comment on Comment {
      value
      mentions {
        ...Comment_commentMention
        ...Mention_commentMention
      }
    }
    ${Mention.fragments.commentMention}
    ${CommentMentionFragment}
  `,
};
