import { MutableRefObject, ReactNode, useEffect, useRef } from "react";
import { Provider } from "react-redux";
import { bindActionCreators } from "redux";
import { useCallback, useMemo } from "use-memo-one";

import { invariant } from "../../invariant";
import preset from "../../screen-reader-message-preset";
import createAutoScroller from "../../services/auto-scroller";
import canStartDrag from "../../services/can-start-drag";
import createDimensionMarshal from "../../services/dimension-marshal/dimension-marshal";
import type { Callbacks as DimensionMarshalCallbacks } from "../../services/dimension-marshal/dimension-marshal-types";
import isMovementAllowed from "../../services/is-movement-allowed";
import useRegistry from "../../services/registry/use-registry";
import {
  collectionStarting,
  flush,
  move,
  updateDroppableIsEnabled,
  updateDroppableScroll,
} from "../../store/actions";
import createStore from "../../store/create-store";
import type { Dispatch, Store } from "../../store/types";
import type { DraggableId, Responders } from "../../types";
import AppContext, { AppContextValue } from "../context/app-context";
import { useAnnouncer } from "../use-announcer";
import useFocusMarshal from "../use-focus-marshal";
import { useHiddenTextElement } from "../use-hidden-text-element";
import usePrevious from "../use-previous-ref";
import useSensorMarshal from "../use-sensor-marshal/use-sensor-marshal";
import useStyleMarshal from "../use-style-marshal/use-style-marshal";
import { scrollWindow } from "../window-utils";
import type { AppCallbacks } from "./drag-drop-context-types";

export type Props = Responders & {
  contextId: string;
  setCallbacks: (callbacks: AppCallbacks) => void;
  // we do not technically need any children for this component
  children: ReactNode | null;
};

const createResponders = (props: Props): Responders => ({
  onBeforeCapture: props.onBeforeCapture,
  onBeforeDragStart: props.onBeforeDragStart,
  onDragStart: props.onDragStart,
  onDragEnd: props.onDragEnd,
  onDragUpdate: props.onDragUpdate,
});

function getStore(lazyRef: MutableRefObject<Store | null>): Store {
  invariant(lazyRef.current, "Could not find store from lazy ref");
  return lazyRef.current;
}

export default function App(props: Props) {
  const { contextId, setCallbacks } = props;
  const lazyStoreRef = useRef<Store | null>(null);

  // lazy collection of responders using a ref - update on ever render
  const lastPropsRef = usePrevious<Props>(props);

  const getResponders = useCallback(
    () => createResponders(lastPropsRef.current),
    [lastPropsRef],
  );

  const announce = useAnnouncer(contextId);

  const dragHandleUsageInstructionsId = useHiddenTextElement({
    contextId,
    text: preset.dragHandleUsageInstructions,
  });
  const styleMarshal = useStyleMarshal(contextId);

  const lazyDispatch = useCallback<Dispatch>((action) => {
    getStore(lazyStoreRef).dispatch(action);
    return action;
  }, []);

  const marshalCallbacks = useMemo(
    (): DimensionMarshalCallbacks =>
      bindActionCreators(
        {
          updateDroppableScroll,
          updateDroppableIsEnabled,
          collectionStarting,
        },
        lazyDispatch,
      ),
    [lazyDispatch],
  );

  const registry = useRegistry();

  const dimensionMarshal = useMemo(
    () => createDimensionMarshal(registry, marshalCallbacks),
    [registry, marshalCallbacks],
  );

  const autoScroller = useMemo(
    () =>
      createAutoScroller({
        scrollWindow,
        scrollDroppable: dimensionMarshal.scrollDroppable,
        ...bindActionCreators(
          {
            move,
          },
          lazyDispatch,
        ),
      }),
    [dimensionMarshal.scrollDroppable, lazyDispatch],
  );

  const focusMarshal = useFocusMarshal(contextId);

  const store = useMemo(
    () =>
      createStore({
        announce,
        autoScroller,
        dimensionMarshal,
        focusMarshal,
        getResponders,
        styleMarshal,
      }),
    [
      announce,
      autoScroller,
      dimensionMarshal,
      focusMarshal,
      getResponders,
      styleMarshal,
    ],
  );

  // assigning lazy store ref
  lazyStoreRef.current = store;

  const tryResetStore = useCallback(() => {
    const current = getStore(lazyStoreRef);
    const state = current.getState();
    if (state.phase !== "IDLE") {
      current.dispatch(flush());
    }
  }, []);

  const isDragging = useCallback((): boolean => {
    const state = getStore(lazyStoreRef).getState();
    return state.isDragging || state.phase === "DROP_ANIMATING";
  }, []);

  const appCallbacks = useMemo(
    (): AppCallbacks => ({
      isDragging,
      tryAbort: tryResetStore,
    }),
    [isDragging, tryResetStore],
  );

  // doing this in render rather than a side effect so any errors on the
  // initial mount are caught
  setCallbacks(appCallbacks);

  const getCanLift = useCallback(
    (id: DraggableId) => canStartDrag(getStore(lazyStoreRef).getState(), id),
    [],
  );

  const getIsMovementAllowed = useCallback(
    () => isMovementAllowed(getStore(lazyStoreRef).getState()),
    [],
  );

  const appContext = useMemo(
    (): AppContextValue => ({
      marshal: dimensionMarshal,
      focus: focusMarshal,
      contextId,
      canLift: getCanLift,
      isMovementAllowed: getIsMovementAllowed,
      dragHandleUsageInstructionsId,
      registry,
    }),
    [
      contextId,
      dimensionMarshal,
      dragHandleUsageInstructionsId,
      focusMarshal,
      getCanLift,
      getIsMovementAllowed,
      registry,
    ],
  );

  useSensorMarshal({
    contextId,
    store,
    registry,
  });

  // Clean store when unmounting
  useEffect(() => {
    return tryResetStore;
  }, [tryResetStore]);

  return (
    <AppContext.Provider value={appContext}>
      <Provider store={store}>{props.children}</Provider>
    </AppContext.Provider>
  );
}
