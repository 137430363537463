import { makeIcon } from "./utils";

export const SvgQuote = makeIcon(
  "SvgQuote",
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.82 16.712c-.86-.866-1.32-1.837-1.32-3.412 0-2.653 1.878-5.044 4.653-6.32a.484.484 0 0 1 .6.173l.197.288a.522.522 0 0 1-.177.741c-2.35 1.385-2.84 3.082-3.043 4.177.447-.22 1.033-.297 1.607-.246 1.504.132 2.689 1.305 2.689 2.762 0 .735-.307 1.44-.854 1.96a2.997 2.997 0 0 1-2.063.81c-.894 0-1.749-.387-2.29-.933Zm8.332 0c-.858-.866-1.319-1.837-1.319-3.412 0-2.653 1.879-5.044 4.653-6.32a.484.484 0 0 1 .6.173l.198.288a.523.523 0 0 1-.177.741c-2.35 1.385-2.84 3.082-3.044 4.177.448-.22 1.034-.297 1.608-.246 1.503.132 2.688 1.305 2.688 2.762 0 .735-.307 1.44-.854 1.96a2.997 2.997 0 0 1-2.063.81c-.894 0-1.749-.387-2.29-.933Z"
      fill="currentColor"
    />
  </svg>,
);
