import { gql } from "@apollo/client";

const CustomFieldValueRichTextNodeFragment = gql`
  fragment CustomFieldValueRichTextNodeFragment on CustomFieldValueRichTextNode {
    id
    type
    text
    metadata
  }
`;

export const CustomFieldValueFragment = gql`
  fragment CustomFieldValueFragment on CustomFieldValue {
    customFieldId
    name
    type
    rank
    value {
      article
      boolean
      customTypeContent
      date
      file
      image
      richText {
        ...CustomFieldValueRichTextNodeFragment
      }
      tag
      text
    }
  }
  ${CustomFieldValueRichTextNodeFragment}
`;

export const CustomFieldFragment = gql`
  fragment CustomFieldFragment on CustomField {
    id
    globalId
    type
    label
    slug
    config {
      ... on TextCustomFieldConfig {
        label
        hint
        placeholder
        required
        multi
        appearance
        allowedValues {
          label
          value
        }
        textInputType
        thirdPartyScript
        default
      }
      ... on RichTextCustomFieldConfig {
        label
        hint
        placeholder
        required
        multi
      }
      ... on DateCustomFieldConfig {
        label
        hint
        placeholder
        required
        multi
      }
      ... on ImageCustomFieldConfig {
        label
        hint
        placeholder
        required
        multi
      }
      ... on FileCustomFieldConfig {
        label
        hint
        placeholder
        required
        multi
      }
      ... on ArticleCustomFieldConfig {
        label
        hint
        placeholder
        required
        multi
        limit
      }
      ... on TagCustomFieldConfig {
        label
        hint
        placeholder
        required
        multi
        limit
      }
      ... on CustomTypeContentCustomFieldConfig {
        label
        hint
        placeholder
        required
        multi
        limit
        customTypeId
      }
      ... on BooleanCustomFieldConfig {
        label
        hint
        placeholder
        checkedDefault
      }
    }
  }
`;
