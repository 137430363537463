import { gql } from "@apollo/client";
import { Tooltip } from "swash/Tooltip";

import { Time } from "@/components/Time";
import { UserAvatar } from "@/containers/user/UserAvatar";
import { UserHoverCard } from "@/containers/user/UserHoverCard";

const Info = ({ children }) => {
  return (
    <span className="text-dusk-500 before:mx-2 before:content-['•']">
      {children}
    </span>
  );
};

export const ActivityTime = ({ date }) => {
  return (
    <Info>
      <Time date={date} format="dd/MM/yyyy à HH:mm" />
    </Info>
  );
};

export const ModifyBy = ({ user, date }) => {
  return (
    <Info>
      <Tooltip
        tooltip={
          <>
            Modifié par {user} <Time date={date} />
          </>
        }
      >
        <span>Modifié</span>
      </Tooltip>
    </Info>
  );
};

export const ActivityItem = ({ user, children }) => {
  return (
    <div role="listitem" className="group flex w-full items-start gap-2">
      <UserHoverCard user={user}>
        <UserAvatar user={user} />
      </UserHoverCard>
      {children}
    </div>
  );
};

ActivityItem.fragments = {
  activityItemAvatar: gql`
    fragment ActivityItemAvatarFragment on ActivityItem {
      user {
        id
        ...UserHoverCard_user
        ...UserAvatar_user
      }
    }
    ${UserHoverCard.fragments.user}
    ${UserAvatar.fragments.user}
  `,
};
