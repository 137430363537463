import { apolloClient } from "@/services/apollo/client";

/**
 * Fetch media from id or url
 * @param {object} params
 * @param {number} [params.id]
 * @param {string} [params.url]
 * @param {import('./providers').MediaProvider["type"]} params.type
 * @param {import('@apollo/client').QueryOptions} params.query
 * @return {Promise<object | null>} media
 */
export async function fetchMedia({
  id,
  url,
  type,
  query,
  fetchPolicy = "cache-first",
}) {
  const {
    data: { media },
  } = await apolloClient.query({
    query,
    fetchPolicy,
    variables: { ...(id ? { id } : { url }), type },
  });

  return media;
}
