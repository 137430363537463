import {
  formatDistanceToNow,
  isToday,
  isYesterday,
  format as rawFormat,
} from "date-fns";
import { fr } from "date-fns/locale";
import {
  Children,
  forwardRef,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  type DateLike,
  formatForParis,
  formatISOinUTC,
} from "@/services/time-formatter";

export type TimeProps = {
  date?: DateLike | null;
  format?: string;
  short?: boolean;
  calendar?: boolean;
} & React.HTMLAttributes<HTMLTimeElement>;

const useFromNow = (date: DateLike | null, short = false) => {
  const getDateFromNow = useMemo(() => {
    if (!date) return null;
    return () => {
      let fromNow = formatDistanceToNow(date, {
        locale: fr,
        addSuffix: !short,
      });

      // FIXME: To preverse Moment's wording
      fromNow = fromNow.replace(
        "moins d’une minute",
        short ? "maintenant" : "quelques secondes",
      );

      return fromNow;
    };
  }, [date, short]);
  const [fromNow, setFromNow] = useState(getDateFromNow);
  useEffect(() => {
    if (!getDateFromNow) return;
    const id = setInterval(() => setFromNow(getDateFromNow()), 1000);
    return () => clearInterval(id);
  }, [getDateFromNow]);
  return fromNow;
};

const formatForCalendar = (date: DateLike) => {
  if (isToday(date)) return `Aujourd'hui à ${formatForParis(date, "HH:mm")}`;
  if (isYesterday(date)) return `Hier à ${formatForParis(date, "HH:mm")}`;
  return formatForParis(date, "Pp");
};

export const Time = memo(
  forwardRef<HTMLTimeElement, TimeProps>(function Time(
    { date, format, short, children, calendar, ...props },
    ref,
  ) {
    const hasChildren = Children.count(children) > 0;
    const showFromNow = !hasChildren && !format;
    const fromNow = useFromNow(showFromNow ? (date ?? null) : null, short);

    const getDisplay = () => {
      if (hasChildren) return children;
      if (!date) return "";
      if (format) return formatForParis(date, format).replace("\\n", "\n");
      if (calendar) return formatForCalendar(date);
      return fromNow;
    };

    if (!date) return null;
    return (
      <time
        ref={ref}
        data-test-hidden
        dateTime={formatISOinUTC(date)}
        title={rawFormat(date, "PPPP p", { locale: fr })}
        {...props}
      >
        {getDisplay()}
      </time>
    );
  }),
);
