import { gql, useApolloClient } from "@apollo/client";
import type { FragmentDefinitionNode } from "graphql";
import moment from "moment";
import { forwardRef, useMemo } from "react";
import { useForm } from "react-final-form";
import { Button } from "swash/Button";
import { Clickable } from "swash/Clickable";
import { IoTrashOutline, LinkTiltedBroken } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";
import { cn } from "swash/utils/classNames";

import { useCheckboxField } from "@/components/fields/CheckboxField";
import { ExposurePlannedDateField } from "@/components/fields/ExposurePlannedDateField";
import { ControlProps, FieldControl } from "@/components/fields/FieldControl";
import { ExposureIndicator } from "@/containers/ExposureIndicator";
import { HasOneField } from "@/containers/admin/CRUD";
import {
  type ArticleExposure,
  type Exposure,
} from "@/containers/article/ArticleExposureSelect";
import {
  type TimeSlot,
  useTimeSlots,
} from "@/containers/article/filters/PlannedDateFilter";
import { formatEditionLabel } from "@/containers/common/ListPublicationsSelector";
import { ConnectionFragment } from "@/services/fragments/connectionFragment";

const ArticleExposureLabel = ({
  label,
  isExposureOnlySuggested,
}: {
  label: string;
  isExposureOnlySuggested: boolean;
}) => {
  return (
    <div className={cn(isExposureOnlySuggested && "text-secondary-on")}>
      {label} {isExposureOnlySuggested ? "(suggéré)" : null}
    </div>
  );
};

const ArticleExposurePlannedDateField = ({
  correlatedFieldName,
  name,
  exposure,
}: {
  correlatedFieldName: string;
  name: string;
  exposure: Exposure;
}) => {
  return (
    // @ts-expect-error use js component
    <ExposurePlannedDateField
      exposure={exposure}
      correlatedFieldName={correlatedFieldName}
      name={name}
      validate={(value: string) => {
        if (!value) return undefined;
        if (moment(value).isBefore(moment())) {
          return "Date dans le passé";
        }
        return undefined;
      }}
    />
  );
};

const PublicationFragment = gql`
  fragment ExposureFieldFragment_Publication on Publication {
    id
    date
  }
`;

export const PublicationsListQuery = gql`
  query PublicationsList(
    $type: String
    $offset: Int
    $value: Int
    $date: DateTime
    $periodicalId: Int
    $newsletterId: Int
  ) {
    connection: publications(
      where: {
        type: { eq: $type }
        id: { eq: $value }
        date: { gt: $date }
        periodicalId: { eq: $periodicalId }
        newsletterId: { eq: $newsletterId }
      }
      limit: 30
      offset: $offset
    ) {
      nodes {
        id
        ...ExposureFieldFragment_Publication
      }
      ...ConnectionFragment
    }
  }
  ${PublicationFragment}
  ${ConnectionFragment}
`;

export const ArticleExposurePublicationField = ({
  name,
  type,
  parentId,
  timeDeadline,
}: {
  name: string;
  type: string;
  parentId: number;
  timeDeadline?: string;
}) => {
  const client = useApolloClient();
  const modelName =
    type === "newsletter" ? "NewsletterPublication" : "PeriodicalPublication";

  const dateFilter = useMemo(() => {
    if (!timeDeadline) return moment().startOf("day").toISOString();
    const [hours, minutes] = timeDeadline.split(":");
    if (
      moment().isAfter(moment().hours(Number(hours)).minutes(Number(minutes)))
    ) {
      return moment().endOf("day").toISOString();
    }

    return moment().startOf("day").toISOString();
  }, [timeDeadline]);

  return (
    // @ts-expect-error use js component
    <HasOneField
      name={`${name}.publication`}
      placeholder="Suggérer pour une édition"
      aria-label="Sélectionner une édition"
      modelName={modelName}
      query={PublicationsListQuery}
      fragment={PublicationFragment}
      labelSelector={({ date }) => formatEditionLabel(date)}
      labelElementSelector={({ date }: { date: string }) =>
        formatEditionLabel(date)
      }
      useInitialValue={(v) => v?.id ?? null}
      format={(v: { id: number }) => {
        if (!v) return null;
        const fragmentDefinitionNode = PublicationFragment
          .definitions[0] as FragmentDefinitionNode;
        return client.readFragment({
          id: `${modelName}:${v.id}`,
          fragment: PublicationFragment,
          fragmentName: fragmentDefinitionNode.name.value,
        });
      }}
      parse={(v: { id: number }) => v ?? null}
      searchVariables={{
        type: type === "newsletter" ? "newsletter" : "digitalPublication",
        date: dateFilter,
        [type === "newsletter" ? "newsletterId" : "periodicalId"]: parentId,
      }}
      searchable={false}
      scale="md"
      clearable
    />
  );
};

function isExposurePlannedDateEditable({
  exposure,
  timeSlots,
}: {
  exposure: Exposure;
  timeSlots: TimeSlot[];
}) {
  if (exposure.type === "tag") {
    return timeSlots.length > 0;
  }

  return Boolean(exposure.plannableMode);
}

const ArticleExposureField = ({
  articleExposure,
  exposure,
  correlatedFieldName,
  name,
}: {
  articleExposure: ArticleExposure;
  exposure: Exposure;
  correlatedFieldName: string;
  name: string;
}) => {
  const timeSlots = useTimeSlots();
  const isExposureOnlySuggested =
    articleExposure.suggested &&
    !articleExposure.fulfilled &&
    !articleExposure.selected;

  const canEditExposurePlannedDate =
    isExposureOnlySuggested &&
    isExposurePlannedDateEditable({ exposure, timeSlots });

  if (canEditExposurePlannedDate)
    return (
      <ArticleExposurePlannedDateField
        exposure={exposure}
        name={`${name}.planning`}
        correlatedFieldName={correlatedFieldName}
      />
    );
  const shouldDisplayPublicationField =
    exposure.type === "periodical" || exposure.type === "newsletter";
  if (isExposureOnlySuggested && shouldDisplayPublicationField)
    return (
      <ArticleExposurePublicationField
        name={name}
        type={exposure.type}
        parentId={
          exposure.type === "periodical"
            ? exposure.periodical?.id
            : exposure.newsletter?.id
        }
        timeDeadline={
          exposure.type === "periodical"
            ? exposure.periodical?.periodicity?.timeDeadline
            : exposure.newsletter?.timeDeadline
        }
      />
    );

  return (
    <ArticleExposureLabel
      label={exposure.label}
      isExposureOnlySuggested={isExposureOnlySuggested}
    />
  );
};

const RecorrelateButton = forwardRef<
  HTMLButtonElement,
  ControlProps & { fieldName: string }
>(({ fieldName }, ref) => {
  const form = useForm();
  return (
    <Tooltip tooltip="Recorréler avec la date de publication">
      <Clickable
        ref={ref}
        role="button"
        onClick={() => {
          form.change(fieldName, true);
        }}
        className="p-2 text-warning-on-light hover:text-primary-on"
      >
        <LinkTiltedBroken size={16} />
      </Clickable>
    </Tooltip>
  );
});

const ArticleExposureCorrelatedField = forwardRef<
  HTMLButtonElement,
  { name: string }
>(({ name }, ref) => {
  const field = useCheckboxField(name);

  return (
    <FieldControl
      ref={ref}
      {...field}
      fieldName={name}
      as={RecorrelateButton}
    />
  );
});

const RemoveButton = ({
  correlatedFieldName,
  onDelete,
  ...props
}: {
  correlatedFieldName: string;
  onDelete: () => void;
}) => {
  const form = useForm();

  return (
    <Button
      iconOnly
      aria-label="Supprimer l’exposition"
      title="Supprimer l’exposition"
      appearance="text"
      variant="danger"
      onClick={() => {
        form.change(correlatedFieldName, false);
        onDelete();
      }}
      {...props}
    >
      <IoTrashOutline />
    </Button>
  );
};

export const ArticleExposureRow = ({
  article,
  articleExposure,
  hasWritePermission,
  onDelete,
  name,
}: {
  article: { id: number };
  articleExposure: ArticleExposure;
  hasWritePermission: boolean;
  onDelete: () => void;
  name: string;
}) => {
  const { fulfilled, selected, correlatedPlannedDateToArticle, exposure } =
    articleExposure;
  const correlatedFieldName = `${name}.correlatedPlannedDateToArticle`;
  const deletable = !fulfilled && !selected;
  const showCorrelatedButton =
    (exposure.warnPosteriorArticlePublication ||
      exposure.requiredPriorArticlePublication) &&
    correlatedPlannedDateToArticle === false &&
    !fulfilled;

  return (
    <div
      className="flex h-8 items-center gap-x-4"
      role="listitem"
      aria-label={exposure.label}
    >
      <ExposureIndicator
        article={article}
        articleExposure={articleExposure}
        exposure={exposure}
      />
      <div className="w-full">
        <ArticleExposureField
          name={name}
          articleExposure={articleExposure}
          exposure={exposure}
          correlatedFieldName={correlatedFieldName}
        />
      </div>
      {showCorrelatedButton ? (
        <ArticleExposureCorrelatedField name={correlatedFieldName} />
      ) : null}
      {hasWritePermission && deletable ? (
        <RemoveButton
          correlatedFieldName={correlatedFieldName}
          onDelete={onDelete}
        />
      ) : null}
    </div>
  );
};
