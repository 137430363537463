import type { Position } from "css-box-model";

import type {
  Displacement,
  DisplacementGroups,
  DisplacementMap,
  DragImpact,
  DraggableDimension,
  DraggableDimensionMap,
  DraggableId,
  DraggableIdMap,
  DroppableDimension,
  LiftEffect,
  Viewport,
} from "../../../types";
import scrollDroppable from "../../droppable/scroll-droppable";
import { isHomeOf } from "../../droppable/utils";
import getClientFromPageBorderBoxCenter from "../../get-center-from-impact/get-client-border-box-center/get-client-from-page-border-box-center";
import getPageBorderBoxCenter from "../../get-center-from-impact/get-page-border-box-center";
import getDisplacementGroups from "../../get-displacement-groups";
import getDraggablesInsideDroppable from "../../get-draggables-inside-droppable";
import { add, subtract } from "../../position";
import scrollViewport from "../../scroll-viewport";
import type { PublicResult } from "../move-in-direction-types";
import { isTotallyVisibleInNewLocation } from "./is-totally-visible-in-new-location";
import { moveToNextIndex } from "./move-to-next-index";

type Args = {
  isMovingForward: boolean;
  draggable: DraggableDimension;
  destination: DroppableDimension;
  draggables: DraggableDimensionMap;
  previousImpact: DragImpact;
  viewport: Viewport;
  previousClientSelection: Position;
  previousPageBorderBoxCenter: Position;
  afterCritical: LiftEffect;
};

export default ({
  isMovingForward,
  draggable,
  destination,
  draggables,
  previousImpact,
  viewport,
  previousPageBorderBoxCenter,
  previousClientSelection,
  afterCritical,
}: Args): PublicResult | null => {
  if (!destination.isEnabled) return null;

  const insideDestination = getDraggablesInsideDroppable(
    destination.descriptor.id,
    draggables,
  );
  const isInHomeList = isHomeOf(draggable, destination);

  const impact = moveToNextIndex({
    isMovingForward,
    isInHomeList,
    draggable,
    destination,
    insideDestination,
    previousImpact,
    viewport,
  });

  if (!impact) return null;

  const pageBorderBoxCenter = getPageBorderBoxCenter({
    impact,
    draggable,
    droppable: destination,
    draggables,
    afterCritical,
  });

  const isVisibleInNewLocation = isTotallyVisibleInNewLocation({
    draggable,
    destination,
    newPageBorderBoxCenter: pageBorderBoxCenter,
    viewport: viewport.frame,
    // already taken into account by getPageBorderBoxCenter
    withDroppableDisplacement: false,
    // we only care about it being visible relative to the main axis
    // this is important with dynamic changes as scroll bar and toggle
    // on the cross axis during a drag
    onlyOnMainAxis: true,
  });

  if (isVisibleInNewLocation) {
    // using the client center as the selection point
    const clientSelection = getClientFromPageBorderBoxCenter({
      pageBorderBoxCenter,
      draggable,
      viewport,
    });
    return {
      clientSelection,
      impact,
      scrollJumpRequest: null,
    };
  }

  const distance = subtract(pageBorderBoxCenter, previousPageBorderBoxCenter);

  const cautious = speculativelyIncrease({
    impact,
    viewport,
    destination,
    draggables,
    maxScrollChange: distance,
  });

  return {
    clientSelection: previousClientSelection,
    impact: cautious,
    scrollJumpRequest: distance,
  };
};

const getDraggables = (ids: DraggableId[], draggables: DraggableDimensionMap) =>
  ids.map((id) => draggables[id]!);

const tryGetVisible = (
  id: DraggableId,
  groups: DisplacementGroups[],
): Displacement | null => {
  for (let i = 0; i < groups.length; i++) {
    const displacement = groups[i]!.visible[id];
    if (displacement) return displacement;
  }
  return null;
};

function speculativelyIncrease({
  impact,
  viewport,
  destination,
  draggables,
  maxScrollChange,
}: {
  impact: DragImpact;
  destination: DroppableDimension;
  viewport: Viewport;
  draggables: DraggableDimensionMap;
  maxScrollChange: Position;
}): DragImpact {
  const scrolledViewport = scrollViewport(
    viewport,
    add(viewport.scroll.current, maxScrollChange),
  );
  const scrolledDroppable = destination.frame
    ? scrollDroppable(
        destination,
        add(destination.frame.scroll.current, maxScrollChange),
      )
    : destination;

  const last = impact.displaced;
  const withViewportScroll = getDisplacementGroups({
    afterDragging: getDraggables(last.all, draggables),
    destination,
    displacedBy: impact.displacedBy,
    viewport: scrolledViewport.frame,
    last,
    // we want the addition to be animated
    forceShouldAnimate: false,
  });
  const withDroppableScroll = getDisplacementGroups({
    afterDragging: getDraggables(last.all, draggables),
    destination: scrolledDroppable,
    displacedBy: impact.displacedBy,
    viewport: viewport.frame,
    last,
    // we want the addition to be animated
    forceShouldAnimate: false,
  });

  const invisible: DraggableIdMap = {};
  const visible: DisplacementMap = {};
  const groups: DisplacementGroups[] = [
    // this will populate the previous entries with the correct animation values
    last,
    withViewportScroll,
    withDroppableScroll,
  ];

  last.all.forEach((id) => {
    const displacement = tryGetVisible(id, groups);
    if (displacement) {
      visible[id] = displacement;
      return;
    }
    invisible[id] = true;
  });

  const newImpact: DragImpact = {
    ...impact,
    displaced: { all: last.all, invisible, visible },
  };

  return newImpact;
}
