import { forwardRef } from "react";
import type { As, Component, Options, Props } from "swash/types";

import { FieldState } from "./FieldState";

function getAriaLabelProps({
  labelId,
  label,
  "aria-label": ariaLabel,
}: FieldState): { "aria-label"?: string; "aria-labelledby"?: string } {
  if (label) {
    return { "aria-label": label };
  }

  if (ariaLabel) {
    return { "aria-label": ariaLabel };
  }

  if (labelId) {
    return { "aria-labelledby": labelId };
  }

  return {};
}
export type ControlProps<T extends As = "input"> =
  React.ComponentPropsWithRef<T> & {
    "data-field-control": boolean;
  };

export type FieldControlOptions<T extends As = "input"> = Omit<
  Options<T>,
  "children"
> & {
  state: FieldState;
  children?: React.ReactNode | React.FC<ControlProps<T>>;
};

export type FieldControlProps<T extends As = "input"> = Props<
  FieldControlOptions<T>
>;

export const FieldControl = forwardRef<HTMLElement, FieldControlProps<"input">>(
  ({ state, children, as, ...props }, ref) => {
    const invalid = state.field.meta.touched
      ? state.field.meta.invalid
      : undefined;

    const controlProps = {
      ref,
      invalid,
      "aria-invalid": invalid,
      "data-field-control": true,
      id: state.id,
      minLength: state.minLength,
      maxLength: state.maxLength,
      required: state.required,
      disabled: state.disabled,
      ...getAriaLabelProps(state),
      ...state.field.input,
      ...props,
    } as ControlProps;

    // FIXME: I don't know how to type that
    const As = as as any;

    return typeof children === "function" ? (
      children(controlProps)
    ) : (
      <As {...controlProps}>{children}</As>
    );
  },
) as Component<FieldControlOptions<"input">>;
if (process.env["NODE_ENV"] !== "production") {
  (FieldControl as React.FC).displayName = "FieldControl";
}
