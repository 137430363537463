import { gql } from "@apollo/client";
import { Tooltip } from "swash/Tooltip";
import { cn } from "swash/utils/classNames";

import { NamedIcon } from "@/components/NamedIcon";
import { MediaIndicator_mediaDescriptorFragment } from "@/gql-types";

export type MediaDescriptor = MediaIndicator_mediaDescriptorFragment;

type MediaIndicatorProps = {
  media: MediaDescriptor;
  included: boolean;
  suggested: boolean;
};

export const MediaIndicator = ({
  media,
  included,
  suggested,
}: MediaIndicatorProps) => {
  function getSuffix() {
    const parts = [];
    if (suggested) parts.push("suggéré");
    if (included) parts.push("inclus");
    return parts.join(" et ");
  }
  function getLabel() {
    const suffix = getSuffix();
    return [media.label, suffix ? `(${suffix})` : null]
      .filter(Boolean)
      .join(" ");
  }
  return (
    <Tooltip tooltip={getLabel()}>
      <div className="flex">
        <NamedIcon
          name={media.icon}
          aria-label={media.label}
          className={cn(!included && "opacity-disabled")}
        />
      </div>
    </Tooltip>
  );
};

MediaIndicator.fragments = {
  suggestedMedias: gql`
    fragment MediaIndicator_suggestedMedias on ArticleSuggestedMedia {
      media {
        id
        label
        icon
      }
      included
      suggested
    }
  `,
  mediaDescriptor: gql`
    fragment MediaIndicator_mediaDescriptor on MediaDescriptor {
      id
      label
      icon
      enabled
    }
  `,
};
