import { gql } from "@apollo/client";

import { ArticleRemoveButton } from "@/containers/article/ArticleRemoveButton";
import type { CellType } from "@/containers/list/ListState";

export type DeleteCellArticle = {
  id: number;
  firstPublished: string | null;
  lastPublishedHidden: boolean;
  acl: { me: string[] };
};

export const deleteCell: CellType<DeleteCellArticle> = {
  key: "delete",
  title: "Supprimer",
  width: 34,
  render: ({ node }) => {
    return (
      <div className="-my-1 flex">
        <ArticleRemoveButton article={node} />
      </div>
    );
  },
  fragment: gql`
    fragment deleteCell_article on Article {
      ...ArticleRemoveButton_article
    }

    ${ArticleRemoveButton.fragments.article}
  `,
};
