import { html } from "@codemirror/lang-html";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import type * as CodeMirror from "@codemirror/state";
import { useCallback, useMemo } from "react";
import { useField, useForm } from "react-final-form";
import { cn } from "swash/utils/classNames";

import { Editor } from "../common/codemirror/Editor";

type RawEditorFieldProps = {
  name: string;
  extensions?: CodeMirror.Extension[];
  lang?: "html" | "js" | "json";
  saveShortcut?: boolean;
  readOnly?: boolean;
};

export function RawEditorField({
  name,
  extensions: extensionsProps,
  lang,
  saveShortcut,
  readOnly,
}: RawEditorFieldProps) {
  const field = useField(name, {
    parse: (value) => value ?? "",
    format: (value) => value ?? "",
  });

  const handleKeydown = useSaveShortcut(saveShortcut);

  const extensions = useMemo(() => {
    if (extensionsProps) return extensionsProps;
    switch (lang) {
      case "html":
        return [html()];
      case "js":
        return [javascript()];
      case "json":
        return [json()];
    }
    return [];
  }, [extensionsProps, lang]);

  return (
    <Editor
      extensions={extensions}
      {...field.input}
      // @ts-expect-error FIXME: Issue with typings
      onFocus={field.input.onFocus}
      onKeyDown={handleKeydown}
      readOnly={readOnly}
    />
  );
}

type EditorFieldProps = RawEditorFieldProps & {
  header?: React.ReactNode;
  className?: string;
};

export function EditorField({ header, className, ...props }: EditorFieldProps) {
  return (
    <div
      className={cn(
        "flex flex-col border-4 border-grey-border-strong bg-dusk-bg-stronger",
        className,
      )}
    >
      {header && (
        <div className="border-b-1 shrink-0 border-grey-border-strong p-2">
          <h3 className="font-accent text-base font-semibold text-grey-on">
            {header}
          </h3>
        </div>
      )}
      <div className="relative shrink-0 grow">
        <RawEditorField {...props} />
      </div>
    </div>
  );
}

const useSaveShortcut = (active = false) => {
  const form = useForm();
  return useCallback(
    (event: KeyboardEvent) => {
      if (!active) return;
      if (event.key === "s" && (event.metaKey || event.ctrlKey)) {
        event.preventDefault();
        form.submit();
      }
    },
    [active, form],
  );
};
