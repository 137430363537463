import { RawCommands } from "@tiptap/core";
import { TextSelection } from "@tiptap/pm/state";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    moveToNextNodeEnd: {
      /**
       * Move the cursor to the end of next node.
       * @example editor.commands.moveToNextNodeEnd()
       */
      moveToNextNodeEnd: () => ReturnType;
    };
  }
}

export const moveToNextNodeEnd: RawCommands["moveToNextNodeEnd"] =
  () =>
  ({ tr, state, dispatch }) => {
    const { doc, selection } = state;
    const { $from } = selection;

    // Find the position of the next node
    const nextPos = $from.after();

    if (nextPos < doc.content.size) {
      const nextNode = doc.nodeAt(nextPos);
      if (nextNode) {
        // Calculate the position at the end of the next node
        const endPos = nextPos + nextNode.nodeSize - 1;
        // Move the selection to the end of the next node
        const newSelection = TextSelection.create(doc, endPos);
        if (dispatch) dispatch(tr.setSelection(newSelection).scrollIntoView());
        return true;
      }
    }

    return false;
  };
