import type { Position } from "css-box-model";

import { timings } from "../../../animation";
import { distance as getDistance } from "../../../services/position";
import type { DropReason } from "../../../types";

const { minDropTime, maxDropTime } = timings;
const dropTimeRange = maxDropTime - minDropTime;
const maxDropTimeAtDistance = 1500;
// will bring a time lower - which makes it faster
const cancelDropModifier = 0.6;

export default ({
  current,
  destination,
  reason,
}: {
  current: Position;
  destination: Position;
  reason: DropReason;
}): number => {
  const distance = getDistance(current, destination);
  // even if there is no distance to travel, we might still need to animate opacity
  if (distance <= 0) return minDropTime;
  if (distance >= maxDropTimeAtDistance) return maxDropTime;

  // * range from:
  // 0px = 0.33s
  // 1500px and over = 0.55s
  // * If reason === 'CANCEL' then speeding up the animation
  // * round to 2 decimal points

  const percentage = distance / maxDropTimeAtDistance;
  const duration = minDropTime + dropTimeRange * percentage;

  const withDuration =
    reason === "CANCEL" ? duration * cancelDropModifier : duration;
  // To two decimal points by converting to string and back
  return Number(withDuration.toFixed(2));
};
