import { Position, Rect, Spacing } from "css-box-model";

import type { Axis } from "../../../types";
import { horizontal, vertical } from "../../axis";
import { apply, isEqual, origin } from "../../position";

const config = {
  // percentage distance from edge of container:
  startFromPercentage: 0.25,
  maxScrollAtPercentage: 0.05,
  // pixels per frame
  maxPixelScroll: 28,

  // A function used to ease a percentage value
  // A simple linear function would be: (percentage) => percentage;
  // percentage is between 0 and 1
  // result must be between 0 and 1
  ease: (percentage: number): number => Math.pow(percentage, 2),
} as const;

// ms: how long to dampen the speed of an auto scroll from the start of a drag
const accelerateAt = 1200;
// ms: when to start accelerating the reduction of duration dampening
const stopAt = 360;
// A scroll event will only be triggered when there is a value of at least 1px change
const minScroll = 1;

// will replace -0 and replace with +0
const clean = apply((value: number) => (value === 0 ? 0 : value));

export const getScroll = ({
  dragStartTime,
  container,
  subject,
  center,
  shouldUseTimeDampening,
}: {
  dragStartTime: number;
  container: Rect;
  subject: Rect;
  center: Position;
  shouldUseTimeDampening: boolean;
}): Position | null => {
  // get distance to each edge
  const distanceToEdges: Spacing = {
    top: center.y - container.top,
    right: container.right - center.x,
    bottom: container.bottom - center.y,
    left: center.x - container.left,
  };

  // 1. Figure out which x,y values are the best target
  // 2. Can the container scroll in that direction at all?
  // If no for both directions, then return null
  // 3. Is the center close enough to a edge to start a drag?
  // 4. Based on the distance, calculate the speed at which a scroll should occur
  // The lower distance value the faster the scroll should be.
  // Maximum speed value should be hit before the distance is 0
  // Negative values to not continue to increase the speed
  const y = getScrollOnAxis({
    container,
    distanceToEdges,
    dragStartTime,
    axis: vertical,
    shouldUseTimeDampening,
  });
  const x = getScrollOnAxis({
    container,
    distanceToEdges,
    dragStartTime,
    axis: horizontal,
    shouldUseTimeDampening,
  });

  const required = clean({ x, y });

  // nothing required
  if (isEqual(required, origin)) return null;

  // need to not scroll in a direction that we are too big to scroll in
  const limited = adjustForSizeLimits({
    container,
    subject,
    proposedScroll: required,
  });

  if (!limited) return null;

  return isEqual(limited, origin) ? null : limited;
};

const adjustForSizeLimits = ({
  container,
  subject,
  proposedScroll,
}: {
  container: Rect;
  subject: Rect;
  proposedScroll: Position;
}): Position | null => {
  const isTooBigVertically = subject.height > container.height;
  const isTooBigHorizontally = subject.width > container.width;

  // not too big on any axis
  if (!isTooBigHorizontally && !isTooBigVertically) return proposedScroll;

  // too big on both axis
  if (isTooBigHorizontally && isTooBigVertically) return null;

  // Only too big on one axis
  // Exclude the axis that we cannot scroll on
  return {
    x: isTooBigHorizontally ? 0 : proposedScroll.x,
    y: isTooBigVertically ? 0 : proposedScroll.y,
  };
};

const getScrollOnAxis = ({
  container,
  distanceToEdges,
  dragStartTime,
  axis,
  shouldUseTimeDampening,
}: {
  container: Rect;
  distanceToEdges: Spacing;
  dragStartTime: number;
  axis: Axis;
  shouldUseTimeDampening: boolean;
}): number => {
  const thresholds = getDistanceThresholds(container, axis);
  const isCloserToEnd = distanceToEdges[axis.end] < distanceToEdges[axis.start];

  if (isCloserToEnd) {
    return getValue({
      distanceToEdge: distanceToEdges[axis.end],
      thresholds,
      dragStartTime,
      shouldUseTimeDampening,
    });
  }

  return (
    -1 *
    getValue({
      distanceToEdge: distanceToEdges[axis.start],
      thresholds,
      dragStartTime,
      shouldUseTimeDampening,
    })
  );
};

const dampenValueByTime = (
  proposedScroll: number,
  dragStartTime: number,
): number => {
  const startOfRange = dragStartTime;
  const endOfRange = stopAt;
  const now = Date.now();
  const runTime = now - startOfRange;

  // we have finished the time dampening period
  if (runTime >= stopAt) return proposedScroll;

  // Up to this point we know there is a proposed scroll
  // but we have not reached our accelerate point
  // Return the minimum amount of scroll
  if (runTime < accelerateAt) return minScroll;

  const betweenAccelerateAtAndStopAtPercentage = getPercentage({
    startOfRange: accelerateAt,
    endOfRange,
    current: runTime,
  });

  const scroll =
    proposedScroll * config.ease(betweenAccelerateAtAndStopAtPercentage);

  return Math.ceil(scroll);
};

// all in pixels
type DistanceThresholds = {
  startScrollingFrom: number;
  maxScrollValueAt: number;
};

// converts the percentages in the config into actual pixel values
const getDistanceThresholds = (
  container: Rect,
  axis: Axis,
): DistanceThresholds => ({
  startScrollingFrom: container[axis.size] * config.startFromPercentage,
  maxScrollValueAt: container[axis.size] * config.maxScrollAtPercentage,
});

const getValueFromDistance = (
  distanceToEdge: number,
  thresholds: DistanceThresholds,
): number => {
  /**
   * This function only looks at the distance to one edge
   * Example: looking at bottom edge
   * |----------------------------------|
   * |                                  |
   * |                                  |
   * |                                  |
   * |                                  |
   * |                                  | => no scroll in this range
   * |                                  |
   * |                                  |
   * |  startScrollingFrom (eg 100px)   |
   * |                                  |
   * |                                  | => increased scroll value the closer to maxScrollValueAt
   * |  maxScrollValueAt (eg 10px)      |
   * |                                  | => max scroll value in this range
   * |----------------------------------|
   */

  // too far away to auto scroll
  if (distanceToEdge > thresholds.startScrollingFrom) return 0;

  // use max speed when on or over boundary
  if (distanceToEdge <= thresholds.maxScrollValueAt)
    return config.maxPixelScroll;

  // when just going on the boundary return the minimum integer
  if (distanceToEdge === thresholds.startScrollingFrom) return minScroll;

  // to get the % past startScrollingFrom we will calculate
  // the % the value is from maxScrollValueAt and then invert it
  const percentageFromMaxScrollValueAt = getPercentage({
    startOfRange: thresholds.maxScrollValueAt,
    endOfRange: thresholds.startScrollingFrom,
    current: distanceToEdge,
  });

  const percentageFromStartScrollingFrom = 1 - percentageFromMaxScrollValueAt;

  const scroll =
    config.maxPixelScroll * config.ease(percentageFromStartScrollingFrom);

  // scroll will always be a positive integer
  return Math.ceil(scroll);
};

const getValue = ({
  distanceToEdge,
  thresholds,
  dragStartTime,
  shouldUseTimeDampening,
}: {
  distanceToEdge: number;
  thresholds: DistanceThresholds;
  dragStartTime: number;
  shouldUseTimeDampening: boolean;
}): number => {
  const scroll = getValueFromDistance(distanceToEdge, thresholds);

  // not enough distance to trigger a minimum scroll
  // we can bail here
  if (scroll === 0) return 0;

  // Dampen an auto scroll speed based on duration of drag
  if (!shouldUseTimeDampening) return scroll;

  // Once we know an auto scroll should occur based on distance,
  // we must let at least 1px through to trigger a scroll event an
  // another auto scroll call
  return Math.max(dampenValueByTime(scroll, dragStartTime), minScroll);
};

const getPercentage = ({
  startOfRange,
  endOfRange,
  current,
}: {
  startOfRange: number;
  endOfRange: number;
  current: number;
}): number => {
  const range = endOfRange - startOfRange;
  if (range === 0) return 0;
  const currentInRange = current - startOfRange;
  return currentInRange / range;
};
