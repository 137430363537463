import { gql } from "@apollo/client";
import { WorkflowStateCorrected } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { Time } from "@/components/Time";

type LivePostCorrectedProps = {
  post: {
    corrected: boolean;
    correctedAt: string;
    correctedBy: { fullName: string };
  };
};
export const LivePostCorrected = ({ post }: LivePostCorrectedProps) => {
  if (!post.corrected) return null;
  return (
    <Tooltip
      tooltip={
        <>
          Corrigé le{" "}
          <Time date={post.correctedAt} format="dd/MM/yyyy à HH:mm" />{" "}
          {`par ${post.correctedBy.fullName}`}
        </>
      }
    >
      <WorkflowStateCorrected className="shrink-0 text-grey-on" />
    </Tooltip>
  );
};

LivePostCorrected.fragments = {
  post: gql`
    fragment LivePostCorrected_post on Post {
      corrected
      correctedAt
      correctedBy {
        id
        fullName
      }
    }
  `,
};
