import { TypedDocumentNode, gql } from "@apollo/client";

import { SelectOptionLabelAuthor } from "@/components/SelectOptionLabelAuthor";
import { SignaturesField_signaturesFragment } from "@/gql-types";

export const AuthorFragment = gql`
  fragment SignaturesField_author on Author {
    id
    origin
    ...SelectOptionLabelAuthor_author
  }

  ${SelectOptionLabelAuthor.fragments.author}
`;

export const SignaturesFieldFragment: TypedDocumentNode<SignaturesField_signaturesFragment> = gql`
  fragment SignaturesField_signatures on Signature {
    id
    origin
    author {
      ...SignaturesField_author
    }
  }

  ${AuthorFragment}
`;
