import { Editor } from "@tiptap/react";
import { ReactNode, forwardRef, useEffect, useState } from "react";

import { Button } from "../../Button";
import { EllipsisVertical } from "../../Icon";
import { Menu, MenuButton, MenuProvider } from "../../Menu";
import {
  FixedMenuPlugin,
  FixedMenuPluginProps,
} from "../extensions/fixed-menu";
import { useEditorContext } from "../index";

const pluginKey = "fixedMenu";

export const FixedMenu = forwardRef<HTMLDivElement, FixedMenuProps>(
  ({ editor, children, hideOnBlur, ...props }, ref) => {
    const { editor: currentEditor } = useEditorContext();
    editor = editor || currentEditor;

    const [element, setElement] = useState<HTMLDivElement | null>(null);
    useEffect(() => {
      if (!element) {
        return;
      }

      if (!editor || editor?.isDestroyed) {
        return;
      }

      const plugin = FixedMenuPlugin({
        editor,
        element,
        pluginKey,
        hideOnBlur,
      });

      editor.registerPlugin(plugin);
      return () => {
        editor.unregisterPlugin(pluginKey);
      };
    }, [editor, element, hideOnBlur]);

    return (
      <div
        ref={setElement}
        {...props}
        onMouseDown={(event) => event.stopPropagation()}
      >
        <MenuProvider>
          <MenuButton
            render={
              <Button
                scale="xs"
                iconOnly
                variant="secondary"
                appearance="text"
              />
            }
            ref={ref}
            className="fixed-menu-button absolute right-1 top-1 z-10"
          >
            <EllipsisVertical />
            <span className="sr-only">More options</span>
          </MenuButton>
          <Menu
            unmountOnHide
            portal
            // hide menu when an item is expanded (e.g. special characters)
            className="has-[[aria-expanded='true']]:hidden"
            onClose={() => {
              //focus back to editor when menu is hidden
              editor?.commands.focus();
              return true;
            }}
          >
            {children}
          </Menu>
        </MenuProvider>
      </div>
    );
  },
);

interface FixedMenuProps
  extends Omit<Partial<FixedMenuPluginProps>, "pluginKey" | "editor"> {
  editor?: Editor | null;
  children: ReactNode;
}
