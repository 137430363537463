/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { gql, useMutation } from "@apollo/client";
import { forwardRef, memo, useCallback, useMemo, useState } from "react";
import { Clickable } from "swash/Clickable";
import { SelectButton, SelectPopover } from "swash/Select";
import { useToaster } from "swash/Toast";
import { useEventCallback } from "swash/utils/useEventCallback";

import { ExposureIndicator } from "@/containers/ExposureIndicator";
import { useHasPermission } from "@/containers/User";

import {
  ArticleExposure,
  ArticleExposuresSelectList,
  getArticleExposure,
  useArticleExposuresSelectState,
} from "./ArticleExposureSelect";

type ArticleExposuresButton = {
  articleExposures: ArticleExposure[];
  onFocus?: () => void;
  onMouseEnter?: () => void;
};

const ArticleExposuresList = ({
  articleExposures,
}: {
  articleExposures: ArticleExposure[];
}) => {
  if (articleExposures.length === 0) {
    return <div className="text-sm text-grey-on">Aucune</div>;
  }
  return (
    <div className="flex select-none flex-wrap items-start gap-2 py-0.5 text-lg">
      {articleExposures.map((articleExposure) => (
        <ExposureIndicator
          key={articleExposure.gid}
          articleExposure={articleExposure}
          article={articleExposure.article}
          exposure={articleExposure.exposure}
        />
      ))}
    </div>
  );
};

const ArticleExposuresButton = memo(
  forwardRef<HTMLButtonElement, ArticleExposuresButton>(
    ({ articleExposures, ...props }, ref) => {
      return (
        <Clickable
          ref={ref}
          role="button"
          tabIndex={0}
          className="flex h-full items-start p-1"
          {...props}
        >
          <ArticleExposuresList articleExposures={articleExposures} />
        </Clickable>
      );
    },
  ),
);

export const filterArticleExposures = (articleExposures: ArticleExposure[]) => {
  return articleExposures.filter(
    (articleExposure) =>
      articleExposure.suggested ||
      articleExposure.fulfilled ||
      articleExposure.selected,
  );
};

export const Mutation = gql`
  mutation ArticleExposuresSelect_updateArticle(
    $articleId: Int!
    $articleExposures: [ArticleExposureInput!]!
  ) {
    updateArticle(
      input: { id: $articleId, articleExposures: $articleExposures }
    ) {
      id
      ...ArticleExposuresSelect_article
    }
  }
  ${useArticleExposuresSelectState.fragments.article}
`;

export const ArticleExposuresSelector = (props: ArticleExposuresEditProps) => {
  const [updateArticleExposures] = useMutation(Mutation);
  const toaster = useToaster();

  const definedArticleExposures = useMemo(() => {
    return filterArticleExposures(props.article.articleExposures.nodes);
  }, [props.article.articleExposures.nodes]);

  const onChange = useCallback(
    (value: ArticleExposure[]) => {
      updateArticleExposures({
        variables: {
          articleId: props.article.id,
          articleExposures: value.map((articleExposure) => ({
            exposureId: articleExposure.exposure.id,
          })),
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateArticle: {
            ...props.article,
            articleExposures: {
              ...props.article.articleExposures,
              nodes: props.article.articleExposures.nodes.map(
                ({ exposure: { id: nodeExposureId } }) => {
                  const articleExposure = getArticleExposure(
                    props.article.articleExposures.nodes,
                    nodeExposureId,
                  );
                  return {
                    ...articleExposure,
                    suggested: value.some(
                      ({ exposure: { id: selectedExposureId } }) =>
                        selectedExposureId === nodeExposureId,
                    ),
                    fulfilled: articleExposure?.fulfilled ?? false,
                  };
                },
              ),
            },
          },
        },
      }).catch(() => {
        toaster.danger("La mise à jour de l’exposition a échoué");
      });
    },
    [props.article, toaster, updateArticleExposures],
  );

  const state = useArticleExposuresSelectState({
    articleId: props.article.id,
    value: definedArticleExposures,
    onChange,
  });

  return (
    <span onClick={(event) => event.stopPropagation()}>
      <SelectButton asChild store={state.select}>
        <ArticleExposuresButton articleExposures={definedArticleExposures} />
      </SelectButton>
      <SelectPopover store={state.select} modal>
        <ArticleExposuresSelectList state={state} />
      </SelectPopover>
    </span>
  );
};

export type ArticleExposuresEditProps = {
  article: {
    id: number;
    articleExposures: {
      nodes: ArticleExposure[];
    };
  };
};

export const ArticleExposuresEdit = (props: ArticleExposuresEditProps) => {
  const [active, setActive] = useState(false);
  const activate = useEventCallback(() => setActive(true));

  const canView = useHasPermission(
    ["exposures:view", "exposures:edit", "exposures:full"],
    {
      method: "some",
    },
  );
  const canEdit = useHasPermission(["exposures:edit", "exposures:full"], {
    method: "some",
  });

  if (!canView) {
    return null;
  }

  const articleExposures = filterArticleExposures(
    props.article.articleExposures.nodes,
  );

  if (!canEdit)
    return <ArticleExposuresList articleExposures={articleExposures} />;

  if (active) {
    return <ArticleExposuresSelector article={props.article} />;
  }

  return (
    <ArticleExposuresButton
      articleExposures={articleExposures}
      onFocus={activate}
      onMouseEnter={activate}
    />
  );
};

ArticleExposuresEdit.fragments = {
  article: gql`
    fragment ArticleExposuresEdit_article on Article {
      id
      ...ArticleExposuresSelect_article
    }
    ${useArticleExposuresSelectState.fragments.article}
  `,
};
