import { useEditorState } from "@tiptap/react";
import { Button } from "swash/Button";
import { IoAddCircleOutline } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";
import { useEditorContext } from "swash/editor";
import { cn } from "swash/utils/classNames";

export function AddLineButton({ className }: { className?: string }) {
  const { editor } = useEditorContext();
  const editable = useEditorState({
    editor,
    selector: ({ editor }) => editor?.isEditable ?? false,
  });

  const label = "Ajouter un bloc";

  if (!editable) return null;

  return (
    <section
      className={cn(
        "add-line-container group/add-line-container text-center",
        className,
      )}
    >
      <Tooltip tooltip={label}>
        <Button
          iconOnly
          appearance="text"
          className="opacity-0 transition group-hover/add-line-container:opacity-100"
          aria-label={label}
          onClick={(event) => {
            if (!editor) return;
            event.preventDefault();
            editor
              .chain()
              .insertContentAt(editor.$doc.to - 1, { type: "paragraph" })
              .focus()
              .run();
          }}
        >
          <IoAddCircleOutline />
        </Button>
      </Tooltip>
    </section>
  );
}
