import styled from "@xstyled/styled-components";
import * as React from "react";
import { Tooltip } from "swash/Tooltip";

import { ControlButton } from "./ControlButton";

export const ControlToolbar = styled.box`
  display: flex;
  flex-wrap: wrap;
  column-gap: 1;
  row-gap: 2;
  height: 28;
`;

export const ControlToolbarItem = React.forwardRef(
  ({ label, shortcut, disabledTooltipLabel, children, ...props }, ref) => {
    const tooltip =
      !props.as && (label || shortcut) && !props.disabled ? (
        <div className="flex flex-col items-center gap-1">
          {label && <div>{label}</div>}
          {shortcut && (
            <div className="mb-1 text-xs text-grey-300">{shortcut}</div>
          )}
        </div>
      ) : props.disabled ? (
        disabledTooltipLabel
      ) : null;
    return (
      <Tooltip tooltip={tooltip}>
        <ControlButton ref={ref} aria-label={label} {...props}>
          {children}
        </ControlButton>
      </Tooltip>
    );
  },
);

export const ControlToolbarSeparator = styled.hrBox`
  border-style: solid;
  border-color: layout-border;
  border-width: 0 1px 0 0;
  margin: 0;
  height: 28;
`;
