import { createContext } from "react";

import type { DroppableId, TypeId } from "../../types";

export type DroppableContextValue = {
  droppableId: DroppableId;
  type: TypeId;
};

export default createContext<DroppableContextValue | null>(null);
