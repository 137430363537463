import { mergeAttributes } from "@tiptap/core";
import { Link as TipTapLink, isAllowedUri } from "@tiptap/extension-link";
import { Plugin, PluginKey } from "@tiptap/pm/state";

export const Link = TipTapLink.extend({
  addAttributes() {
    return {
      href: {
        default: null,
        parseHTML(element) {
          return element.getAttribute("href");
        },
      },
      affiliate: {
        default: false,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    // prevent XSS attacks
    if (
      !this.options.isAllowedUri(HTMLAttributes.href, {
        defaultValidate: (href) => !!isAllowedUri(href, this.options.protocols),
        protocols: this.options.protocols,
        defaultProtocol: this.options.defaultProtocol,
      })
    ) {
      // strip out the href
      return [
        "a",
        mergeAttributes(
          this.options.HTMLAttributes,
          {
            ...HTMLAttributes,
            href: "",
          },
          {
            "data-affiliate": HTMLAttributes.affiliate ? "" : undefined,
          },
        ),
        0,
      ];
    }

    return [
      "a",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        "data-affiliate": HTMLAttributes.affiliate ? "" : undefined,
      }),
      0,
    ];
  },

  addKeyboardShortcuts() {
    return {
      "Mod-k": () => this.editor.commands.toggleLink({ href: "" }),
    };
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey(this.name),
        props: {
          handleDOMEvents: {
            click: (view, event) => {
              const isEditable = view.editable;
              const target = event.target as HTMLElement;

              const linkElement = target.closest("a");
              if (isEditable && linkElement === target) {
                event.preventDefault(); // Disable click when editable
                return true;
              }

              return false;
            },
          },
        },
      }),
    ];
  },
});
