import { TypedDocumentNode, gql } from "@apollo/client";
import { createContext, useContext } from "react";

import { Image } from "@/components/Image";
import { RemoteConfigQuery as RemoteConfigQueryResult } from "@/gql-types";

import { useSafeQuery } from "./Apollo";

export type Clients =
  | "local"
  | "lemonde"
  | "lemonde-en"
  | "lobs"
  | "telerama"
  | "courrier"
  | "letemps"
  | "huffpost"
  | "linforme"
  | "nicematin";

export type RemoteConfig = Omit<
  RemoteConfigQueryResult["config"],
  "clientId"
> & { clientId: Clients };

export const RemoteConfigContext = createContext<RemoteConfig | null>(null);

export function useRemoteConfig() {
  const config = useContext(RemoteConfigContext);
  if (!config) {
    throw Error(
      "useRemoteConfig requires RemoteConfigInitializer to be used higher in the component tree",
    );
  }
  return config;
}

const RemoteConfigQuery: TypedDocumentNode<RemoteConfigQueryResult> = gql`
  query RemoteConfig {
    config {
      env
      clientId
      internalDomains
      webNotification
      version
      images
      services
      files {
        accept
      }
      clientImage {
        id
        fixed(width: 32, height: 32) {
          ...Image_fixed
        }
      }
      homeTags
      features
      articles
      browserslistWarning
      frontBaseUrl
      brandColor
      zendesk {
        enabled
      }
      statusPage {
        enabled
      }
      amplitude {
        apiKey
      }
      facebook {
        appId
        redirectUri
      }
      publicationSlots
      editorConfig {
        plugins {
          name
        }
      }
      beamer {
        enabled
        productId
      }
      legacyPubSub {
        topic
        subscription
      }
      slack {
        clientId
        redirectUri
        scopes
      }
      textToSpeech {
        enabled
      }
      forecast {
        enabled
        insights {
          conversions
          tunnelAccess
        }
      }
      adminPermissions
      defaultLanguage
      hasPrintSystem
      webPush {
        publicKey
      }
      homeTeaserContext {
        id
        key
      }
      genai {
        enabled
      }
      videos {
        searchProvider
      }
      hocuspocus {
        collaborationUrl
      }
    }
  }
  ${Image.fragments.fixed}
`;

export function RemoteConfigInitializer(props: { children: React.ReactNode }) {
  const { data } = useSafeQuery(RemoteConfigQuery);
  if (!data) return null;

  return (
    <RemoteConfigContext.Provider value={data.config as RemoteConfig}>
      {props.children}
    </RemoteConfigContext.Provider>
  );
}
