import { useMemo } from "react";
import { useStorage } from "swash/utils/useStorage";

import { CHARACTERS, SpecialCharacter } from "./characters";

const HISTORY_MAX_LENGTH = 200;
const MAX_FREQUENTLY_USED = 30;

const CHARACTERS_BY_CHAR = CHARACTERS.flatMap(
  (group) => group.characters,
).reduce<{
  [char: string]: SpecialCharacter;
}>((indexed, character) => {
  indexed[character.char] = character;
  return indexed;
}, {});

export const useFrequentlyUsed = (): [
  frequentlyUsed: SpecialCharacter[],
  registerUse: (character: SpecialCharacter) => void,
] => {
  const [history, setHistory] = useStorage(
    "editor-special-characters-history",
    [] as SpecialCharacter[],
  );

  const frequentlyUsed = useMemo(() => {
    const stats = history.reduce<{ [char: string]: number }>(
      (stats, character) => {
        stats[character.char] = (stats[character.char] ?? 0) + 1;
        return stats;
      },
      {},
    );
    return Object.entries(stats)
      .sort(([, aCount], [, bCount]) => bCount - aCount)
      .map(([char]) => CHARACTERS_BY_CHAR[char])
      .filter((char): char is SpecialCharacter => char != null)
      .slice(0, MAX_FREQUENTLY_USED);
  }, [history]);

  const registerUse = (character: SpecialCharacter) => {
    setHistory((history) =>
      [character, ...history].slice(0, HISTORY_MAX_LENGTH),
    );
  };

  return [frequentlyUsed, registerUse];
};
