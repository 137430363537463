import { gql } from "@apollo/client";

import { UserAvatar } from "@/containers/user/UserAvatar";
import { UserHoverCard } from "@/containers/user/UserHoverCard";
import { UserOptionLabel_userFragment } from "@/gql-types";

export const UserOptionLabel = ({ user, size = 16 }: UserOptionLabelProps) => {
  return (
    <UserHoverCard user={user} placement="left" gutter={30}>
      <div className="flex w-full items-center gap-2">
        <UserAvatar user={user} size={size} /> {user.fullName}
      </div>
    </UserHoverCard>
  );
};

interface UserOptionLabelProps {
  user: UserOptionLabel_userFragment;
  size?: number;
}

UserOptionLabel.fragments = {
  user: gql`
    fragment UserOptionLabel_user on User {
      fullName
      ...UserHoverCard_user
      ...UserAvatar_user
    }
    ${UserHoverCard.fragments.user}
    ${UserAvatar.fragments.user}
  `,
};
