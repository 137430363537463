import { gql } from "@apollo/client";
import { useMediaQuery } from "react-responsive";

import { mediaQueries } from "@/config/mediaQueries";
import {
  ArticleSuggestedMediasEdit,
  ArticleSuggestedMediasEditProps,
} from "@/containers/article/ArticleSuggestedMediasEdit";
import { useListReadOnly } from "@/containers/list/List";
import type { CellType } from "@/containers/list/ListState";

export type MediasCellArticle = ArticleSuggestedMediasEditProps["article"];

export const mediasCell: CellType<MediasCellArticle> = {
  key: "medias",
  title: "Médias",
  width: 80,
  cellPadding: "0.25rem 0",
  render: ({ node }) => <ArticleSuggestedMediasEdit article={node} />,
  useIsVisible: () => {
    const matchesMediaQuery = useMediaQuery({
      query: mediaQueries.desktop.list.md,
    });
    const listReadOnly = useListReadOnly();
    return !matchesMediaQuery || listReadOnly;
  },
  fragment: gql`
    fragment mediasCell_article on Article {
      ...ArticleSuggestedMediasEdit_article
    }

    ${ArticleSuggestedMediasEdit.fragments.article}
  `,
};
