import { TypedDocumentNode, gql } from "@apollo/client";
import { NamedExoticComponent, memo } from "react";

import { SelectOptionLabelAuthor_authorFragment } from "@/gql-types";

import { AuthorAvatar } from "./AuthorAvatar";

interface SelectOptionLabelAuthorProps {
  author: SelectOptionLabelAuthor_authorFragment;
}

export const SelectOptionLabelAuthor = memo(function SelectOptionLabelAuthor({
  author,
}: SelectOptionLabelAuthorProps) {
  return (
    <>
      <AuthorAvatar author={author} />
      <span>
        {author.longName}
        {author.shortName && ` (${author.shortName})`}
      </span>
    </>
  );
}) as NamedExoticComponent<SelectOptionLabelAuthorProps> & {
  fragments: {
    author: TypedDocumentNode<SelectOptionLabelAuthor_authorFragment>;
  };
};

SelectOptionLabelAuthor.fragments = {
  author: gql`
    fragment SelectOptionLabelAuthor_author on Author {
      ...AuthorAvatar_author
      longName
      shortName
    }

    ${AuthorAvatar.fragments.author}
  `,
};
