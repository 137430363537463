import { Position } from "css-box-model";
import memoizeOne from "memoize-one";

import { invariant } from "../../../invariant";
import type {
  DroppableDimension,
  DroppableDimensionMap,
  DroppableId,
} from "../../../types";
import { toDroppableList } from "../../dimension-structures";
import isPositionInFrame from "../../visibility/is-position-in-frame";

const getScrollableDroppables = memoizeOne(
  (droppables: DroppableDimensionMap): DroppableDimension[] =>
    toDroppableList(droppables).filter((droppable) => {
      // exclude disabled droppables
      if (!droppable.isEnabled) return false;

      // only want droppables that are scrollable
      if (!droppable.frame) return false;

      return true;
    }),
);

const getScrollableDroppableOver = (
  target: Position,
  droppables: DroppableDimensionMap,
): DroppableDimension | null => {
  const maybe = getScrollableDroppables(droppables).find((droppable) => {
    invariant(droppable.frame, "Invalid result");
    return isPositionInFrame(droppable.frame!.pageMarginBox)(target);
  });

  return maybe ?? null;
};

export default ({
  center,
  destination,
  droppables,
}: {
  center: Position;
  destination: DroppableId | null;
  droppables: DroppableDimensionMap;
}): DroppableDimension | null => {
  // We need to scroll the best droppable frame we can so that the
  // placeholder buffer logic works correctly
  if (destination) {
    const dimension = droppables[destination]!;
    if (!dimension.frame) return null;
    return dimension;
  }

  // If we are not over a droppable - are we over a droppable frame?
  return getScrollableDroppableOver(center, droppables);
};
