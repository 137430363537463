import { Extension } from "@tiptap/core";
import { Node as ProsemirrorNode } from "@tiptap/pm/model";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { Decoration, DecorationSet } from "@tiptap/pm/view";

import { findNodePos } from "../../helpers/findNodePos";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    hoveredBlock: {
      /** Set the current node to be displayed as hovered */
      setHoveredBlock: (node: ProsemirrorNode | null) => ReturnType;
    };
  }
}

const TransactionMetaKey = "SetHoveredBlock";

export const HoveredBlockExtension = Extension.create<never>({
  name: "hoveredBlock",

  addCommands() {
    return {
      setHoveredBlock:
        (node) =>
        ({ commands }) =>
          commands.setMeta(TransactionMetaKey, { node }),
    };
  },

  addProseMirrorPlugins() {
    const editor = this.editor;

    return [
      new Plugin({
        key: new PluginKey("hoveredBlock"),

        state: {
          init: () => {
            return { node: null as ProsemirrorNode | null };
          },
          apply: (tr, current) => {
            const meta = tr.getMeta(TransactionMetaKey);
            if (!meta) return current;

            return { node: meta.node };
          },
        },

        props: {
          decorations(state) {
            const active = editor.isEditable;
            if (!active) return null;

            const decorations: Decoration[] = [];

            const { node } = this.getState(state) ?? { node: null };
            if (node) {
              const pos = findNodePos(state.doc, node);
              decorations.push(
                Decoration.node(pos, pos + node.nodeSize, {
                  class: "is-hovered",
                }),
              );
            }

            return DecorationSet.create(state.doc, decorations);
          },
        },
      }),
    ];
  },
});
