import { makeIcon } from "./utils";

export const SvgImage = makeIcon(
  "SvgImage",
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.25 5.375H6.75c-.663 0-1.298.251-1.767.696A2.319 2.319 0 0 0 4.25 7.75v9.5c0 .63.264 1.233.733 1.679a2.57 2.57 0 0 0 1.767.696h12.5c.663 0 1.298-.251 1.767-.696a2.319 2.319 0 0 0 .733-1.679v-9.5c0-.63-.264-1.233-.733-1.679a2.571 2.571 0 0 0-1.767-.696ZM16.125 7.75c.37 0 .733.104 1.042.3.308.196.548.474.69.8a1.7 1.7 0 0 1 .107 1.029 1.756 1.756 0 0 1-.513.912c-.262.249-.596.418-.96.487s-.74.034-1.083-.101a1.858 1.858 0 0 1-.842-.656 1.72 1.72 0 0 1-.316-.99c0-.472.198-.925.55-1.259a1.928 1.928 0 0 1 1.325-.522ZM6.75 18.438c-.332 0-.65-.126-.884-.348a1.158 1.158 0 0 1-.366-.84v-2.51l3.705-3.128a1.937 1.937 0 0 1 1.3-.448c.477.013.931.198 1.27.518l2.537 2.405-4.579 4.35H6.75ZM20.5 17.25c0 .315-.132.617-.366.84a1.284 1.284 0 0 1-.884.348h-7.749l4.743-4.506c.336-.272.762-.421 1.203-.422.44-.001.868.146 1.205.416l1.848 1.463v1.861Z"
      fill="currentColor"
    />
  </svg>,
);
