import { useApolloClient } from "@apollo/client";
import { memo, useCallback, useMemo } from "react";
import { useField } from "react-final-form";
import { useStoreState } from "swash/utils/useStoreState";
import { useComboboxStore } from "swash/v2/Combobox";
import { RemoteSelect, useRemoteSelectState } from "swash/v2/RemoteSelect";

import { CapsuleList } from "@/components/CapsuleList";
import { ModernCapsuleCounter } from "@/components/ModernCapsuleCounter";
import { ModernCapsuleLimit } from "@/components/ModernCapsuleLimit";
import {
  SimpleSortable,
  getIndices,
  useSimpleSortable,
} from "@/components/SimpleSortable";
import { useSafeQuery } from "@/containers/Apollo";
import { moveItems } from "@/services/utils";

const ContentItem = ({
  item,
  limit = 10,
  labelSelector,
  onRemove,
  disabled,
  multi,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    style,
    isDragging,
    newIndex: dragIndex,
  } = useSimpleSortable({
    id: item.id,
    disabled,
  });

  const dragLimit = useMemo(() => {
    if (isDragging) return null;
    if (dragIndex === limit) return { position: "top" };
    if (dragIndex === limit - 1) return { position: "bottom" };
    return null;
  }, [isDragging, dragIndex, limit]);

  if (!item) return null;

  return (
    <div
      ref={setNodeRef}
      className="group relative mb-4 flex items-center gap-2 outline-0 first:mt-2"
      data-testid="draggable-capsule"
      data-dragging={isDragging}
      {...attributes}
      {...listeners}
      style={style}
    >
      {multi && (
        <ModernCapsuleCounter disabled={disabled} dragIndicator>
          {dragIndex + 1}
        </ModernCapsuleCounter>
      )}
      {labelSelector({ item, onRemove, disabled, dragIndex })}
      {dragLimit && <ModernCapsuleLimit position={dragLimit.position} />}
    </div>
  );
};

const DraggableCapsuleList = memo(
  ({
    disabled, //
    limit,
    onRemove,
    onDragEnd,
    labelSelector,
    multi,
    items,
  }) => {
    if (!items.length) return null;
    return (
      <SimpleSortable
        items={items}
        direction="vertical"
        onDragEnd={onDragEnd}
        withDragOverlay
      >
        <CapsuleList aria-disabled={disabled} data-testid="droppable">
          {items.map((item) => (
            <ContentItem
              key={item.id}
              item={item}
              onRemove={onRemove}
              disabled={disabled}
              limit={limit}
              labelSelector={labelSelector}
              multi={multi}
            />
          ))}
        </CapsuleList>
      </SimpleSortable>
    );
  },
);

const useResourceSelectState = ({
  name,
  onChange,
  value,
  label,
  query,
  searchVariables,
  model,
  fragment,
  labelElementSelector,
}) => {
  const client = useApolloClient();

  const combobox = useComboboxStore();
  const search = useStoreState(combobox, "value");

  const field = useField(name);
  const { onChange: onInputChange } = field.input;

  const { data } = useSafeQuery(query, {
    variables: {
      limit: 20,
      filters: {
        search,
        ...searchVariables,
      },
    },
  });

  const items = useMemo(
    () =>
      data?.connection.nodes.filter(
        (item) => !value.map(({ id }) => id).includes(item.id),
      ) ?? [],
    [data, value],
  );

  const remoteData = useMemo(
    () => ({
      items,
      hasMore: false,
      totalCount: items.length,
    }),
    [items],
  );

  const select = useRemoteSelectState({
    title: label,
    data: remoteData,
    combobox,
    value: null,
    onChange: (item) => {
      if (!item) {
        onInputChange(null);
        return;
      }
      onChange([...value, { id: item.id }]);
    },
    labelSelector: (item) => (
      <div className="pointer-events-none flex w-64">
        {labelElementSelector(item)}
      </div>
    ),
    valueSelector: (item) => item.id?.toString(),
    getItem: (id) => {
      const value = client.readFragment({
        id: `${model}:${id}`,
        fragment,
        fragmentName: fragment.definitions[0].name.value,
      });
      if (!value) {
        throw new Error(`Item not found`);
      }
      return value;
    },
  });

  return select;
};

const ResourceSelect = ({
  placeholder,
  name,
  label,
  onChange,
  value,
  disabled,
  query,
  model,
  multi,
  limit,
  fragment,
  labelElementSelector,
  searchVariables,
}) => {
  const select = useResourceSelectState({
    name,
    onChange,
    value,
    query,
    model,
    fragment,
    label,
    labelElementSelector,
    searchVariables,
  });

  const searchFieldVisible = multi
    ? Boolean(!limit) || value.length < limit
    : value.length < 1;

  if (!searchFieldVisible) return null;

  return (
    <RemoteSelect
      state={select}
      disabled={disabled}
      aria-label={label}
      scale="lg"
      placeholder={placeholder}
    />
  );
};

export const ResourceDraggableField = memo(
  ({
    value,
    placeholder,
    name,
    label,
    onChange,
    limit,
    multi,
    disabled,
    query,
    model,
    fragment,
    searchVariables,
    labelSelector,
    labelElementSelector,
  }) => {
    const resourceIds = useMemo(() => value.map(({ id }) => id), [value]);

    const { data, loading, previousData } = useSafeQuery(query, {
      variables: {
        limit: 100,
        filters: {
          id: { in: resourceIds },
        },
      },
      skip: !value.length,
    });

    const resources = useMemo(() => {
      if (loading) return previousData?.connection.nodes ?? [];
      return data?.connection.nodes ?? [];
    }, [data, previousData, loading]);

    const items = useMemo(() => {
      return [...resources].sort(
        (a, b) => resourceIds.indexOf(a.id) - resourceIds.indexOf(b.id),
      );
    }, [resources, resourceIds]);

    const handleDeleteItem = useCallback(
      (contentIndex) => {
        onChange(value.filter((_, index) => index !== contentIndex));
      },
      [onChange, value],
    );

    const moveItem = useCallback(
      (from, to) => {
        onChange(moveItems(from, to, value));
      },
      [onChange, value],
    );

    const handleMoveItem = useCallback(
      (event) => {
        const { activeIndex, overIndex } = getIndices(value, event);
        if (activeIndex === overIndex) return;
        moveItem(activeIndex, overIndex);
      },
      [moveItem, value],
    );

    return (
      <>
        <DraggableCapsuleList
          disabled={disabled}
          items={items}
          limit={limit}
          onRemove={handleDeleteItem}
          onDragEnd={handleMoveItem}
          multi={multi}
          labelSelector={labelSelector}
        />
        <ResourceSelect
          placeholder={placeholder}
          name={name}
          label={label}
          onChange={onChange}
          value={value}
          disabled={disabled}
          query={query}
          model={model}
          multi={multi}
          limit={limit}
          fragment={fragment}
          searchVariables={searchVariables}
          labelElementSelector={labelElementSelector}
        />
      </>
    );
  },
);
ResourceDraggableField.name = "ResourceDraggableField";
