import {
  AllSelection,
  EditorState,
  Plugin,
  PluginKey,
  Transaction,
} from "@tiptap/pm/state";
import { DecorationSet } from "@tiptap/pm/view";

import { InvisibleCharactersOptions } from "./invisible-characters-extension";

export const InvisibleCharactersPluginKey =
  new PluginKey<InvisibleCharactersPluginState>("invisibleCharacters");

type InvisibleCharactersPluginState = {
  visible: boolean;
  decorations: DecorationSet;
};

export const InvisibleCharactersPlugin = (
  editorState: EditorState,
  options: InvisibleCharactersOptions,
) => {
  const empty = DecorationSet.create(editorState.doc, []);

  const createDecoration = (
    from: number,
    to: number,
    state: EditorState,
    decorations: DecorationSet,
  ) =>
    options.builders
      .sort((A, B) => (A.priority > B.priority ? 1 : -1))
      .reduce(
        (current, builder) =>
          builder.createDecoration(from, to, state.doc, current),
        decorations,
      );

  return new Plugin<InvisibleCharactersPluginState>({
    key: InvisibleCharactersPluginKey,
    state: {
      init: () => {
        const { $from, $to } = new AllSelection(editorState.doc);
        return {
          visible: options.visible,
          decorations: createDecoration(
            $from.pos,
            $to.pos,
            editorState,
            DecorationSet.empty,
          ),
        };
      },
      apply: (tr, value, _oldState, newState) => {
        const next = applyVisible(
          value,
          tr.getMeta("setInvisibleCharactersVisible"),
        );
        const decorations = getChangedRangesFromTransaction(tr).reduce(
          (decorations, [from, to]) =>
            createDecoration(from, to, newState, decorations),
          next.decorations.map(tr.mapping, tr.doc),
        );
        return { ...next, decorations };
      },
    },
    props: {
      decorations(state) {
        const visible = this.getState(state)?.visible;
        const decorations = this.getState(state)?.decorations;
        return visible ? decorations : empty;
      },
    },
  });
};

const applyVisible = <T extends { visible: boolean }>(
  state: T,
  visible?: boolean,
) => (visible === undefined ? state : { ...state, visible: visible });

const getChangedRangesFromTransaction = ({ mapping }: Transaction) => {
  const ranges: [number, number][] = [];
  mapping.maps.forEach((step, index) => {
    step.forEach((_oldStart, _oldEnd, newStart, newEnd) => {
      ranges.push([
        mapping.slice(index + 1).map(newStart),
        mapping.slice(index + 1).map(newEnd),
      ]);
    });
  });
  return ranges;
};
