import * as React from "react";

import { Button } from "./Button";
import { IoChevronBackOutline, IoChevronForward, IoClose } from "./Icon";
import { cn } from "./utils/classNames";
import { useId } from "./utils/hooks";

type PanelContextType = {
  titleId: string | undefined;
};

const PanelContext = React.createContext<PanelContextType | null>(null);

export const usePanelContext = () => {
  return React.useContext(PanelContext);
};

export type PanelProviderProps = {
  children?: React.ReactNode;
};

export const PanelProvider = (props: PanelProviderProps) => {
  const titleId = useId();
  const value = React.useMemo(() => ({ titleId }), [titleId]);
  return (
    <PanelContext.Provider value={value}>
      {props.children}
    </PanelContext.Provider>
  );
};

export type PanelProps = {
  className?: string;
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const WrappedPanel = React.forwardRef<HTMLDivElement, PanelProps>(
  ({ className, ...props }, ref) => {
    const context = usePanelContext();
    return (
      <div
        ref={ref}
        aria-labelledby={context?.titleId}
        className={cn(
          className,
          "flex flex-col overflow-hidden bg-white shadow-panel",
        )}
        {...props}
      />
    );
  },
);

export const Panel = React.forwardRef<HTMLDivElement, PanelProps>(
  (props, ref) => {
    return (
      <PanelProvider>
        <WrappedPanel ref={ref} {...props} />
      </PanelProvider>
    );
  },
);

export type PanelHeaderProps = {
  title: React.ReactNode;
  back?: React.ReactNode;
  tabs?: React.ReactNode;
  bordered?: boolean;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  onClose?: (() => void) | undefined;
};

export const PanelHeader = ({
  bordered = true,
  ...props
}: PanelHeaderProps) => {
  const context = usePanelContext();
  return (
    <div
      className={cn(
        "shrink-0 px-4 py-3",
        !props.tabs
          ? cn(bordered && "border-b border-b-grey-border-light")
          : "pb-0",
      )}
    >
      <div className="flex items-start gap-2">
        {props.back && (
          <div className="flex-grow text-sm text-grey-on">
            <Button
              appearance="text"
              variant="secondary"
              scale="sm"
              disabled={!props.onClose}
              onClick={props.onClose}
            >
              <IoChevronBackOutline />
              {props.back}
            </Button>
          </div>
        )}
        <div className="flex-grow">
          {props.tabs ? (
            <div
              id={context?.titleId}
              aria-label={props.title as string}
              className="mt-[0.3125rem]"
            >
              {props.tabs}
            </div>
          ) : (
            <h2
              id={context?.titleId}
              className="mt-[0.1875rem] font-accent text-base font-semibold leading-5"
            >
              {props.title}
            </h2>
          )}
        </div>
        <div
          className={cn(
            "flex shrink-0 items-center justify-end gap-2 self-center",
            props.back && "flex-grow",
            props.tabs && "self-start",
          )}
        >
          {props.actions}
          {!props.back && (
            <Button
              type="button"
              iconOnly
              appearance="text"
              variant="secondary"
              scale="sm"
              onClick={props.onClose}
              disabled={!props.onClose}
              aria-label="Fermer"
            >
              <IoClose />
            </Button>
          )}
        </div>
      </div>
      {props.children}
    </div>
  );
};

export type PanelBodyProps = {
  children?: React.ReactNode;
  asChild?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const PanelBody = React.forwardRef<HTMLDivElement, PanelBodyProps>(
  ({ className, asChild, children, ...props }, ref) => {
    const childProps = {
      ref,
      className: cn(
        className,
        "flex-grow overflow-y-auto break-words p-4 overflow-x-hidden scrollbar-light",
      ),
      ...props,
    };
    if (asChild) {
      return React.cloneElement(children as React.ReactElement, childProps);
    }
    return <div {...childProps}>{children}</div>;
  },
);

export type PanelFooterProps = {
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const PanelFooter = React.forwardRef<HTMLDivElement, PanelFooterProps>(
  ({ className, children, ...props }, ref) => {
    const childProps = {
      ref,
      className: cn(
        "p-4 shrink-0 border-t border-t-grey-border-light flex gap-4 justify-end",
        className,
      ),
      ...props,
    };
    return <div {...childProps}>{children}</div>;
  },
);

export type PanelSectionProps = {
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const PanelSection = React.forwardRef<HTMLDivElement, PanelSectionProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          className,
          "-mx-4 border-t border-t-grey-border-light p-4 first:-mt-4 first:border-t-0",
          props.role === "button" &&
            "group/panel-button transition hover:bg-grey-bg-hover-transparent",
        )}
        {...props}
      />
    );
  },
);

export type PanelSectionTitleProps = {
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const PanelSectionTitle = React.forwardRef<
  HTMLDivElement,
  PanelSectionTitleProps
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(className, "font-accent text-base font-semibold")}
      {...props}
    />
  );
});

export type PanelSectionHeaderProps = {
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export const PanelSectionHeader = React.forwardRef<
  HTMLDivElement,
  PanelSectionHeaderProps
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(className, "flex items-center justify-between")}
      {...props}
    />
  );
});

export type PanelSectionArrowProps = Record<"string", never>;

export const PanelSectionArrow = React.forwardRef<
  HTMLDivElement,
  PanelSectionArrowProps
>((_props, ref) => {
  return (
    <div
      ref={ref}
      className="pointer-events-none flex transition group-hover/panel-button:translate-x-1 group-hover/panel-button:text-primary-on-hover-light group-aria-disabled/panel-button:opacity-disabled"
    >
      <IoChevronForward />
    </div>
  );
});
